import { TDirection, EMPTY_DIRECTION } from "components/DUTI/direction-form";
import { TDirectionATIB } from "components/DUTI/direction-form-ATIB";
import { translate } from "utils/i18n";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import { validateNif } from "utils/validateNif";


export type TExtraValidation = {
  error:boolean, 
  error_msg: string | undefined
  componentIG?: string
}


type ObtainKeys<T, V> = keyof {
  [K in keyof T as T[K] extends V ? K : never]: any
}

/** SELECTORES DIRECCION */
//export type TSelectExtended = TSelect & { param?: any}
export type TSelect = {id:string, nombre: string}
export function formatSelectOptions<T>(
    idName: keyof T,
    textName: keyof T,
    list: T[]
  ) {
    const out: TSelect[] = list.map((item: T) => ({
      id: String(item[idName]) ,
      nombre: String(item[textName])
    }));
    return out;
  }

export type TSelectDUTI = {id:number,code: string, nombre: string}

export function formatSelectDUTIOptions<T>(
    idName: keyof T,
    codeName: keyof T,
    textName: keyof T,
    list: T[]
) {
  const out: TSelectDUTI[] = list.map((item: T) => ({
    id: Number(item[idName]) ,
    code: String(item[codeName]) ,
    nombre: String(item[textName])
  }));
  return out;
}

interface IGenericObject {[key: string]: any;}


// Transforma TDireccion a texto
export const direccionToText = (dir: TDirection | TDirectionATIB, terms:any) => {
  const itemsRow1: (string|number)[]= []
  const itemsRow2= []
  if(dir !== undefined){
    itemsRow1.push(dir.tipoVia? dir.tipoVia.id : '')
    itemsRow1.push((dir.nombreVia ?? '').concat(', '))
    itemsRow1.push(dir.numero ?? '')
    itemsRow1.push(dir.piso ? translate('DUTI', 'piso',terms).concat(`: ${dir.piso}`) : '')
    itemsRow1.push(dir.puerta ? translate('DUTI', 'puerta',terms).concat(`: ${dir.puerta}`) : '')
    itemsRow1.push(dir.escalera ? translate('DUTI', 'escalera',terms).concat(`: ${dir.escalera}`) : '')
    itemsRow1.push(dir.letra ? translate('DUTI', 'letra',terms).concat(`: ${dir.letra}`) : '')

    itemsRow1.push(dir.cp ? translate('DUTI', 'CP',terms).concat(`: ${dir.cp}`) : '')
    itemsRow1.push( dir.municipio?.nombre ? ', '.concat(dir.municipio.nombre) : '')
    itemsRow1.push( dir.provincia?.nombre ? ', '.concat(dir.provincia.nombre) : '')

    const r1 = itemsRow1.join(' ')
    //const r2 = itemsRow2.join(' ')
    return r1 //[r1,r2]. join(`\n`)
  } else {
    return ''
  }
}
// Transforma direccion del catastro al objeto TDirection
export const REGEX_CP = new RegExp(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/) // CP españa 01000 - 52999

const REGEX_NUM = new RegExp(/^[0-9]{1,3}$/) // solo numeros y longitud 3 => 999
const REGEX_Letters = new RegExp(/^[A-Z]+$/i) // solo letras

//FEGA.gob.es => Referencia Catastral, Texto(20), \d{7}[A-Z]{2}\d{4}[A-Z]\d{4}[A-Z]{2}|\d{5}[A-Z]\d{12}[A-Z]{2},
// Formato Referencia Catastral 1: 7 dígitos, 2 letras, 4 dígitos, Letra, 4 dígitos, 2 letras
// Formato Referencia Catastral 2: 5 dígitos, Letras, 12 dígitos, Letra,  2 letras
// Diseminados: formato en base a ejemplo 000201400DD89C0001KT (ALARÓ)
export const REGEX_REF_CATASTRAL_1 = new RegExp(/^\d{7}[A-Z]{2}\d{4}[A-Z]\d{4}[A-Z]{2}$/)
export const REGEX_REF_CATASTRAL_2 = new RegExp(/^\d{5}[A-Z]\d{12}[A-Z]{2}$/)
export const REGEX_REF_CATASTRAL_DISEMINADOS = new RegExp(/^\d{9}[A-Z]{2}\d{2}[A-Z]\d{4}[A-Z]{2}$/)

export type AuxDirFormater = { municipio: TSelectDUTI[],provincia: TSelect[],tipoVia: TSelect[] }
export const domicilioCatastroToDireccion = (dir: string, aux: AuxDirFormater) => {
    const dir_split = dir.split(' ')
    console.log('domicilioCatastroToDireccion => ', dir)

    const numIdx = dir_split.findIndex( s => REGEX_NUM.test(s))
    console.log('numero: ',dir_split[numIdx])
    const cpIdx = dir_split.findIndex( s => REGEX_CP.test(s))
    console.log('cp: ',dir_split[cpIdx])
    const pisoIdx = dir_split.findIndex( s => s.includes('Pl:') )
    const piso = dir_split[pisoIdx].replace('Pl:', '')
    const puertaIdx = dir_split.findIndex( s => s.includes('Pt:') )
    const puerta = dir_split[puertaIdx].replace('Pt:', '') 

    const provInitIdx = dir_split.findIndex( s => s.includes('(') )
    const provEndIdx = dir_split.findIndex( s => s.includes(')') )
    const prov = provInitIdx === provEndIdx 
        ? dir_split[provInitIdx].replace('(','') 
        : [ dir_split[provInitIdx].replace('(',''), dir_split[provEndIdx].replace(')','') ].join(' ')
    const provincia = aux.provincia.find( p => p.nombre.toUpperCase()  === prov.toUpperCase() )
   

    const muni = dir_split.slice(cpIdx+1,provInitIdx).join(' ')
    const municipio = aux.municipio.find( m => m.nombre.toUpperCase() === muni.toUpperCase())
    const calle = dir_split.slice(1,numIdx ?? pisoIdx).filter( d => REGEX_Letters.test(d))
    const sigla = dir_split[0]
    const tipoVia= aux.tipoVia.find( t=> t.id.toUpperCase() === sigla.toUpperCase()  || t.id.toUpperCase() .includes(sigla.toUpperCase() ))

    const out: TDirection = {...EMPTY_DIRECTION,
        cp: cpIdx ? dir_split[cpIdx]: undefined,
        numero: dir_split[numIdx] ? Number(dir_split[numIdx]): undefined,
        tipoVia: tipoVia,
        provincia: provincia,
        municipio: municipio,
        nombreVia: calle.join(' '),
        piso: piso,
        puerta: puerta
    }

    console.log('Formated => ', out)

    return out
}



export const calcularEdad = (p_fechaNacimiento: Date) => {
  const today = new Date();
  const fechaNacimiento= new Date(p_fechaNacimiento);
  //Restamos los años
  const año = fechaNacimiento.getFullYear()
  let años = today.getFullYear() - año;
  // Si no ha llegado su cumpleaños le restamos el año por cumplir
  if (fechaNacimiento.getMonth() > (today.getMonth()) || fechaNacimiento.getDate() > today.getDate()){ 
    años--
  };
  return años
}

// Formatear numero con separador de miles => STRING
export const formatNumStr = ( value: number ): string => {
  const formatedNum= value.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFractionDigits:0})
  return formatedNum
}


export const nifValidate = (nif: string) => {
    const isValid = validateNif(nif)
    let result: TExtraValidation = { 
        error: isValid ? false: true, 
        error_msg: 'nif_error_format',
        componentIG: 'GenericComponents'
    }
    return result
}