import React, { useState, FC, useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, WithStyles } from "@material-ui/core"
import { translate } from "utils/i18n";
import { renderConfirmDUSTI } from "containers/DUTI/utils";
import { LiteralsContext } from "containers/shared/literals";
import CheckBox from "./DUTI/check-box";

interface IProps {
    open: boolean;
    renderContent: () => JSX.Element
    onSave: () => void
    onClose: () => void
}
const ModalConformidad : FC<IProps>= ({open,  renderContent, onSave, onClose}) => {
    const terms = useContext(LiteralsContext);
    const [conforme, setConforme] = useState(false)
    const [conformeError, setConformeError] = useState(false)
    useEffect(() => {
        if(open){ 
            setConforme(false); 
            setConformeError(false) 
        }
    }, [open])

    return (
        <Dialog open={open} maxWidth='lg'>
                <DialogTitle>
                    <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>
                        {translate("Global", "UstedEnviaLaSiguienteInformacion", terms)}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding:10, display: 'flex', flexGrow:1, overflowX: 'hidden', overflowY:'auto', backgroundColor: '#e0eaf4', minHeight: 150, maxHeight: 700}}>
                        {renderContent()}
                    </div>
                    <div style={{paddingLeft: 10}}>
                        <Typography style={{marginTop: 20, marginBottom: 15}} >
                            {translate("Global","UstedEnviaLaSiguienteInformacionReglamento", terms)}
                        </Typography>
                        <Typography style={{marginBottom: 10}} >
                            {translate("Global","UstedEnviaLaSiguienteInformacionConformidad",terms)}
                        </Typography>
                        <Typography color='error' style={{minHeight: 45}}>
                            { conformeError ? translate('Global', 'required_conforme', terms): ''}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions >
                    <Grid container direction='row' alignItems="center" justify="space-between" style={{paddingRight: 20, paddingLeft: 20}}>
                        <Grid item >
                            <CheckBox 
                                value={conforme} 
                                componentIG={"Global"}
                                fieldTerm={'conforme'} 
                                onChangeValue={ (v:boolean) => {
                                    setConforme(v); 
                                    setConformeError(false)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" style={{marginRight: 10}} 
                                onClick={() => {
                                    setConformeError(false);
                                    setConforme(false)
                                    onClose()
                                }
                            }>
                                {translate('Global','Cancelar', terms)}
                            </Button>
                            <Button variant="contained"  color="primary" onClick={() => { 
                                if(conforme){ 
                                    onSave()
                                    onClose()
                                } else {
                                    setConformeError(true)
                                }
                            }}>
                                {translate('Global','Continuar', terms)}
                            </Button>
                        </Grid>
                    </Grid>
                    

                </DialogActions>
            </Dialog>
    )
}

export default ModalConformidad;