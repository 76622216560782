import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { createValidationError } from "utils/response";
import { ISujeto } from "./perfil.interfaces";
import { IResponseCallback } from "./response-callback";
import { IProvincia } from "./provincia.interface";
import { IMunicipio } from "./municipio.interface";
import { ISiglas } from "./siglas.interface";
import { IPoblacion } from "./poblacion.interface";
import { ISolicitudCodigoContribuyente, ISolicitudCallback } from "./solicitudes.interfaces";
import { IPais } from "./pais.interface";
import { ICalle } from "./calle.interface";
import { IDireccionData } from "./direccion.interfaces";

@inject('axios')
export class SujetosGateway {

    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async changePassword(newPassword: string) {
        try {
            const response = await this.axios.post(
                'sujetos/update-password', {
                newPassword
            }
            );
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getProvincias(idPais: string = '00'): Promise<IProvincia[]> {
        try {
            const response = await this.axios.get(
                'geo/provincias', {
                params: { idPais }
            }
            );
            return await response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }

    }

    public async getPaises(): Promise<IPais[]> {
        try {
            const response = await this.axios.get(
                'geo/paises'
            );
            return response.data.map((p: IPais) => ({...p, nombre: p.nombre.toUpperCase()}));
        } catch (e) {
            throw createValidationError(e as any);
        }

    }

    public async getMunicipios(idProvincia: string): Promise<IMunicipio[]> {
        try {
            const response = await this.axios.get(
                'geo/municipios', {
                params: { idProvincia }
            });
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getPoblacion(idProvincia: string, idMunicipio: string): Promise<IPoblacion[]> {
        try {
            const response = await this.axios.get(
                'geo/poblaciones', {
                params: { idProvincia, idMunicipio }
            }
            );
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async validateCP(idPoblacion: string, cp: string): Promise<boolean> {
        try {
            const response = await this.axios.get(
                'geo/validar-cp', {
                params: { idPoblacion, cp }
            }
            );
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getSiglas(): Promise<ISiglas[]> {
        try {
            const response = await this.axios.get(
                'geo/siglas'
            );
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async sendEmail(email: string): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/send-email?email=' + email,
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async saveEmail(email: string): Promise<IResponseCallback> {
        try {
            const response = await this.axios.post(
                'sujetos/save-email', {
                email
            }
            );
            return response;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    // tslint:disable-next-line: variable-name
    public async saveNumber(number: string): Promise<IResponseCallback> {
        try {
            const response = await this.axios.post(
                'sujetos/save-phone', {
                phone: number
            }
            );
            return response;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async validatePhone(code: string, phone: string): Promise<boolean> {
        try {

            const response = await this.axios.post(
                'sujetos/validate-phone', {
                code,
                constraint: phone
            }
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async validateEmail(code: string): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/validate-email', {
                code,
            }
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async registerEmailNotify(activar: boolean): Promise<IResponseCallback> {
        try {
            const response = await this.axios.post(
                'sujetos/notifications-mail', {
                activar
            }
            );
            return response;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async registerMovilNotify(activar: boolean): Promise<IResponseCallback> {
        try {
            const response = await this.axios.post(
                'sujetos/notifications-sms', {
                activar
            }
            );
            return response;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async changeLanguage(language: string): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/update-idioma', {
                idioma: language
            }
            );

            return response.data;
        } catch (error) {
            return false;
        }
    }

    public async sendCodigoContribuyente(solicitud: ISolicitudCodigoContribuyente, key: string): Promise<ISolicitudCallback> {
        try {
            const dto = { ...solicitud, code: key }
            const response = await this.axios.post(
                'sujetos/codigo-contribuyente', dto
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async sendMailSolicitudRecuperarContrasenya(nif: string, mail: string) {
        try {
            const response = await this.axios.post(
                'sujetos/send-new-password', {
                nif,
                mail
            }
            );
            return response;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async getDatosSujeto(): Promise<ISujeto | null> {
        try {
            const response = await this.axios.get(
                'sujetos/datos-sujeto'
            );

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            if (response.data) {
                const datosSujeto = response.data;
                const sujeto: ISujeto = {
                    nombre: datosSujeto.nombre,
                    nif: datosSujeto.nif,
                    direccionNotificacion: datosSujeto.direccionNotificacion,
                    direccionNotificacionString: datosSujeto.direccionNotificacionString,
                    email: datosSujeto.email,
                    movil: datosSujeto.movil,
                    emailNotificacion: datosSujeto.email,
                    movilNotificacion: datosSujeto.movil,
                    idiomaEnvioAvisos: datosSujeto.idiomaEnvioAvisos !== null ? datosSujeto.idiomaEnvioAvisos : 'E',
                    notificacion: datosSujeto.adheridoNotificaciones,
                    notificacionPendienteConfirmacion: datosSujeto.notificacionPendienteConfirmacion,
                    bajaNotificacionPendienteConfirmacion: datosSujeto.bajaNotificacionPendienteConfirmacion,
                    modNotificacionPendienteConfirmacion: datosSujeto.modNotificacionPendienteConfirmacion,
                    activarAvisos: datosSujeto.activarAvisos,
                    adhesionDeOficio: response.data.adhesionDeOficio,
                }
                return sujeto;
            }

            return null;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async verificarDatos(sujeto: ISujeto): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/verificar-datos', sujeto
            );

            if (response.status !== 201 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async sendDirectionPhone(sujeto: ISujeto): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/send-direction-phone',
                sujeto,
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async sendDirectionEmail(sujeto: ISujeto): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/send-direction-email',
                sujeto,
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async validateDirectionPhone(code: string, phone: string): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'sujetos/validate-direction-phone', {
                code,
                constraint: phone,
            }
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async validateDirectionEmail(code: string): Promise<any> {
        try {
            const response = await this.axios.post(
                'sujetos/validate-direction-email', {
                code,
            }
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async getCallejero(idPoblacion: string): Promise<ICalle[]> {
        try {
            const response = await this.axios.get(
                'sujetos/getCallejero?idPoblacion=' + idPoblacion,
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async getCallejeroByMuni(codMuni: string): Promise<ICalle[]> {
        try {
            const response = await this.axios.get(
                'sujetos/getCallejeroByMuni?codMuni=' + codMuni,
            );
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }


    public async getFechaValidacionDatos(): Promise<Date | null> {
        try {
            const response = await this.axios.get(
                'sujetos/fecha-validacion'
            );
            return response.data;
        }
        catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async getMesesExpiracionValidacionDatosSujeto(): Promise<number> {
        try {
            const response = await this.axios.get(
                'sujetos/meses-expiracion-validacion-sujeto'
            );
            return response.data;
        }
        catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async validarDatosSujeto(): Promise<boolean> {
        try {
            const response = await this.axios.put(
                'sujetos/update-fecha-validacion'
            );
            return response.data;
        }
        catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async updateDatosSujeto(direccion: IDireccionData, email: string, phone: string): Promise<boolean> {
        try {
            const direccionTemp = {
                idDireccion: direccion.idDireccion,
                idProvincia: direccion.provincia,
                idMunicipio: direccion.municipio,
                poblacion: direccion.poblacion,
                idSigla: direccion.siglas,
                calle: direccion.calle,
                numero: direccion.numero,
                km: direccion.km,
                codigoPostal: direccion.codigoPostal,
                bloque: direccion.bloque,
                escalera: direccion.escalera,
                portal: direccion.portal,
                piso: direccion.piso,
                puerta: direccion.puerta,
                toponimia: direccion.toponimia,
            };

            const response = await this.axios.put(
                'sujetos/update-datos', {
                direccionTemp,
                email,
                phone
            }
            );

            return response.data;
        }
        catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async recuperarJustificanteVerificacionDatos() {
        try {
            const response = await this.axios.get("sujetos/justificante-verificacion-datos");
            return response.data;
        }
        catch (error) {
            throw createValidationError(error as any);
        }
    }
}
