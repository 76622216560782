import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, CircularProgress, Grid, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import { translate } from "utils/i18n";
import { isCIF, validateNif } from "utils/validateNif";
import { validateCP } from "utils/validateCP";
import ContactForm, { ALL_CONTACTO_DISABLED, EMPTY_CONTACTO, EMPTY_CONTACTO_ERRORs, TContacto, TContactoFlags } from "../../DUTI/contact-form";
import DirectionFormATIB, { ALL_DIRECTION_DISABLED, EMPTY_DIRECTION, EMPTY_DIRECTION_ERRORs, TDirectionATIB, TDirectionFlags } from "../../DUTI/direction-form-ATIB";
import InputText from "components/DUTI/input-text";
import { TExtraValidation } from "components/DUTI/types";
import usePage from "hooks/page.hook";
import IoC from "contexts/ioc.context";
import { ContenidosGateway } from "gateways/contenido.gateway";
import DUTIGateway from "gateways/duti.gateway";

import { TSujeto } from "containers/tributos-autonomicos-locales/componentesAsistente/shared/types";
import { TInfoTemplateSize } from "contexts/more_info.reducer";


export interface AdditionalInfoRenderProps<T> {
    esPersFisica: boolean;
    showRequireds:boolean;
    initialValues:T | undefined;
    onChange: (v:T|undefined) => void
    reset: boolean
    onReset: (v:boolean) => void
    renderType: 'modal'| 'form' |'detail';
    moreInfoNumOp?: {
        template: string, size?: TInfoTemplateSize
    }
}

interface IProps<T=undefined> extends WithStyles{
    renderType: 'modal'| 'form' |'detail'; // Tipos de render: modal (formulario es contenido de una modal), form (es un componente formulario) y detail (es formulario deshabilitado)
    action: 'new'|'edit'; // Indica si es un item nuevo o es edición
    allowChargeLogged: boolean // Habilita Botón para cargar info de sujeto Logueado 
    allowChargeRepresentadoLoggued: boolean // Habilita Botón para cargar info de sujeto representado sujeto Logueado 
    allowChargeRepresentante?:boolean// Habilita Botón para cargar info del representante
    currentInListNIFs: string[] // valida que el nif del formulario no esté en el listado actual
    repeatedMsgErr: string // msgError nif en el listado actual
    notAllowedNIFs: string[] // valida que el nif del formulario no esté en el listado de NO permitidos
    notAllowedNIFsMsgErr: string // msgError motivo por el que no esta el nif permitido
    initialInfo: TSujeto|undefined, 
    onSave: (action: 'new' | 'edit', item: TSujeto, additionalInfo?:any) => {ok: boolean, msgErr: undefined|string}
    onEdit: () => void
    onEnd: () => void
    additionalInfo:T
    AdditionalInfoRender?: React.FC<AdditionalInfoRenderProps<T>>
    validateAditionalInfo?: (info?: T) => {err: boolean, msg: string}
}

const SujetoForm  = <T extends {}> ({
    classes, 
    renderType,
    action,
    initialInfo,
    allowChargeLogged, 
    allowChargeRepresentadoLoggued,
    allowChargeRepresentante,
    currentInListNIFs, repeatedMsgErr,
    notAllowedNIFs,
    notAllowedNIFsMsgErr,
    onSave, onEdit, onEnd,
    additionalInfo, AdditionalInfoRender, validateAditionalInfo
}: IProps<T>) => {

    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [pageState, pageDispatcher] = usePage();
    const componentMounted = useRef(true);
    
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    
    // Datos del formualario transmitente comprador/vendedor
    const [localNif, setLocalNif] = useState<string|undefined>(undefined);
    const [localNifErr, setLocalNifErr] = useState(false);
    const [localPersFisica, setLocalPersFisica] = useState<boolean>(false);

    const [localApeNom, setLocalApeNom] = useState<string|undefined>(undefined);
    const [localApeNomErr, setLocalApeNomErr] = useState(false);
    const [localDir, setLocalDir] = useState<TDirectionATIB>({...EMPTY_DIRECTION});
    const [localDirErrors, setLocalDirErrors] = useState<TDirectionFlags>({...EMPTY_DIRECTION_ERRORs});
    const [localContact, setLocalContact] = useState<TContacto>({...EMPTY_CONTACTO});
    const [localContactErrors, setLocalContactErrors] = useState<TContactoFlags>({...EMPTY_CONTACTO_ERRORs});

    const [localAdditionalInfo,setLocalAdditionalInfo]=useState<T|undefined>(undefined)
    const [localShowRequiredsAdditional, setLocalShowRequiredsAdditional]=useState(false);
    const [localResetAdditional, setLocalResetAdditional] = useState(false)



    const [sujLogged, setSujLogged]=useState<TSujeto | null>(null);
    const [sujRepreLogged, setSujRepreLogged]=useState<TSujeto | null>(null);
    const [sujRepresentanteLogged, setSujRepresentanteLogged]=useState<TSujeto | null>(null);

    //En caso de ser ususario logged o RepreLogged => disabled NIF y NomApe
    const [isAnyLoggedSuje, setIsAnyLoggedSuj] = useState<boolean>(false);


    const [loading, setLoading] = useState<boolean>(false);


    const resetLocalErorsData = () => {
        setLocalNifErr(false); 
        setLocalApeNomErr(false);
        setLocalDirErrors({...EMPTY_DIRECTION_ERRORs});
        setLocalContactErrors({...EMPTY_CONTACTO_ERRORs});
    }
    // Data undefined if RESET info
    const onChargeLocalData = (data?: TSujeto ) => {
        setLocalNif(data?.nif ?? undefined);
        setLocalApeNom(data?.nomApe ?? undefined);
        setLocalDir(data?.direccion ?? {...EMPTY_DIRECTION});
        setLocalContact(data?.contacto ?? {...EMPTY_CONTACTO});
        setLocalPersFisica(data?.personaFisica ?? false);
    }
    const resetLocalDataAndErrors = () => {
        onChargeLocalData()
        resetLocalErorsData()
        setLocalResetAdditional(true)
    }

    //Validaciones 
    const field_validateNIF = (nif: string) => {
        const isValid = validateNif(nif)
        setLocalPersFisica(isCIF(nif) ? false : true)
        setLocalNifErr(!isValid)
        let result: TExtraValidation = { 
            error: isValid ? false: true, 
            error_msg: translate('DUTI', 'nif_error_format',terms)
        }
        return result
    }

    const onValidateSujeto = () => {
        console.log('onValidateSujeto - ',validateAditionalInfo && validateAditionalInfo(localAdditionalInfo))
        let err: boolean=false;
        let errNotAllowed = false
        let errRepeated = false
        let newItem: TSujeto = {
            nif: localNif ?? '',
            nomApe: localApeNom ?? '',
            personaFisica: localPersFisica,
            direccion: {...localDir},
            contacto: {...localContact},
        }

        // validaciones Direccion
        let newError={...localDirErrors}
        const cpErr =( newItem.direccion.cp === undefined|| !newItem.direccion.cp )
            ? {error: true, msg:'required_err'} 
            : validateCP(newItem.direccion.cp, newItem.direccion.pais.id,newItem.direccion.provincia?.id)
        newError = {
            ...newError,
            cp: cpErr.error,
            provincia: newItem.direccion.provincia === undefined,
            municipio: newItem.direccion.municipio === undefined,
            nombreVia: newItem.direccion.nombreVia === undefined|| !newItem.direccion.nombreVia,
            numero: newItem.direccion.numero === undefined|| !newItem.direccion.numero,
            tipoVia: newItem.direccion.tipoVia === undefined || !newItem.direccion.tipoVia.id,
            pais: newItem.direccion.pais === undefined || !newItem.direccion.pais.id
        }
       
        err = Object.values(newError).includes(true)
        const formatNif = field_validateNIF(newItem.nif) 

        // Validaciones comunes
        if( newItem.nomApe && newItem.nomApe !== '' && 
            newItem.nif  && newItem.nif !== '' && !formatNif.error
            && !err
        ){
            // Validar que no sea NIF repetido en caso de multiple
            // TODO  Revisar si es edicion - debe existir - ver porque no se muestra botón cancelar
            if(action === 'new' && currentInListNIFs && currentInListNIFs.includes(newItem.nif)){
                errRepeated= true
            }
            if(notAllowedNIFs && notAllowedNIFs.includes(newItem.nif)){
                errNotAllowed = true
            }

        } else {
            err=true
            setLocalApeNomErr(newItem.nomApe === '')
            setLocalNifErr(newItem.nif === '' || formatNif.error)
            setLocalDirErrors( d => ({...newError}))
            setLocalShowRequiredsAdditional(true)
        }
        let errAdditional = {err: false, msg: ''}
        if(AdditionalInfoRender && validateAditionalInfo){
            errAdditional= validateAditionalInfo(localAdditionalInfo)
        }
        if(!err && !errNotAllowed && !errRepeated &&  !errAdditional.err){
            // is valid
            const {ok, msgErr} = onSave(action, newItem, localAdditionalInfo)
            console.log()
            if(ok){
                onEnd() 
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: msgErr,
                        variant: 'error',
                    }
                })
            }
        } else {
            if(errNotAllowed){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: notAllowedNIFsMsgErr,
                        variant: 'error',
                    }
                })
            } else if(errRepeated){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: repeatedMsgErr,
                        variant: 'error',
                    }
                })
            }else if(errAdditional.err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: errAdditional.msg,
                        variant: 'error',
                    }
                })
            } else{
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global','err_check_required_fields',terms),
                        variant: 'error',
                    }
                })
            }


        }
    }
    

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                let loggedS: TSujeto|null= null
                let loggedR: TSujeto|null= null
                
                if(allowChargeLogged || allowChargeRepresentadoLoggued){
                    if(allowChargeLogged && pageState.jwp && pageState.jwp.nif !== undefined){
                        if(pageState.jwp.representante && pageState.jwp.nifRepresentante){
                            // El sujeto logueado es el representante
                            const suj = await dutiGateway.getSujeto(pageState.jwp.nifRepresentante)
                            if(suj){
                                loggedS  = {
                                    contacto: suj.contacto,
                                    direccion: {...suj.direccion, pais: {id: suj.direccion.pais.id, nombre: suj.direccion.pais.nombre.trim()}},
                                    nif: suj.nif,
                                    nomApe: suj.nomApe,
                                    personaFisica: suj.personaFisica,
                                }
                            }
                        } else {
                            const suj = await dutiGateway.getSujeto(pageState.jwp.nif)
                            if(suj){
                                loggedS  = {
                                    contacto: suj.contacto,
                                    direccion: {...suj.direccion, pais: {id: suj.direccion.pais.id, nombre: suj.direccion.pais.nombre.trim()}},
                                    nif: suj.nif,
                                    nomApe: suj.nomApe,
                                    personaFisica: suj.personaFisica,
                                }
                            }
                        }
                        
                    }
                }
                if( (allowChargeRepresentadoLoggued || allowChargeRepresentante) 
                    && pageState.jwp && pageState.jwp.nif !== undefined && pageState.jwp.representante && pageState.jwp.nifRepresentante
                ){
                    if(allowChargeRepresentadoLoggued){
                        const suj = await dutiGateway.getSujeto(pageState.jwp.nif)
                        if(suj){
                            loggedS  = {
                                contacto: suj.contacto,
                                direccion: {...suj.direccion, pais: {id: suj.direccion.pais.id, nombre: suj.direccion.pais.nombre.trim()}},
                                nif: suj.nif,
                                nomApe: suj.nomApe,
                                personaFisica: suj.personaFisica,
                            }
                        }
                    }
                    if(allowChargeRepresentante){
                        const sujR = await dutiGateway.getSujeto(pageState.jwp.nifRepresentante)
                        if (sujR) {
                            loggedR ={
                                contacto: sujR.contacto,
                                direccion: {...sujR.direccion, pais: {id: sujR.direccion.pais.id, nombre: sujR.direccion.pais.nombre.trim()}},
                                nif: sujR.nif,
                                nomApe: sujR.nomApe,
                                personaFisica: sujR.personaFisica,
                            }
                        } 
                    }
                }

                if(componentMounted.current){
                    //setters
                    setSujLogged(loggedS);
                    setSujRepreLogged(loggedS);
                    setSujRepresentanteLogged(loggedR)
                    if(additionalInfo){
                        setLocalAdditionalInfo(additionalInfo ? {...additionalInfo}: undefined)
                    }
                    
                }

            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: error,
                        variant: "error"
                    }
                });
            } finally {
                setLoading(false)
            }
        })();
        
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }

    }, [pageState.jwp])

    useEffect(()=> {
        if(initialInfo){
            onChargeLocalData(initialInfo)
            if((sujLogged && initialInfo.nif === sujLogged.nif) ||(sujRepreLogged && initialInfo.nif === sujRepreLogged.nif)){
                setIsAnyLoggedSuj(true)
            }
        }
        if(additionalInfo){
            setLocalAdditionalInfo(additionalInfo)
        }
        setLocalShowRequiredsAdditional(false)
    },[initialInfo, additionalInfo])


    return (
        (loading)
        ? <CircularProgress/>
        :
        <Grid container direction="column" style={{marginLeft:0}}>
            {/** Botones Cargar Suj/Repre o BorrarDatos (if not isModal) */}
            {renderType !== 'detail' && 
                <Grid item container direction="row" spacing={2} style={{marginBottom:10}}>
                    {(allowChargeLogged || allowChargeRepresentadoLoggued) && 
                        <Grid item>
                            <Button variant="contained" color='primary'
                                disabled={sujLogged === null}
                                onClick={()=> {
                                    if(sujLogged){
                                        onChargeLocalData(sujLogged)
                                        setIsAnyLoggedSuj(true)
                                    }}}
                            >
                                {translate('Global', allowChargeLogged? 'chargeUserLogged' : 'chargeUserRepreLogged', terms)}
                            </Button>
                        </Grid>
                    }
                    {allowChargeRepresentante && 
                        <Grid item>
                            <Button variant="contained" color='primary'
                                disabled={sujRepreLogged === null}
                                onClick={ ()=> {
                                    if(sujRepreLogged){
                                        onChargeLocalData(sujRepreLogged)
                                        setIsAnyLoggedSuj(true)
                                    }
                                } }
                            >
                                {translate('Global', 'chargeUserRepresentante', terms)}
                            </Button>
                        </Grid>
                    }
                    
                    <Grid item>
                        <Button 
                            variant="contained" color="inherit"
                            onClick={() => {
                                resetLocalDataAndErrors()
                                setIsAnyLoggedSuj(false)
                            }}
                        >
                            {translate('Global', 'cleanInforForm', terms)}
                        </Button>
                    </Grid>
                </Grid>
            }
            

            <Grid item>
                <div className={classes.row} style={{flexWrap: 'wrap'}} >
                    <InputText key='nif'
                        fieldTerm='nif'
                        value={localNif}
                        disabled={isAnyLoggedSuje || renderType === 'detail'}
                        onChangeValue={(v:string|undefined, err: boolean) => {
                            setLocalNif(v)
                            if(v && !err){
                                setLocalPersFisica(isCIF(v) ? false : true)
                            }
                            setLocalNifErr(err)
                        }}
                        required={true}
                        forceUpperCase={true}
                        extraValidation={field_validateNIF}
                        error={localNifErr}
                    />

                    <InputText key='nomApe'
                        fieldTerm='nomApeRazonSoc'
                        value={localApeNom}
                        disabled={isAnyLoggedSuje || renderType === 'detail'}
                        onChangeValue={(v:string|undefined, err: boolean) => {
                            setLocalApeNom(v)
                            setLocalApeNomErr(err)
                        }}
                        required
                        error={localApeNomErr}
                        className={classes.input360}
                        forceUpperCase={true}
                    />

                </div>

                <DirectionFormATIB key='direccion'
                    direction={localDir}
                    setDirection={setLocalDir} 
                    dirErrors={localDirErrors} 
                    setDirErrors={setLocalDirErrors} 
                    disabled={renderType === 'detail' ? (ALL_DIRECTION_DISABLED as TDirectionFlags) : undefined}
                />
                
                <ContactForm key='contacto'
                    contact={localContact} 
                    setContact={setLocalContact} 
                    contactErrors={localContactErrors} 
                    setContactErrors={setLocalContactErrors}
                    disabled={renderType === 'detail' ? (ALL_CONTACTO_DISABLED as TContactoFlags) : undefined}
                />
            </Grid>
            {AdditionalInfoRender !== undefined && 
                <AdditionalInfoRender renderType={renderType}
                    showRequireds = {localShowRequiredsAdditional}
                    esPersFisica = {localPersFisica}
                    initialValues={localAdditionalInfo} 
                    onChange={(v) =>{ 
                        setLocalAdditionalInfo(v)
                    }} 
                    reset={localResetAdditional}
                    onReset={(isDone:boolean) => setLocalResetAdditional(!isDone)}
                    moreInfoNumOp={{template: 'plusvalias_info_field_numOperaciones', size: 'xs'}}
                />
            }
  
            {/** Botón/es Save y Cancel (if isModal) */}
            {renderType === 'detail' 
                ?
                    <Grid item>
                        <Button 
                            color="primary"
                            variant="contained"
                            onClick={onEdit} 
                        >
                            {translate('Global', 'btnEdit', terms)}
                        </Button>
                    </Grid>
                :
                <Grid item container direction="row" justify="flex-end" spacing={2}style={{marginBottom: 5}} >
                    {(renderType === "modal" || (renderType === "form" && action==="edit"))&&
                        <Grid item>
                            <Button 
                                color="inherit"
                                variant="contained"
                                onClick={() => {
                                    resetLocalDataAndErrors();
                                    onEnd();
                                }}
                            >
                                {translate('Global', 'Cancelar', terms)}
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button 
                            color="primary"
                            variant="contained"
                            onClick={()=> {
                                setLocalShowRequiredsAdditional(true)
                                onValidateSujeto()
                            }}
                            
                        >
                            {translate('Global', 'btnSave', terms)}
                        </Button>
                    </Grid>
                </Grid>
            }
        </Grid>
            
    )
}

const styles =  (theme: Theme): StyleRules => ({
    column: {
        display: 'flex',
        flexDirection:  'column',
        alignItems: 'center',
        justifyItems: 'flex-start'
    },
    row:{
        display:' flex',
        flexDirection:'row', 
        alignItems: 'center',
    },
    input360: { // mediano: nombre
        width: 360
    },

})

export default withLiterals(['DUTI','Global'])(withStyles(styles)(SujetoForm));
