import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";

import { mdiAlertCircleOutline, mdiEyeCircleOutline, mdiFileCertificateOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";

import duti_styles from "../styles";
import { BLUE } from "containers/shared/colors";
import { LiteralsContext } from "containers/shared/literals";
import { DocumentsUtils } from "utils/documents";
import { translate } from "utils/i18n";
import { TSelect } from "../utils";

import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";

import { 
    TDTInfoTransmision, TipoDocTransmision_OPTIONS, TipoTransmision_OPTIONS, TipoDerechoTransmision_OPTIONS, 
    TCausaDatosTransmInfoTransm, TTipoTransmisionDerecho, EMPTY_DT_INFOTRANS_PLUSV 
} from "./types";
import { TExtraValidation } from "components/DUTI/types";

import  DUTIGateway  from "gateways/duti.gateway";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { IDocumentoAtib, IOficina } from "gateways/duti.interface";

import FullLoading from "components/full-loading";
import LabelWithInfo from "components/DUTI/label-with-info";
import InputDate from "components/DUTI/input-date";
import InputSelect from "components/DUTI/input-select";
import TypedInputNumber from "components/DUTI/typed-input-number";
import InputText from "components/DUTI/input-text";
import InputSearch from "components/DUTI/input-search";
import ModalNotarios from "components/DUTI/modal-notarios";
import ModalNrd from "components/DUTI/modal-nrd";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";


const useStyles = makeStyles(duti_styles);
type TInfoTransmisionFlags = Record<keyof TDTInfoTransmision, boolean>

const EMPTY_ERRORS : TInfoTransmisionFlags = {
    withPlusval: false,
    refCatastral: false,
    fecha: false,
    tipoTransmision: false,
    derechoTransmision: false,
    tipoDocTransmision: false,
    anyo: false,
    nifNotario: false,
    notario: false,
    plusval: false,
    numeroProtocolo: false,    
    nrd: false,
    codMuni: false,
    porcentaje: false,
    valorDeclarado: false,
    idDocumentoEscritura: false
}
interface IDTInfoTransmision {
    idDeclaracion : number;
    codMuni: string,
    refCatastral: string,
    valorRef: string|undefined,
    withPlusval: boolean,
    initialDTInfoTransmision: TDTInfoTransmision,
    //setChanges: () => void,
    //setDTInfoTransmision: (v:TDTInfoTransmision) => void,
    handleContinuar: (v:TDTInfoTransmision,haveChanges:boolean,notClean: boolean) => void
    handleBack: () => void,
    handleCancelDUTI: (c: TCausaDatosTransmInfoTransm, v:TDTInfoTransmision) => void,
    setShouldBlock: (v: boolean) => void,
}
const DatosTransmisionInfoTransmision: FC<IDTInfoTransmision> = ({ 
    idDeclaracion, codMuni,
    refCatastral, withPlusval,
    initialDTInfoTransmision, 
    handleContinuar, handleBack,
    handleCancelDUTI, setShouldBlock,
}) => {
    const terms = useContext(LiteralsContext);
    const classes= useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    
    const [loading, setLoading] = useState(false);
    const [msgLoading, setMsgLoading] = useState<string | undefined>(undefined);

    const [DTInfoTransmision, setDTInfoTransmision]= useState({} as TDTInfoTransmision)

    const [errors, setErrors] = useState<TInfoTransmisionFlags>(EMPTY_ERRORS)


    const [oficinas, setOficinas]= useState<IOficina[]>([])
    const [oficina, setOficina]= useState<TSelect|undefined>(undefined)


    const [showMsgYaExiste,setShowMsgYaExiste] = useState(false);
    const [MSGEndDUSTIyaExiste,setMSGEndDUSTIyaExiste] = useState<string>('');
    const [MSGEscritura,setMSGEscritura] = useState<{si:string, no:string}>({si: '', no:''});
    const [MSGValorDeclarado, setMSGValorDeclarado] = useState<string>('');
    const [valorRefLocal, setValorRefLocal]= useState(0)
    const [showModalEscritura, setShowModalEscritura]= useState(false);
    const [, setIsValid]= useState(false)
    const [localEscritura, setLocalEscritura] = useState<IDocumentoAtib |'notFound' |undefined>(undefined);
    //const [notariosList, setNotariosList] = useState<INotario[]>([]);
    
    const [showModalNotarios, setShowModalNotarios]= useState(false)
    const [haveChanges, setHaveChanges] = useState(false)
    const [existTramiteMotivoAnulacion, setExistTramiteMotivoAnulacion]= useState<'porcentaje-plusval-excedido' | 'escritura-presentada' |undefined>(undefined)

    const [showModalNRD, setShowModalNRD] = useState(false)
    const [NRD, setNRD] = useState<string|undefined>(undefined)
    const [showCheckNRD, setShowCheckNRD] = useState(false)
    const [NRDResponse, setNRDResponse] = useState<{ok:boolean, msg:string} | undefined>(undefined);


    
    /**Format SELECT options */
    const fieldTipoTransmision_OPTIONS = TipoTransmision_OPTIONS.map( opt => ({id: opt, nombre: translate('DUTI', opt,terms)}) as TSelect)
    const fieldTipoDocTransmision_OPTIONS = TipoDocTransmision_OPTIONS.map( opt => ({id: opt, nombre: translate('DUTI', opt,terms)}) as TSelect)
    const fieldDerechoTransmision_OPTIONS = TipoDerechoTransmision_OPTIONS.map( opt => ({id: opt, nombre: translate('DUTI', opt,terms)}) as TSelect)

    const componentMounted = useRef(true)

    const checkNRD = async () => {
        try {
            if(NRD){
                setLoading(true);
                const result = await dutiGateway.checkNRD(DTInfoTransmision.fecha, DTInfoTransmision.anyo, DTInfoTransmision.numeroProtocolo ?? "", DTInfoTransmision.nifNotario ?? "", NRD);
                if(result === false){
                    setNRD(undefined);
                    setLocalEscritura('notFound');
                    setDTInfoTransmision(curr => ({...curr, nrd: undefined}));
                    setNRDResponse( { ok: false, msg: translate('DUTI', 'check_nrd_not_valid', terms)});
                } else {
                    setHaveChanges(true);
                    setDTInfoTransmision(curr => ({...curr, nrd: NRD}));
                    setNRDResponse( { ok: true, msg: ''});
                }
                return result;
            } else {
                return false;
            }
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const validateNumProtocolo = ( num: string) => {
        let result: TExtraValidation={ error: false, error_msg: undefined}
        const numN = Number(num)
        if(isNaN(numN)){
            result={error: true, error_msg: translate('DUTI', 'numeroProtocolo_error_num',terms)}
        } else{
            if(numN > 99999){
                result={error: true, error_msg: translate('DUTI', 'numeroProtocolo_error',terms)}
            }
        }
        
       return result
    }

    const validateFieldsAndTramites=  async () => {
        try {
            setLoading(true)
            let err = false
            // Validar campos
            if( !Object.values(errors).includes(true) &&
                DTInfoTransmision.fecha && DTInfoTransmision.anyo && DTInfoTransmision.numeroProtocolo
                && DTInfoTransmision.refCatastral !== '' &&  DTInfoTransmision.codMuni !== '' 
                && DTInfoTransmision.derechoTransmision 
                && DTInfoTransmision.nifNotario && DTInfoTransmision.nifNotario.length>0
                && DTInfoTransmision.notario && DTInfoTransmision.notario.length>0
                && DTInfoTransmision.porcentaje>0 && DTInfoTransmision.valorDeclarado>0
            ){ 
                try {
                    setMsgLoading(translate('DUTI', 'verificando_no_tramites_activos', terms))
                    const exist = await dutiGateway.checkIfExistTramite(
                        idDeclaracion,
                        DTInfoTransmision.anyo,
                        DTInfoTransmision.nifNotario,
                        DTInfoTransmision.numeroProtocolo,
                        DTInfoTransmision.codMuni ,
                        DTInfoTransmision.derechoTransmision.nombre as TTipoTransmisionDerecho,
                        DTInfoTransmision.refCatastral, 
                        DTInfoTransmision.fecha,
                        DTInfoTransmision.porcentaje
                    );
                    //console.log('exist', exist)
                    if(exist.ok === false){                        
                        err=true;
                        setShowMsgYaExiste(true);
                        setExistTramiteMotivoAnulacion(exist.descripcion);
                    } else{
                        if(!localEscritura){
                            
                            //console.log('buscar escr')
                            setMsgLoading(translate('DUTI', 'buscando_escritura', terms))
                            // buscar escritura
                            const escritura = await dutiGateway.getEscrituraPublica(
                                DTInfoTransmision.fecha, 
                                DTInfoTransmision.anyo,
                                DTInfoTransmision.numeroProtocolo, 
                                DTInfoTransmision.nifNotario
                            )
                            if(escritura && escritura.idDocumento){
                                setLocalEscritura(escritura) 
                                setNRD(escritura.NRD)
                                setHaveChanges(true)
                                setDTInfoTransmision(curr => ({...curr,idDocumentoEscritura: escritura.idDocumento, nrd: undefined}))
                            } else {
                                err=true
                                setLocalEscritura('notFound')
                                setNRD(undefined)
                            }
                            setShowModalEscritura(true)
                        } 
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                }
            } else {
                err=true
                setErrors(curr => ({...curr,
                    fecha: DTInfoTransmision.fecha || errors.fecha ? false: true,
                    anyo: DTInfoTransmision.anyo || errors.anyo? false : true,
                    numeroProtocolo: DTInfoTransmision.numeroProtocolo || errors.numeroProtocolo ? false: true,
                    valorDeclarado:DTInfoTransmision.valorDeclarado>0 || errors.valorDeclarado ? false: true,
                    porcentaje: DTInfoTransmision.porcentaje>0  || errors.porcentaje?false: true,
                    notario: ((DTInfoTransmision.notario && DTInfoTransmision.notario.length> 0) || (DTInfoTransmision.nifNotario && DTInfoTransmision.nifNotario.length>0)) ? false : true
                }))
            }
        
            setLoading(false)
            return err ? false : true

        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'can_not_check_tramites_activos', terms),
                    variant: 'error',
                }
            });
            return false
        }

    }
    
    const onContinuar = async() => {
        const infoOk = await validateFieldsAndTramites()
        if(infoOk){
            if(localEscritura){
                if(valorRefLocal > 0 && valorRefLocal > DTInfoTransmision.valorDeclarado){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: MSGValorDeclarado,
                            isHtml: true,
                            variant: 'warning',
                            hasCustomAction: true,
                            defaultCloseActionTemplateName: translate("DUTI", "btn_close", terms).toUpperCase(),
                            actionTemplateName: translate("DUTI", "btn_continuar", terms).toUpperCase(),
                            handleCustomAction: () => { 
                                handleContinuar({...DTInfoTransmision}, haveChanges, false)
                                alertsDispatch({type: 'hide-alert'}) 
                            }
                        }
                    });

                } else {
                    localEscritura!== undefined && handleContinuar({...DTInfoTransmision}, haveChanges, false)
                }
            } else {
                setMsgLoading(translate('DUTI', 'buscando_escritura', terms))
                setNRDResponse(undefined)
                // buscar escritura
                const escritura = await dutiGateway.getEscrituraPublica(
                    DTInfoTransmision.fecha, 
                    DTInfoTransmision.anyo,
                    DTInfoTransmision.numeroProtocolo, 
                    DTInfoTransmision.nifNotario,
                )
                if(escritura && escritura.idDocumento){
                    setLocalEscritura(escritura);
                    setHaveChanges(true);
                    setDTInfoTransmision(curr => ({...curr, idDocumentoEscritura: escritura.idDocumento, nrd: undefined}))
                } else {
                    //err=true
                    setLocalEscritura('notFound');
                }
                setShowModalEscritura(true);
            }
        }

    }

    const showDoc = async() => {
        try {
            setLoading(true)
            DocumentsUtils.downloadDoc(DTInfoTransmision.idDocumentoEscritura);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'can_not_show_escritura_publica', terms),
                    variant: 'error',
                }
            });
        }
    }

   const consultaValorRef = async (fecha: Date | undefined) => {        
        if(fecha && fecha instanceof Date && !isNaN(fecha.getTime())){
            //console.log('consultaValorRef', fecha);
            const valRef = await dutiGateway.getValorReferencia(refCatastral, fecha);
            setValorRefLocal(valRef ?? 0);
        }
   }

    useEffect(() => {
        // inicializamos componente
        (async () => {
            setLoading(true);

            try {
                setHaveChanges(false);
                const MSGExist = await contenidoGateway.getContent('info_modal_ya_existe_datos_transmision', {});
                const MSGNoEscritura = await contenidoGateway.getContent('info_modal_escritura_no_encontrada', {});
                const MSGSiEscritura = await contenidoGateway.getContent( 'info_modal_escritura_encontrada', {});
                const MSGValorDecl = await contenidoGateway.getContent( 'info_modal_valorReferencia_Declarado', {});
                

                const oficinas = await dutiGateway.getOficinas();
                const escritura = await dutiGateway.getDocAtib(initialDTInfoTransmision.idDocumentoEscritura);
                console.log('escr ', escritura)
                if(componentMounted.current){
                    if(initialDTInfoTransmision.plusval?.oficina){
                        const ofiFound = oficinas.find(ofi => ofi.Descrip === initialDTInfoTransmision.plusval?.oficina);
                        setOficina(ofiFound ? {id: ofiFound.Codigo.toString(), nombre: ofiFound.Descrip}: undefined);
                    }
                    if(initialDTInfoTransmision.idDocumentoEscritura !== "" && !localEscritura){
                        if(escritura && escritura.idDocumento){ 
                            setIsValid(true); 
                        }
                        setLocalEscritura(escritura);
                        setNRD(escritura.NRD)
                    }
                    setDTInfoTransmision({
                        ...initialDTInfoTransmision, 
                        codMuni: codMuni, 
                        refCatastral: refCatastral,
                        withPlusval: withPlusval,
                        plusval: withPlusval && initialDTInfoTransmision.plusval ? initialDTInfoTransmision.plusval : {...EMPTY_DT_INFOTRANS_PLUSV},
                    });


                    setOficinas(oficinas);
                    setMSGEndDUSTIyaExiste(MSGExist[0].contenido);
                    setMSGEscritura({
                        si: MSGSiEscritura[0].contenido,
                        no: MSGNoEscritura[0].contenido,
                    });
                    setMSGValorDeclarado(MSGValorDecl[0].contenido);
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [])

    useEffect(() => {
        if(initialDTInfoTransmision.plusval?.oficina){
            const ofiFound = oficinas.find(ofi => ofi.Descrip === initialDTInfoTransmision.plusval?.oficina)
            setOficina(ofiFound && ofiFound.Codigo ? {id: ofiFound.Codigo.toString(), nombre: ofiFound.Descrip}: undefined)
        }
           
    },[oficinas])



    return (
        <Grid container direction='column' className={classes.componenteContainer}>
            <FullLoading loading={ loading } msg={msgLoading} animation={{type:'intricom', name:'DUSTI'}} />       
                   
            <Grid item container  direction='column'className={classes.columnContainer}>
                <LabelWithInfo type="title" fieldTerm="DT_IntroDatosDUTI_InfoTransmision_title"  moreInfo= {{template: 'info_title_DT_IntroDatosDUTI_InfoTransmision_title', size: 'xs'}}/> 
                <div style={{flex:1, flexGrow:1, overflowY: 'auto'}}>
                    <Grid item container direction="row" alignItems="center">
                        <Grid item xs={6} md={3} >
                            <InputDate 
                                fieldTerm="fecha"
                                value={DTInfoTransmision.fecha}
                                onChangeValue={(v: Date| undefined, err:boolean) =>{ 
                                    setDTInfoTransmision(curr => ({...curr, fecha: v}))
                                    consultaValorRef(v)
                                    setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges(true)
                                    setLocalEscritura(undefined);
                                    setErrors(prev => ({...prev, fecha: err}))
                                }}
                                maxDate={ new Date() }
                                disabled={false}
                                required={true}
                                error={errors.fecha}
                                className={classes.inputFullWidth}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <InputSelect 
                                fieldTerm='tipoTransmision'
                                value={DTInfoTransmision.tipoTransmision}
                                options={fieldTipoTransmision_OPTIONS}
                                onChangeValue={(v: TSelect, err: boolean) => {
                                    setDTInfoTransmision(curr => ({...curr, tipoTransmision: v}))
                                    //setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges( initialDTInfoTransmision.tipoTransmision=== undefined)
                                    setErrors(prev => ({...prev, tipoTransmision: err }))
                                }}
                                disabled
                                required
                                className={classes.inputFullWidth}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <InputSelect 
                                fieldTerm='derechoTransmision'
                                value={DTInfoTransmision.derechoTransmision}
                                options={fieldDerechoTransmision_OPTIONS}
                                onChangeValue={(v: TSelect, err: boolean) => {
                                    setDTInfoTransmision(curr => ({...curr, derechoTransmision: v}))
                                    //setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges( initialDTInfoTransmision.derechoTransmision=== undefined)
                                    setErrors(prev => ({...prev, derechoTransmision: err }))
                                }}
                                disabled
                                required
                                className={classes.inputFullWidth}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <InputSelect 
                                fieldTerm='tipoDocTransmision'
                                value={DTInfoTransmision.tipoDocTransmision}
                                options={fieldTipoDocTransmision_OPTIONS}
                                onChangeValue={(v: TSelect, err: boolean) => {
                                    setDTInfoTransmision(curr => ({...curr, tipoDocTransmision: v}))
                                    //setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges( initialDTInfoTransmision.tipoDocTransmision=== undefined)
                                    setErrors(prev => ({...prev, tipoDocTransmision: err }))
                                }}
                                disabled
                                required
                                className={classes.inputFullWidth}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container direction="row" alignItems="center">
                        <Grid item xs={6} md={3}>
                            <TypedInputNumber
                                type="year"
                                fieldTerm={'anyo'}
                                value={DTInfoTransmision.anyo}
                                onChangeValue={(v:number|undefined, err:boolean) =>{ 
                                    setDTInfoTransmision(curr => ({...curr, anyo: v}))
                                    setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges(true)
                                    setLocalEscritura(undefined);
                                    setErrors(prev => ({...prev, anyo:err}))
                                }}
                                error={errors.anyo}
                                required
                                className={classes.inputFullWidth}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                        <InputText
                            fieldTerm={'numeroProtocolo'}
                            value={DTInfoTransmision.numeroProtocolo}
                            onChangeValue={(v:string|undefined, err: boolean) =>{ 
                                setDTInfoTransmision(curr => ({...curr, numeroProtocolo: v}))
                                setIsValid(curr => false)
                                setShowMsgYaExiste(false)
                                setHaveChanges(true)
                                setLocalEscritura(undefined);
                                setErrors(prev => ({...prev, numeroProtocolo:err}))
                            }}
                            required={true}
                            error={errors.numeroProtocolo}
                            extraValidation={validateNumProtocolo}
                            className={classes.inputFullWidth}
                        />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InputSearch
                                fieldTerm='notario'
                                value={DTInfoTransmision.notario}
                                required
                                disabledOnlyInput={true}
                                error={errors.notario}
                                errorMsgExterno= {errors.notario ? translate('DUTI','error_required', terms) : undefined}
                                onChangeValue={ (v:string|undefined, err: boolean) => {}}
                                onSearch={ () => setShowModalNotarios(true)}
                                className={classes.inputFullWidth} 
                            />
                        </Grid>

                    </Grid>

                    <Grid item container direction="row" alignItems="flex-start">
                        <Grid item xs={6} md={3}>
                            <TypedInputNumber 
                                type="percentaje"
                                allowZero={false}
                                maxDecimal={2}
                                fieldTerm={'porcentajeTransmision'}
                                value={DTInfoTransmision.porcentaje}
                                onChangeValue={(v: number, err: boolean) => {
                                    setDTInfoTransmision(curr => ({...curr, porcentaje: v}))
                                    setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges(true)
                                    setErrors(prev => ({...prev,porcentaje:err}))
                                }}
                                required={true} 
                                error={errors.porcentaje}
                                className={classes.inputFullWidth}
                                moreInfo={{
                                    template: 'info_field_porcentajeTransmision'
                                }}
                            /> 
                        </Grid>
                        <Grid item xs={6}  md={3}>
                            <TypedInputNumber
                                type="money"
                                allowZero={false}
                                min={0}
                                fieldTerm={'valorDeclarado'}
                                value={DTInfoTransmision.valorDeclarado}
                                onChangeValue={(v: number|undefined, err: boolean) => {
                                    setDTInfoTransmision(curr => ({...curr, valorDeclarado: v ?? 0}))
                                    setIsValid(curr => false)
                                    setShowMsgYaExiste(false)
                                    setHaveChanges(true)
                                    setErrors(prev => ({...prev,valorDeclarado:err}))
                                }}
                                required={true} 
                                error={errors.valorDeclarado}
                                className={classes.inputFullWidth}
                                moreInfo={{
                                    template: 'info_field_valorDeclarado'
                                }}
                            /> 
                        </Grid>
                        <Grid item xs={6} md={6} style={{textAlign:'right'}}>
                            <Button style={{ marginRight: 10}}
                                variant="outlined" color="primary" 
                                onClick={() => {
                                    setNRDResponse(undefined)
                                    setShowModalNRD(true)
                                }}
                            >
                                {translate('DUTI','btn_search_nrd',terms)}
                            </Button>

                        </Grid>
                        
                        
                    </Grid>

                    { DTInfoTransmision.withPlusval &&
                        <Grid item container direction="row" alignItems="center" className={classes.borderPlusval} style={{marginTop: 30}}>
                            <div className={classes.divTextBorderPlusval}>
                                <Typography className={classes.textBorderPlusval}>{translate('DUTI','title_fields_plusval',terms)}</Typography>
                            </div>                       
                            <Grid item  xs={6} md={3}>
                                <InputText
                                    className={classes.inputFullWidth}
                                    fieldTerm='tomo'
                                    value={DTInfoTransmision.plusval.tomo}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setDTInfoTransmision(curr => ({...curr, plusval: {...DTInfoTransmision.plusval, tomo: v}}))
                                        setHaveChanges(true)
                                    }}
                                />
                            </Grid>
                            <Grid item  xs={6} md={3}>
                                <InputText
                                    className={classes.inputFullWidth}
                                    fieldTerm='libro'
                                    value={DTInfoTransmision.plusval.libro}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setDTInfoTransmision(curr => ({...curr,  plusval: {...DTInfoTransmision.plusval, libro: v}}))
                                        setHaveChanges(true)
                                    }}
                                />
                            </Grid>
                            <Grid item  xs={6} md={3}>
                                <InputText
                                    className={classes.inputFullWidth}
                                    fieldTerm='folio'
                                    value={DTInfoTransmision.plusval.folio}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setDTInfoTransmision(curr => ({...curr,  plusval: {...DTInfoTransmision.plusval, folio: v}}))
                                        setHaveChanges(true)
                                    }}
                                />
                            </Grid>
                            <Grid item  xs={6} md={3}>
                                <InputText
                                    className={classes.inputFullWidth}
                                    fieldTerm='finca'
                                    value={DTInfoTransmision.plusval.finca}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setDTInfoTransmision(curr => ({...curr, plusval: {...DTInfoTransmision.plusval, finca: v}}))
                                        setHaveChanges(true)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputSelect 
                                className={classes.inputFullWidth}
                                    fieldTerm='oficina'
                                    value={oficina}
                                    options={oficinas.map(ofi => ({id: ofi.Codigo, nombre: ofi.Descrip}))}
                                    onChangeValue={(v: TSelect | undefined, err: boolean) => {
                                        setOficina(v)
                                        setDTInfoTransmision(curr => ({...curr, plusval: {...DTInfoTransmision.plusval, oficina: v?.nombre ?? undefined} }))
                                        setHaveChanges(true)
                                    }}
                                    disabled={ oficinas.length === 0}
                                />
                            </Grid>
                        </Grid>
                    }

                    {localEscritura !== undefined && 
                        <div style={{marginTop:25}} >
                            { localEscritura !== 'notFound' ? 
                                <div className={classes.borderPlusval} >
                                    <div className={classes.divTextBorderPlusval}>
                                        <Typography className={classes.textBorderPlusval}>{translate('DUTI','title_box_escrituraPubl',terms)}</Typography>
                                    </div> 
                                    <div className={classes.row}>
                                        <div style={{marginRight: 20}}>
                                            <Icon path={mdiFileCertificateOutline} color={BLUE} style={{alignSelf:'flex-start'}} size={3} />
                                        </div>
                                        <div className={classes.column}>
                                            <Typography variant="body1" style={{}}>{translate('DUTI','idDocumentoEscritura_found',terms)} <Icon path={mdiEyeCircleOutline} size={1}/></Typography>
                                            <div className={classes.row} style={{alignItems: 'center', minHeight: 48}}>
                                                <Typography variant="body2" style={{marginRight: 30, fontWeight: 'bolder'}}>{translate('DUTI','idDocumentoEscritura',terms).toUpperCase()}</Typography>
                                                <Typography variant="body1" style={{marginRight: 20}}>{localEscritura.NombreFichero}</Typography>
                                                <IconButton 
                                                    onClick={async() => {
                                                        if(NRD){ await showDoc() }
                                                        else { setShowCheckNRD(true) }
                                                    }}
                                                >
                                                    <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            <div className={classes.textCancelDusti}>
                                <div style={{marginRight: 10}}>
                                    <Icon path={mdiAlertCircleOutline} color={'#ef1111'} style={{alignSelf:'flex-start'}} size={4} />
                                </div>
                                <div className={classes.row} style={{alignItems: 'center', minHeight: 48}}>
                                    <Typography style={{fontWeight:'bolder'}}>{translate('DUTI','idDocumentoEscritura_notFound',terms).toUpperCase()}</Typography>
                                    <div style={{marginLeft: 5}}>
                                        <Icon path={mdiFileCertificateOutline} style={{alignSelf:'flex-start'}} size={1} />
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                        
                    }

                    { showMsgYaExiste && 
                        <div className={classes.textCancelDusti}>
                            <div style={{marginRight: 10}}>
                                <Icon path={mdiAlertCircleOutline} color={'#ef1111'} style={{alignSelf:'flex-start'}} size={4} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: MSGEndDUSTIyaExiste }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                        </div>
                    }
                </div>
            </Grid>

            <Grid item container direction="row" justify="space-between" alignItems="flex-end" className={classes.footerColumnContainer}>
                <Button variant="contained" onClick={handleBack} color="inherit">
                    {translate('DUTI', 'btn_back',terms)}
                </Button>
                { localEscritura === 'notFound'  || showMsgYaExiste
                    ?
                        <div style={{alignSelf:'flex-end'}}>
                            <Button 
                                variant="contained" 
                                onClick={() =>( 
                                    handleCancelDUTI( 
                                        localEscritura === 'notFound' 
                                        ? 'escritura-no-encontrada' 
                                        : existTramiteMotivoAnulacion as TCausaDatosTransmInfoTransm, {...DTInfoTransmision})
                                )}
                                className={classes.btnCancelDusti}
                            >
                                {translate('DUTI', 'btn_exit_duti',terms)}
                            </Button>
                        </div>
                    : 
                        <div >
                            <Button style={{marginRight: 10}}
                                variant="outlined" color="primary"
                                onClick={() => {
                                    handleCancelDUTI('continuar-mas-tarde', {...DTInfoTransmision})
                                    // Modal que muestra el useBlocker - DutiComponent
                                    // alertsDispatch({
                                    //     type: 'show-alert',
                                    //     payload: {
                                    //         message: translate('DUTI', 'confirm_cancelDUTI_user_choice', terms),
                                    //         variant: 'warning',
                                    //         hasCustomAction: true,
                                    //         defaultCloseActionTemplateName: translate("DUTI", "no", terms).toUpperCase(),
                                    //         actionTemplateName: translate("DUTI", "si", terms).toUpperCase(),
                                    //         handleCustomAction: () => { 
                                    //             handleCancelDUTI('continuar-mas-tarde', {...DTInfoTransmision})
                                    //             alertsDispatch({type: 'hide-alert'}) 
                                    //         }
                                    //     }
                                    // });
                                }} 
                                
                            >
                                {translate('DUTI', 'btn_pause_duti',terms)}
                            </Button>
                            <Button 
                                style={{marginRight: 10}}
                                variant="contained"  color="primary"
                                onClick={async() =>{ onContinuar()}}
                            >
                                {translate('DUTI', 'btn_continuar',terms)}
                            </Button>
                        </div>
                }
            </Grid>
            
            <ModalNrd
                show={showModalNRD && !loading}
                currentInfoTransmision={DTInfoTransmision}
                onConfirm={(escr: IDocumentoAtib|undefined, info: TDTInfoTransmision, nrd:string) => {
                    setLocalEscritura(escr)
                    setDTInfoTransmision({...info})
                    setShowModalNRD(false)
                    setNRD(nrd)
                }}
                onClose={() =>{ 
                    setShowModalNRD(false)
                }} 
            />

            <ModalNotarios
                show={showModalNotarios}
                currentNotario={DTInfoTransmision.notario}
                currentNifNotario={DTInfoTransmision.nifNotario}
                onSelected= { (nifNotario: string, notario: string) => { 
                    setDTInfoTransmision(curr => ({...curr, nifNotario, notario}))
                    setErrors(curr => ({...curr, nifNotario:false, notario:false}))
                    setLocalEscritura(undefined)
                    setHaveChanges(true) 
                    setIsValid(curr => false)
                    setShowModalNotarios(false)
                }}          
                onClose={() =>{ 
                    setShowModalNotarios(false)
                }}         
            />


            {/** Modal Informativa - MSG Escritura encontrada  */}
            <Dialog open={showModalEscritura}>
                <DialogTitle>
                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <Typography style={{fontSize: 25, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'DT_docTransmision_title', terms)}</Typography>
                     </div>
                </DialogTitle>
                <DialogContent>
                    {DTInfoTransmision.idDocumentoEscritura === '' || localEscritura === undefined ||  localEscritura === 'notFound' 
                     ?  <>
                            <div dangerouslySetInnerHTML={{ __html: MSGEscritura.no }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                            {NRDResponse !== undefined && 
                                <Typography color="error">{NRDResponse.msg}</Typography>
                            }
                        </>
                     : 
                        <>
                            <div dangerouslySetInnerHTML={{ __html: MSGEscritura.si }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                                <Typography>{translate('DUTI','nrd_to_see_escritura',terms)}</Typography>
                                <InputSearch
                                    fieldTerm='NRD'
                                    value={NRD}
                                    onChangeValue={(v: string|undefined, err: boolean) => {
                                        setNRD(v)
                                    }}
                                    onSearch={checkNRD}
                                    required={false}
                                />
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                        <Button
                            variant="contained" color="primary" 
                            onClick={() =>{ 
                                setShowModalEscritura(false)
                            }} 
                        >
                            {translate('DUTI', 'btn_close',terms)}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog> 

            {/** Modal CheckNRD - MSG Escritura encontrada  */}
            <Dialog open={showCheckNRD}>
                <DialogTitle>
                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <Typography style={{fontSize: 25, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'DT_docTransmision_title', terms)}</Typography>
                     </div>
                </DialogTitle>
                <DialogContent>
                    <Typography>{translate('DUTI','nrd_to_see_escritura',terms)}</Typography>
                    <InputSearch
                        fieldTerm='NRD'
                        value={NRD}
                        onChangeValue={(v: string|undefined, err: boolean) => {
                            setNRD(v)
                        }}
                        onSearch={() => { 
                            (async() => {
                                const checked = await checkNRD()
                                console.log('checked' ,checked)
                                if(checked){ 
                                    showDoc()
                                    setShowCheckNRD(false)
                                }
                            })()
                        }}
                        required={false}
                    />
                    {NRDResponse && NRDResponse.msg !== ''&&
                        <Typography color="error">{NRDResponse?.msg}</Typography>
                    }

                </DialogContent>
                <DialogActions>
                    <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                        <Button
                            variant="contained" color="primary" 
                            onClick={() =>{ 
                                setShowCheckNRD(false)
                            }} 
                        >
                            {translate('DUTI', 'btn_close',terms)}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog> 

        </Grid>
    )
}

export default DatosTransmisionInfoTransmision;

