import React from 'react';
import { WithStyles, withStyles, Typography, Theme, makeStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import Term from './term';

const styles = (theme: Theme): StyleRules => ({
    footerContainer: {
        marginTop: '2rem',
        marginBottom: '1rem',
        textAlign: 'center',
        color: '#000',
        fontSize: 13,
    },
    legalWarnLink: {
        color: '#000'
    },
    footerWrapper: {
        bottom: 0,
    },
    footerCalculated: {
        width: 'calc(100% - 300px)',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    }
});


interface IProps extends WithStyles<typeof styles> {
    footerCalculated?: boolean,
}

const PageFooter: React.FC<IProps> = (props) => {
    const { classes, footerCalculated } = props;
    return (

        <div className={!footerCalculated ? classes.footerWrapper : classes.footerCalculated}>
            <footer className={classes.footerContainer}>
                
                <Typography>
                    <a href="https://www.atib.es" className={classes.legalWarnLink} rel="noopener noreferrer" target="_blank">© Agència Tributària de les Illes Balears</a>
                </Typography>
                <div style={{display:'inline-flex'}}>
                    <Typography>
                        <a href="./contenidos/aviso-legal" className={classes.legalWarnLink}><Term component="Global" text="avisoLegal" /></a>
                    </Typography>
                    <Typography className={classes.legalWarnLink} style={{marginRight:10, marginLeft:10}}>|</Typography>
                    <Typography>
                        <a href="./mapa-web" className={classes.legalWarnLink}><Term component="Global" text="MapaWeb" /></a>
                    </Typography>
                </div>
                
            </footer>
        </div>
    );
}

export default withStyles(styles)(PageFooter);
