import { IPersonaTransmitente, TPasoDatosTransmision } from "containers/DUTI/steps/types";
import moment from "moment";
import { translate } from "utils/i18n";
import { TBorradorModelo600 } from "./modelo_600";
import { TBorradorModelo081, TBorradorModelo080, TBorradorModelo082} from "./modelo_08x";
import { calcularEdad, formatNumStr } from "containers/DUTI/utils";
import { inRange } from "lodash";

  
export const extraerBorradorM600 = (datos: TPasoDatosTransmision, terms: any) => {
  return ({
    M600_0: [
        {term: 'concepto', value: datos.datosOperacionModelo600.concepto?.nombre.split('-')[0] ?? undefined, casilla: 1},
        {term: 'codigo', value: translate('DUTI','borrador_ofi_central_100',terms), casilla: 82},
        {term: 'fechaDevengo', value: moment(datos.infoTransmision.fecha).format('DD/MM/YYYY') ,casilla: 2},
        {term: 'sujetosPasivos', value: datos.datosComprador.length ,casilla:83 },
        {term: 'transmitentes', value: datos.datosVendedor.length ,casilla: 84 },
    ],
    M600_A:[
        {term: 'MXX_nif', value:datos.datosComprador[0].nif ,casilla:5 },
        {term: 'MXX_nomApeRazonSoc', value: datos.datosComprador[0].nomApe,casilla: 6},
        {term: 'MXX_siglas', value: datos.datosComprador[0].direccion.tipoVia?.id ,casilla: 7},
        {term: 'MXX_nombreVia', value: datos.datosComprador[0].direccion.nombreVia,casilla: 8},
        {term: 'MXX_numero', value: datos.datosComprador[0].direccion.numero,casilla: 9},
        {term: 'MXX_letra', value: datos.datosComprador[0].direccion.letra,casilla: 10},
        {term: 'MXX_escalera', value: datos.datosComprador[0].direccion.escalera,casilla: 11},
        {term: 'MXX_piso', value: datos.datosComprador[0].direccion.piso,casilla: 12},
        {term: 'MXX_puerta', value:datos.datosComprador[0].direccion.puerta ,casilla:13},
        {term: 'MXX_telefono', value:datos.datosComprador[0].contacto.telefono ,casilla: 14},
        {term: 'MXX_email', value:datos.datosComprador[0].contacto.email ,casilla: 15},
        {term: 'MXX_municipio', value:datos.datosComprador[0].direccion.municipio?.nombre ,casilla: 16},
        {term: 'MXX_provincia', value:datos.datosComprador[0].direccion.provincia?.nombre ,casilla: 17},
        {term: 'MXX_cp', value: datos.datosComprador[0].direccion.cp,casilla: 18}
    ],
    M600_B:[
        {term: 'MXX_nif', value:datos.datosVendedor[0].nif ,casilla: 19},
        {term: 'MXX_nomApeRazonSoc', value: datos.datosVendedor[0].nomApe,casilla: 20},
        {term: 'MXX_siglas', value: datos.datosVendedor[0].direccion.tipoVia?.id,casilla: 21},
        {term: 'MXX_nombreVia', value: datos.datosVendedor[0].direccion.nombreVia,casilla: 22},
        {term: 'MXX_numero', value: datos.datosVendedor[0].direccion.numero,casilla: 23},
        {term: 'MXX_letra', value: datos.datosVendedor[0].direccion.letra,casilla: 24},
        {term: 'MXX_escalera', value: datos.datosVendedor[0].direccion.escalera,casilla:25 },
        {term: 'MXX_piso', value: datos.datosVendedor[0].direccion.piso,casilla: 26},
        {term: 'MXX_puerta', value:datos.datosVendedor[0].direccion.puerta ,casilla: 27},
        {term: 'MXX_telefono', value:datos.datosVendedor[0].contacto.telefono ,casilla: 28},
        {term: 'MXX_email', value:datos.datosVendedor[0].contacto.email,casilla: 29},
        {term: 'MXX_municipio', value:datos.datosVendedor[0].direccion.municipio?.nombre ,casilla: 30},
        {term: 'MXX_provincia', value:datos.datosVendedor[0].direccion.provincia?.nombre ,casilla: 31},
        {term: 'MXX_cp', value: datos.datosVendedor[0].direccion.cp,casilla: 32}
    ], 
    M600_C: [
        {term: 'MXX_nif', value:datos.datosPrevios.representante? datos.datosPrevios.representante.nif: '' ,casilla: 33},
        {term: 'MXX_nomApeRazonSoc', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.nomApe: '' ,casilla: 34},
        {term: 'MXX_siglas', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.tipoVia?.id: '' ,casilla: 35},
        {term: 'MXX_nombreVia', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.nombreVia: '' ,casilla: 36},
        {term: 'MXX_numero', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.numero : '', casilla: 37},
        {term: 'MXX_letra', value:datos.datosPrevios.representante? datos.datosPrevios.representante.direccion.letra: '' ,casilla: 38},
        {term: 'MXX_escalera', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.escalera: '' ,casilla: 39},
        {term: 'MXX_piso', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.piso: '' ,casilla: 40},
        {term: 'MXX_telefono', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.contacto.telefono: ''  ,casilla: 41},
        {term: 'MXX_email', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.contacto.email: ''  ,casilla: 42},
        {term: 'MXX_municipio', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.municipio?.nombre: ''  ,casilla:43 },
        {term: 'MXX_provincia', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.provincia?.nombre: ''  ,casilla: 44},
        {term: 'MXX_cp', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.cp: '' ,casilla: 45}
      ],
    M600_D: {
        docPublico:[ 
            {term: 'notarial', value:datos.infoTransmision.tipoDocTransmision?.id === 'publico-notarial' ? 'X': '' ,casilla:46 },
            {term: 'judicial', value: datos.infoTransmision.tipoDocTransmision?.id === 'publico-judicial' ? 'X': '',casilla:47 },
            {term: 'administrativo', value: datos.infoTransmision.tipoDocTransmision?.id === 'publico-administrativo' ? 'X': '',casilla:48 },
            {term: 'otros', value: '' ,casilla: 49}
        ],
        docPrivado:[
            {term:'privado', value: datos.infoTransmision.tipoDocTransmision?.id === 'privado' ? 'X': '', casilla: 50}
        ],
        operacion: [
            {term: 'notario', value:datos.infoTransmision.notario ,casilla: 51},
            {term: 'MXX_nifNotario', value:datos.infoTransmision.nifNotario ,casilla: 52},
            {term: 'MXX_refCatastral', value: datos.datosPrevios.infoCatastral?.refCatastral,casilla: 54 },
            {term: 'anyoProtocolo', value: datos.infoTransmision.anyo+'/'+ datos.infoTransmision.numeroProtocolo,casilla: 53 },
            {term: 'valorCatastral', value: datos.datosPrevios.infoCatastral?.datosEconomicos?.valorCatastral ? formatNumStr(parseFloat(datos.datosPrevios.infoCatastral.datosEconomicos.valorCatastral.replace(',','.')))+"€": "" ,casilla: 55},
            {term: 'superficie', value: datos.datosPrevios.infoCatastral?.datosEconomicos?.superficieConstruida ,casilla: 56},
            {term: 'valorDeclarado', value: formatNumStr(datos.infoTransmision.valorDeclarado)+"€" ,casilla: 57},
        ], 
        operacionContinuacion:[
          {term: 'descripcion', value: '' ,casilla:59,fullWidth: true },
          {term: 'concepto', value:  datos.datosOperacionModelo600.concepto?.id ,casilla:58 },
          {term: 'MXX_provincia', value: 'ILLES BALEARS',casilla: 61},
          {term: 'MXX_municipio', value: datos.datosPrevios.municipio?.nombre,casilla: 62 },
          {term: 'MXX_direccion', value: datos.datosPrevios.infoCatastral?.direccion ,casilla: 60,fullWidth: true},
        ]
    },
    M600_E:{ 
      liqComplementaria: [
      {term: 'totalValorDeclarados', value: '', casilla: 63},
      {term: 'exento', value: datos.datosOperacionModelo600.exento ? 'X': '', casilla: 64},
      {term: 'noSujeto', value: datos.datosOperacionModelo600.noSujeto ? 'X': '',casilla: 65},
      {term: 'fundamento', value: datos.datosOperacionModelo600.observaciones,casilla: 66, fullWidth: true},
      {term: 'liquidacionComplementaria', value: '' ,casilla: 67},
      {term: 'liquidacion1', value: '' ,casilla: 68},
      {term: 'importeIngresado', value: '' ,casilla: 69},
      ],
      liquidacion: [
        {term: 'MXX_baseImponible', value: formatNumStr(datos.datosOperacionModelo600.baseImponible)+"€",casilla: 70, fullWidth: true},
        {term: 'MXX_reduccion', value: datos.datosOperacionModelo600.reduccion ? formatNumStr(datos.datosOperacionModelo600.reduccion)+"%": "",casilla:71 },
        {term: 'reduccionSobreImponible', value: datos.datosOperacionModelo600.reduccionSobreImponible ? formatNumStr(datos.datosOperacionModelo600.reduccionSobreImponible)+"€" : "",casilla: 72},
        {term: 'baseLiquidable', value: datos.datosOperacionModelo600.baseLiquidable ? formatNumStr(datos.datosOperacionModelo600.baseLiquidable)+"€" : "" ,casilla: 73,fullWidth: true},
        {term: 'tipoAplicable', value: datos.datosOperacionModelo600.tipoAplicable,casilla: 74},
        {term: 'MXX_cuota', value: datos.datosOperacionModelo600.cuotaIntegra ? formatNumStr(datos.datosOperacionModelo600.cuotaIntegra)+"€":"" ,casilla:75 },
        {term: 'MXX_bonificacion', value: datos.datosOperacionModelo600.bonificacion ? datos.datosOperacionModelo600.bonificacion +"%" : "" ,casilla: 76},
        {term: 'bonificacionSobreCuota', value: datos.datosOperacionModelo600.bonificacionSobreCuota ? formatNumStr(datos.datosOperacionModelo600.bonificacionSobreCuota)+"€" : "" ,casilla: 77},
        {term: 'aIngresar', value: datos.datosOperacionModelo600.aIngresar ? formatNumStr(datos.datosOperacionModelo600.aIngresar)+":€" : "",casilla:78},
        {term: 'MXX_recargo', value:datos.datosOperacionModelo600.recargo ? formatNumStr(datos.datosOperacionModelo600.recargo)+"%" :"" ,casilla:79 },
        {term: 'MXX_interesesDemora', value: datos.datosOperacionModelo600.interesesDemora ? formatNumStr(datos.datosOperacionModelo600.interesesDemora)+"€" : "",casilla: 80, fullWidth: true},
        {term: 'MXX_totalIngresar', value: datos.datosOperacionModelo600.totalIngresar ? formatNumStr(datos.datosOperacionModelo600.totalIngresar)+"€" :"",casilla: 81, fullWidth: true}
      ]
    },
    M600_ANEXOS1: //relacion suj Pasivos
        datos.datosComprador.length>1 
        ? datos.datosComprador.slice(1,).map((c, idx) => {
            const incr = idx*10 
            return([
                    {term: 'MXX_nif', value:c.nif ,casilla:125+incr },
                    {term: 'MXX_nomApe', value: c.nomApe,casilla: 126+incr },
                    {term: 'participacion', value: c.porcentaje,casilla: 133+incr },
                    {term: 'edad', value: c.fechaNacimiento ? calcularEdad(new Date(c.fechaNacimiento)): '' ,casilla: 134+incr },
                    {term: 'MXX_nombreVia', value: c.direccion.nombreVia,casilla: 127+incr },
                    {term: 'MXX_numero', value: c.direccion.numero,casilla: 128+incr },
                    {term: 'MXX_piso', value: c.direccion.piso,casilla: 129+incr },
                    {term: 'MXX_municipio', value: c.direccion.municipio?.nombre ,casilla: 130+incr },
                    {term: 'MXX_provincia', value: c.direccion.provincia?.nombre ,casilla: 131+incr },
                    {term: 'MXX_cp', value: c.direccion.cp,casilla: 132+incr }
                ])
            }) 
        : null,
    M600_ANEXOS2: 
      datos.datosVendedor.length>1 
      ? datos.datosVendedor.slice(1,).map((v,idx) =>{ 
        const incr = idx*10 
        return ([
            {term: 'participacion', value: v.porcentaje,casilla: 313+incr },
            {term: 'MXX_nif', value: v.nif ,casilla:305+incr },
            {term: 'MXX_nomApe', value: v.nomApe,casilla: 306+incr },
            {term: 'MXX_nombreVia', value: v.direccion.nombreVia,casilla: 307+incr },
            {term: 'MXX_numero', value: v.direccion.numero,casilla: 308+incr },
            {term: 'MXX_piso', value: v.direccion.piso,casilla: 309+incr },
            {term: 'MXX_municipio', value: v.direccion.municipio?.nombre ,casilla: 310+incr },
            {term: 'MXX_provincia', value: v.direccion.provincia?.nombre ,casilla: 311+incr },
            {term: 'MXX_cp', value: v.direccion.cp,casilla: 312+incr }
        ])
      })
      : null,
  } as TBorradorModelo600)
}

export const extraerBorradorM081 = (datos: TPasoDatosTransmision, terms: any) => {
  //Por cada sujetos de plusvalia
  const out = datos.datosOperacionPlusvalia.map((datosPlusv) => {
    const sujetoPasivo: IPersonaTransmitente | undefined = datos.datosPrevios.tramitesGest.PLUSVAL && datos.datosPrevios.tramitesGest.PLUSVAL.sujetoPasivo === true 
      ? datos.datosVendedor.find(v => v.nif === datosPlusv.nifSujetoPasivo)
      : datos.datosComprador.find(c => c.nif === datosPlusv.nifSujetoPasivo)
    const sujetoAdquiriente = datos.datosComprador[0]
    
    const numOP= datos.datosVendedor.find(v => v.nif === datosPlusv.nifSujetoPasivo)?.numOperacionesAdquisicion ?? datosPlusv.operaciones.length
    const vendPorcentaje= datos.datosVendedor.find(v => v.nif === datosPlusv.nifSujetoPasivo)?.porcentaje 



    return({
      M081_A: [
        {term: 'MXX_nif',value: sujetoPasivo?.nif ,casilla:5 },
        {term: 'MXX_nomApeRazonSoc', value: sujetoPasivo?.nomApe,casilla: 6},
        {term: 'MXX_siglas', value: sujetoPasivo?.direccion.tipoVia?.id ,casilla: 7},
        {term: 'MXX_nombreVia', value: sujetoPasivo?.direccion.nombreVia,casilla: 8},
        {term: 'MXX_numero', value: sujetoPasivo?.direccion.numero,casilla: 9},
        {term: 'MXX_letra', value: sujetoPasivo?.direccion.letra,casilla: 10},
        {term: 'MXX_escalera', value: sujetoPasivo?.direccion.escalera,casilla: 11},
        {term: 'MXX_piso', value: sujetoPasivo?.direccion.piso,casilla: 12},
        {term: 'MXX_puerta', value: sujetoPasivo?.direccion.puerta ,casilla:13},
        {term: 'MXX_telefono', value: sujetoPasivo?.contacto.telefono ,casilla: 14},
        {term: 'MXX_email', value: sujetoPasivo?.contacto.email ,casilla: 15},
        {term: 'MXX_municipio', value: sujetoPasivo?.direccion.municipio?.nombre ,casilla: 16},
        {term: 'MXX_provincia', value: sujetoPasivo?.direccion.provincia?.nombre ,casilla: 17},
        {term: 'MXX_cp', value: sujetoPasivo?.direccion.cp,casilla: 18}
      ],
      M081_B: [
        {term: 'MXX_nif', value:datos.datosPrevios.representante? datos.datosPrevios.representante.nif: '' ,casilla: 19},
        {term: 'MXX_nomApeRazonSoc', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.nomApe: '' ,casilla: 20},
        {term: 'MXX_siglas', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.tipoVia?.id: '' ,casilla: 21},
        {term: 'MXX_nombreVia', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.nombreVia: '' ,casilla: 22},
        {term: 'MXX_numero', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.numero : '', casilla: 23},
        {term: 'MXX_letra', value:datos.datosPrevios.representante? datos.datosPrevios.representante.direccion.letra: '' ,casilla: 24},
        {term: 'MXX_escalera', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.escalera: '' ,casilla: 25},
        {term: 'MXX_piso', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.piso: '' ,casilla: 26},
        {term: 'MXX_puerta', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.puerta: '' ,casilla: 27},
        {term: 'MXX_telefono', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.contacto.telefono: ''  ,casilla: 28},
        {term: 'MXX_email', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.contacto.email: ''  ,casilla: 29},
        {term: 'MXX_municipio', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.municipio?.nombre: ''  ,casilla:30 },
        {term: 'MXX_provincia', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.provincia?.nombre: ''  ,casilla: 31},
        {term: 'MXX_cp', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.cp: '' ,casilla: 32}      
      ],
      M081_C: [
        {term: 'MXX_nif', value: sujetoAdquiriente.nif ,casilla: 33},
        {term: 'MXX_nomApeRazonSoc', value: sujetoAdquiriente.nomApe ,casilla: 34},
        {term: 'MXX_siglas', value: sujetoAdquiriente.direccion.tipoVia?.id ,casilla: 35},
        {term: 'MXX_nombreVia', value:sujetoAdquiriente.direccion.nombreVia ,casilla: 36},
        {term: 'MXX_numero', value: sujetoAdquiriente.direccion.numero, casilla: 37},
        {term: 'MXX_letra', value: sujetoAdquiriente.direccion.letra ,casilla: 38},
        {term: 'MXX_escalera', value: sujetoAdquiriente.direccion.escalera ,casilla: 39},
        {term: 'MXX_piso', value: sujetoAdquiriente.direccion.piso ,casilla: 40},
        {term: 'MXX_telefono', value: sujetoAdquiriente.contacto.telefono ,casilla: 41},
        {term: 'MXX_email', value: sujetoAdquiriente.contacto.email ,casilla: 42},
        {term: 'MXX_municipio', value: sujetoAdquiriente.direccion.municipio?.nombre  ,casilla:43 },
        {term: 'MXX_provincia', value: sujetoAdquiriente.direccion.provincia?.nombre  ,casilla: 44},
      ],
      MXX_D_PLUSV: [ 
        {term: 'MXX_municipio', value: datos.datosPrevios.municipio?.nombre  ,casilla:62 },
        {term: 'M08x_tipoTransmision', value: datos.infoTransmision.tipoTransmision?.nombre  ,casilla:63 },
        {term: 'M08x_otrosSupuestos', value: ''  ,casilla: 64},
        {term: 'MXX_refCatastral', value: datos.infoTransmision.refCatastral  ,casilla: 65},
        {term: 'valorSuelo', value: datos.datosPrevios.infoCatastral?.datosEconomicos?.valorCatastralSuelo ? formatNumStr(parseFloat(datos.datosPrevios.infoCatastral.datosEconomicos.valorCatastralSuelo.replace(',','.')))+"€" :""  ,casilla: 66},
        {term: 'M08x_fechaDevengo', value:  moment(datosPlusv.modelo081?.fechaDevengo).format('DD/MM/YYYY') ,casilla:67 },
        {term: 'M08x_fechaPresentacion', value: moment(datosPlusv.modelo081?.fechaPresentacion).format('DD/MM/YYYY')  ,casilla:68 },
        {term: 'MXX_reduccion', value: datosPlusv.modelo081?.reduccion ? formatNumStr(datosPlusv.modelo081.reduccion)+"%" :"" ,casilla:69 },
        {term: 'MXX_bonificacion', value: datosPlusv.modelo081?.bonificacion ? formatNumStr(datosPlusv.modelo081.bonificacion)+"%" :"" ,casilla:70 },
        {term: 'MXX_recargo', value: datosPlusv.modelo081?.recargo ? datosPlusv.modelo081.recargo+"%" : "" ,casilla:71 },
        {term: 'valorSueloReducido', value: datosPlusv.modelo081?.valorSueloReducido ? formatNumStr(datosPlusv.modelo081.valorSueloReducido)+"€" :"",casilla: 72},
        {term: 'porcentajeTransm', value: datosPlusv.modelo081?.porcentajeTransm ? datosPlusv.modelo081.porcentajeTransm+"%" :""  ,casilla:73 },
        {term: 'tipoDerecho', value: datos.infoTransmision.derechoTransmision?.nombre  ,casilla:74 },
        {term: 'edadUsufructuaria', value: datosPlusv.modelo081?.edadUsufructuaria,casilla:75 },
        {term: 'fechaAdquisicionBien', value: datosPlusv.modelo081?.fechaAdquisicionBien ? moment(datosPlusv.modelo081.fechaAdquisicionBien).format('DD/MM/YYYY') : "" , casilla:76 },
      ],
      M081_D: [
        {term: 'M08x_fechaDoc', value: moment(datos.infoTransmision.fecha).format('DD/MM/YYYY'), casilla: 50},
        {term: 'M08x_tipoDoc', value: datos.infoTransmision.tipoDocTransmision?.nombre, casilla:51 },
        {term: 'M08x_oficina', value: datos.infoTransmision.plusval?.oficina, casilla: 52},
        {term: 'M08x_numProtocolo', value: datos.infoTransmision.numeroProtocolo, casilla: 53},
        {term: 'MXX_nifNotario', value: datos.infoTransmision.nifNotario, casilla: 47},
        {term: 'MXX_nomApe', value: datos.infoTransmision.notario, casilla: 48 },
        {term: 'MXX_direccion', value: '', casilla: 49 },
        {term: 'M08x_tomo', value: datos.infoTransmision.plusval?.tomo, casilla: 54},
        {term: 'M08x_libro', value: datos.infoTransmision.plusval?.libro, casilla: 55},
        {term: 'M08x_folio', value: datos.infoTransmision.plusval?.folio, casilla: 56},
        {term: 'M08x_finca', value: datos.infoTransmision.plusval?.finca, casilla: 57},
        {term: 'inscripcion', value: '', casilla: 58},
      ],
      M081_E_OP: {
        calculo: [
          {term: 'tipoDerecho', value: datos.infoTransmision.derechoTransmision?.nombre ?? "" ,casilla: null },
          {term: 'edadUsufructuaria', value: "",casilla: null },
          {term: 'M08x_numOperaciones', value: numOP ?? "",casilla: null },
          {term: 'M08x_porcentajeTitularidad', value: vendPorcentaje ? vendPorcentaje+'%': "",casilla: null },
        ],
        desglose: datosPlusv.modelo081?.adquisiciones
          ? datosPlusv.modelo081?.adquisiciones.map(op => { 
              return ({
                  operacion: [
                      {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoObjetivo.fechaAdquisicion ? moment(op.metodoObjetivo.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                      {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoObjetivo.porcentaje +"%", casilla: null},
                      {term: 'M08x_importeAnteriorAdquisicion', value: op.metodoObjetivo.importeAnteriorAdquisicion ? formatNumStr(Number(op.metodoObjetivo.importeAnteriorAdquisicion ))+"€" : "", casilla: null},
                      
                  ],
                  objetivo:[
                      // {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoObjetivo.fechaAdquisicion ? moment(op.metodoObjetivo.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                      // {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoObjetivo.porcentaje +"%", casilla: null},
                      {term: 'M08x_valorParcial', value: op.metodoObjetivo.valorParcial ? formatNumStr(Number(op.metodoObjetivo.valorParcial))+"€" : "", casilla: null},
                      {term: 'M08x_anyosTenencia', value: op.metodoObjetivo.anyosTenencia ?? "", casilla: null},
                      {term: 'baseImponibleParcial', value: op.metodoObjetivo.baseImponible ? formatNumStr(Number(op.metodoObjetivo.baseImponible))+"€" : "", casilla: null},
                      {term: 'coeficienteAplicado', value: op.metodoObjetivo.tipoAnual  ? op.metodoObjetivo.tipoAnual +"%" : "", casilla: null},
                      {term: 'MXX_tipoGravamen', value: op.metodoObjetivo.tipoGravamen ? op.metodoObjetivo.tipoGravamen+"%" : "", casilla: null},
                      {term: 'MXX_cuotaIntegraParcial', value: op.metodoObjetivo.cuotaIntegra ? formatNumStr(Number(op.metodoObjetivo.cuotaIntegra))+"€"  : "", casilla: null},
                  ],
                  real: op.metodoReal 
                      ? [
                          // {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoReal.fechaAdquisicion ? moment(op.metodoReal.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                          // {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoReal.porcentaje +"%", casilla: null},
                          {term: 'M08x_valorParcial', value: op.metodoReal.valorParcial ? formatNumStr(Number(op.metodoReal.valorParcial))+"€" : "", casilla: null},
                          {term: 'M08x_anyosTenencia', value: op.metodoReal.anyosTenencia ?? "", casilla: null},
                          {term: 'baseImponibleParcial', value: op.metodoReal.baseImponible ? formatNumStr(Number(op.metodoReal.baseImponible))+"€" : "", casilla: null},
                          {term: 'coeficienteAplicado', value: op.metodoReal.tipoAnual  ? op.metodoReal.tipoAnual +"%" : "", casilla: null},
                          {term: 'MXX_tipoGravamen', value: op.metodoReal.tipoGravamen ? op.metodoReal.tipoGravamen+"%" : "", casilla: null},
                          {term: 'MXX_cuotaIntegraParcial', value: op.metodoReal.cuotaIntegra ? formatNumStr(Number(op.metodoReal.cuotaIntegra))+"€"  : "", casilla: null},
                      ]
                      : []
              })
            })
          : datosPlusv.operaciones.map(op => ([
              {term: 'M08x_fechaAnteriorAdquisicion', value: op.fechaAnteriorAdquisicion ? moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
              {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.porcentajeAnteriorAdquisicion +"%", casilla: null},
              {term: 'M08x_importeAnteriorAdquisicion', value: op.importeAnteriorAdquisicion ? formatNumStr(op.importeAnteriorAdquisicion)+"€" : "", casilla: null},
            ])),

        liquidacion:  datosPlusv.modelo081  ? [
            {term: 'importeMetodoObjetivo', value: datosPlusv.modelo081?.importeMetodoObjetivo ? formatNumStr(datosPlusv.modelo081.importeMetodoObjetivo)+"€": "", casilla: null },
            {term: 'importeMetodoReal', value:  datosPlusv.modelo081?.importeMetodoReal ? formatNumStr(datosPlusv.modelo081.importeMetodoReal)+"€": "", casilla: null },
            {term: 'MXX_cuotaIntegra', value: datosPlusv.modelo081?.cuotaIntegra ? formatNumStr(datosPlusv.modelo081.cuotaIntegra) +"€" :"", casilla: null }, //84 },
            {term: 'baseImponible', value: datosPlusv.modelo081.baseImponible? formatNumStr(datosPlusv.modelo081.baseImponible)+"€" : "", casilla: null }, //80 },
            {term: 'MXX_tipoGravamen', value: datosPlusv.modelo081?.tipoGravamen ? datosPlusv.modelo081.tipoGravamen+"%": "", casilla: null }, //82 },
            {term: 'MXX_bonificacion', value:datosPlusv.modelo081?.bonificacion?datosPlusv.modelo081.bonificacion+"%" :"", casilla: null }, //85 },
            {term: 'MXX_cuotaLiq', value: datosPlusv.modelo081?.cuotaLiquida ? formatNumStr(datosPlusv.modelo081.cuotaLiquida)+"€" :"", casilla: null }, //86 },
            {term: 'MXX_recargo', value: datosPlusv.modelo081?.recargo ? datosPlusv.modelo081.recargo+"%" :"", casilla: null }, //87 },
            {term: 'MXX_interesesDemora', value: datosPlusv.modelo081?.interesesDemora ? datosPlusv.modelo081.interesesDemora+"%":"", casilla: null }, //88 },
            {term: 'importeAdquisicion081', value: datosPlusv.modelo081?.importeAdquisicion ? formatNumStr(datosPlusv.modelo081.importeAdquisicion)+"€" :"", casilla: null }, //91 },
            {term: 'importeTransmision081', value: datosPlusv.modelo081?.importeTransmision ? formatNumStr(datosPlusv.modelo081.importeTransmision)+"€":"", casilla: null }, //92 },
            {term: 'MXX_totalIngresar', value:  datosPlusv.modelo081?.totalIngresar ? formatNumStr(datosPlusv.modelo081?.totalIngresar)+"€": "", casilla: null }, //61 },

          ]: []
      
        // datosPlusv.operaciones.map(op => ([
        //   {term: 'M08x_fechaAnteriorAdquisicion', value: moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY'), casilla: null},
        //   {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.porcentajeAnteriorAdquisicion +"%", casilla: null},
        //   {term: 'M08x_importeAnteriorAdquisicion', value: op.importeAnteriorAdquisicion ? op.importeAnteriorAdquisicion+"€" : "", casilla: null},
        // ]))
      },
      M081_E: [
        // {term: 'baseImponibleReal', value: datosPlusv.modelo081?.baseImponibleReal ? formatNumStr(datosPlusv.modelo081.baseImponibleReal)+"€" :"", casilla: 80 },
        // {term: 'baseImponibleObjetivo', value: datosPlusv.modelo081?.baseImponibleObjetivo? formatNumStr(datosPlusv.modelo081.baseImponibleObjetivo)+"€" :"", casilla: 81 },
        // {term: 'MXX_tipoGravamen', value: datosPlusv.modelo081?.tipoGravamen ? datosPlusv.modelo081.tipoGravamen+"%": "", casilla: 82 },
        // {term: 'cuotaIntegraReal', value: datosPlusv.modelo081?.cuotaIntegraReal ? formatNumStr(datosPlusv.modelo081.cuotaIntegraReal)+"€" :"", casilla: 83 },
        // {term: 'cuotaIntegraObjetiva', value: datosPlusv.modelo081?.cuotaIntegraObjetiva ? formatNumStr(datosPlusv.modelo081.cuotaIntegraObjetiva) +"€" :"", casilla: 84 },
        // {term: 'MXX_bonificacion', value: datosPlusv.modelo081?.bonificacion081 ? datosPlusv.modelo081.bonificacion081+"%" :"", casilla: 85 },
        // {term: 'MXX_cuotaLiq', value: datosPlusv.modelo081?.cuotaLiq081 ? formatNumStr(datosPlusv.modelo081.cuotaLiq081)+"€" :"", casilla: 86 },
        // {term: 'MXX_recargo', value: datosPlusv.modelo081?.recargo081 ? datosPlusv.modelo081.recargo081+"%" :"", casilla: 87 },
        // {term: 'MXX_interesesDemora', value: datosPlusv.modelo081?.interesesDemora081 ? datosPlusv.modelo081.interesesDemora081+"%":"", casilla: 88 },
        // {term: 'importeAdquisicion081', value: datosPlusv.modelo081?.importeAdquisicion081 ? formatNumStr(datosPlusv.modelo081.importeAdquisicion081)+"€" :"", casilla: 91 },
        // {term: 'importeTransmision081', value: datosPlusv.modelo081?.importeTransmision081 ? formatNumStr(datosPlusv.modelo081.importeTransmision081)+"€":"", casilla: 92 },
        // {term: 'MXX_totalIngresar', value: datosPlusv.modelo081?.totalIngresar ? formatNumStr(datosPlusv.modelo081?.totalIngresar)+"€": "", casilla: 61 },
        {term: 'MXX_totalIngresar', value: datosPlusv.modelo081?.totalIngresar ? formatNumStr(datosPlusv.modelo081?.totalIngresar)+"€": "", casilla: 61 },
      ]
    })
  })
  console.log(out)
  return out as TBorradorModelo081[];
}

export const extraerBorradorM082 = (datos: TPasoDatosTransmision, terms: any) => {
  const out = datos.datosOperacionPlusvalia.map((datosPlusv) => {
    const sujetoAdquiriente = datos.datosComprador[0]
    return({
      M082_A: [
        {term: 'MXX_nif',value: sujetoAdquiriente?.nif ,casilla:5 },
        {term: 'MXX_nomApeRazonSoc', value: sujetoAdquiriente?.nomApe,casilla: 6},
        {term: 'MXX_siglas', value: sujetoAdquiriente?.direccion.tipoVia?.id ,casilla: 7},
        {term: 'MXX_nombreVia', value: sujetoAdquiriente?.direccion.nombreVia,casilla: 8},
        {term: 'MXX_numero', value: sujetoAdquiriente?.direccion.numero,casilla: 9},
        {term: 'MXX_letra', value: sujetoAdquiriente?.direccion.letra,casilla: 10},
        {term: 'MXX_escalera', value: sujetoAdquiriente?.direccion.escalera,casilla: 11},
        {term: 'MXX_piso', value: sujetoAdquiriente?.direccion.piso,casilla: 12},
        {term: 'MXX_puerta', value: sujetoAdquiriente?.direccion.puerta ,casilla:13},
        {term: 'MXX_telefono', value: sujetoAdquiriente?.contacto.telefono ,casilla: 14},
        {term: 'MXX_email', value: sujetoAdquiriente?.contacto.email ,casilla: 15},
        {term: 'MXX_municipio', value: sujetoAdquiriente?.direccion.municipio?.nombre ,casilla: 16},
        {term: 'MXX_provincia', value: sujetoAdquiriente?.direccion.provincia?.nombre ,casilla: 17},
        {term: 'MXX_cp', value: sujetoAdquiriente?.direccion.cp,casilla: 18}
      ],
      M082_D: [
        {term: 'M08x_fechaDoc', value: moment(datos.infoTransmision.fecha).format('DD/MM/YYYY'), casilla: 50},
        {term: 'M08x_tipoDoc', value: datos.infoTransmision.tipoDocTransmision?.nombre, casilla:51 },
        {term: 'M08x_oficina', value: datos.infoTransmision.plusval?.oficina, casilla: 52},
        {term: 'M08x_valorTransmision', value: datosPlusv.modelo082?.valorTransmision ? formatNumStr(datosPlusv.modelo082.valorTransmision)+"€" : "", casilla: 92},
        {term: 'M08x_tomo', value: datos.infoTransmision.plusval?.tomo, casilla: 54},
        {term: 'M08x_libro', value: datos.infoTransmision.plusval?.libro, casilla: 55},
        {term: 'M08x_folio', value: datos.infoTransmision.plusval?.folio, casilla: 56},
        {term: 'M08x_finca', value: datos.infoTransmision.plusval?.finca, casilla: 57},
        {term: 'M08x_notario', value: datos.infoTransmision.notario, casilla: 48 },
        {term: 'M08x_localidadNotario', value: '', casilla: 49 },
        {term: 'M08x_numProtocolo', value: datos.infoTransmision.numeroProtocolo, casilla: 53},
      ],
      MXX_D_PLUSV: [
        {term: 'MXX_municipio', value: datos.datosPrevios.municipio?.nombre  ,casilla:62 },
        {term: 'M08x_tipoTransmision', value: datos.infoTransmision.tipoTransmision?.nombre  ,casilla:63 },
        {term: 'M08x_otrosSupuestos', value: datosPlusv.modelo082?.otrosSupuestos ,casilla: 64},
        {term: 'MXX_refCatastral', value: datos.infoTransmision.refCatastral ,casilla: 65},
        {term: 'M08x_fechaPresentacion', value: datosPlusv.modelo082? moment(datosPlusv.modelo082?.fechaPresentacion).format('DD/MM/YYYY'): '' ,casilla:68 },
      ]
  
    })
  })
  return out as TBorradorModelo082[];
}

export const extraerBorradorM080 = (datos: TPasoDatosTransmision, terms: any) => {

  const out = datos.datosOperacionPlusvalia.map((datosPlusv) => {
    const sujetoPasivo: IPersonaTransmitente | undefined = datos.datosPrevios.tramitesGest.PLUSVAL && datos.datosPrevios.tramitesGest.PLUSVAL.sujetoPasivo === true 
      ? datos.datosVendedor.find(v => v.nif === datos.datosOperacionPlusvalia[0].nifSujetoPasivo)
      : datos.datosComprador.find(c => c.nif === datos.datosOperacionPlusvalia[0].nifSujetoPasivo)
    const sujetoAdquiriente = datos.datosComprador[0]
    return ({
      M080_A: [
        {term: 'MXX_nif',value: sujetoPasivo?.nif ,casilla:5 },
        {term: 'MXX_nomApe', value: sujetoPasivo?.nomApe,casilla: 6},
        {term: 'MXX_siglas', value: sujetoPasivo?.direccion.tipoVia?.id ,casilla: 7},
        {term: 'MXX_nombreVia', value: sujetoPasivo?.direccion.nombreVia,casilla: 8},
        {term: 'MXX_numero', value: sujetoPasivo?.direccion.numero,casilla: 9},
        {term: 'MXX_letra', value: sujetoPasivo?.direccion.letra,casilla: 10},
        {term: 'MXX_escalera', value: sujetoPasivo?.direccion.escalera,casilla: 11},
        {term: 'MXX_piso', value: sujetoPasivo?.direccion.piso,casilla: 12},
        {term: 'MXX_puerta', value: sujetoPasivo?.direccion.puerta ,casilla:13},
        {term: 'MXX_telefono', value: sujetoPasivo?.contacto.telefono ,casilla: 14},
        {term: 'MXX_email', value: sujetoPasivo?.contacto.email ,casilla: 15},
        {term: 'MXX_municipio', value: sujetoPasivo?.direccion.municipio?.nombre ,casilla: 16},
        {term: 'MXX_provincia', value: sujetoPasivo?.direccion.provincia?.nombre ,casilla: 17},
        {term: 'MXX_cp', value: sujetoPasivo?.direccion.cp,casilla: 18}
      ],
      M080_B: [
        {term: 'MXX_nif', value:datos.datosPrevios.representante? datos.datosPrevios.representante.nif: '' ,casilla: 19},
        {term: 'MXX_nomApeRazonSoc', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.nomApe: '' ,casilla: 20},
        {term: 'MXX_siglas', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.tipoVia?.id: '' ,casilla: 21},
        {term: 'MXX_nombreVia', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.nombreVia: '' ,casilla: 22},
        {term: 'MXX_numero', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.numero : '', casilla: 23},
        {term: 'MXX_letra', value:datos.datosPrevios.representante? datos.datosPrevios.representante.direccion.letra: '' ,casilla: 24},
        {term: 'MXX_escalera', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.escalera: '' ,casilla: 25},
        {term: 'MXX_piso', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.piso: '' ,casilla: 26},
        {term: 'MXX_puerta', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.puerta: '' ,casilla: 27},
        {term: 'MXX_telefono', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.contacto.telefono: ''  ,casilla: 28},
        {term: 'MXX_email', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.contacto.email: ''  ,casilla: 29},
        {term: 'MXX_municipio', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.municipio?.nombre: ''  ,casilla:30 },
        {term: 'MXX_provincia', value:datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.provincia?.nombre: ''  ,casilla: 31},
        {term: 'MXX_cp', value: datos.datosPrevios.representante ? datos.datosPrevios.representante.direccion.cp: '' ,casilla: 32}      
      ],
      M080_C: [
        {term: 'MXX_nif', value: sujetoAdquiriente.nif ,casilla: 33},
        {term: 'MXX_nomApeRazonSoc', value: sujetoAdquiriente.nomApe ,casilla: 34},
        {term: 'MXX_siglas', value: sujetoAdquiriente.direccion.tipoVia?.id ,casilla: 35},
        {term: 'MXX_nombreVia', value:sujetoAdquiriente.direccion.nombreVia ,casilla: 36},
        {term: 'MXX_numero', value: sujetoAdquiriente.direccion.numero, casilla: 37},
        {term: 'MXX_letra', value: sujetoAdquiriente.direccion.letra ,casilla: 38},
        {term: 'MXX_escalera', value: sujetoAdquiriente.direccion.escalera ,casilla: 39},
        {term: 'MXX_piso', value: sujetoAdquiriente.direccion.piso ,casilla: 40},
        {term: 'MXX_telefono', value: sujetoAdquiriente.contacto.telefono ,casilla: 41},
        {term: 'MXX_email', value: sujetoAdquiriente.contacto.email ,casilla: 42},
        {term: 'MXX_municipio', value: sujetoAdquiriente.direccion.municipio?.nombre  ,casilla:43 },
        {term: 'MXX_provincia', value: sujetoAdquiriente.direccion.provincia?.nombre  ,casilla: 44},
        {term: 'MXX_cp', value: sujetoAdquiriente.direccion.cp ,casilla: 45}
        
      ],
      MXX_D_PLUSV: [
        {term: 'MXX_municipio', value: datos.datosPrevios.municipio?.nombre  ,casilla:62 },
        {term: 'M08x_tipoTransmision', value: datos.infoTransmision.tipoTransmision?.nombre  ,casilla:63 },
        {term: 'M08x_otrosSupuestos', value: ''  ,casilla: 64},
        {term: 'MXX_refCatastral', value: datos.infoTransmision.refCatastral  ,casilla: 65},
        {term: 'M08x_fechaPresentacion', value: datosPlusv.modelo080? moment(datosPlusv.modelo080?.fechaPresentacion).format('DD/MM/YYYY') : '' ,casilla:68 },
      ],
      M080_D:  [
        {term: 'M08x_fechaDoc', value: moment(datos.infoTransmision.fecha).format('DD/MM/YYYY'), casilla: 50},
        {term: 'M08x_tipoDoc', value: datos.infoTransmision.tipoDocTransmision?.nombre, casilla:51 },
        {term: 'M08x_oficina', value: datos.infoTransmision.plusval?.oficina, casilla: 52},
        {term: 'M08x_valorTransmision', value: datosPlusv.modelo080?.valorTransmision ? formatNumStr(datosPlusv.modelo080.valorTransmision)+"€": "", casilla: 92},
        {term: 'M08x_tomo', value: datos.infoTransmision.plusval?.tomo, casilla: 54},
        {term: 'M08x_libro', value: datos.infoTransmision.plusval?.libro, casilla: 55},
        {term: 'M08x_folio', value: datos.infoTransmision.plusval?.folio, casilla: 56},
        {term: 'M08x_finca', value: datos.infoTransmision.plusval?.finca, casilla: 57},
        {term: 'M08x_notario', value: datos.infoTransmision.notario, casilla: 48 },
        {term: 'M08x_localidadNotario', value: '', casilla: 49 },
        {term: 'M08x_numProtocolo', value: datos.infoTransmision.numeroProtocolo, casilla: 53},
      ],
      M08X_E_OP: 
        datosPlusv.operaciones.map(op => ([
          {term: 'M08x_fechaAnteriorAdquisicion', value:op.fechaAnteriorAdquisicion ? moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY'): "", casilla: null},
          {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.porcentajeAnteriorAdquisicion ? op.porcentajeAnteriorAdquisicion +"%": "", casilla: null},
          {term: 'M08x_importeAnteriorAdquisicion', value: op.importeAnteriorAdquisicion ? formatNumStr(op.importeAnteriorAdquisicion)+"€" : "", casilla: null},
        ]))
      ,
      M080_E: [
        {term: 'fechaDocAdquisicion', value:  datosPlusv.modelo080?.fechaDocAdquisicion ?  moment(datosPlusv.modelo080?.fechaDocAdquisicion).format('DD/MM/YYYY') : '', casilla: 83},
        {term: 'tipoDocAdquisicion', value: datosPlusv.modelo080?.tipoDocAdquisicion , casilla:84 },
        {term: 'valorAdquisicion', value:  datosPlusv.modelo080?.valorAdquisicion ? formatNumStr(datosPlusv.modelo080.valorAdquisicion)+"€":"", casilla: 91 },

      ]
    })

  })
  
  return out as TBorradorModelo080[];
}
  