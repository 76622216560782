import { CardContent, Typography, makeStyles } from "@material-ui/core";
import { BorderTop } from "@material-ui/icons";
import Term from "components/term";
import IoC from "contexts/ioc.context";
import { IDatosEmbargo } from "gateways/embargos.interface";
import { RecibosGateway } from "gateways/recibo.gateway";
import moment from "moment";
import { FC,  useContext, useEffect, useMemo, useState } from "react";

const useStyles = makeStyles((theme) => ({
    card: {
        marginRight: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-arround',
        width: '100%',

        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            flexDirection:'column-reverse',
            },
    },

    content: {
        display: 'grid',
        gridTemplateColumns: '2fr 2fr 3fr 3fr 2fr 2fr 1fr',
        gap: '10px',
        borderTop: 'solid 1px',
        paddingTop: '15px',

        [theme.breakpoints.down("sm")]: {           
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr 1fr',
            padding: 5, 
            borderTop: 'none',       
          },    
    },

    principal: {
        [theme.breakpoints.down("sm")]: {
        display: 'flex',
        gridColumn: '1 / 3' ,
        gridRow: 1,
        },
      },
  
      recargo: {
        [theme.breakpoints.down("sm")]: {
        display: 'flex',
        gridColumn: '1 / 4',
        gridRow: 2,
        },
      },
  
      intereses: {
        [theme.breakpoints.down("sm")]: {
        display: 'flex',
        gridColumn: '1 / 4',
        gridRow: 3,
        },
      },
  
      costas: {
        [theme.breakpoints.down("sm")]: {
        display: 'flex',
        gridColumn: '1 / 4',
        gridRow: 4,
        },
      },

      fecha: {
        [theme.breakpoints.down("sm")]: {
        display: 'flex',
        gridColumn: '1 / 4',
        gridRow: 5,
        },
      },

      info: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: 10,
        
        [theme.breakpoints.down("sm")]: {
            gridColumn: '1 / 4',
            gridRow: 1,
            width: '80vw',
            },
      
      },

    datos: {
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 5, 
            }, 
    },

    pre: {
        wordWrap: "break-word",
        display: "block",
        margin: "0 0 10px",
        color: "#333",
        wordBreak: "break-all",
        backgroundColor: "#e3eff3",
        borderRadius: 4,
        padding: 15,
        [theme.breakpoints.down("sm")]: {
            width: '80vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            },
    },
}))

interface IProps {
    embargo: IDatosEmbargo
}

export const EmbargosDetail: FC<IProps> = ({ embargo }) => {
    const classes = useStyles();
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);
    const [lineasAux, setLineasAux] = useState('');

    useEffect(() => {
        (async () => {
            const lineas = await gateway.getLineasAuxiliaresNoIdentification(embargo.idRecibo);
            setLineasAux(lineas);
        })()
    }, [gateway, embargo.idRecibo]);


    return (
        <CardContent className={classes.card}>
            <div className={classes.content} >
                <div className={classes.principal}>
                    <Typography ><Term component="Global" text="Principal" />:</Typography>
                    <Typography className={classes.datos}>{embargo.principal.toString().replace('.', ',')}</Typography>
                </div>
                <div className={classes.recargo}>
                    <Typography><Term component="Tramites" text="Recargo" />:</Typography>
                    <Typography className={classes.datos}>{embargo.recargo.toString().replace('.', ',')}</Typography>
                </div>
                <div className={classes.intereses}>
                    <Typography><Term component="Global" text="Intereses" />:</Typography>
                    <Typography className={classes.datos}>{embargo.intereses.toString().replace('.', ',')}</Typography>
                </div>
                <div className={classes.costas}>
                    <Typography><Term component="Global" text="Costas" />:</Typography>
                    {embargo.costas !== null ?(
                        <Typography>{embargo.costas.toString().replace('.', ',')}</Typography>
                    ): (
                        <Typography className={classes.datos}>--</Typography>
                    )

                    }
                </div>
                <div className={classes.fecha}>
                    <Typography><Term component="Tramites" text="ValidoHasta" />:</Typography>
                    <Typography className={classes.datos}>{moment(embargo.fValida).format("DD/MM/YYYY")}</Typography>
                </div>
            </div>
            <div className={classes.info} >
                <p><Term component="CarteroVirtual" text="Información tributo" /></p>
                <pre className={classes.pre} dangerouslySetInnerHTML={{ __html: lineasAux }} />
            </div>
        </CardContent>
    )
}