// LIBRARY IMPORTS
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Tooltip, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

// LOCAL IMPORTS
import { format } from 'utils/i18n';
import usePage from "hooks/page.hook";

interface RezisableTermProps {
    // MANDATORY
    
    labelComponent: string;
    labelText: string
    dateFormat: string
    value: Date | null | undefined;
    
    onChange: (date: MaterialUiPickersDate) => void;

    // OPTIONAL
    
    minDate?: Date | undefined;
    maxDate?: Date | undefined;
    InputLabelProps?: any;
    params?: any[];
    isSmall?: Boolean;
    termLength?: number;
    disablePast?: boolean;
    style?: React.CSSProperties;
    open?: boolean;
    tooltipPlacment?: "left" | "right" | "bottom" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined;

    shouldDisableDate?: (day: any) => boolean;
    onToggle?: () => void;
}


const CustomDatePicker: React.FC<RezisableTermProps> = (props) => {
    const {
        labelComponent,
        labelText,
        dateFormat,
        value,
        minDate,
        maxDate,
        InputLabelProps,
        params,
        isSmall,
        termLength,
        disablePast,
        style,
        tooltipPlacment,
        open,

        onChange,
        shouldDisableDate,
        onToggle
    } = props

    // HOOKS
    const [state] = usePage();

    // STATES
    const [translatedText, setTranslatedText] = useState('');

    const [isHover, setIsHover] = useState(false);
    const [flg, setFlg] = useState(false);

    // MEMOS
    const terms = useMemo(() => {
        const key = ('norm_literals.' + state.lang + '.' + (labelComponent || 'Global').toLowerCase()).toLowerCase();
        return JSON.parse(sessionStorage.getItem(key) || '{}');
    }, [state.lang, labelComponent]);

    // CONSTANTS
    const abbreviation = isSmall
        ? translatedText ? translatedText.split(' ').map(part => part[0]).join('') : ''
        : translatedText ? translatedText.substring(0, termLength) + '...' : '';



    // EFFECTS & HANDLERS

    useEffect(() => {
        if (labelText !== undefined) {
            let textResult = labelText;
            const termLoaded = terms[labelText.toLowerCase()];

            if (termLoaded) {
                textResult = termLoaded;
            } else {
                textResult = labelText;
            }
            setTranslatedText(format(textResult, params));
        }
    }, [terms, labelComponent, labelText, params]);

    const handleClickFlg = () => {     
        setFlg(!flg);
    }

    const handleClick = () => {
        onToggle && onToggle();
    };

    return (
        <Tooltip open={!flg && isHover} title={translatedText} arrow placement={tooltipPlacment ? tooltipPlacment : "top"}>
            <div style={{ cursor: 'pointer' }} onClick={handleClickFlg}>
                <DatePicker
                    disableToolbar

                    variant="inline"
                    margin="dense"
                    id="datepicker-fecha"

                    label={<Typography>{abbreviation}</Typography>}

                    open={open}
                    format={dateFormat}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={onChange}
                    style={style ? { ...style, cursor: 'pointer' } : { maxWidth: '180px', cursor: 'pointer' }}
                    InputLabelProps={{
                        style: { top: '-8px', overflow: 'hidden', maxWidth: '120px', textOverflow: 'ellipsis', whiteSpace: "nowrap" },                        
                        ...InputLabelProps
                    }}
                    InputProps={{
                        onClick: handleClick,
                    }}
                    
                    // ToolTip triggers
                    onMouseOver={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    disablePast={disablePast}
                    shouldDisableDate={shouldDisableDate}
                />
            </div>
        </Tooltip >
    );
};

export default CustomDatePicker;
