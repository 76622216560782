// LIBRARY IMPORTS
import { useEffect, useMemo, useState } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
// LOCAL IMPORTS
import { IDatosIbanPagoCarta, ITributo } from "gateways/tributos.interfaces";
import { MONTH_NAMES } from "constants/moment";
import Term from "components/term";
// STYLES IMPORTS
import styles from './../../../../shared/tributo.detail.styles';

const useStyles = makeStyles(styles);

interface IProps {
    pagoCarta: IDatosIbanPagoCarta;
    type: 'info' | 'gestion';
    tributo: ITributo;
    onHandleFechaCargo?: (tributo: ITributo) => void;
}

const PagoCartaCard = (props: IProps) => {
    // PROPS
    const { pagoCarta, type, tributo,onHandleFechaCargo } = props;
    // HOOKS
    const classes = useStyles();
    // LOCAL STATES
    const [monthsPagoCarta, setMonthsPagoCarta] = useState<string[]>([]);

    // Gestión de pago carta -- REVISAR DWA-15626
    // const periodoCerrado=useMemo(() => {
    //     const today = new Date().setHours(0,0,0,0)
    //     const limite= new Date(pagoCarta.fechaCierrePeriodo).setHours(0,0,0,0)
    //     return today>limite ? true : false
    // },[pagoCarta.fechaCierrePeriodo])

    useEffect(() => {
        handleMonthsPagoCarta();
    }, [pagoCarta]);

    const handleMonthsPagoCarta = () => {
        const pagoCartaTypeAny: any = pagoCarta;

        for (let i = 1; i <= 12; i++) {
            const propName = `mes${i}`;
            if (pagoCartaTypeAny[propName] && monthsPagoCarta.findIndex(month => month === MONTH_NAMES[i - 1]) === -1) {
                setMonthsPagoCarta(prev => [...prev, MONTH_NAMES[i - 1]])
            };
        }
    };

    const handleMonthTranlsations = () => {
        return <strong>
            {monthsPagoCarta.map((month, i) =>
                i === monthsPagoCarta.length - 1
                    ? <Term component="Global" text={month} />
                    : <><Term component="Global" text={month} />, </>
            )}
        </strong>;
    };


    return (
        <>
            <Grid container direction ='column' className={classes.orangeBorder}>      
                <div className={classes.divColoredTextBorder}>
                    <Typography className={classes.orangeTextBorder}>{pagoCarta.ejercicio}</Typography>
                </div>
                <Grid item container direction='row' alignItems="baseline" justify="space-between" style={{padding: 15}} >
                    <Grid item>
                        <Typography component="p" className={[classes.marginBottom5, classes.marginRight20].join(' ')}>
                            <Term component="Domiciliaciones" text="Datos titular" />: <strong>{[pagoCarta.nombreTitular,pagoCarta.nifTitular].join(' - ')}</strong>
                        </Typography>
                        <Typography component="p" className={[classes.marginRight20].join(' ')}>
                            <Term component="Domiciliaciones" text="IBAN" />: <strong>{pagoCarta.iban}</strong>
                        </Typography>
                    </Grid>
                    <Grid item style={{alignSelf:'flex-end'}}>
                        {type === 'gestion' && onHandleFechaCargo &&
                            <Button variant="outlined" color="secondary" style={{backgroundColor: '#fff'}}
                                //disabled={periodoCerrado}
                                onClick={() => onHandleFechaCargo(tributo)} >
                                <Term component="Domiciliaciones" text={"Modificar cuenta"} />
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Grid item className={classes.orangeContainer} container direction='column' >
                    <Grid item>
                        <Typography component="p">
                            <Term component="Domiciliaciones" text="Pago carta aplicado para el ejercicio" />: <strong>{pagoCarta.ejercicio}</strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="p">
                            <Term component="Domiciliaciones" text="Plazos pago carta" />: {handleMonthTranlsations()}
                        </Typography>
                    </Grid>
                </Grid>
                {/* {periodoCerrado && 
                    <Grid item className={classes.redContainer}>
                        <Typography component="p" className={[classes.tag].join(' ')}>
                            <Term component="Domiciliaciones" text="Periodo pago carta cerrado" /> <strong>{moment(new Date(pagoCarta.fechaCierrePeriodo).setHours(0,0,0,0)).format('DD/MM/YYYY')}</strong>
                        </Typography>
                    </Grid>
                } */}
            </Grid>
        </>
    )
}

export { PagoCartaCard };