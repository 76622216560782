import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, withStyles, WithStyles, Button, ListItem, List } from "@material-ui/core";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { IConceptoM600 } from "gateways/duti.interface";
import FullLoading from "../full-loading";
import usePage from "hooks/page.hook";
import { TTipoInmueble, TUsoInmueble } from "gateways/catastro.interface";


interface IProps extends WithStyles {
    show: boolean,
    conceptos: IConceptoM600[]

    tipoBien: TTipoInmueble|undefined,
    uso: TUsoInmueble| undefined,
    viviendaHabitual: boolean | undefined,
    edad: number[] | undefined,
    valorInmueble: number,
    
    onSelected: (c: IConceptoM600) => void
    onClose: () => void
}

const ModalConceptosSugeridosM600: FC<IProps> = ({
    classes, show, conceptos, tipoBien,edad,uso,valorInmueble,viviendaHabitual, onSelected,onClose
}) => {
    const terms = useContext(LiteralsContext);
    const [state] = usePage();
    const [,alertsDispatch] = useContext(AlertsContext)
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const componentMounted = useRef(true)

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<IConceptoM600|null>(null)
    const [conceptosFiltrados, setConceptosFiltrados] = useState<IConceptoM600[]|null>(null)
   
    const EDAD_TP9 = 30
    const EDAD_TP5 = 36
    const IMPORTE_TP = 270151.20 //4,5,6,8,9,10)
    const IMPORTE_TP7= 350000.00
    //Urbanos
    const CONCEPT_UR = ['TU1','DN4','TGA','TP4','TU2','TU0','TP0','TS0','TU7','TP5','TP2','TP9','TO0','TP7','TP10','TU6','TP6','TP8'] // TP1, TP3
    //Rusticos
    const CONCEPT_RU = ['TR0','TR2','TR1']
    // Mix top 5
    const TOP5 = ['TU1','DN4','TGA','TR0','TR2']
    const ALL = CONCEPT_UR.concat(CONCEPT_RU)




    useEffect(() => {
        if(conceptos){
            let toOrder: string[]= []
            //console.log(edad, valorInmueble, tipoBien)
            let isALL = false
            if(tipoBien && tipoBien === 'RU'){
                toOrder = CONCEPT_RU
            } else if( tipoBien && tipoBien === 'UR'){
                toOrder=CONCEPT_UR
            } else {
                toOrder=ALL
                isALL=true
            }
            let deleteConceptos: string[] = []
            if(valorInmueble){
                if(valorInmueble > IMPORTE_TP){
                    deleteConceptos = ['TP4','TP5','TP6','TP8','TP9','TP10']
                }
                if(valorInmueble > IMPORTE_TP7){
                    deleteConceptos.push("TP7")
                }
            }
            if(edad){
                const inf_30 = edad.map( e => (e<= EDAD_TP5))
                if(inf_30.includes(false)){
                    deleteConceptos.push("TP5")
                }
                const inf_36 = edad.map( e => (e<= EDAD_TP9))
                if(inf_36.includes(false)){
                    deleteConceptos.push("TP9")
                }
            }

            if(deleteConceptos.length>0) {
                //conceptos que cumplen
                const c = conceptos && conceptos.filter(c => !(deleteConceptos.includes(c.codigo)))
                const cOrdered = toOrder.map( cTop => {
                    const found = c.find( concept => concept.codigo === cTop)
                    return found
                })
                const toShow = cOrdered.filter( c => c !== undefined)
                setConceptosFiltrados(toShow as IConceptoM600[]) 
            } else {
                if(isALL){
                    const c = conceptos && conceptos.filter(c => TOP5.includes(c.codigo))
                    setConceptosFiltrados(c)
                } else {
                    const c = conceptos && conceptos.filter(c => toOrder.includes(c.codigo))
                    setConceptosFiltrados(c)
                }
            }
        }
     
    }, [conceptos/*, edad, valorInmueble, tipoBien*/]) 

    return (   
        <Dialog open={show} fullWidth maxWidth='lg'>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'title_modal_conceptos_sugeridos_m600', terms)}</Typography>
                </div>
            </DialogTitle> 

            <DialogContent>
                <Grid container direction='column' spacing={1} style={{paddingRight: 20, paddingLeft: 20}} >
                    <Typography >{translate('DUTI', 'text_modal_conceptos_sugeridos_m600', terms)}</Typography>

                    <div style={{height: 400, overflow:'auto'}}>

                        <List>
                            {conceptosFiltrados && conceptosFiltrados.slice(0,5).map( concepto => (
                                <ListItem 
                                    key={concepto.codigo}
                                    button
                                    selected={concepto.codigo === selected?.codigo}
                                    onClick={() => {
                                        setSelected(concepto)
                                    }}
                                    divider
                                >
                                    <Grid container direction="row" alignItems="flex-start" justify="space-between">
                                        <Grid item xs={11}>
                                            {/* <Typography >{ state.lang === 'es'? concepto.Texto_ES : concepto.Texto_CA }</Typography> */}
                                            <Typography>{translate('DUTI', `600_concepto_${concepto.codigo}`,terms)}</Typography>
                                        </Grid>
                                        
                                        <Grid item xs={1}>
                                            <Typography style={{textAlign: 'center'}} >{concepto.codigo}</Typography>
                                        </Grid>

                                        
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        
                    </div>
            
                </Grid>
               
            </DialogContent>
           
            <DialogActions>
                <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={onClose} 
                            disabled={loading}
                        >
                        {translate('DUTI', 'btn_otro_concepto',terms)}
                    </Button>
                    <Button
                        variant="contained" color="primary"
                        onClick={() =>  selected && onSelected(selected) }
                        disabled={!selected}
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalConceptosSugeridosM600));