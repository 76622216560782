import { CircularProgress, Hidden, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "./styles";
import { RouteComponentProps } from "react-router";
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import TabPanel from 'components/tab-panel';
import InfiniteScroll from "react-infinite-scroller";
import usePage from "hooks/page.hook";
import Term from "components/term";
import { mdiWarehouse } from "@mdi/js";
import OrderReducer from "containers/tramites/multas/order.reducer";
import IoC from "contexts/ioc.context";
import { TramitesGateway } from "gateways/tramites.gateway";
import { Establecimiento, EstablecimientoWithEjercicio, Modelo017 } from "gateways/tramites.interfaces";
import useSujeto from "hooks/sujeto.hook";
import NoContent from "components/no-content";
import image from '../../../../resources/no-deuda.png';
import EstablecimientoCard from "./components/establecimiento/establecimiento-card";
import CustomTabs from "components/custom-tabs";
import CustomCountTerm from 'components/count-term/count-term';
import { FiltersContext } from "./filters/filters.context";
import { FilterDataContext } from "./filters/filter-data.context";
import FiltersReducer, { IFiltersState } from "./filters/filters.reducer";
import { IFilterData } from "./filters/filter.interface";
import PanelDrawer from "./filters/panel.drawer";
import FiltersActive from "./filters/filters.active";
import { IFilterOption } from "utils/interfaces";
import { TipoEstablecimiento } from "./values.enum";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { filter } from "lodash";
import moment from "moment";




interface IProps extends WithStyles<typeof styles> {

}

/* CONSTANTS */
const numberElementsPerPage = 30;

const Censo: React.FC<IProps & RouteComponentProps> = ({ classes, history }) => {
    /* VARIABLES */


    /* SUJETO */
    const { sujeto } = useSujeto();

    /* USE STATES */

    /* PAGE UTILS    */
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);


    /* LODAERS */
    const [isLoading, setIsLoading] = useState<boolean>(true);


    /* ESTABLECIMIENTOS */
    const [ejercicios, setEjercicios] = useState<Modelo017[]>([]);
    const [establecimientos, setEstablecimientos] = useState<EstablecimientoWithEjercicio[]>([]);



    /* FICTICE PAGINATION */
    const [tabActive, setTabActive] = useState(0);
    const [page, setPage] = useState(0);


    const hasMore = useMemo(() => establecimientos.length > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, establecimientos]);
    const noContent: boolean = useMemo(() => (!isLoading && !!sujeto && (establecimientos.length === 0 || ejercicios.length === 0)), [isLoading, establecimientos, sujeto, ejercicios]);

    const orderReducer = useReducer(OrderReducer, { orden: 1 });
    const [order,] = orderReducer;


    /* GATEWAYS */
    const ioc = useContext(IoC);
    const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);


    /* FILTERS */
    // const [filters, filtersDispatch] = filtersReducer;
    const [openFilters, setOpenFilters] = useState<boolean>(true);

    const [filterData, setFilterData] = useState<IFilterData>({});
    const filtersReducer = useReducer(FiltersReducer, {});
    const [filters, filtersDispatch] = filtersReducer;




    /* FUNCTIONS */


    const handleGetEjercicios = useCallback(async (nif: string) => {
        setIsLoading(true)
        const result = await tramitesG.getEstablecimientos(nif)
        setEjercicios(result)
        setIsLoading(false)
    }, [tramitesG.getEstablecimientos, setEjercicios])



    const handleLoadMoreAllEstablecimientos = useCallback(() => {

        if (hasMore) {
            const nextItemsStartIndex = page * numberElementsPerPage;
            const nextItemsEndIndex = nextItemsStartIndex + numberElementsPerPage;

            if (nextItemsEndIndex <= establecimientos.length) {
                setPage(prevPage => prevPage + 1);
            }
        }
    }, [hasMore, page, establecimientos.length]);

    const handleChange = useCallback((_E: React.ChangeEvent<{}>, newValue: number) => {
        //SHOULD Change the page but we don't use it
    }, []);


    /* RENDER ESTABLECIMIENTOS */

    const handleInitialFilterValues = useCallback(() => {

        let updates: Partial<IFilterData> = {};

        if (!filterData.hasOwnProperty('tipoEstablecimiento') || (filterData.tipoEstablecimiento && filterData.tipoEstablecimiento.length === 0)) {
            const tipoEstablecimientoOptions: Array<IFilterOption<string>> = Object.entries(TipoEstablecimiento).map(([key, value]) => {
                return { value: key, label: translate("Global", value, terms) };
            });
            const tiposEstablecimientosFiltrados = tipoEstablecimientoOptions.filter(tipo =>
                establecimientos.some(establecimiento => establecimiento.tipoEstablecimiento === tipo.value)
            );

            updates.tipoEstablecimiento = tiposEstablecimientosFiltrados;
        }

        if (!filterData.hasOwnProperty('ejercicio') || (filterData.ejercicio && filterData.ejercicio.length === 0)) {
            let yearArray: Array<IFilterOption<string>> = [];
            establecimientos.forEach((establecimiento: EstablecimientoWithEjercicio) => {
                let year = moment(establecimiento.fechaAlta).year().toString();
                if (!yearArray.some(option => option.value === year)) {
                    yearArray.push({ value: year, label: translate("Global", "año", terms) + " " + year });
                }
            });

            updates.ejercicio = yearArray.sort((a, b) => parseInt(a.value) - parseInt(b.value));;
        }

        if (Object.keys(updates).length > 0) {
            const filterDataTmp: IFilterData = {
                ...filterData,
                ...updates,
            };

            setFilterData(filterDataTmp);
        }

    }, [filterData, establecimientos, setFilterData])

    const handleRenderEstablecimientos = useCallback((ejerciciosArray?: Modelo017[]): EstablecimientoWithEjercicio[] => {
        let resultArray: EstablecimientoWithEjercicio[] = []
        let tmpArray: Modelo017[] = ejerciciosArray ? ejerciciosArray : ejercicios;
        if (tmpArray) {
            tmpArray.map((ejercicio: Modelo017) => {
                ejercicio.anexo2.map((establecimiento: Establecimiento) => {
                    resultArray.push({ ...establecimiento, localizador: ejercicio.localizador, fechaAlta: ejercicio.fechaAlta, metodoDeterminacion: ejercicio.causa.metodoDeterminacion })
                })
            })
        }
        setEstablecimientos(resultArray)

        return resultArray
    }, [ejercicios, setEstablecimientos])

    /* HANDLE FILTERS */

    const handleAreFiltersEmpty = (filters: IFiltersState): boolean => {
        const allValuesAreEmptyArrays = Object.values(filters).every(valor => {
            return (Array.isArray(valor) && valor.length === 0) || (valor === undefined || valor === null);
        });
        return allValuesAreEmptyArrays;
    }

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        let establecimientosNew: EstablecimientoWithEjercicio[] = handleRenderEstablecimientos()
        if (handleAreFiltersEmpty(newFilters)) {
            return
        }
        setPage(0);
        let tmpArray: EstablecimientoWithEjercicio[] = establecimientosNew;
        /* 
        *FILTERS ORDENED FROM MORE GENERL TO MORE SPECIFIC 
        */
        // if (newFilters.localizador) {
        //     const localizadorRegex = new RegExp(newFilters.localizador.trim().toLocaleUpperCase(), 'i');
        //     tmpArray = tmpArray.filter((establecimiento) => localizadorRegex.test(establecimiento.localizador));
        // }
        if (newFilters.tipoEstablecimiento && newFilters.tipoEstablecimiento.length !== 0) {
            tmpArray = tmpArray.filter((establecimiento: EstablecimientoWithEjercicio) => newFilters.tipoEstablecimiento?.includes(establecimiento.tipoEstablecimiento));
        }
        if (newFilters.ejercicio && newFilters.ejercicio.length !== 0) {
            tmpArray = tmpArray.filter((establecimiento: EstablecimientoWithEjercicio) => newFilters.ejercicio?.includes(moment(establecimiento.fechaAlta).year().toString()));
        }
        if (newFilters.matricula) {
            const matriculaRegex = new RegExp(newFilters.matricula.trim().toLocaleUpperCase(), 'i');
            tmpArray = tmpArray.filter((establecimiento) => matriculaRegex.test(establecimiento.matricula));
        }
        if (newFilters.referenciaCatastral) {
            const matriculaRegex = new RegExp(newFilters.referenciaCatastral.trim().toLocaleUpperCase(), 'i');
            tmpArray = tmpArray.filter((establecimiento) => matriculaRegex.test(establecimiento.referenciaCatastral));
        }
        setEstablecimientos(tmpArray)
    }, [establecimientos]);



    /* USE EFFECTS */

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWarehouse,
                title: <Term text="consulta_de_censo" component="Tramites" />,



                //TODO Implementar el botón de más información


                // moreInfoTemplate: 'mas_info_identificacion_con_autenticacion',
                // right: (
                //     <div>
                //         <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                //         <MenuItem button onClick={handleShowOrder}><Term component="Global" text="Ordenar resultados" /></MenuItem>
                //         <MenuItem button onClick={() => handleShowMasInfo("mas_info_identificacion_con_autenticacion")}><Term component="Global" text="Mas informacion" /></MenuItem>
                //     </div>
                // )



            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    /* RENDER ejercicios EACH TIME THE sujeto CHANGES */

    useEffect(() => {
        if (sujeto) {
            (async () => {
                await handleGetEjercicios(sujeto.nif)
            })()
        }
    }, [sujeto]);

    /* RENDER establecimientos EACH TIME THE ejercicios changed */

    useEffect(() => {

        handleRenderEstablecimientos()

    }, [ejercicios]);

    useEffect(() => {
        handleInitialFilterValues()
    }, [establecimientos]);

    /* re-RENDER establecimientos EACH TIME THE filters changed */


    useEffect(() => {
        handleFiltersChanged(filters)
    }, [filters]);

    return (
        <div className={classes.root}>
            {
                isLoading
                    ?
                    <div className={classes.centerContent}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {/* FILTER CONTEXT AND PROVIDERS */}

                        <FiltersContext.Provider value={filtersReducer}>
                            <FilterDataContext.Provider value={filterData}>
                                <div className={classes.root}>
                                    <div className={classes.recibosContainer}>
                                        <FiltersActive
                                            onChange={handleFiltersChanged}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                                            <CustomTabs
                                                labelTab1={<Term component="Tramites" text="establecimientos"></Term>}
                                                labelTab2={null}
                                                onChange={handleChange}
                                                tabActive={tabActive}
                                            />
                                            <CustomCountTerm totalCount={<Term component="Tramites" text={'ContadorEstablecimientos'} params={[establecimientos.length]} />}
                                            />
                                        </div>





                                        {/* LIST OF ESTABLECIMIENTOS */}

                                        <TabPanel value={tabActive} index={0}>

                                            <NoContent
                                                image={image}
                                                visible={noContent}
                                                component='Tramites'
                                                text={'NoEstablecimientos'}
                                            />
                                            {
                                                establecimientos.length > 0 ?
                                                    <>
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            initialLoad={false}
                                                            loadMore={handleLoadMoreAllEstablecimientos}
                                                            hasMore={hasMore}
                                                            loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                                        >
                                                            <>
                                                                {establecimientos.slice(0, (page + 1) * numberElementsPerPage).map((establecimiento: EstablecimientoWithEjercicio, index: number) => (
                                                                    <EstablecimientoCard
                                                                        key={'Establecimiento_' + index}
                                                                        establecimiento={establecimiento}
                                                                    />
                                                                ))}
                                                            </>
                                                        </InfiniteScroll>
                                                    </>
                                                    : noContent
                                                        ? null
                                                        : <div key="progress" className={classes.progressContainer}>
                                                            <CircularProgress />
                                                        </div>
                                            }

                                        </TabPanel>


                                        {/* FILTER DISPLACMENT */}

                                    </div>
                                    <div className={classes.drawer}>
                                        <Hidden smDown implementation="css">
                                            <PanelDrawer
                                                screen={'censo'}
                                                onFiltersChange={handleFiltersChanged}
                                            />
                                        </Hidden>
                                    </div>
                                </div>
                            </FilterDataContext.Provider>
                        </FiltersContext.Provider >
                    </>
            }

        </div >
    )
}
export default withStyles(styles)(Censo);