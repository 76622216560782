import React, { useEffect, useState, useContext, useMemo, useReducer, useCallback } from 'react';
import IoC from 'contexts/ioc.context';
import { WithStyles, withStyles, CircularProgress, Theme, Hidden, MenuItem, Dialog, DialogTitle, DialogContent, Button, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import usePage from '../../../hooks/page.hook';
import { StyleRules } from '@material-ui/styles';
import { mdiShieldCar } from '@mdi/js';
import Term from 'components/term';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { IMultas, IFilterData, IOrderData, IRemesaMultas } from 'gateways/tramites.interfaces';
import FiltersReducer, { IFiltersState } from './filters.reducer';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter-data.context';
import FiltersActive from './filters.active';
import FiltersDialog from './filters.dialog';
import { green } from '@material-ui/core/colors';
import PanelDrawer from './panel.drawer';
import NoContent from 'components/no-content';
import image from '../../../resources/no-deuda.png';
import InfiniteScroll from 'react-infinite-scroller';
import MultaCard from './multas.card';
import { RouteComponentProps, useLocation } from 'react-router';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import OrderReducer, { IOrderState } from './order.reducer';
import { OrderContext } from './order.context';
import { OrderDataContext } from './order-data.context';
import OrderDialog from './order.dialog';
import { Icon } from '@mdi/react';
import { mdiFileDownload } from '@mdi/js';
import { mdiFileImportOutline } from '@mdi/js';
import { mdiFileExportOutline } from '@mdi/js';
import { download } from 'utils/download';
import { AlertsContext } from 'contexts/alerts.context';
import TableWizard from '../../../components/table-wizard';
import WizardImporter from '../../../components/wizard';
import { ImporterGateway } from 'gateways/importer.gateway';
import { IMessageResponse, IResult } from 'gateways/importer.interfaces';
import TableWizardResult from 'components/table-wizard-result';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import CustomTabs from 'components/custom-tabs';
import TabPanel from 'components/tab-panel';
import MultaRemesaCard from './multa-remesa.card';
import { FILE_EXTENSION_CSV, FILE_EXTENSION_XLSX } from 'constants/file-extension-types';
import useSujeto from 'hooks/sujeto.hook';
import CustomCountTerm from 'components/count-term/count-term';

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            flexShrink: 0,
            width: 300,
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    progressContainer: {
        textAlign: 'center',
        marginTop: 30
    },
    recibosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        recibosContainer: {
            margin: '0 2rem'
        },
    },
    centerContent: {
        margin: '0 auto',
    },
    identificacionDialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1000px !important',
            minHeight: '325px !important',
        }
    },
    actionsContainer: {
        display: 'inline-flex',
        marginTop: 20,
        width: '100%',
        height: 100
    },
    actionContent: {
        marginRight: 20,
    },
    actionIcon: {
        width: 42,
        heigth: 42,
        fill: 'grey'
    },
    border: {
        border: '1px solid lightgrey',
        borderRadius: 10
    },
    formControlRadioBtns: {
        gap: '1rem',
        marginTop: 5
    },
    // FLEX
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
});

interface IProps extends WithStyles<typeof styles> { }

const numberElementsPerPage = 30;
const numberElementsPerRemesaPage = 3;

const Conductor: React.FC<IProps & RouteComponentProps> = ({ classes, history }) => {
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const { sujeto } = useSujeto();
    const location = useLocation();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);

    const [loadingMultas, setLoadingMultas] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingIdentificacionAccion, setLoadingIdentificacionAccion] = useState<boolean>(false);
    const [multas, setMultas] = useState<IMultas[]>([]);
    const filtersReducer = useReducer(FiltersReducer, { estado: ['Identificable'], fechaRemesa: null, fechaLimiteDesde: null, fechaLimiteHasta: null, fechaMultaDesde: null, fechaMultaHasta: null });
    const orderReducer = useReducer(OrderReducer, { orden: 1 });
    const [page, setPage] = useState(0);
    const [filters, filtersDispatch] = filtersReducer;
    const [order,] = orderReducer;
    const [multasCount, setMultasCount] = useState(0);
    const [filterData, setFilterData] = useState<IFilterData>({});
    const [orderData, setOrderData] = useState<IOrderData>({});
    const [showFilters, setShowFilters] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [isRentACar, setIsRentACar] = useState(false);
    const [idReciboAIdentificar, setIdReciboAIdentificar] = useState<number | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [dataToImport, setDataToImport] = useState<Record<string, any>[] | null>(null);
    const [resultData, setResultData] = useState<IResult[]>([]);
    const [errorsFile, setErrorsFile] = React.useState<IMessageResponse[]>([]);
    const [errorData, setErrorData] = React.useState<string>("");
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [numElementsImported, setNumElementsImported] = useState(1);
    const [importDisabled, setImportDisabled] = useState(false);
    const [listaMultasIdentificadas, setListaMultasIdentificadas] = useState<any[]>();
    const [remesasNoContent, setRemesasNoContent] = useState(false)
    const hasMore = useMemo(() => multasCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, multasCount]);
    const noContent = useMemo(() => !loadingMultas && multas.length === 0, [loadingMultas, multas]);

    const ioc = useContext(IoC);
    const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);
    const importerG = useMemo(() => ioc.get(ImporterGateway) as ImporterGateway, [ioc]);

    const [tabActive, setTabActive] = useState(0);
    const [multasRemesa, setMultasRemesa] = useState<IRemesaMultas[]>([]);
    const [remesasMultasCount, setRemesasMultasCount] = useState(0);
    const [listaMultaRemesa, setListaMultaRemesa] = useState<IMultas[]>([]);
    const [pemisoConvenioRemesas, setPemisoConvenioRemesas] = useState<boolean>(false);
    const hasMoreRemesas = useMemo(() => remesasMultasCount > multasRemesa.length, [multasRemesa, remesasMultasCount]);
    const noContentRemesa = useMemo(() => !loadingMultas && multasRemesa.length == 0, [loadingMultas, multasRemesa]);
    let remesaScrollParentRef: HTMLElement | null;
    let multasRemesaScrollParentRef: HTMLElement | null;

    const [showExportImportDialog, setShowExportImportDialog] = useState<boolean>(false);
    const [fileExtension, setFileExtension] = useState<'csv' | 'xlsx'>('xlsx');

    // Events
    const handleChangeFileExtension = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileExtension((event.target as HTMLInputElement).value as 'csv' | 'xlsx');
    };

    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
    }, []);

    const handleShowOrder = useCallback(() => {
        setShowOrder(true);
    }, []);

    const handleIdentificar = useCallback((multa: IMultas) => {
        const multasIdentificadas = multas.filter(x => x.idIdentificacionWeb !== multa.idIdentificacionWeb);
        setMultas(multasIdentificadas);
        setMultasCount(multasIdentificadas.length);
    }, [multas]);

    const handleIdentificarMultas = useCallback((multasIdentificadas: number[]) => {
        const matchingMultas = multas.filter(m => {
            return !multasIdentificadas.some(id => m.idIdentificacionWeb === id)
        });
        setMultas(matchingMultas);
        setMultasCount(multasCount - multasIdentificadas.length);
    }, [multas, multasCount]);

    const handleHideFilters = useCallback(() => setShowFilters(false), []);
    const handleHideOrder = useCallback(() => setShowOrder(false), []);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerPage),
            take: exportData ? (multasCount > 0 ? multasCount : numberElementsPerPage) : (numberElementsPerPage)
        });
    }, [multasCount, numberElementsPerPage]);

    const buildQueryRequestPorRemesa = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerRemesaPage),
            take: exportData ? (multasCount > 0 ? multasCount : numberElementsPerRemesaPage) : (numberElementsPerRemesaPage)
        });
    }, [remesasMultasCount, numberElementsPerRemesaPage]);

    const getListaMultasPorRemesa = async (query?: IFiltersState & IOrderState & { skip: number; take: number; }) => {
        
        let queryTemp = buildQueryRequestPorRemesa(filters, order, page);
        if(query){
            queryTemp = query
        }
        
        const listaMultasPorRemesa = await tramitesG.getMultasIdentificarPorRemesa(queryTemp);
        setListaMultaRemesa(listaMultasPorRemesa);
        setRemesasNoContent(listaMultaRemesa.length === 0)
    }


    const handleLoadMultas = useCallback(async (newFilters: IFiltersState, newOrder, newPage: number, activeTab: number = 0) => {
        try {
            setRemesasNoContent(false);
            setLoadingMultas(true);
            if (activeTab == 0) {
                const query = buildQueryRequest(newFilters, newOrder, newPage);
                const multasReceived = await tramitesG.getMultasIdentificar(query);
                setMultas(newPage === 0 ? multasReceived : multas.concat(multasReceived));
            } else {
                const query = buildQueryRequestPorRemesa(newFilters, newOrder, newPage);
                const multasPorRemesa = await tramitesG.getRemesasdeMultas(query);
                setMultasRemesa(newPage === 0 ? multasPorRemesa : multasRemesa.concat(multasPorRemesa));
                if (query['idRemesa'] && multasPorRemesa.length == 1) {
                    getListaMultasPorRemesa(query);
                }
            }
            setLoading(false);
            setLoadingMultas(false);
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        }
    }, [buildQueryRequest, tramitesG, multas, buildQueryRequestPorRemesa, multasRemesa, pageDispatcher]);

    const handleLoadMoreMultas = useCallback(() => {
        if (hasMore && !loadingMultas) {
            setPage(page + 1);
            handleLoadMultas(filters, order, page + 1, tabActive);
        }
    }, [hasMore, loadingMultas, page, handleLoadMultas, filters, order, tabActive]);

    const handleLoadMoreRemesasMultas = useCallback(() => {
        if (hasMoreRemesas && !loadingMultas) {
            setPage(page + 1);
            handleLoadMultas(filters, order, page + 1, tabActive);
        }
    }, [hasMoreRemesas, loadingMultas, page, handleLoadMultas, filters, order, tabActive]);

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(0);
        handleLoadMultas(newFilters, order, 0, tabActive);
    }, [handleLoadMultas, order, tabActive]);

    const handleOrderChanged = useCallback((newOrder: IOrderState) => {
        setPage(0);
        handleLoadMultas(filters, newOrder, 0, tabActive);
    }, [filters, handleLoadMultas])

    const handleNotAllowedDocuments = useCallback(() => {
        pageDispatcher({
            type: 'show-notification',
            payload: {
                message: translate('Tramites', 'soloPdfs', terms),
                variant: 'warning',
            }
        });
    }, [pageDispatcher, terms]);

    const handleShowIdentificacionDialog = useCallback(() => {
        setShowExportImportDialog(true);
    }, []);

    const handleCloseExportImportDialog = () => {
        setShowExportImportDialog(false);
        handleLoadMultas(filters, order, page, tabActive);
    }

    const handleExportRequest = useCallback(async () => {
        setLoadingIdentificacionAccion(true);
        const query = buildQueryRequest(filters, order, 0, true);
        const response = await tramitesG.exportarMultas(query);
        setLoadingIdentificacionAccion(false);
        download(response.blob, alertsDispatch,
            translate('Global', 'No se ha podido exportar', terms),
            translate('Global', 'Exportar', terms), response.fileName);
    }, [buildQueryRequest, filters, order, page, tramitesG, alertsDispatch, terms]);

    const handleDownloadDatos = useCallback(async () => {
        setLoadingIdentificacionAccion(true);
        const response = await tramitesG.downloadDatosParaIdentificar();
        setLoadingIdentificacionAccion(false);
        download(response.blob, alertsDispatch,
            translate('Global', 'No se ha podido exportar', terms),
            translate('Global', 'Exportar', terms), response.fileName);
    }, [alertsDispatch, terms, tramitesG]);

    const handleExportPlantillaNotificaciones = useCallback(async () => {
        setLoadingIdentificacionAccion(true);
        let response;
        if (tabActive === 0) {
            const query = buildQueryRequest(filters, order, 0, true);
            query.take = multasCount;
            query['estado'] = ['Identificable'];
            query.fileExtension = fileExtension;

            if (sujeto && sujeto.nif) {
                query.nifSujeto = sujeto.nif;
            }

            response = await tramitesG.exportarPlantillaMultas(query);
        } else {
            const query = buildQueryRequestPorRemesa(filters, order, 0, true);
            query.take = multasRemesa.length;
            query['estado'] = ['Identificable'];
            query.fileExtension = fileExtension;

            if (sujeto && sujeto.nif) {
                query.nifSujeto = sujeto.nif;
            }

            response = await tramitesG.exportarPlantillaMultasRemesa(query);
        }
        setLoadingIdentificacionAccion(false);
        if (response) {
            download(response.blob, alertsDispatch,
                translate('Global', 'No se ha podido exportar', terms),
                translate('Global', 'Exportar', terms), response.fileName);
        }
    }, [buildQueryRequest, buildQueryRequestPorRemesa, filters, order, page, multasCount, tramitesG, alertsDispatch, terms, /*multaRemesaSelected,*/ multasRemesa, fileExtension]);

    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handlePrevious = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
    }, []);

    const handleNew = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }, []);

    const handleClose = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
        setOpen(false);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }, []);

    const handleUpload = React.useCallback(async (file: File) => {
        try {
            setLoadingUpload(true);
            setFile(file);
            const result = await importerG.importXLSX(file, 'conductor');
            if (result && result.data && result.data.length > 0) {
                setDataToImport(result.data);
                setResultData((result.data as any[])
                    .filter(item => !item.errors || item.errors.length === 0)
                    .map(item => ({
                        id: item.idIdentificacionWeb,
                        boletin: item.boletin,
                        refExpediente: item.refExpediente,
                        matricula: item.matricula,
                        nif: item.nif,
                        nombre: item.nombre,
                        apellidos: item.apellidos,
                        result: null,
                    })
                    ));
                setHasErrors((result.data as any[]).some(item => item.errors && item.errors.length > 0));
                setErrorData("");
            } else if (result && result.errors.length > 0) {
                setErrorsFile(result.errors);
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setLoadingUpload(false);
        }
    }, []);

    const handleImport = React.useCallback(async () => {
        try {
            if (!dataToImport || !file) {
                return;
            }
            setImportDisabled(true);
            const idDocumento = await importerG.saveExcel(file);
            if (idDocumento) {
                const multasIdentificadas: number[] = [];
                const listMultasIdent: any[] = [];
                let newResultData = resultData;
                let newNumElementsImported = numElementsImported;
                for (const data of dataToImport) {
                    const resultToIdentificar = resultData.find(item => item.id === data.idIdentificacionWeb);
                    if (resultToIdentificar) {
                        const result = await importerG.identificarConductor(data, idDocumento);
                        newResultData = newResultData.map(item => {
                            if (item.id === resultToIdentificar.id) {
                                return { ...item, result };
                            }

                            return item;
                        });
                        newNumElementsImported++;
                        setNumElementsImported(newNumElementsImported);
                        setResultData(newResultData);
                        multasIdentificadas.push(parseInt(data.idIdentificacionWeb, 10));
                        listMultasIdent.push({
                            idRecibo: multas.filter((multa) => multa.idIdentificacionWeb === data.idIdentificacionWebinfoDispatchgetMultasIdentificarPorRemesa)[0]?.idRecibo,
                            idIdentificacionWeb: parseInt(data.idIdentificacionWeb, 10)
                        })
                    }
                }
                const result = await tramitesG.asociarJustificanteMasivoARecibos(listMultasIdent);
                setListaMultasIdentificadas(listMultasIdent)
                handleIdentificarMultas(multasIdentificadas);
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setImportDisabled(false);
        }
    }, [dataToImport, file, importerG, pageDispatcher, resultData]);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    const handlePrintJustificante = useCallback(async () => {
        setLoading(true)
        let response: any;
        try {
            if (listaMultasIdentificadas && listaMultasIdentificadas.length > 0) {
                response = await tramitesG.printJustificanteIdentificacionMasiva(listaMultasIdentificadas);
                download(response.blob, alertsDispatch, translate('CitaPrevia', 'Ha ocurrido un error', terms), 'JustificanteIdentificacionMasiva')
                setLoading(false);
                setOpen(false);
            }
        }
        catch {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:
                        <Term component="CitaPrevia" text="Ha ocurrido un error" />,
                    variant: 'warning',
                }
            })
        }
        setLoading(false);
    }, [alertsDispatch, listaMultasIdentificadas]);

    const handleChange = (_E: React.ChangeEvent<{}>, newValue: number) => {
        setTabActive(newValue);
        setListaMultaRemesa([]);
        setRemesasMultasCount(0);
        setMultasCount(0);
        setMultas([]);
        handleCleanFilters(newValue);
    };

    const handleCleanFilters = (tabActive: number) => {
        if (tabActive === 0) {
            filtersDispatch({ type: 'update', filter: 'idRemesa', value: null });
            filtersDispatch({ type: 'update', filter: 'fechaRemesa', value: null });
        }
    };

    const handleSelectRemesa = async (multaRemesaSelected: IRemesaMultas) => {
      filtersDispatch({ type: 'update', filter: 'idRemesa', value: multaRemesaSelected.idRemesa });
    }


    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldCar,
                title: <Term text="Identificar conductor" />,
                moreInfoTemplate: 'mas_info_identificacion_con_autenticacion',
                right: (
                    <div>
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                        <MenuItem button onClick={handleShowOrder}><Term component="Global" text="Ordenar resultados" /></MenuItem>
                        <MenuItem button onClick={() => handleShowMasInfo("mas_info_identificacion_con_autenticacion")}><Term component="Global" text="Mas informacion" /></MenuItem>
                    </div>
                )
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            setMultas([]);
            setMultasRemesa([]);
            setListaMultaRemesa([]);
            handleFiltersChanged(filters);
            setMultasCount(0);
            setRemesasMultasCount(0);
            if (tabActive == 0) {
                const query: any = buildQueryRequest(filters, order, 0);
                delete query.skip;
                delete query.take;
                const countResult = await tramitesG.getCount(query);
                setMultasCount(countResult);
            } else {
                const query: any = buildQueryRequest(filters, order, 0);
                delete query.skip;
                delete query.take;
                const countResult = await tramitesG.getCountRemesasDeMulta(query);
                setRemesasMultasCount(countResult);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, tramitesG, tabActive]);

    useEffect(() => {
        (async () => {
            handleOrderChanged(order);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, tramitesG]);

    useEffect(() => {
        const query: any = buildQueryRequest(filters, order, 0);
        delete query.skip;
        delete query.take;
        const asyncEffect = async () => {
            const filterDataTemp = await tramitesG.getFilterData(query);
            if (!!filterDataTemp.gravedad) {
                for (const g of filterDataTemp.gravedad) {
                    g.label = translate('Tramites', 'gravedad_' + g.value.toLowerCase(), terms);
                }
            }
            if (!!filterDataTemp.estado) {
                for (const g of filterDataTemp.estado) {
                    g.label = translate('Tramites', 'estado_' + g.value.toLowerCase(), terms);
                }
            }
            setFilterData(filterDataTemp);
        };

        asyncEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tramitesG]);

    useEffect(() => {
        const query: any = buildQueryRequest(filters, order, 0);
        delete query.skip;
        delete query.take;
        const asyncEffect = async () => {
            const orderDataTemp = await tramitesG.getOrderData(query);
            setOrderData(orderDataTemp);
        };

        asyncEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tramitesG]);

    useEffect(() => {
        const asyncEffect = async () => {
            const isRentACar = await tramitesG.isRentACar();
            setIsRentACar(isRentACar);
            const permisoConvenioRemesasUsuario = await tramitesG.getPermisoConvenioRemesas();
            setPemisoConvenioRemesas(permisoConvenioRemesasUsuario)
        };

        asyncEffect();
    }, [tramitesG])

    useEffect(() => {
        const idRecibo = location.state as number;
        if (idRecibo && multas.find(multa => multa.idRecibo === idRecibo)) {
            setIdReciboAIdentificar(idRecibo);
        }
    }, [location.state, multas])

    return (
        <div className={classes.root}>
            {
                loading
                    ?
                    <div className={classes.centerContent}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <OrderContext.Provider value={orderReducer}>
                            <OrderDataContext.Provider value={orderData}>
                                <FiltersContext.Provider value={filtersReducer}>
                                    <FilterDataContext.Provider value={filterData}>
                                        <div className={classes.root}>
                                            <div className={classes.recibosContainer}>
                                                <FiltersActive
                                                    onChange={handleFiltersChanged}
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                                                    <CustomTabs
                                                        labelTab1={translate('Tramites', 'identificar_conductor', terms)}
                                                        labelTab2={pemisoConvenioRemesas ? translate('Tramites', 'identificar_conductor_remesas', terms) : null}
                                                        onChange={handleChange}
                                                        tabActive={tabActive}
                                                    />
                                                    <CustomCountTerm totalCount={tabActive == 0 ? <Term component="Tramites" text={'TituloMultas'} params={[multasCount]} /> : <Term component="Tramites" text={'TituloMultasRemesa'} params={[remesasMultasCount]} />} />
                                                </div>
                                                <TabPanel value={tabActive} index={0}>
                                                    <NoContent
                                                        image={image}
                                                        visible={noContent}
                                                        component='Tramites'
                                                        text={'No se han encontrado denuncias idenfiticables'}
                                                    />
                                                    {
                                                        multasCount > 0 ?
                                                            <>
                                                                <InfiniteScroll
                                                                    pageStart={0}
                                                                    initialLoad={false}
                                                                    loadMore={handleLoadMoreMultas}
                                                                    hasMore={hasMore}
                                                                    loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                                                >
                                                                    {multas.map((multa: IMultas, index: number) => (
                                                                        <MultaCard
                                                                            key={'Multa_' + index}
                                                                            handleIdentificar={handleIdentificar}
                                                                            multa={multa}
                                                                            isRentACar={isRentACar}
                                                                            handleNotAllowedDocuments={handleNotAllowedDocuments}
                                                                            idReciboAIdentificar={idReciboAIdentificar}
                                                                        />
                                                                    ))}
                                                                </InfiniteScroll>
                                                            </>
                                                            : noContent
                                                                ? null
                                                                : <div key="progress" className={classes.progressContainer}>
                                                                    <CircularProgress />
                                                                </div>
                                                    }
                                                </TabPanel>
                                                {pemisoConvenioRemesas &&
                                                    <TabPanel value={tabActive} index={1}>
                                                        <NoContent
                                                            image={image}
                                                            visible={noContentRemesa}
                                                            component='Tramites'
                                                            text={'No se han encontrado remesas de multas idenfiticables'}
                                                        />
                                                        {
                                                            multasRemesa.length > 0 ?
                                                                <>
                                                                    <div style={{ marginTop: '20px', height: '200px', overflowX: 'hidden', overflowY: 'auto', WebkitScrollSnapType: 'none' }} ref={(ref) => remesaScrollParentRef = ref}>
                                                                        <InfiniteScroll
                                                                            pageStart={0}
                                                                            initialLoad={false}
                                                                            loadMore={handleLoadMoreRemesasMultas}
                                                                            hasMore={hasMoreRemesas}
                                                                            useWindow={false}
                                                                            getScrollParent={() => remesaScrollParentRef}
                                                                            loader={<div key="infiniteProgress" style={{ display: hasMoreRemesas ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                                                        >
                                                                            <>
                                                                                {multasRemesa.map((multaRemesa: IRemesaMultas, index: number) => (
                                                                                    <MultaRemesaCard multaRemesa={multaRemesa} handleSelectRemesa={(multaRemesa: IRemesaMultas) => handleSelectRemesa(multaRemesa)}></MultaRemesaCard>
                                                                                ))}
                                                                            </>
                                                                        </InfiniteScroll>
                                                                    </div>
                                                                    {filters && filters['idRemesa'] && listaMultaRemesa && listaMultaRemesa.length > 0 ?
                                                                        <>
                                                                            <div style={{ marginTop: '40px', borderBottom: '#888 0.2em solid', color: '#888', fontWeight: 500, scrollbarWidth: 'none' }}>
                                                                                <Term component="Tramites" text="SancionesRemesa" />
                                                                            </div>
                                                                            <div style={{ marginTop: '10px', height: '45vh', overflowX: 'hidden', overflowY: 'auto' }} ref={(ref) => multasRemesaScrollParentRef = ref}>
                                                                                <InfiniteScroll
                                                                                    pageStart={0}
                                                                                    initialLoad={false}
                                                                                    loadMore={handleLoadMoreMultas}
                                                                                    hasMore={hasMore}
                                                                                    getScrollParent={() => multasRemesaScrollParentRef}
                                                                                    loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                                                                >
                                                                                    {listaMultaRemesa.map((multa: IMultas, index: number) => (
                                                                                        <MultaCard
                                                                                            key={'Multa_' + index}
                                                                                            handleIdentificar={handleIdentificar}
                                                                                            multa={multa}
                                                                                            isRentACar={isRentACar}
                                                                                            handleNotAllowedDocuments={handleNotAllowedDocuments}
                                                                                            idReciboAIdentificar={idReciboAIdentificar}
                                                                                        />
                                                                                    ))}
                                                                                </InfiniteScroll>
                                                                            </div>
                                                                        </>
                                                                        : filters && filters['idRemesa'] && (!listaMultaRemesa || (listaMultaRemesa && listaMultaRemesa.length == 0)) ?
                                                                            <div key="progress" className={classes.progressContainer}>
                                                                            {
                                                                                !remesasNoContent 
                                                                                ? <CircularProgress />
                                                                                : null
                                                                            }
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </>
                                                                : noContent
                                                                    ? null
                                                                    : <div key="progress" className={classes.progressContainer}>
                                                                        <CircularProgress />
                                                                    </div>
                                                        }
                                                    </TabPanel>
                                                }
                                            </div>
                                            <div className={classes.drawer}>
                                                <Hidden mdUp implementation="css">
                                                    <FiltersDialog
                                                        open={showFilters}
                                                        screen={'conductor'}
                                                        onClose={handleHideFilters}
                                                        onChange={handleFiltersChanged}
                                                    />
                                                    <OrderDialog
                                                        open={showOrder}
                                                        screen={'conductor'}
                                                        onClose={handleHideOrder}
                                                        onChange={handleOrderChanged}
                                                    />
                                                </Hidden>

                                                <Hidden smDown implementation="css">
                                                    <PanelDrawer
                                                        screen={'conductor'}
                                                        activeTab={tabActive}
                                                        onFiltersChange={handleFiltersChanged}
                                                        onExportClick={handleExportRequest}
                                                        onIdentificacionClick={handleShowIdentificacionDialog}
                                                    />
                                                </Hidden>
                                            </div>
                                        </div>
                                        <Dialog
                                            open={showExportImportDialog}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            className={classes.identificacionDialog}
                                        >
                                            <DialogTitle
                                                id="responsive-dialog-title"
                                                style={{ marginTop: 20 }}
                                                className={classes.content}
                                            >
                                                <span className={classes.title}>
                                                    <Term component="Tramites" text="TitleIdentificaciónMasiva" />
                                                </span>
                                            </DialogTitle>
                                            <DialogContent className={classes.content}>
                                                <div>
                                                    <span><Term component="Tramites" text={'TextoIdentificacionMasiva'} /></span>
                                                </div>
                                                <div className={classes.actionsContainer}>
                                                    {
                                                        loadingIdentificacionAccion ?
                                                            <div className={classes.centerContent}>
                                                                <CircularProgress size={75} />
                                                            </div>
                                                            :
                                                            <div className={classes.flexColumn}>
                                                                {/* Buttons export - import documents */}
                                                                <div className={classes.flexRow}>
                                                                    <div className={[classes.actionContent, classes.flexColumn].join(' ')}>
                                                                        <Button onClick={handleDownloadDatos} variant='outlined'>
                                                                            <Icon path={mdiFileDownload}
                                                                                className={classes.actionIcon}
                                                                            />
                                                                            <span><Term component="Tramites" text={'DesacargarDatosAyuda'} /></span>
                                                                        </Button>
                                                                    </div>
                                                                    <div className={[classes.actionContent, classes.border, classes.flexColumn].join(' ')}>
                                                                        <Button onClick={handleExportPlantillaNotificaciones}>
                                                                            <Icon path={mdiFileExportOutline}
                                                                                className={classes.actionIcon}
                                                                            />
                                                                            <span><Term component="Tramites" text={'ExportarPlantillaIdentificaciones'} /></span>
                                                                        </Button>

                                                                        {/* Radio buttons file type */}
                                                                        <FormControl className={[classes.formControlRadioBtns, classes.flexRow, classes.alignCenter, classes.justifyCenter].join(' ')}>
                                                                            <FormLabel id="demo-radio-buttons-group-label">
                                                                                <Term component="Tramites" text={'Format'} />
                                                                            </FormLabel>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                name="radio-buttons-group"
                                                                                defaultValue="xlsx"
                                                                                value={fileExtension}
                                                                                onChange={handleChangeFileExtension}
                                                                                className={classes.flexRow}
                                                                            >
                                                                                <FormControlLabel value={FILE_EXTENSION_XLSX} control={<Radio color='primary' />} label=".xlsx" />
                                                                                <FormControlLabel value={FILE_EXTENSION_CSV} control={<Radio color='primary' />} label=".csv" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className={[classes.actionContent, classes.flexColumn].join(' ')}>
                                                                        <Button onClick={handleOpen} variant='outlined'>
                                                                            <Icon path={mdiFileImportOutline}
                                                                                className={classes.actionIcon}
                                                                            />
                                                                            <span><Term component="Tramites" text={'ImportarIdentificaciones'} /></span>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </DialogContent>
                                            <DialogActions className={classes.actions}>
                                                <Button
                                                    onClick={handleCloseExportImportDialog}
                                                    color="primary"
                                                    autoFocus
                                                    style={{ width: "100px" }}
                                                    variant='outlined'
                                                >
                                                    <Term component={"Global"} text={"Cerrar"} />
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <WizardImporter
                                            open={open}
                                            title={translate('Wizard', 'identificacion_masiva', terms)}
                                            showIcon={true}
                                            dataElement={dataToImport ? <TableWizard data={dataToImport} hiddenCells={['idPais', 'idPoblacion']} /> : null}
                                            dataElementResult={resultData ? <TableWizardResult data={resultData} numElementsImported={numElementsImported} /> : null}
                                            disabledStep1={(errorsFile && errorsFile.length > 0) || !dataToImport}
                                            loadingUpload={loadingUpload}
                                            hasErrors={hasErrors}
                                            importDisabled={importDisabled}
                                            errorsFile={errorsFile}
                                            errorData={errorData}
                                            onUpload={handleUpload}
                                            onSubmit={handleImport}
                                            onPrevious={handlePrevious}
                                            onNew={handleNew}
                                            onClose={handleClose}
                                            onPrintJustificante={handlePrintJustificante} />
                                    </FilterDataContext.Provider>
                                </FiltersContext.Provider >
                            </OrderDataContext.Provider>
                        </OrderContext.Provider>
                    </>
            }
        </div>

    );
}

export default withStyles(styles)(Conductor);