import React, { useState, useEffect, FC, useContext, useCallback, useMemo } from "react";
import { mdiShieldPlus } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps } from "react-router-dom";
import { IRenderComponent, ITipoExpediente, IDetalleExpediente } from "gateways/tramites.interfaces";
import { CircularProgress, makeStyles, Button, Typography } from "@material-ui/core";
import ExpedienteCard from './expediente-card';
import { LiteralsContext } from "containers/shared/literals";
import { TramitesGateway } from "gateways/tramites.gateway";
import IoC from "contexts/ioc.context";
import PresentacionDialog from "./presentacion-modal";
import { translate } from "utils/i18n";
import { DocumentsUtils } from "utils/documents";
import { AlertsContext } from "contexts/alerts.context";

const useStyles = makeStyles({
    root: {
        flex: 1,
        margin: '0 1rem'
    },
    expediente: {
        marginBottom: '1rem'
    },
    componenteBaseDusti: {
        marginLeft: 0,
    },
    componenteBase: {
        marginLeft: 45,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    button: {
        width: 200
    },
});

interface IProps {
    diccionarioComponentes: { [component: string]: IRenderComponent };
    showContinuar: boolean;
    idExpediente?: number;
    onHandleShowContinuar?: () => void;
    handleNext?: () => void;
}

export const DetallePresentacion: FC<IProps & RouteComponentProps<{ idExpediente: string }>> = ({ diccionarioComponentes, showContinuar, idExpediente, onHandleShowContinuar, handleNext, match, ...props }) => {
    const [, pageDispatcher] = usePage();
    const classes = useStyles();
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const gateway: TramitesGateway = ioc.get(TramitesGateway);
    const [, alertsDispatch] = useContext(AlertsContext);

    const [loading, setLoading] = useState(true);
    const [imprimiendo, setImprimiendo] = useState(false);
    const [detalleExpediente, setDetalleExpediente] = useState<IDetalleExpediente | null>(null);
    const [tipoExpediente, setTipoExpediente] = useState<ITipoExpediente | null>(null);
    const [open, setOpen] = useState(false);
   
    const isDUSTI = useMemo(() => {
        if(props.location.pathname.toUpperCase().includes('/DUSTI/') ){ 
            return true
        } else { 
            return false
        }
    },[props.location])

    const handlePresentacion = (detalle: IDetalleExpediente) => {        
        setDetalleExpediente(detalle);
        setOpen(false);
        if (onHandleShowContinuar) {
            onHandleShowContinuar();
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldPlus,
                title: <Term component="Tramites" text="Presentación de expedientes" />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            setLoading(true);

            console.log('match', match);
            const detalleExpedienteTemp = await gateway.getDetalleExpediente(parseInt(match.params.idExpediente, 10));
            setDetalleExpediente(detalleExpedienteTemp);

            if (detalleExpedienteTemp !== null) {
                const detalleTipoExpedienteTemp = await gateway.getDetalleTipoExpediente(detalleExpedienteTemp.tipo.id);

                setTipoExpediente(detalleTipoExpedienteTemp);
            }

            setLoading(false);
        })();
    }, [gateway, match.params.idExpediente, isDUSTI]);

    const handleChangeExpediente = (detalle: IDetalleExpediente) => {
        setDetalleExpediente(detalle);
    }

    const handleImprimirJustificante = useCallback(async (detalle?: IDetalleExpediente | null) => {
        setImprimiendo(true);
        try {
            if (!!!detalle) {
                return;
            }
            if (detalle.idDocumentoJustificante !== null) {
                DocumentsUtils.downloadDoc(detalle.idDocumentoJustificante);
                return;
            }

            const idDoc = await gateway.generarJustificante(detalle.idExpediente);
            DocumentsUtils.downloadDoc(idDoc);
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setImprimiendo(false);
        }
    }, [alertsDispatch, gateway, terms]);

    // avoiding switch statements / long conditionals in react-ts   ---->   using dictionaries in TS + enum type
    enum ExpedienteSteps {
        INI = 'INI',
        PRE = 'PRE',
        ANU = 'ANU',
    }

    const handleEstadoExpediente = useCallback((detalle?: IDetalleExpediente | null) => {
        if (detalle) {
            switch (detalle.estado.id) {
                case ExpedienteSteps.INI:
                    return (<Button variant="contained" color="primary" className={classes.button} size="small" onClick={handleOpen}>
                        <Term component='Tramites' text='Presentar' />
                    </Button>);
                case ExpedienteSteps.PRE:
                    return (<Button variant="contained" color="primary" className={classes.button} size="small" onClick={() => handleImprimirJustificante(detalle)} disabled={imprimiendo}>
                        {imprimiendo && <CircularProgress size={12} />}
                        <Term component='Tramites' text='Imprimir justificante' />
                    </Button>);
                case ExpedienteSteps.ANU:
                    return (<Typography color="secondary" className={isDUSTI ? classes.componenteBaseDusti : classes.componenteBase} >
                        <Term component='Tramites' text='No se puede presentar, expediente anulado' />
                    </Typography>);
            }
        }
        return null;
    }, [ExpedienteSteps.ANU, ExpedienteSteps.INI, ExpedienteSteps.PRE, classes.button, classes.componenteBase, classes.componenteBaseDusti, handleImprimirJustificante, imprimiendo]);


    return loading || !!!tipoExpediente || !!!detalleExpediente || !!!tipoExpediente.componentes || !!!detalleExpediente.tipo
        ? <CircularProgress />
        : <div className={classes.root}>
            <div className={classes.expediente}>
                <ExpedienteCard step={ isDUSTI ? -1 : 1} title={'TipoDocumentoLiquiadcion'}>
                    <small className={ isDUSTI ? classes.componenteBaseDusti : classes.componenteBase}>
                        <b>
                            <Term component='Tramites' text={detalleExpediente.tipo.nombre} />
                        </b>
                    </small>
                </ExpedienteCard>
            </div>
            {
                tipoExpediente.componentes.map((c, index) =>
                (
                    <div key={'expediente_card_' + index} className={classes.expediente}>
                        <ExpedienteCard step={ isDUSTI ? -1 : index + 2} title={c.tipo.nombre} subTitle={c.mensajeOpcional} moreInfo={c.masInfoPlantilla}>
                            { diccionarioComponentes[c.tipo.nombre] &&
                                React.createElement(
                                    diccionarioComponentes[c.tipo.nombre].renderComponent,
                                    { onChange: handleChangeExpediente, detalle: {...detalleExpediente, isDUSTI: isDUSTI}, tipo: tipoExpediente }
                                )
                            }
                        </ExpedienteCard>
                    </div>
                )
                )
            }
            <div className={classes.expediente}>
                <ExpedienteCard step={ isDUSTI ? -1 : tipoExpediente.componentes.length + 2} title={'PresentacionExpediente'}>
                { showContinuar ?
                    <div style={{textAlign: "end", marginRight: 15}}>
                        <Button color="primary" variant="contained" size="medium" onClick={handleNext}>
                            <Term component="Global" text="Continuar" />
                        </Button>
                    </div>
                    :
                    <div className={classes.buttonList}>
                        {
                            handleEstadoExpediente({...detalleExpediente, isDUSTI})
                        }
                    </div>
                }
                </ExpedienteCard>
            </div>
            <PresentacionDialog
                open={open}
                onClose={handleClose}
                onChange={handlePresentacion}
                idExpediente={parseInt(match.params.idExpediente, 10)}
            />
        </div>;

}
