import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IFindAllQuery, IFilterData } from "./recibo.interfaces";
import { ITributo, IResultadoDomiciliacion, IDatosIban, IConvenioPlazos, ITributoRelacionado, IPagoCartaAlta, IPagoCartaMod, IDomiciliacionCompFVol, IDatosIbanPagoCarta } from "./tributos.interfaces";
import { IDatosDomiciliacion, IModifDataFechaVoluntariaDomiciliacion } from "./datos.domiciliacion.interfaces";
import { IBaseResponse } from "./base.response.interfaces";

@inject('axios')
export class TributosGateway {
    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async findAll(query?: IFindAllQuery): Promise<ITributo[]> {
        try {
            const response = await this.axios.get('tributos', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return [];
        }
    }

    public async getCount(query?: IFindAllQuery): Promise<number> {
        try {
            const response = await this.axios.get('tributos/count', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return 0;
        }
    }

    public async getFilterData(query?: IFindAllQuery): Promise<IFilterData> {
        try {
            const response = await this.axios.get('tributos/filters', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return {
                organismos: response.data.organismos.map((x: any) => ({ value: x.codigo, label: x.nombre })),
                conceptos: response.data.conceptos.map((x: any) => ({ value: x.id, label: x.nombre }))
            };
        } catch (e) {
            return {};
        }
    }

    public async getDatosDomiciliacion(idRecibo: number): Promise<IDatosIban[]> {
        const response = await this.axios.get( `tributos/getDatosDomiciliacion/${idRecibo}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getDatosPagoCarta(idRecibo: number): Promise<IDatosIbanPagoCarta[]> {
        const response = await this.axios.get(`tributos/getDatosPagoCarta/${idRecibo}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async domiciliar(datosDomiciliacion: IDatosDomiciliacion): Promise<IBaseResponse<IResultadoDomiciliacion>> {
        // Eliminamos los espacios en blanco y nos aseguramos de que las letras sean mayusculas
        datosDomiciliacion.iban = datosDomiciliacion.iban.toUpperCase().replace(/\s/, '').replace('-', '');
        datosDomiciliacion.nif = datosDomiciliacion.nif.toUpperCase().replace(/\s/, '');
        const response = await this.axios.post('tributos/domiciliar', datosDomiciliacion);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    public async valdiarPagoCarta(datosDomiciliacion: IDatosDomiciliacion): Promise<IBaseResponse<IResultadoDomiciliacion>> {
        // Eliminamos los espacios en blanco y nos aseguramos de que las letras sean mayusculas
        datosDomiciliacion.iban = datosDomiciliacion.iban.toUpperCase().replace(/\s/, '').replace('-', '');
        datosDomiciliacion.nif = datosDomiciliacion.nif.toUpperCase().replace(/\s/, '');
        const response = await this.axios.post('tributos/validaPagoCarta', datosDomiciliacion);


        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    public async exportarDomiciliados(query?: IFindAllQuery): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get('tributos/exportarDomicilados', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        const match = /^filename="?(.+?)"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async getJustificanteDomiciliacion(idOrden: number): Promise<Blob> {
        const response = await this.axios.get('tributos/justificante-domiciliacion/' + idOrden, {
            responseType: 'blob',
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getJustificantePagoCarta(idRecibo: number): Promise<Blob> {
        const response = await this.axios.get('tributos/justificante-pagoCarta/' + idRecibo, {
            responseType: 'blob',
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getConveniosRecibo(idRecibo: number): Promise<IConvenioPlazos[]> {
        const response = await this.axios.get('tributos/convenio/' + idRecibo);
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getSimilaresPC(idRecibo: number, idConvenio: number | string): Promise<ITributoRelacionado[]> {
        const response = await this.axios.get('tributos/similaresPC/' + idRecibo + '/' + idConvenio);
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getMesesPlazos(idConvenio: number | string, idioma: string): Promise<string[]> {
        const response = await this.axios.get('tributos/getMesesPlazos/' + idConvenio + '/' + (idioma === "es" ? 'E' : 'C'));
        console.log(response.data)
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async checkPendientes(idMunis: number[]): Promise<boolean> {
        const response = await this.axios.post('tributos/pendientesPC/', idMunis);
        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async altaPagoCarta(pagoCartaDto: IPagoCartaAlta): Promise<any> {
        const response = await this.axios.post('tributos/altaPagoCarta/', pagoCartaDto);
        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async modPagoCarta(pagoCartaDto: IPagoCartaMod): Promise<any> {
        const response = await this.axios.post('tributos/modPagoCarta/', pagoCartaDto);
        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async getFechasVoluntaria(idRecibo: number, idOrden: number | null): Promise<any> {
        const response = await this.axios.get(`tributos/voluntaria/${idRecibo}/${idOrden}`);

        if (response.status !== 200 && response.status !== 403)
            throw new Error('Bad response status: ' + response.statusText);

        return response.data;
    }

    public async findTributosPorFechaVoluntaria(idTributo: number, diaVoluntariaSeleccionado: string): Promise<IDomiciliacionCompFVol[] | null> {
        const response = await this.axios.get(`tributos/compatiblesVoluntaria/${idTributo}/${diaVoluntariaSeleccionado}`);

        if (response.status !== 200 && response.status !== 403)
            throw new Error('Bad response status: ' + response.statusText);

        return response.data;
    }

    public async getFechaPorDefectoDomiVoluntaria(idRecibo: number): Promise<string | undefined> {
        const response = await this.axios.get(`tributos/fechaVoluntariaDefault/${idRecibo}`);

        if (response.status !== 200 && response.status !== 403)
            throw new Error('Bad response status: ' + response.statusText);

        return response.data;
    }

    public async updateFechaVoluntariaDomiciliacion(modifDataFechaVoluntariaDomiciliacion: IModifDataFechaVoluntariaDomiciliacion): Promise<IBaseResponse<IResultadoDomiciliacion>> {
        const response = await this.axios.post('tributos/modificar/fechaVoluntaria', modifDataFechaVoluntariaDomiciliacion);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getFechaDomiciliacionIncompatible(codigoOrganismo: string): Promise<string | null> {
        const response = await this.axios.get(`tributos/getFechaDomiciliacionIncompatible/${codigoOrganismo}`);

        if (response.status !== 200 && response.status !== 403)
            throw new Error('Bad response status: ' + response.statusText);

        return response.data;
    }

    public async getFechaUltimoDiaDomi(idRecibo: number): Promise<null | string> {
        const response = await this.axios.get(`tributos/getFechaUltimoDiaDomi/${idRecibo}`);

        if (response.status !== 200 && response.status !== 403)
            throw new Error('Bad response status: ' + response.statusText);

        return response.data;
    }
}