import { Button, CircularProgress, Dialog, Typography, WithStyles, withStyles } from "@material-ui/core"
import { styles } from "../styles"
import { IDocListRN } from "../../cid.interface";
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Term from "components/term";
import { RecibosGateway } from "gateways/recibo.gateway";
import IoC from "contexts/ioc.context";



interface IProps extends WithStyles<typeof styles> {
    open: boolean,
    item: IDocListRN | undefined,
    handleClose: () => void
}



const DetailList: React.FC<IProps> = ({ classes, open, item, handleClose }) => {
    const [lineasAux, setLineasAux] = useState('');
    const [auxLoading, setIsAuxLoading] = useState<boolean>(false);
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);

    const handleRecoverAuxLines = async (item: IDocListRN) => {
        setIsAuxLoading(true)
        let result = await gateway.getLineasAuxiliaresNoIdentification(item?.idRecibo)
        setLineasAux(result)
        setIsAuxLoading(false)
    }

    useEffect(() => {
        if (item)
            handleRecoverAuxLines(item)
    }, [item]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        // TransitionComponent={Transition}
        >
            <div style={{ display: 'flex', flexDirection: 'column', margin: "5%" }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                        <Term text="Descripción del documento"></Term>
                    </Typography>
                    <Typography >
                        <Term text="Referencia"></Term>
                        {`: ${item?.ReferenciaRecibo}`}
                    </Typography>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '5%' }}>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Principal"></Term>
                        </Typography>
                        <Typography>
                            {item?.Principal} €
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Recargo"></Term>
                        </Typography>
                        <Typography>
                            {item?.Recargo} €
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Intereses"></Term>
                        </Typography>
                        <Typography>
                            {item?.Intereses} €
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="DocumentoValidoHasta"></Term>
                        </Typography>
                        <Typography>
                            {item ? moment(item.DocumentoValidoHasta).format("DD/MM/YYYY").toString() : "--"}
                        </Typography>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '5%' }}>
                    {auxLoading ?
                        <div className={classes.centerContent}>
                            <CircularProgress />
                        </div>
                        :
                        <pre className={classes.pre} dangerouslySetInnerHTML={{ __html: lineasAux }} />
                    }

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: '5%' }}>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="NIF"></Term>
                        </Typography>
                        <Typography>
                            {item?.nif}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Nombre"></Term>
                        </Typography>
                        <Typography>
                            {item?.Nombre}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Adreça"></Term>
                        </Typography>
                        <Typography>
                            {item && item.DomicilioTr
                                ? item.DomicilioTr
                                : "--"}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Objeto"></Term>
                        </Typography>
                        <Typography>
                            {item?.ObjetoTributario}
                        </Typography>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <Button onClick={handleClose} variant="contained" > Tancar</Button>
                </div>
            </div>
        </Dialog>
    );
}
export default withStyles(styles)(DetailList);