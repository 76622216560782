// LIBRARY IMPORTS
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Divider, makeStyles, useTheme } from '@material-ui/core';
import { mdiLock, mdiLockOpenVariant, mdiOpenInNew } from '@mdi/js';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';

// LOCAL IMPORTS
import { TMenuItem, TSubMenuItem } from 'containers/shared/menu-item-types';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { LiteralsContext } from 'containers/shared/literals';
import { tryReadStorageAccesoModulo } from 'utils/storage';
import { getImgIcon } from 'containers/tramites/tramites';
import { IAccesoModulo } from 'gateways/auth.interfaces';
import { translate } from 'utils/i18n';
import IconAtomic from 'atomic/atoms/icon';
import usePage from 'hooks/page.hook';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 10,
        width: '100%'
    },
    titleContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    },
    title: {
        fontSize: 22,
        color: '#004f84',
        fontWeight: 400,
        textTransform: 'uppercase',
        marginLeft: 5,
        marginBottom: 0,

    },
    text: {
        textAlign: 'justify',
        color: '#000',
        flex: 1,
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        marginRight: 30,
    },
    textDisabled: {
        textAlign: 'justify',
        color: 'grey',
        flex: 1,
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        marginRight: 30,
    },
    childContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 10,
        width: '90%'
    },
    childContainerContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    childInlineContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        width: "100%",
    }
}));



const AsLink = (to: string) => React.forwardRef((props, ref: any) => (
    <Link {...props} style={{
        textTransform: 'unset',
        textDecoration: 'none',
        width: '100%',
    }} to={to} ref={ref as any} />) as any
);



interface Props {
    item: TMenuItem,
    onLogin?: (levelProtected: 0 | 1 | 2) => void
}

const BoxMenuItem: FC<Props> = ({ item, onLogin }) => {
    // PROPS
    const { title, icon, img, children } = item;

    // HOOKS
    const theme = useTheme();
    const classes = useStyles(theme);
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [pageState,] = usePage();

    // STATES
    const [accesoModulos, setAccesoModulos] = useState<IAccesoModulo[]>([]);

    // MEMOS
    const imgIcon = useMemo(() => {
        if (img) return img;
        if (title.text) return getImgIcon(title.text)
        return undefined
    }, [title.text]);



    // USE EFFECTS

    useEffect(() => {
        const accesoModulosStoraged = tryReadStorageAccesoModulo("acceso.modulos", localStorage);
        if (accesoModulosStoraged) {
            setAccesoModulos(accesoModulosStoraged);
        }
    }, [item]);



    // FUNCTIONS

    const checkProcedimientos = (idModulo?: number) => {
        if (pageState.jwp && idModulo &&
            pageState.jwp.representante &&
            pageState.jwp.procedimientos) {
            const accesoModulo = accesoModulos.find(modulo => modulo.idModulo === idModulo);
            return accesoModulo &&
                !pageState.jwp.procedimientos.some(item => accesoModulo.procedimientos.includes(item));
        }
        return false;
    }

    const handleAccessDisabled = (levelProtected: 0 | 1 | 2) => {
        if (pageState.jwp &&
            pageState.jwp.representante) {
            handleInfoDispatch(infoDispatch, 'show-info', 'info_acceso_prohibido');
        } else {
            onLogin && onLogin(levelProtected);
        }
    };

    const getExternalUrl = (title: string, externalUrl: string) => {
        if (title === 'DiasInhabiles') {
            return pageState.lang === 'es' ? 'https://www.atib.es/normas/INH24_s.pdf' : 'https://www.atib.es/normas/INH2024_c.pdf';
        }
        if (externalUrl.includes('lang=')) {
            return externalUrl.replace('lang=', `lang=${pageState.lang}`);
        }
        return externalUrl;
    }

    const handleOnClick = (child: TSubMenuItem) => () => {
        const indexItem = item.children.findIndex((c) => c === child);
        sessionStorage.setItem('tributos-list-ref', indexItem.toString());

        if (child.onClick) child.onClick();
        else if (child.isExternLink) window.open(getExternalUrl(child.title.text, child.link), "_blank");
    }



    return (
        <div className={classes.container} >
            <div className={classes.titleContainer}>
                {imgIcon
                    ? <img width={64} height={64} className={classes.image} src={imgIcon} alt={title.text} />
                    : icon ? <IconAtomic name={icon as any} size={3} color="#004f82" /> : ''
                }

                <p className={classes.title}>
                    {translate(title.component, title.text, terms)}
                </p>
            </div>

            <div className={classes.childContainer}>
                {children.map((child, idx) => {
                    let disabled = child.protected > 0 && (pageState.jwp === null || (pageState.jwp && pageState.jwp.level < child.protected));

                    if (checkProcedimientos(child.idModulo)) {
                        disabled = true;
                    }

                    if (disabled) {
                        return (
                            <Button key={'btn' + idx} component={"button"}
                                onClick={() => handleAccessDisabled(child.protected)}
                                style={{ textTransform: 'unset', width: '100%' }}
                            >
                                <div className={classes.childContainerContent}>
                                    <div className={classes.childInlineContainer}>
                                        <p className={classes.textDisabled} dangerouslySetInnerHTML={{ __html: translate(child.title.component, child.title.text, terms) }} />
                                        <Icon size={0.8} path={mdiLock} color={'#b1b1b1'}
                                            title={
                                                child.protected > 1
                                                    ? translate("Acceso", "requiredClaveOrCertified", terms)
                                                    : translate("Acceso", "requiredUserLogin", terms)
                                            }
                                        />
                                    </div>
                                    <Divider color='grey' />
                                </div>
                            </Button>
                        )
                    }
                    else {
                        return (
                            <Button key={'btn' + idx}
                                component={
                                    child.isExternLink || (child.link === '' && child.onClick)
                                        ? "button"
                                        : AsLink(child.link)
                                }
                                onClick={handleOnClick(child)}
                                style={{ textTransform: 'unset', width: '100%' }}
                            >
                                <div className={classes.childContainerContent}>
                                    <div className={classes.childInlineContainer}>
                                        <p className={classes.text} dangerouslySetInnerHTML={{ __html: translate(child.title.component, child.title.text, terms) }} />
                                        {(child.protected > 0) &&
                                            <Icon size={0.8} path={mdiLockOpenVariant} color={'#000'} />
                                        }
                                        {(child.isExternLink) &&
                                            <Icon size={0.8} path={mdiOpenInNew} color={'#000'} />
                                        }
                                    </div>
                                    <Divider color='grey' />
                                </div>
                            </Button>
                        )
                    }
                })}
            </div>
        </div>
    );
}

BoxMenuItem.defaultProps = {
    onLogin: () => { }
}

export default BoxMenuItem;