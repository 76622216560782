import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, StyleRules, Typography, withStyles, WithStyles } from '@material-ui/core';
import { Icon } from '@mdi/react'
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import Term from './term';
import { IMessageResponse } from 'gateways/importer.interfaces';
import { mdiMicrosoftExcel } from '@mdi/js';

const styles = (): StyleRules => ({
    steps: {
        color: 'black',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 25,
        height: 25,
        cursor: 'default'
    },
    activeStep: {
        backgroundColor: '#004f84',
        color: 'white'
    },
    contentActions: {
        flexDirection: 'column'
    },
    rowButtons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    rowSteps: {
        flex: 1,
        display: 'flex',
    },
    dialog: {
        height: 600,
        padding: 20
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },
    bodyError: {
        backgroundColor: '#e3eaee',
        minHeight: '80%',
        padding: 10,
        marginTop: 5
    },
    titleFile: {
        marginLeft: 15,
        marginBottom: 10,
    },
    input: {
        marginLeft: 15,
    },
    button: {
        marginRight: 10,
    },
    pageTitle: {
        fontWeight: 300,
        fontSize: 25,
        color: '#004f84',
        textTransform: 'uppercase',
    },
    iconContainer: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#004f82',
        padding: 7,
        borderRadius: '50%',
        marginRight: 10,
    },
});

interface IProps extends WithStyles<typeof styles> {
    open: boolean,
    title: string,
    dataElement: React.ReactNode,
    dataElementResult: React.ReactNode,
    disabledStep1: boolean;
    loadingUpload: boolean;
    hasErrors: boolean;
    importDisabled: boolean;
    showIcon?: boolean,
    errorsFile?: IMessageResponse[],
    errorData?: string,    
    onUpload: (file: File) => void,
    onSubmit: () => Promise<void>,
    onPrevious: () => void,
    onNew: () => void,
    onClose: () => void,
    onPrintJustificante: () => void
}

const WizardImporter: FC<IProps> = ({open, title, dataElement, dataElementResult, disabledStep1, loadingUpload, hasErrors, importDisabled, showIcon, errorsFile, errorData, onUpload, onSubmit, onPrevious, onNew, onClose, onPrintJustificante, classes }) => {
    const terms = useContext(LiteralsContext);

    // States
    const [step, setStep] = useState(1);
    const [loading] = useState(false);
    const [errorFormat, setErrorFormat] = useState<string | null>(null);
    const [openAdvice, setOpenAdvice] = useState(false);

    useEffect(() => {
        if (step === 3) {
            window.scrollTo(0, 0);
        }
    }, [step]);
    // Events
    const handleStepBackward = useCallback(() => {
        setStep(step - 1);
        onPrevious();
    }, [onPrevious, step]);

    const handleStepForward = useCallback(() => {
        setStep(step + 1);        
    }, [step]);

    const handleImport = useCallback(async () => {
        setOpenAdvice(false);
        setStep(step + 1);
        await onSubmit();        
    }, [onSubmit, step]);

    const handleSubmit = useCallback(async () => {
        if (hasErrors) {
            setOpenAdvice(true);
        } else {
            await handleImport();
        }
    }, [handleImport, hasErrors]);

    const handleNew = useCallback(() => {
        setStep(1);
        setErrorFormat(null);
        onNew();
    }, [onNew]);

    const handleFileAttach = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            setErrorFormat(null);
            if (event.target.files.length === 0) {
                return;
            }
            const file = event.target.files[0];
            const fileFormat = file.name.split(".").slice(-1).pop()?.toUpperCase() || '';
            console.log('fileFormat',fileFormat);
            if (!['CSV','ODS','OTS','XLS','XLSX','XLTX'].includes(fileFormat)) {
                setErrorFormat(translate('Wizard', 'fichero_no_valido', terms));
            } else {
                onUpload(event.target.files[0]);
            }
        }
    }, [onUpload, terms]);

    const handlePrintJustificante = () => {
        onPrintJustificante();
    };

    const handleClose = () => {
        setErrorFormat(null);
        onClose();
    }

    return (
        <>
        <Dialog open={open} scroll="paper" fullWidth={true} maxWidth={"lg"} classes={{ paper: classes.dialog }}>
            <DialogTitle>
                <h1 className={classes.pageTitle}>
                    <span className={classes.iconContainer}>
                        <Icon
                            path={mdiMicrosoftExcel}
                            size={0.6}
                            color="white"
                        />                        
                    </span>
                    {title}
                </h1>
            </DialogTitle>
            <DialogContent>
                {
                    step === 1 &&
                    <>
                        <Typography variant="h6" className={classes.titleFile}>{translate('Wizard', 'titulo_importador', terms)}</Typography>
                        <input type='file' onChange={handleFileAttach} className={classes.input}/>
                        {
                            (errorFormat || (errorsFile && errorsFile.length > 0)) &&
                            <>
                                <p className={classes.titleFile}>
                                    {translate('Wizard', 'titulo_errores', terms)}
                                </p>
                                <div className={classes.bodyError}>
                                    {
                                        errorFormat &&
                                        <p>{errorFormat}</p>
                                    }

                                    {errorsFile && !errorFormat && errorsFile.map((e, i) => (
                                        <p key={`error_${i}`}>{e.value}: {translate('Wizard', e.messages[0], terms)}</p>
                                    ))}
                                </div>
                            </>
                        }
                    </>
                }
                {
                    step === 2 &&
                    <>
                        {
                            dataElement
                        }
                    </>
                }
                {
                    step === 3 &&
                    <>
                        {
                            dataElementResult
                        }
                    </>
                }
            </DialogContent>
            <DialogActions className={classes.contentActions}>
                <div className={classes.rowSteps}>
                    <span className={step !== 1 ? classes.steps : [classes.steps, classes.activeStep].join(' ')} >
                        1
                    </span>
                    <span className={step !== 2 ? classes.steps : [classes.steps, classes.activeStep].join(' ')}>
                        2
                    </span>
                    <span className={step !== 3 ? classes.steps : [classes.steps, classes.activeStep].join(' ')}>
                        3
                    </span>
                </div>
                <div className={classes.rowButtons}>
                    {
                        step === 3 &&
                            <Button
                                autoFocus
                                variant="contained"
                                color="inherit"
                                className={classes.button}
                                disabled={loading}                                
                                onClick={handlePrintJustificante}>
                                <Term component={"Global"} text={"Imprimir Justificante"} />
                            </Button>
                    }
                    <Button 
                        autoFocus 
                        variant="contained"
                        color="inherit"
                        className={classes.button}
                        disabled={loading || importDisabled}
                        onClick={() => {
                            setStep(1);
                            handleClose();
                        }}
                    >
                        <Term component={"Global"} text={"Cerrar"} />
                    </Button>
                    {
                        step === 2 &&
                        <>
                            <Button
                                autoFocus
                                variant="contained"
                                color="inherit"
                                className={classes.button}
                                disabled={loading}                                
                                onClick={handleStepBackward}>
                                <Term component={"Global"} text={"Previous"} />
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                {loading ?
                                <CircularProgress size={16} />
                                :
                                <Term component={"Global"} text={"Importar"} />
                                }
                            </Button>
                        </>
                    }
                    {
                        step === 1 &&
                        <Button 
                            variant="contained"
                            color="primary"
                            disabled={
                                (errorsFile && errorsFile.length > 0) || dataElement === null || disabledStep1
                            }
                            onClick={handleStepForward}
                        >
                            {
                                loadingUpload ?
                                 <CircularProgress size={24} className={classes.buttonProgress} />
                                 :
                                 <Term component={"Global"} text={"Siguiente"} />
                            }
                        </Button>
                    }
                    {
                        step === 3 &&
                        <>
                            {
                                errorData && errorData !== "" &&
                                <Button variant="contained" color="inherit" onClick={handleStepBackward}>
                                    <Term component={"Global"} text={"Previous"} />
                                </Button>
                            }
                            {
                                (errorData === undefined || errorData === "") &&
                                <Button variant="contained" color="primary" onClick={handleNew} disabled={importDisabled}>
                                    <Term component={"Global"} text={"Nuevo"} />
                                </Button>
                            }
                        </>

                    }
                </div>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openAdvice}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.identificacionDialog}
        >
            <DialogTitle
                id="responsive-dialog-title"
                style={{ marginTop: 20 }}
                className={classes.content}
            >
                <span className={classes.title}>
                    <Term component="Wizard" text="identificacion_masiva" />
                </span>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div>
                    <span><Term component="Wizard" text={'errores_identificacion_masiva'} /></span>
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    onClick={() => {setOpenAdvice(false)}}
                    color="primary"
                    autoFocus
                    style={{ width: "100px" }}
                    variant='outlined'
                >
                    <Term component={"Global"} text={"Cerrar"} />
                </Button>
                <Button
                    onClick={handleImport}
                    color="primary"
                    autoFocus
                    style={{ width: "100px" }}
                    variant='outlined'
                >
                    <Term component={"Global"} text={"Importar"} />
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default withStyles(styles)(WizardImporter);
