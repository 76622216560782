import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import IoC from 'contexts/ioc.context';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { WithStyles, withStyles, CircularProgress, Theme, CardContent, Card, Typography, MenuItem, Button } from '@material-ui/core';
import ServiciosDialog from './servicios.dialog';
import { StyleRules } from '@material-ui/styles';
import OficinasGateway from 'gateways/oficinas.gateway';
import IOficinaDto, { IOficinaFotos } from 'gateways/oficinas.interfaces';
import { Icon } from '@mdi/react';
import { mdiBriefcaseOutline, mdiCalendarClock, mdiOfficeBuilding, mdiChevronLeft, mdiMail, mdiEmail, mdiPhone, mdiMapMarker } from '@mdi/js';
import ImagePlus from './../../components/image-plus';
import Term from '../../components/term';
import usePage from 'hooks/page.hook';
import GoogleMapReact from 'google-map-react';
import env from '../../env';
import imagelogoBola from '../../resources/logoBola.png';
import imageNOFOTO from '../../resources/NOFOTO.jpg';

interface IRouteParams {
    idOficina: string;
}

const styles = (theme: Theme): StyleRules => ({
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
    },
    root: {
        minWidth: 300,
        marginLeft: 20,
        marginRight: 20,
        width: "100%",
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        marginBottom: 20,
    },
    content: {
        display: "contents",
    },
    title: {
        fontWeight: "lighter",
        color: "#2f90c4",
        display: "flex",
        marginTop: 5,
    },
    alignLeft: {
        float: "left",
    },
    icon: {
        margin: "0 10px 0 0",
    },
    button: {
        borderWidth: '1px',
        borderRadius: '3px',
        borderColor: "#696969",
        borderStyle: 'solid'
    },
    iconHeader: {
        cursor: "pointer",
        '&:hover': {
            transform: "scale(1.5, 1.5)",
        },
    },
    textHeader: {
        float: "right",
        textAlign: "end",
    },
    descriptionCard: {
        width: "inherit",
        display: "inline-flex",
        padding: "20px 0 0 0",
    },
    serviciosCard: {
        display: "grid",
        marginRight: 15,
        width: "70%",
    },
    serviciosHoraios: {
        display: "flex",
        color: "#2f90c4",
        float: "left",
        padding: "20px 0 0 20px",
    },
    subcard: {
        display: "flex",
        height: "100%",
        boxSizing: "content-box",
    },
    listaServicios: {
        marginRight: 20,
        width: 400,
        flex: 1,
    },
    horariosServiciosText: {
        paddingLeft: 10,
    },
    images: {
        display: "flex",
        flex: 1,
    },
    imageContainer: {
        paddingRight: 15,
        flex: 1,
    },
    imagePlus: {
        width: "auto",
        height: 235,
        borderRadius: "2%",
    },
    horariosCard: {
        display: "grid",
        marginLeft: 10,
        width: "30%",
    },
    horario: {
        margin: "0 15px 0",
    },
    logoMap: {
        width: 30,
        marginTop: -15,
        marginLeft: -15
    },
    [theme.breakpoints.down('xs')]: {
        root: {
            margin: 10,
        },
        cardHeader: {
            display: "grid",
            padding: 10,
            marginBottom: 20,
            marginTop: 40,
        },
        title: {
            float: "none",
            alignItems: "center",
            textAlign: "center",
            display: "block",
            paddingRight: 20,
        },
        textHeader: {
            float: "none",
            textAlign: "center",
            paddingTop: 5,
        },
        iconHeader: {
            verticalAlign: "text-top",
            marginLeft: "auto",
            width: 30,
        },
        descriptionCard: {
            display: "block",
        },
        subcard: {
            display: "block",
        },
        serviciosCard: {
            margin: 0,
            width: 300,
        },
        imageContainer: {
            width: "auto",
            textAlign: "center",
            padding: 10,
        },
        imagePlusContainer: {
            width: "auto",
        },
        images: {
            display: "block",
        },
        listaServicios: {
            width: "auto",
        },
        horariosCard: {
            margin: 10,
            width: "auto",
        },
    },
    [theme.breakpoints.down('sm')]: {
        serviciosCard: {
            margin: 0,
        },
        images: {
            padding: 0,
        },
    },
    [theme.breakpoints.down('md')]: {
        descriptionCard: {
            display: "block",
        },
        serviciosCard: {
            width: "auto",
            margin: 0,
        },
        horariosCard: {
            width: "auto",
            margin: "20px 0 0 0",
        },
        subcard: {
            display: "block",
        },
        listaServicios: {
            width: "auto",
        },
        imageContainer: {
            width: "auto",
            textAlign: "center",
            padding: 10,
            flex: 1,
        },
    },
    [theme.breakpoints.up('lg')]: {
        images: {
            paddingBottom: 15,
        },
        imagePlus: {
            height: 245,
        },
    },
});

type Props = WithStyles<typeof styles> & RouteComponentProps<IRouteParams>;

const OficinaDetalle: React.FC<Props> = ({ match, history, classes }) => {

    const ioc = useContext(IoC);
    const oficinasGateway: OficinasGateway = useMemo(() => ioc.get(OficinasGateway), [ioc]);
    const [loading, setLoading] = useState(false);
    const [{ lang }, pageDispatcher] = usePage();
    const [, setShowFilters] = useState(false);
    const handleCloseSubMenu = useCallback(() => setOpenSubMenuAnchorEl(null), []);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
        handleCloseSubMenu();
    }, [handleCloseSubMenu]);
    const [oficina, setOficina] = useState<IOficinaDto | null>(null);
    const [telefonos, setTelefonos] = useState<(string | undefined)[]>([])
    const [mails, setMails] = useState<(string | undefined)[]>([])
    const [idOficina, setIdOficina] = useState<number | null>(null);
    const [oficinaTextoServicios, setOficinaTextoServicios] = useState<string | null>(null);
    const [oficinaTextoHorarios, setOficinaTextoHorarios] = useState<string | null>(null);
    const [smallPhotoOficina, setSmallPhotoOficina] = useState<string | null>(null);
    const [largePhotoOficina, setLargePhotoOficina] = useState<string | null>(null);
    const [open, setOpen] = React.useState(false);
    const [color,] = useState('#267cab');
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const AnyReactComponent = ({ lat, lng }: any) => <img alt="oficina logo" className={classes.logoMap} src={imagelogoBola}></img>

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiOfficeBuilding,
                title: <Term component="Global" text="Oficinas y servicios" />,
                right: (
                    <>
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                    </>)
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, handleToggleSubMenu]);

    const handleCloseServiciosDialog = useCallback(() => {
        setOpen(false);
    }, []);


    const handleRedirectToList = useCallback(() => {
        history.push('/oficinas');
    }, [history]);

    useEffect(() => {
        const handleLoadOficina = async () => {
            if (idOficina === null) {
                return;
            }
            setLoading(true);
            try {
                const oficinaTemp = await oficinasGateway.findOne(idOficina);
                if (oficinaTemp) {
                    setCenter({ lat: oficinaTemp.latitud, lng: oficinaTemp.longitud });
                    setOficina(oficinaTemp);
                    setTelefonos(oficinaTemp.datosContacto.filter((x) => x.nombre === "TELEFONO").map((x) => x.valor))
                    setMails(oficinaTemp.datosContacto.filter((x) => x.nombre !== "TELEFONO").map((x) => x.valor))
                }
                setOficinaTextoHorarios(oficinaTemp.textos.filter((x) =>
                    x.nombre === "HORARIO GENERAL")[0].valor
                );
                setOficinaTextoServicios(oficinaTemp.textos.filter((x) =>
                    x.nombre === "SERVICIOS")[0].valor
                )
                if (oficinaTemp && oficinaTemp.fotos.length > 0) {
                    const sortedOffices = oficinaTemp.fotos.sort(ordenar(true))
                    setSmallPhotoOficina(sortedOffices[0].idDocumento)
                    if (oficinaTemp.fotos.length > 1) {
                        setLargePhotoOficina(sortedOffices[1].idDocumento)
                    }
                }
            } catch (e) {
                handleRedirectToList();
            } finally {
                setLoading(false);
            }
        }
        handleLoadOficina();
    }, [idOficina, oficinasGateway, handleRedirectToList, lang]);


    useEffect(() => {
        const idOficinaTemp = parseInt(match.params.idOficina, 10)
        if (isNaN(idOficinaTemp)) {
            handleRedirectToList();
            return;
        }
        setIdOficina(idOficinaTemp);
    }, [match.params.idOficina, handleRedirectToList]);

    function ordenar(ascending: boolean) {
        return function (a: IOficinaFotos, b: IOficinaFotos) {
            if (a.orden === b.orden) {
                return 0;
            }
            if (a.orden === null) {
                return 1;
            }
            if (b.orden === null) {
                return -1;
            }
            if (ascending) {
                return a.orden < b.orden ? -1 : 1;
            }
            return a.orden < b.orden ? 1 : -1;
        };
    }


    useEffect(() => {

    }, []);

    return (
        <>
            {
                !loading && oficina != null
                    ?
                    <div className={classes.root}>
                        <Card className={classes.cardHeader}>
                            <CardContent className={classes.content}>
                                <div>
                                    <Typography className={[classes.title].join(' ')} variant="h4">
                                        <Link to={"/oficinas"}>
                                            <Icon path={mdiChevronLeft}
                                                size={"40px"}
                                                color={color}
                                                className={[classes.icon, classes.iconHeader].join(' ')}
                                            />
                                        </Link>
                                        {oficina.nombre}
                                    </Typography>
                                </div>
                                <div className={classes.cardHeader} style={{marginTop:'10px'}}>
                                    <div style={{marginRight:'40px'}}>
                                        <Typography className={classes.textHeader}>
                                            <div style={{ display: 'flex', margin: '10px' }}>
                                                <Icon path={mdiMapMarker}
                                                    color={"#696969"}
                                                    size={"20px"}
                                                    className={classes.icon}
                                                />
                                                {oficina.direccion}
                                            </div>
                                            <div style={{ display: 'flex',justifyContent:'space-between', margin: '10px'}}>
                                                <div>
                                                    <Icon path={mdiPhone}
                                                        color={"#696969"}
                                                        size={"20px"}
                                                        className={classes.icon}
                                                    />
                                                    {telefonos.join(' - ')}
                                                </div>
                                                <div style={{ marginLeft: '25px' }}>
                                                    <Icon path={mdiEmail}
                                                        color={"#696969"}
                                                        size={"20px"}
                                                        className={classes.icon}
                                                    />
                                                    {mails.join(' - ')}
                                                </div>
                                            </div>
                                        </Typography>
                                    </div>
                                    <div style={{
                                        paddingInlineStart: '20px',
                                        paddingInlineEnd: '10px'
                                    }}>
                                        <Link to={"/cita-previa/cita"} >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<Icon path={mdiCalendarClock}
                                                    color={"#2196f3"}
                                                    size={"25px"}
                                                    className={classes.icon}
                                                />}
                                            >
                                                <Term component="CitaPrevia" text="Solicitar Cita" />
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div style={{ height: '40vh', width: '100%', borderRadius: 10, overflow: "hidden" }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: env.GOOGLE_API_KEY }}
                                defaultCenter={center}
                                defaultZoom={10}
                            >
                                <AnyReactComponent
                                    lat={oficina.latitud}
                                    lng={oficina.longitud}
                                />
                            </GoogleMapReact>
                        </div>
                        <div className={classes.descriptionCard}>
                            <Card className={classes.serviciosCard}>
                                <CardContent className={classes.content}>
                                    <Typography className={classes.serviciosHoraios} variant="h6">
                                        <Icon path={mdiBriefcaseOutline}
                                            size={"30px"}
                                            color={color}
                                            className={classes.icon}
                                        />
                                        <Term component="Oficinas" text="Servicios" />
                                    </Typography>
                                    <div className={classes.subcard}>
                                        <div className={classes.listaServicios}>
                                            <Typography >
                                                <div className={classes.horariosServiciosText}> {oficinaTextoServicios !== null
                                                    ?
                                                    <div dangerouslySetInnerHTML={{ __html: oficinaTextoServicios }} />
                                                    :
                                                    <ul>
                                                        <li>
                                                            <Term component="Oficinas" text="No tiene servicios" />
                                                        </li>
                                                    </ul>
                                                }
                                                </div>
                                            </Typography>
                                        </div>
                                        <div className={classes.images}>
                                            <div className={classes.imageContainer}> {smallPhotoOficina === null
                                                ?
                                                <ImagePlus
                                                    className={classes.imagePlus}
                                                    imagenSmall={imageNOFOTO}
                                                    imagenLarge={imageNOFOTO}
                                                />
                                                :
                                                <ImagePlus
                                                    className={classes.imagePlus}
                                                    imagenSmall={env.API_URL + 'documentos/' + smallPhotoOficina + '/content'}
                                                    imagenLarge={env.API_URL + 'documentos/' + smallPhotoOficina + '/content'}
                                                />
                                            }
                                            </div>
                                            <div className={classes.imageContainer}> {largePhotoOficina === null
                                                ?
                                                <ImagePlus
                                                    className={classes.imagePlus}
                                                    imagenSmall={imageNOFOTO}
                                                    imagenLarge={imageNOFOTO}
                                                />
                                                :
                                                <ImagePlus
                                                    className={classes.imagePlus}
                                                    imagenSmall={env.API_URL + 'documentos/' + largePhotoOficina + '/content'}
                                                    imagenLarge={env.API_URL + 'documentos/' + largePhotoOficina + '/content'}
                                                />
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card className={classes.horariosCard}>
                                <CardContent className={classes.content}>
                                    <Typography className={classes.serviciosHoraios} variant="h6">
                                        <Icon path={mdiCalendarClock}
                                            size={"30px"}
                                            color={color}
                                            className={classes.icon}
                                        />
                                        <Term component="Oficinas" text="Horario" />
                                    </Typography>
                                    <Typography className={classes.horario}>
                                        <div className={classes.horariosServiciosText}> {
                                            oficinaTextoHorarios !== null
                                                ?
                                                <div dangerouslySetInnerHTML={{ __html: oficinaTextoHorarios }} />
                                                :
                                                <ul>
                                                    <li>
                                                        <Term component="Oficinas" text="No tiene horarios" />
                                                    </li>
                                                </ul>}
                                        </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    :
                    <div className={classes.centerContent}>
                        <CircularProgress size={30} />
                    </div>
            }
            <ServiciosDialog idOficina={idOficina} open={open} onClose={handleCloseServiciosDialog} />
        </>
    )
}

export default withRouter(withStyles(styles)(OficinaDetalle));