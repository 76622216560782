import { inject } from "aurelia-dependency-injection";
import { IPaymentMethod } from "./payment.method.interfaces";
import { AxiosInstance } from "axios";
import { IBaseResponse } from "./base.response.interfaces";
import { IModelGateway } from "./modelGateway.interface";
import { IDatosPago } from "./datos.pago.interface";
import { Header } from "react-native/Libraries/NewAppScreen";

@inject('axios')
export class PaymentGateway {
    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async getEntidades(): Promise<IPaymentMethod[]> {
        const response = await this.axios.get('pagos');

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getModelosAPagar(idReferencia: string): Promise<IBaseResponse<IModelGateway>> {
        const response = await this.axios.get('pagos/getModelosAPagar/' + idReferencia);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getDatosPago(idReferencia: string): Promise<IBaseResponse<IDatosPago>> {        
        const response = await this.axios.get('pagos/getDatosPago/' + idReferencia);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }    

    public async pagarTributos(idFormaPago: string, modelos: IModelGateway[]): Promise<IBaseResponse<string>> {
        const response = await this.axios.post('pagos/pagarTributos/' + idFormaPago, modelos);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async pagarTributosDusti(idDeclaracion: number, idFormaPago: string, idTipoModelo: string, modelos: IModelGateway[]): Promise<IBaseResponse<string>> {        
        const response = await this.axios.post(`pagos/pagarTributosDusti?idFormaPago=${idFormaPago}&idDeclaracion=${idDeclaracion}&idTipoModelo=${idTipoModelo}`, modelos);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async confirmarPago(idOperacion: string): Promise<IBaseResponse<IModelGateway>> {
        const response = await this.axios.post('pagos/confirmarPago/' + idOperacion);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getCartaDePago(tributos: any[], token: string, individual: boolean): Promise<Blob> {        
        
        try{
            const response = await this.axios.post('pagos/getCartaDePago/' + token + '/' + individual, tributos, {
                responseType: 'blob',
            });
        
            if (response.status !== 201 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        }catch(error){
            //console.log('error',error)
            return {} as Blob
        }
    }

    public async getJustificanteModeloPago(idModelo: string): Promise<Blob> {
        try {
            const response = await this.axios.get('pagos/getJustificanteModeloPago/' + idModelo, {
                responseType: 'blob',
            });
            
            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }
    
            return response.data;
            
        } catch (error) {
            //console.log('error',error)
            return {} as Blob
        }
       
    }

    public async getJustificantePagoTributo(idTributo: number): Promise<Blob> {
        const response = await this.axios.get('pagos/getJustificantePagoTributo/' + idTributo, {
            responseType: 'blob',
        });
        
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    
    public async getJustificantePagoTributoGrupo(idTributos: string[]): Promise<Blob> {
        const response = await this.axios.post('pagos/getJustificantePagoTributoGrupo', {
            ids: idTributos
        },
        {responseType: 'blob'});
        
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    public async getJustificantePagoTributoConsultasExternas(idTributo: number, nif: string): Promise<Blob> {
        const response = await this.axios.get(`pagos/getJustificantePagoTributoConsultasExternas?idTributo=${idTributo}&nif=${nif}`, {
            responseType: 'blob',
        });
        
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getBizumPaymentFormData(modelos: IModelGateway[]): Promise<any> {
        const response = await this.axios.post('pagos/get-bizum-form', {'modelos':modelos});
        console.log('response', response);
        if (response.status !== 200 && response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }
}