import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import duti_styles from "../styles";
import { 
    IPlusvalVendedor, TCausaUserChoice, 
    TDTDatosVendedor, TDTDatosVendedorPlusval, EMPTY_DT_DATOSOPERACION_PLUSVAL, EMPTY_PLUSVAL_OPERACION, TPlusvOperacion, TDatosM081, InfoModelo081 } from "./types";
import FullLoading from "components/full-loading";
import { LiteralsContext } from "containers/shared/literals";
import { translate } from "utils/i18n";
import ExpandPanel from "components/DUTI/expand-panel";
import LabelWithInfo from "components/DUTI/label-with-info";
import INIimg from 'resources/dusti/iniciada.png';
import FINimg from 'resources/dusti/finalizada.png';
import DUTIGateway from "gateways/duti.gateway";
import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";
import WarningIcon from '@material-ui/icons/Warning'
import PlusvalVendedorForm, { TTipoPlusval } from "components/DUTI/plusval-vendedor-form";
import PlusvalVendedorDetail from "components/DUTI/plusval-vendedor-detail";

const useStyles = makeStyles(duti_styles);
const EMPTY_M081: TDatosM081 = {
    importeMetodoObjetivo: null,
    importeMetodoReal: null,
    totalIngresar: undefined,
    adquisiciones: [],
    declaracionResponsable: false,
    nifSujetoPasivo: undefined,
    porcentajeTransm: undefined
}
interface IProps {
    idDeclaracion: number
    tipoPlusval:  TTipoPlusval| undefined;
    sujetoPasivo: boolean | undefined;
    valorDeclarado: number;
    fecha: Date,
    vendedores: TDTDatosVendedor,
    compradores: {id: string,nombre:string}[], // id -> nif, nombre: nombre y apellidos - NIF
    initialDatos: TDTDatosVendedorPlusval,
    handleContinuar: (v:TDTDatosVendedorPlusval, haveChanges:boolean,notClean: boolean) => void
    handleBack: () => void,
    handleCancelDUTI: (c: TCausaUserChoice, v:TDTDatosVendedorPlusval) => void
}

interface IGestionSujeto {
    idGestionSujeto: number,
    nifSujetoPasivo: string,
}

const DatosVendedorPlusvalia: FC<IProps> = ({idDeclaracion,tipoPlusval, sujetoPasivo = true, valorDeclarado,fecha, vendedores,compradores, initialDatos, /*setChanges,*/handleContinuar,handleBack, handleCancelDUTI}) => {
    const terms = useContext(LiteralsContext);
    const classes= useStyles();
    const [,alertsDispatch] = useContext(AlertsContext)
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);

    const [datosVendPlusval, setDatosVendPlusval] = useState([] as TDTDatosVendedorPlusval)
    // Gestión contenido Exand => detalle o formulario
    const EMPTY_ON_EDIT: {savedChanges: boolean, idx: number} = {savedChanges: true, idx: -1}     
    const [onEdit, setOnEdit] = useState({...EMPTY_ON_EDIT});
    const [haveChanges, setHaveChanges]=useState(false);
    const [idGestion, setIdGestion] = useState<number | null>(null);
    const [gestionesSujeto, setGestionesSujeto] = useState<IGestionSujeto[]>([]);

    const ready = useMemo(() => {
        if(tipoPlusval === 'autoliquidacion'){
            const all = datosVendPlusval.map(item => (item.modelo081 && typeof item.modelo081.totalIngresar === 'number') ? true: false)
            return all.includes(false) ? false : true
        }
        if(tipoPlusval === 'comunicacion'){
            const all = datosVendPlusval.map(item => item.modelo082?.nifSujetoPasivo ? true: false)
            return all.includes(false) ? false : true
        }
        if(tipoPlusval === 'declaracion'){
            const all = datosVendPlusval.map(item => item.modelo080?.nifSujetoPasivo ? true: false)
            return all.includes(false) ? false : true
        }
    },[datosVendPlusval, tipoPlusval])    
    
    const onContinuar = async() => {
        if(tipoPlusval === 'autoliquidacion' ||tipoPlusval === 'comunicacion'){
            // validaciones relizadas anteriormente
            handleContinuar(datosVendPlusval,haveChanges,false)
        } else {
            let err =false
            let vendWithErr: string[] = []
            if(tipoPlusval === 'declaracion' ){
                // Validaciones - todos los nifs definidos
                const allNifs = datosVendPlusval.map( (v, idx) => {
                    if(!v.modelo080){ 
                        vendWithErr.push(v.nifVendedor)
                        return false 
                    }
                    const isOK = (v.modelo080.nifSujetoPasivo !== undefined && v.modelo080.nifSujetoPasivo !== "")
                    if(!isOK){ vendWithErr.push(v.nifVendedor)}
                    return isOK;
                })
                err = allNifs.includes(false)
            }
    
            if(!err){
                handleContinuar(datosVendPlusval,haveChanges,false)
            } 
        }
    }

    const setIdGestionSujeto = (idGestionSujeto: number, nifSujetoPasivo: string) => {
        if (!gestionesSujeto.some(g => g.idGestionSujeto === idGestionSujeto)) {
            gestionesSujeto.push({idGestionSujeto, nifSujetoPasivo});
            setGestionesSujeto([...gestionesSujeto]);
        }
    }

    /** --------------------------  */


    useEffect(() => {
        setInitialLoading(true)
        // Precragar obj
        let infoTosend : IPlusvalVendedor[]= [...initialDatos];
        //console.log('initialDatos ', initialDatos)
        let sameNIFs : boolean[]= []
        let requiredCalcM08x = false
        if(initialDatos && initialDatos.length === vendedores.length ){
            // ver si algun nif de vendedor ha cambiado
            sameNIFs = vendedores.map(v => {
                const find = initialDatos.find( item => item.nifVendedor === v.nif)
                return find ? true : false
            })
        }
        requiredCalcM08x = sameNIFs.length>0 && sameNIFs.includes(false)
        if(initialDatos.length=== 0 || initialDatos.length !== vendedores.length || requiredCalcM08x){
            infoTosend = vendedores.map( v => { 
                //console.log('numOperacionesAdquisicion - ', typeof v.numOperacionesAdquisicion)
                const op: TPlusvOperacion[] = new Array( Number(v.numOperacionesAdquisicion) ).fill({...EMPTY_PLUSVAL_OPERACION});
                //console.log('numOperacionesAdquisicion - ',op)
                return ({
                    ...EMPTY_DT_DATOSOPERACION_PLUSVAL,
                    nifVendedor: v.nif,
                    operaciones: op,
                    /**sujetoPasivo => no aplic ane comunicacion -------------------------------------------------
                     * TRUE = tiene condicionSujPasivo
                     * FALSE = es GUIRI*/
                    sujetoPasivo: sujetoPasivo, 
                    nifSujetoPasivo:( sujetoPasivo || tipoPlusval === 'comunicacion') ? v.nif : '',
                    // -------------------------------------------------------------------------------------------
                    modelo080: tipoPlusval === 'declaracion' ? { nifSujetoPasivo: sujetoPasivo==true ? v.nif: undefined }: undefined,
                    modelo082: tipoPlusval === 'comunicacion' ? { nifSujetoPasivo: v.nif }: undefined ,
                    modelo081: tipoPlusval === 'autoliquidacion' ? {
                        ...EMPTY_M081,
                        nifSujetoPasivo: v.nif,
                    }  : undefined
                })
            })
            setHaveChanges(true)
        }
        //console.log('info - ',infoTosend)
        setDatosVendPlusval([...infoTosend])
        setInitialLoading(false)
    }, [])

    return (
        <Grid container direction='column' className={classes.componenteContainer}>
            <FullLoading loading={ loading || initialLoading } animation={{type:'intricom',name:'DUSTI'}} />          
            {!initialLoading && 
            <Grid item container className={classes.columnContainer}>
                <LabelWithInfo type="title" fieldTerm="DT_DatosPlusvalVend_title" moreInfo= {{template: 'info_title_DT_DatosPlusvalVend_title', size: 'xs'}}/>  
                <div style={{flex:1, flexGrow:1, overflowY: 'auto'}}> 
                    {datosVendPlusval.map( (v, idx) => {
                        console.log('datosVendPlusval', v, tipoPlusval)
                        const estado = tipoPlusval === 'comunicacion' 
                        ? v.modelo082?.nifSujetoPasivo ? 'FIN' : 'PEN'
                        : tipoPlusval === 'declaracion' 
                            ? (v.modelo080?.nifSujetoPasivo && typeof v.modelo080.valorAdquisicion=== 'number' && typeof v.modelo080.valorTransmision === 'number') ? 'FIN' : 'PEN'
                            : (v.modelo081 && typeof v.modelo081.totalIngresar === 'number') ? 'FIN' : 'PEN';
                        return (
                            <ExpandPanel
                                initialExpand={idx === 0}
                                key={v.nifVendedor} 
                                withDelete={false}
                                renderHeader={() => (
                                    <Grid item container direction="row" justify="space-between" alignItems="center" style={{marginLeft:10}} >
                                        <Grid item md={6} lg={7} className={classes.row} >
                                            <Typography>{v.nifVendedor}</Typography>
                                            <Typography style={{marginRight: 10, marginLeft: 10}}>{'-'}</Typography>
                                            <Typography>{vendedores.find(vend => vend.nif === v.nifVendedor)?.nomApe}</Typography>
                                        </Grid>
                                        <Grid item md={3} lg={2} className={classes.row}>
                                            <Typography>{vendedores.find(vend => vend.nif === v.nifVendedor)?.porcentaje}</Typography>
                                            <Typography>{translate('DUTI','porcentajeTitularidad',terms)}</Typography>
                                        </Grid>
                                        <Grid item md={3} lg={3} className={classes.row} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                                            <Typography style={{marginRight: 10}}>{translate('DUTI',`plusval_vend_datos_${estado}`,terms)}</Typography>
                                            <img width={30} height={30} className={classes.image} alt={'estado'} 
                                                src={estado==='FIN' ? FINimg : INIimg}
                                            />
                                            { onEdit.idx === idx && !onEdit.savedChanges &&
                                                <div style={{ marginLeft:10}}>
                                                    <WarningIcon/> 
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                )} 
                                renderContent={() => (
                                    <>
                                        { onEdit.idx !== idx &&
                                         <PlusvalVendedorDetail
                                            editable={onEdit.idx === -1}
                                            item={v}
                                            vendedores={vendedores}
                                            tipoPlusval={tipoPlusval}
                                            sujetoPasivo={sujetoPasivo}
                                            porcentaje={100}
                                            compradores={compradores}
                                            valorDeclarado={valorDeclarado}
                                            onEdit={() => setOnEdit({idx: idx, savedChanges: false})}
                                        />
                                        }
                                        { onEdit.idx === idx && tipoPlusval !== undefined &&
                                            <PlusvalVendedorForm 
                                                idDeclaracion={idDeclaracion}
                                                p_item={v}
                                                infoVendedor={vendedores.find(vend => vend.nif === v.nifVendedor)}
                                                tipoPlusval={tipoPlusval}
                                                sujetoPasivo={sujetoPasivo}
                                                porcentaje={100}
                                                comprSelect={compradores}
                                                valorDeclarado={valorDeclarado}
                                                fechaLimite={fecha}
                                                idGestion={idGestion}
                                                idGestionSujeto={gestionesSujeto.find(g => g.nifSujetoPasivo === v.nifVendedor)?.idGestionSujeto}
                                                setIdGestion={setIdGestion}
                                                setIdGestionSujeto={setIdGestionSujeto}
                                                onCancel={() => setOnEdit({...EMPTY_ON_EDIT})}
                                                onSave={(newItem:IPlusvalVendedor) => {
                                                    setHaveChanges(true)
                                                    const newDPV = datosVendPlusval.map(( item, idx )=> (idx === onEdit.idx) ? newItem: item)
                                                    setDatosVendPlusval([...newDPV])
                                                    setOnEdit({...EMPTY_ON_EDIT})
                                                }}
                                            />
                                        }
                                       
                                    </>
                                )}
                            />
                        )
                    })}

                </div>
                
            </Grid>}
            <Grid item container direction="row" justify="space-between" alignItems="flex-end" className={classes.footerColumnContainer}>
                <Button variant="contained" onClick={handleBack} color="inherit">
                    {translate('DUTI', 'btn_back',terms)}
                </Button>
                <div>
                    <Button style={{marginRight: 10}}
                        variant="outlined" color="primary"
                        onClick={() => {
                            handleCancelDUTI('continuar-mas-tarde', datosVendPlusval)
                            // Modal que muestra el useBlocker - DutiComponent
                            // alertsDispatch({
                            //     type: 'show-alert',
                            //     payload: {
                            //         message: translate('DUTI', 'confirm_cancelDUTI_user_choice', terms),
                            //         variant: 'warning',
                            //         hasCustomAction: true,
                            //         defaultCloseActionTemplateName: translate("DUTI", "no", terms).toUpperCase(),
                            //         actionTemplateName: translate("DUTI", "si", terms).toUpperCase(),
                            //         handleCustomAction: () => { 
                            //             handleCancelDUTI('continuar-mas-tarde', datosVendPlusval)
                            //             alertsDispatch({type: 'hide-alert'}) 
                            //         }
                            //     }
                            // });
                        }} 
                        
                    >
                        {translate('DUTI', 'btn_pause_duti',terms)}
                    </Button>
                
                    <Button disabled={!ready}
                        variant="contained" 
                        onClick={onContinuar} color="primary">
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
                
            </Grid>
        </Grid>
    )
}

export default DatosVendedorPlusvalia;


