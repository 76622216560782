import { Button, CircularProgress, Dialog, Typography, WithStyles, withStyles } from "@material-ui/core"
import { styles } from "../styles"
import { IDadaRebut, IDocDO, IDocListRN } from "../../cid.interface";
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Term from "components/term";
import { RecibosGateway } from "gateways/recibo.gateway";
import IoC from "contexts/ioc.context";




interface IProps extends WithStyles<typeof styles> {
    open: boolean,
    item: IDocDO | undefined,
    handleClose: () => void
}



const DomiDetail: React.FC<IProps> = ({ classes, open, item, handleClose }) => {
    const [lineasAux, setLineasAux] = useState('');
    const [auxLoading, setIsAuxLoading] = useState<boolean>(false);
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);

    const handleRecoverAuxLines = async (item: IDocDO) => {
        setIsAuxLoading(true)
        let result = await gateway.getLineasAuxiliaresNoIdentification(item?.domi.idRecibo)
        setLineasAux(result)
        setIsAuxLoading(false)
    }

    useEffect(() => {
        if (item)
            handleRecoverAuxLines(item)
    }, [item]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        // TransitionComponent={Transition}
        >
            <div style={{ display: 'flex', flexDirection: 'column', margin: "5%" }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                        <Term text="descripcionDocumento" component="Tramites"></Term>
                    </Typography>
                    <Typography >
                        <Term text="Referencia"></Term>
                        {`: ${item?.domi.Refe}`}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '5%' }}>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="nif"></Term>
                        </Typography>
                        <Typography>
                            {item?.domi.DF_Nif}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="recargo" component="Tramites"></Term>
                        </Typography>
                        <Typography>
                            {item?.domi.DF_Nom}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Adreça"></Term>
                        </Typography>
                        <Typography>
                            {item?.domi.DF_DomNumEscPisPta}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Objecte"></Term>
                        </Typography>
                        <Typography>
                            {item?.domi.Obj}
                        </Typography>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', marginTop: '5%' }}>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Municipio"></Term>
                        </Typography>
                        <Typography>
                            {item?.domi.Muni}
                        </Typography>
                    </div>
                    <div >
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Term text="Concepto"></Term>
                        </Typography>
                        <Typography>
                            {item?.domi.ConCString}
                        </Typography>
                    </div>
                </div>


                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', marginTop: '5%' }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                        <Term text="datosDelRecibo" component="Tramites"></Term>
                    </Typography>
                    {auxLoading ?
                        <div className={classes.centerContent}>
                            <CircularProgress />
                        </div>
                        :
                        <pre className={classes.pre} dangerouslySetInnerHTML={{ __html: lineasAux }} />
                    }

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <Button onClick={handleClose} variant="contained" > Tancar</Button>
                </div>
            </div>
        </Dialog >
    );
}
export default withStyles(styles)(DomiDetail);