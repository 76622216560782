import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IPlusvalVendedor, TCausaDatosTramGenerados, TDUSTIInfo, TPasoDatosTransmision, TPasoTramitesGenerados } from "./types";
import { LiteralsContext } from "containers/shared/literals";
import { IDUTIStepStepper } from "components/DUTI/stepper-duti";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, makeStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import FullLoading from "components/full-loading";
import duti_styles from "../styles";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import CheckBox from "components/DUTI/check-box";
import { ITasasTramiteMunicipio, TEstadoDeclaracion } from "gateways/duti.interface";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiEyeCircleOutline } from "@mdi/js";
import { AlertsContext } from "contexts/alerts.context";
import TypedInputNumber from "components/DUTI/typed-input-number";
import InputText from "components/DUTI/input-text";
import ModalModeloBorrador from "components/DUTI/modal-modelo-borrador";
import { BLUE } from "containers/shared/colors";
import ModalJustificanteBorrador from "components/DUTI/modal-justificante-borrador";
import { renderConfirmDUSTI } from "../utils";
import { ContenidosGateway } from "gateways/contenido.gateway";
import usePage from "hooks/page.hook";
import { CustomError } from "utils/custom.error";


import AEATLogo from '../../../resources/dusti/AEAT_listado2.jpg';
import CatastroLogo from '../../../resources/dusti/Catastro_listado.jpg';
import AtibLogo from '../../../resources/dusti/ATIB_listado.jpg';




interface ITramitesGenerados {
    idDeclaracion: number
    idPaso: number,
    steps: IDUTIStepStepper[] | null
    setFinished: (steps: IDUTIStepStepper[]|null) => void,
    onExit: () => void,
    handleBack: (steps: IDUTIStepStepper[]|null) => void,
    setShouldBlock: (v: boolean) => void,
}
const useStyles = makeStyles(duti_styles);

const TramitesGenerados: FC<ITramitesGenerados> = ({idDeclaracion, idPaso, steps, handleBack, onExit, setFinished, setShouldBlock}) => {
    const terms = useContext(LiteralsContext);
    const classes= useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);

    const [loading, setLoading] = useState(false);
    const [ datosTransmision, setDatosTransmision] = useState<TPasoDatosTransmision | undefined>(undefined)
    const [ tasas, setTasas] = useState<ITasasTramiteMunicipio[]>([])
    const [ dirFiscal, setDirFiscal] = useState<string>('-')

    const [ datosTramitesGenerados, setDatosTramitesGenerados] = useState<TPasoTramitesGenerados>({} as TPasoTramitesGenerados)
    const [ plusvalKey,setPlusvalKey] = useState<keyof Pick<TPasoTramitesGenerados, 'modelo080'|'modelo081'|'modelo082'> | undefined>(undefined)
    const [toShow, setToShow]= useState({
        m600: false,
        plusval:false, 
        com_domfisc: false,
        com_catastro: false,
        tasas: false,
    })

    const [borrador, setBorrador] = useState<'600' | '080' | '081' | '082' | undefined>(undefined)
    const [borradorJustif, setBorradorJustif] = useState< 'ibi' | 'aeat' | 'tasas'| undefined>(undefined)

    const [show, setShow] = useState(false)
    const [showJustif, setShowJustif] = useState(false)
    const [modalConfirmaFinalizar, setModalConfirmaFinalizar] = useState(false)
    const [conforme, setConforme] = useState(false)
    const [conformeError, setConformeError] = useState(false)
    const [loadingMSG, setLoadingMSG]=useState<string| undefined>(undefined)
    const componentMounted= useRef(true);
    const [toRender,setToRender]= useState<TDUSTIInfo>({} as TDUSTIInfo)
    const [cancelMSG,setCancelMSG]=useState('')

    const [status, setStatus] = useState<TEstadoDeclaracion | undefined>(undefined)


    const handleCancelDUTI = async (causa: TCausaDatosTramGenerados) => {
        try {
            setLoading(true)
            // change info dusti - status CANCEL
            let result: {idDeclaracion: number; saved: boolean;infoDeclaracion: TDUSTIInfo|null}= {idDeclaracion: idDeclaracion, saved: false, infoDeclaracion: null}
            if(causa === 'continuar-mas-tarde'){
                result = await dutiGateway.savePaso(idDeclaracion,idPaso, {...datosTramitesGenerados})
            } else {
                // change info dusti - status CANCEL
                const isCanceled = await dutiGateway.cancelDeclaracion(idDeclaracion,idPaso, causa)
                result={...result, saved: isCanceled}
            }
        
            setLoading(false)
            if( result.saved ){ 
                onExit()
            } else {
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_cancel_dusti', terms).concat(` id:${idDeclaracion}, paso: TramitesGenerados, motivo: ${causa}`),
                        variant: "error"
                    }
                })
            }
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_save_paso', terms),
                        variant: "error"
                    }
                });
            }
        }  
        
    }

    const onContinuar = async () => {
        try{
            // change info dusti - status CANCEL
            if(status && status === 'INI'){
                setLoading(true);
                try {
                    const response = await dutiGateway.savePaso(idDeclaracion,idPaso, datosTramitesGenerados)
                    if (response.saved){ 
                        setToRender(response.infoDeclaracion);
                        setModalConfirmaFinalizar(true);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_save_paso', terms),
                                variant: "error"
                            }
                        });
                    }
                    
                    const declaracion = await dutiGateway.getDeclaracion(idDeclaracion);
                    setStatus(declaracion.estado.codigo);
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                onFinish()
            }
           
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_save_paso', terms),
                        variant: "error"
                    }
                });
            }
        }  
    }

    const onFinish = async () => {
        if (status === "INI") {
            if (conforme) {
                setLoadingMSG(translate('DUTI','generando_modelos', terms));
                setLoading(true);

                try {
                    const datosModelosFinalizados = await dutiGateway.finalizarDusti(idDeclaracion);
                    setLoading(false);
                    if(datosModelosFinalizados.ok){
                        // En el caso de que no sea necesario realizar el pago redirigimos directamente a la pantalla de trámites
                        if (!datosModelosFinalizados.pago) {
                            steps?.forEach( s => {
                                s.active = (s.termTitle === 'FinalizacionPago');
                            });
                        }                        
                        setFinished(steps);
                    } else{
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','finalizar_dusti_error', terms),
                                variant: "error"
                            }
                        });
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setConformeError(true);
            }
        } else {
            setFinished(steps);
        }                                       
    }

    useEffect(() => {
         
        (async() => {
            if (idDeclaracion > 0) {
                // inicializamos componente            
                setLoading(true);

                try {
                    //console.log('effect recuperar dusti', idDeclaracion);
                    const dutiCancelMSG = await contenidoGateway.getContent('info_modal_no_tramites_seleccionados',{});
                    // Extraer datos XML
                    const declaracion = await dutiGateway.getDeclaracion(idDeclaracion)
                    const xmlToObject = await dutiGateway.getDetalleDeclaracion(idDeclaracion)
                    //console.log('xmlToObject ', xmlToObject)
                    const tasas = await dutiGateway.getTasasTramitesMunicipio(
                        xmlToObject.DatosTransmision.datosPrevios.municipio?.id,
                        xmlToObject.DatosTransmision.datosPrevios.tipoTransmitente ,
                        xmlToObject.DatosTransmision.datosPrevios.actuaNombrePropio
                    )
                    if(componentMounted.current){
                        setStatus(declaracion.estado.codigo)
                        setTasas(tasas)
                        setCancelMSG(dutiCancelMSG[0].contenido)
                        setDatosTransmision({ ...xmlToObject.DatosTransmision})
                        let COM_domFisc = false;
                        let valorDomFisc= ''
                        if(xmlToObject.DatosTransmision.datosPrevios.tramitesGest.COM_DOMFISC === true){
                            const sujeto = xmlToObject.DatosTransmision.datosPrevios.tipoTransmitente === 'comprador' 
                                ? xmlToObject.DatosTransmision.datosComprador.find(comp => comp.nif === xmlToObject.DatosTransmision.datosPrevios.nifSujeto )
                                : xmlToObject.DatosTransmision.datosVendedor.find(vend => vend.nif === xmlToObject.DatosTransmision.datosPrevios.nifSujeto )
                            if(sujeto && sujeto.direccionFiscal && /*sujeto.direccionFiscal!== undefined &&*/ sujeto.direccionFiscal.tipo !== 'fiscal'){
                                COM_domFisc= true
                                valorDomFisc=sujeto.direccionFiscal.direccion
                            }
                        }
                        const defaultTramitesGenerados= {
                            modelo600: Object.values(xmlToObject.DatosTransmision.datosPrevios.tramitesGest.MODELO600).includes(true),
                            modelo080: xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL !== false  && xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.declaracion === true,
                            modelo081: xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL !== false && xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.autoliquidacion === true,
                            modelo082: xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL !== false  && xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.comunicacion === true,
                            comunicacionCatastro: xmlToObject.DatosTransmision.datosPrevios.tramitesGest.IBI,
                            comunicacionTasasATIB: xmlToObject.DatosTransmision.datosPrevios.tramitesGest.TASAS_MUNI,
                            comunicacionDomicilioFiscalAEAT: COM_domFisc, //xmlToObject.DatosTransmision.datosPrevios.tramitesGest.COM_DOMFISC // validar qu eno sea la direccion AEAT actual
                        }
                        
                        setPlusvalKey(
                            xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL !== false  && xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL 
                            ?
                                xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.declaracion === true 
                                    ? 'modelo080'
                                    : xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.autoliquidacion === true  ? 'modelo081' : 'modelo082'
                            :undefined
                        )
                        setToShow({
                            m600:  (xmlToObject.DatosTransmision.datosPrevios.tramitesGest.MODELO600=== false || Object.values(xmlToObject.DatosTransmision.datosPrevios.tramitesGest.MODELO600).includes(true)) ? true: false,
                            plusval:( xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL=== false || Object.keys(xmlToObject.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL).length === 0 )? false: true,
                            com_domfisc: COM_domFisc, //xmlToObject.DatosTransmision.datosPrevios.tramitesGest.COM_DOMFISC ===true,
                            com_catastro: xmlToObject.DatosTransmision.datosPrevios.tramitesGest.IBI===true,
                            tasas:  xmlToObject.DatosTransmision.datosPrevios.tramitesGest.TASAS_MUNI===true,
                        })
                        
                        if(COM_domFisc){ 
                            setDirFiscal(valorDomFisc) 
                        }
                        setDatosTramitesGenerados({...defaultTramitesGenerados});
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                } finally {
                    setLoading(false);
                }
            }
            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
       
    }, [idDeclaracion])

    return(
        <>
            <Grid container direction='column' className={classes.componenteContainer}>
                <FullLoading loading={ loading } msg={loadingMSG} animation={{type:'intricom', name: 'DUSTI'}} />  
                <Grid item container className={classes.columnContainer}>
                    <div style={{flex:1, flexGrow:1, overflowY: 'auto'}}>
                        <Grid container direction="column">
                            {toShow.m600 &&   
                                <Grid item className={classes.sectionJustif}> 
                                    <div className={classes.row}>
                                        
                                        <img className={classes.logoTramites} src={AtibLogo} alt = "logo-atib"/>
                                        
                                        <div className={[classes.column,classes.grow].join(' ')}>
                                            <CheckBox fieldTerm='tramite_modelo_600'
                                                disabled={status && status !== "INI"}
                                                value={datosTramitesGenerados.modelo600}
                                                onChangeValue={(v:boolean, err:boolean) => {
                                                    setDatosTramitesGenerados(curr =>({...curr, modelo600: v}))
                                                }}
                                            />
                                            <div style={{display:'flex', flexDirection:'row',flexWrap:'wrap', marginTop: 10, paddingRight:10, paddingLeft: 30}}>
                                                {datosTransmision?.datosOperacionModelo600.recargo && datosTransmision?.datosOperacionModelo600.recargo>0 ?
                                                        <TypedInputNumber key="recargo"
                                                            type="money"
                                                            disabled
                                                            allowZero={true}
                                                            required={false}
                                                            min={0}
                                                            maxDecimal={2}
                                                            fieldTerm="recargo"
                                                            value={datosTransmision?.datosOperacionModelo600.recargo ?? 0}
                                                            onChangeValue={(v, err) => {}}
                                                            className={classes.input160 } 
                                                        />
                                                        : null
                                                    }
                                                    {datosTransmision?.datosOperacionModelo600.interesesDemora && datosTransmision?.datosOperacionModelo600.interesesDemora>0 ?
                                                        <TypedInputNumber key="interesesDemora"
                                                            type="money"
                                                            disabled
                                                            allowZero={true}
                                                            required={false}
                                                            min={0}
                                                            maxDecimal={2}
                                                            fieldTerm="interesesDemora"
                                                            value={datosTransmision?.datosOperacionModelo600.interesesDemora ?? 0}
                                                            onChangeValue={(v, err) => {}}
                                                            className={classes.input160} 
                                                        />
                                                        : null
                                                    }
                                                    {datosTransmision?.datosOperacionModelo600.totalIngresar && datosTransmision?.datosOperacionModelo600.totalIngresar>0 ?
                                                        <TypedInputNumber key="totalIngresar"
                                                            type="money"
                                                            disabled
                                                            allowZero={true}
                                                            required={false}
                                                            min={0}
                                                            maxDecimal={2}
                                                            fieldTerm="totalIngresar"
                                                            value={datosTransmision?.datosOperacionModelo600.totalIngresar ?? 0}
                                                            onChangeValue={(v, err) => {}}
                                                            className={classes.input160} 
                                                            />
                                                        : null 
                                                    }
                                            </div>
                                        </div>
                                        <div className={classes.btnTramites}>
                                            <Button variant="outlined" color='primary' size="small"
                                                startIcon= { <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} /> }
                                                onClick={() => {
                                                    setBorrador('600')
                                                    setShow(true)
                                                }}
                                            >
                                                {translate('DUTI','btn_consulta_borrador',terms)}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            }

                            {(toShow.plusval ||toShow.tasas) &&
                                <Grid item container direction="row" className={classes.sectionJustif}>
                                    <div className={classes.row}>
                                        <img className={classes.logoTramites} src={`https://www.atib.es/App_Themes/LogosMuni/${datosTransmision?.datosPrevios.municipio?.code}.png`} alt = "logo-muni"/>
                                    </div>
                                    <div className={[classes.column,classes.grow3].join(' ')} >
                                        {toShow.plusval && 
                                            <div className={[classes.sectionJustifNoMargin, classes.row].join(' ')} >
                                                <div className={[classes.column,classes.grow2].join(' ')}>
                                                    <CheckBox 
                                                        fieldTerm='tramite_plusvalia_atib'
                                                        addToLabel={
                                                            ' - '.concat(
                                                                plusvalKey === 'modelo081' 
                                                                ? translate('DUTI','tramite_plusvalia_autoliquidacion_atib',terms)
                                                                : plusvalKey === 'modelo080' 
                                                                    ? translate('DUTI','tramite_plusvalia_declaracion_atib',terms) 
                                                                    : translate('DUTI','tramite_plusvalia_comunicacion_atib',terms)
                                                            )
                                                        }
                                                        disabled={status && status !== "INI"}
                                                        value={(datosTramitesGenerados.modelo080 || datosTramitesGenerados.modelo081 || datosTramitesGenerados.modelo082) }
                                                        onChangeValue={(v:boolean, err:boolean) => {
                                                            if(plusvalKey){
                                                                setDatosTramitesGenerados(curr =>({...curr, [plusvalKey]: v}))
                                                            }
                                                        }}
                                                    />
                                                    <div style={{ paddingLeft: 30, marginTop: 10}}>
                                                        {datosTransmision?.datosOperacionPlusvalia && datosTransmision?.datosOperacionPlusvalia.length > 1 ?
                                                        <>
                                                            <div className={classes.row} style={{alignItems:'flex-start'}}>
                                                                <div className={classes.column} style={{maxWidth:340, marginRight: 30, alignContent:'center'}}>
                                                                    <Typography>
                                                                        {translate('DUTI', 'tramiGen_totalDeclarantes', terms)}: {datosTransmision?.datosOperacionPlusvalia.length}
                                                                    </Typography>
                                                                    {/* <Typography style={{fontSize: 15, fontStyle: 'italic'}}>{translate('DUTI','m081_ver_borrador',terms)}</Typography> */}
                                                                </div>
                                                                <TypedInputNumber 
                                                                    key="totalIngresar"
                                                                    type="money"
                                                                    disabled
                                                                    allowZero={true}
                                                                    required={false}
                                                                    min={0}
                                                                    maxDecimal={2}
                                                                    fieldTerm="totalIngresar"
                                                                    value={  Number(
                                                                        datosTransmision?.datosOperacionPlusvalia.map(m => (m.modelo081?.totalIngresar ?? 0)).reduce( 
                                                                            (valorAnterior, valorActual) => {
                                                                                return (valorAnterior+valorActual)
                                                                            },0).toFixed(2)
                                                                        )
                                                                    }
                                                                    onChangeValue={(v, err) => {}}
                                                                    className={classes.input160} 
                                                                />  
                                                            </div>
                                                            
                                                        </>
                                                                            
                                                        :
                                                            <div className={classes.row}>
                                                                <InputText
                                                                    required 
                                                                    disabled
                                                                    fieldTerm='tramiGen_nifSuetoPasivo'
                                                                    value = {plusvalKey ? datosTransmision?.datosOperacionPlusvalia[0][plusvalKey as keyof Pick<IPlusvalVendedor, 'modelo080'|'modelo081'|'modelo082'>]?.nifSujetoPasivo : ' - '}
                                                                    onChangeValue= {(v:string, err:boolean) => {}}
                                                                    className={classes.input260} 
                                                                />
                                                                {plusvalKey === 'modelo081' && 
                                                                    <TypedInputNumber key="totalIngresar"
                                                                        type="money"
                                                                        disabled
                                                                        allowZero={true}
                                                                        required={false}
                                                                        min={0}
                                                                        maxDecimal={2}
                                                                        fieldTerm="totalIngresar"
                                                                        value={datosTransmision?.datosOperacionPlusvalia[0].modelo081?.totalIngresar ?? 0}
                                                                        onChangeValue={(v, err) => {}}
                                                                        className={classes.input160} 
                                                                    />                                            
                                                                }
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className={classes.btnTramites}>
                                                    <Button variant="outlined" color='primary' size="small"
                                                        startIcon= { 
                                                            <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} /> 
                                                        }
                                                        onClick={() => {
                                                            if(plusvalKey === 'modelo081'){
                                                                setBorrador('081')
                                                            }
                                                            else if(plusvalKey === 'modelo080'){
                                                                setBorrador('080')
                                                            }
                                                            else if(plusvalKey === 'modelo082'){
                                                                setBorrador('082')
                                                            }
                                                            setShow(true)
                                                        }}
                                                    >
                                                        {translate('DUTI','btn_consulta_borrador',terms)}
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        
                                        {toShow.tasas && 
                                            <div className={classes.row} style={{flexGrow: 1, paddingTop:10}}>
                                                <div className={[classes.column,classes.grow2].join(' ')}>
                                                    <CheckBox fieldTerm='tramite_tasas_atib'
                                                        disabled={status && status !== "INI"}
                                                        value={datosTramitesGenerados.comunicacionTasasATIB}
                                                        onChangeValue={(v:boolean, err:boolean) => {
                                                            setDatosTramitesGenerados(curr =>({...curr, comunicacionTasasATIB: v}))
                                                        }}
                                                    />
                                                    <div style={{ paddingRight:10, paddingLeft: 30}}>
                                                        {tasas.map( t => ( 
                                                            <Typography style={{color: '#bdbdbd', maxWidth:'fit-contemt'}} key={t.id}>- {translate('DUTI', t.term, terms)}</Typography> 
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={classes.btnTramites}>
                                                    <Button variant="outlined" color='primary' size="small"
                                                        startIcon= { 
                                                            <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} /> 
                                                        }
                                                        onClick={() => {
                                                            setBorradorJustif('tasas');
                                                            setShowJustif(true)
                                                        }}
                                                    >
                                                        {translate('DUTI','btn_consulta_borrador',terms)}
                                                    </Button>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </Grid>
                                
                            }

                            { toShow.com_domfisc && 
                                <Grid item className={classes.sectionJustif}> 
                                    <div className={classes.row} style={{paddingTop:10}}>
                                        
                                        <img className={classes.logoTramites} src={AEATLogo} alt="logo-aeat"/>
                                        
                                        <div className={[classes.column,classes.grow].join(' ')}>
                                            <CheckBox fieldTerm='tramite_comunicacion_domicilio_fiscal_aeat'
                                                disabled={status && status !== "INI"}
                                                value={datosTramitesGenerados.comunicacionDomicilioFiscalAEAT}
                                                onChangeValue={(v:boolean, err:boolean) => {
                                                    setDatosTramitesGenerados(curr =>({...curr, comunicacionDomicilioFiscalAEAT: v}))
                                                }}
                                            />
                                            <div style={{display:'flex', flexDirection:'row',flexWrap:'wrap', marginTop: 10, paddingRight:10, paddingLeft: 30}} >
                                                <Typography style={{color:  '#bdbdbd', maxWidth:'fit-content'}} >
                                                    {translate('DUTI', 'tramiGen_direccionFiscal', terms)}: <i style={{ maxWidth:'fit-content'}}>{dirFiscal}</i>
                                                </Typography> 
                                            </div> 
                                        </div>
                                        <div className={classes.btnTramites}>
                                            <Button variant="outlined" color='primary' size="small"
                                                startIcon= { 
                                                    <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} /> 
                                                }
                                                onClick={() => {
                                                    setBorradorJustif('aeat');
                                                    setShowJustif(true)
                                                }}
                                            >
                                                {translate('DUTI','btn_consulta_borrador',terms)}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            }

                            {toShow.com_catastro && 
                                <Grid item className={classes.sectionJustif}> 
                                    <div className={classes.row}>

                                        <img className={classes.logoTramites} src={CatastroLogo} alt = "logo-catastro"/>
                                       
                                        <div className={[classes.column,classes.grow].join(' ')}>
                                            <CheckBox fieldTerm='tramite_ibi_atib'
                                                disabled={status && status !== "INI"}
                                                value={datosTramitesGenerados.comunicacionCatastro}
                                                onChangeValue={(v:boolean, err:boolean) => {
                                                    setDatosTramitesGenerados(curr =>({...curr, comunicacionCatastro: v}))
                                                }}
                                            />
                                        </div>    
                                        <div className={classes.btnTramites}>
                                            <Button variant="outlined" color='primary' size="small"
                                                startIcon= { 
                                                    <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} /> 
                                                }
                                                onClick={() => {
                                                    setBorradorJustif('ibi');
                                                    setShowJustif(true)
                                                }}
                                            >
                                                {translate('DUTI','btn_consulta_borrador',terms)}
                                            </Button>
                                        </div> 
                                    </div>      
                                </Grid>
                            }
                           
                            
                        </Grid>
                    </div>
                    {!loading && !(Object.values(datosTramitesGenerados).includes(true)) && 
                        <div className={classes.textCancelDusti}>
                            <div style={{marginRight: 10}}>
                                <Icon path={mdiAlertCircleOutline} color={'#ef1111'} style={{alignSelf:'flex-start'}} size={4} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: cancelMSG ?? 'Oops ! Lost MSG_complemtaria' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                        </div>
                    }

                </Grid>
                
                <Grid item container direction="row" 
                    justify={status && status === "INI"  ? "space-between": "flex-end"} 
                    alignItems={"flex-end"} 
                    className={classes.footerColumnContainer}
                >
                
                    { status && status === "INI" && 
                        <Button variant="contained" onClick={() => handleBack(steps)} color="inherit">
                            {translate('DUTI', 'btn_back',terms)}
                        </Button>
                    }
                
                   
                    <div>
                        <Button style={{marginRight: 10}}
                            variant="outlined" color="primary"
                            onClick={() => {
                                alertsDispatch({
                                    type: 'show-alert',
                                    payload: {
                                        message: translate('DUTI', 'confirm_cancelDUTI_user_choice', terms),
                                        variant: 'warning',
                                        hasCustomAction: true,
                                        defaultCloseActionTemplateName: translate("DUTI", "no", terms).toUpperCase(),
                                        actionTemplateName: translate("DUTI", "si", terms).toUpperCase(),
                                        handleCustomAction: () => { 
                                            handleCancelDUTI('continuar-mas-tarde') 
                                            alertsDispatch({type: 'hide-alert'})
                                        }
                                    }
                                });
                            }} 
                        >
                            {translate('DUTI', 'btn_pause_duti',terms)}
                        </Button>

                        { Object.values(datosTramitesGenerados).includes(true)
                            ?
                            <Button
                                    variant="contained" color="primary"
                                    onClick={onContinuar}>
                                    {translate('DUTI', 'btn_continuar',terms)}
                            </Button>
                        :
                           !loading ?
                                <Button 
                                    variant="contained" 
                                    onClick={() => handleCancelDUTI( 'no-selecciona-tramites')}
                                    className={classes.btnCancelDusti}
                                >
                                    {translate('DUTI', 'btn_exit_duti',terms)}
                                </Button>
                            : null
                        }
                       
                    </div>  
                </Grid>
            </Grid> 

            <Dialog open={modalConfirmaFinalizar} maxWidth='lg'>
                <DialogTitle>
                    <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>
                        {translate("DUTI", "UstedEnviaLaSiguienteInformacion", terms)}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div style={{ paddingLeft: 10, display: 'flex', flexGrow:1, overflowX: 'hidden', overflowY:'auto', backgroundColor: '#e0eaf4', minHeight: 150, maxHeight: 700}}>
                        {renderConfirmDUSTI(toRender, terms)}
                    </div>
                    <div style={{paddingLeft: 10}}>
                        <Typography style={{marginTop: 20, marginBottom: 15}} >
                            {translate("DUTI","UstedEnviaLaSiguienteInformacionReglamento", terms)}
                        </Typography>
                        <Typography style={{marginBottom: 10}} >
                            {translate("DUTI","UstedEnviaLaSiguienteInformacionConformidad",terms)}
                        </Typography>
                        {conformeError && 
                            <Typography color='error'>
                                { translate('DUTI', 'required_conforme', terms)}
                            </Typography>
                        }
                    </div>
                </DialogContent>
                <DialogActions >
                    <Grid container direction='row' alignItems="flex-start" justify="space-between" style={{paddingRight: 20, paddingLeft: 20}}>
                        <Grid item >
                            <CheckBox 
                                value={conforme} 
                                fieldTerm={'conforme'} 
                                onChangeValue={ (v:boolean) => {
                                    setConforme(v); 
                                    setConformeError(false)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" style={{marginRight: 10}} onClick={() => {
                                setModalConfirmaFinalizar(false); 
                                setConformeError(false);
                                setConforme(false)
                                }
                            }>
                                {translate('DUTI','btn_cancel', terms)}
                            </Button>
                            <Button variant="contained"  color="primary" onClick={onFinish}>
                                {translate('DUTI','btnFinish', terms)}
                            </Button>
                        </Grid>
                    </Grid>
                    

                </DialogActions>

            </Dialog>

            <ModalModeloBorrador
                show={show}
                onClose={() => setShow(false)}
                datos={datosTransmision}
                modelo={borrador}
            />
            <ModalJustificanteBorrador
                show={showJustif}
                onClose={() => setShowJustif(false)}
                datos={datosTransmision}
                justificante={borradorJustif}
                tasas={tasas}
            />
        </>
    )
}

export default TramitesGenerados