
import { inject } from "aurelia-dependency-injection";
import { AxiosInstance, AxiosResponse } from "axios";
import { IDatosCuota, IDatosVehiculo, IMarca, IResponse } from "gateways/funciones-621.interfaces";
import { TipoTributo, TIPOS_TRIBUTO, Option, decodeHtml, encodeXmlValues, encodeModeloValues } from "containers/tributos-autonomicos-locales/utils";
import { DatosModeloSujetoLiqTrimestral, 
    IApartado, IAsistentePLVObj, IAsistenteStep, 
    IBonificacion, ICalculoPlusvaliaResponse, ICalculoPlusvaliaSimuladorResponse, ICampo, IFinPlusvaliaResponse, IInfoStepPagoModelos, IModelo, IModeloGroup, 
    IMotivosExencNoSuj, 
    IMunicipioPLV, IRespuestaConfirmarPagoFict, IRespuestaEstadoAuloquidacion, IReturnModeloLiqTrimestral, 
    ISaveModelo, IStep, ITipoModelo, ITipoTransmision, IUpdateEstadoModelo, 
    TModeloDocSource 
} from "./model.new.interface";
import { IConcepto } from "./model.new.interface";
import { TDatosAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/shared/types";
import { IAsistentePLV, TBoniPLV, TCalcPlusvalia, TCalculo } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { IDocumentoAtib, TDocSource } from "./documentos.interface";
import { IConsultaEscrituraPublica } from "./duti.interface";
import { TInmuebleCatastro } from "./catastro.interface";
import { TSelect } from "containers/DUTI/utils";
import { TCalculoSimulador, TDatosPrevios } from "containers/tramites/simulador-plusvalias/types";

@inject('axios')
export default class ModelGateway {
    constructor(
        private axios: AxiosInstance
    ) {
        if (!axios) {
            throw new Error('ModelGateway requires an axios instance');
        }
    }

    public async getAllTipoModelosVisibleWeb(): Promise<string[]> {
        try {
            const response = await this.axios.get(`modelos_new/getAllTipoModelosVisibleWeb`);
            if (response.status !== 200) {
                throw new Error(`GetTipoModelosWebActive - Bad response status: ${response.statusText}`)
            }
            const out: string[] = response.data.map((item: { idTipoModelo: string; }) => item.idTipoModelo);
            return out;
        } catch (error) {
            throw new Error(error as string);
        }

    }

    public async getModelos(tipo: TipoTributo, errorMsg: string,): Promise<ITipoModelo[]> {
        try {
            const letter = tipo === TIPOS_TRIBUTO.autonomicos ? 'A' : 'L';
            const response = await this.axios.get(`modelos_new/getTipoModelosByTipoTributo/${letter}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`)
            }
            return response.data as ITipoModelo[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getGroupedModelos(tipo: TipoTributo, errorMsg: string): Promise<IModeloGroup[]> {
        try {
            const letter = tipo === TIPOS_TRIBUTO.autonomicos ? 'A' : 'L';
            const response = await this.axios.get(`modelos_new/getGroupedTipoModelosByTipoTributo/${letter}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`)
            }
            return response.data as IModeloGroup[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getModeloInfo(idTipoModelo: string, errorMsg: string): Promise<ITipoModelo | null> {
        try {
            const response = await this.axios.get(`modelos_new/getTipoModeloById/${idTipoModelo}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg}: ${idTipoModelo}. (Bad response status: ${response.statusText})`)
            }
            return response.data ? response.data as ITipoModelo : null;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getModeloById(idModelo: string, errorMsg: string): Promise<IModelo> {
        try {
            const response = await this.axios.get(`modelos_new/getModeloById/${idModelo}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg}: ${idModelo}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IModelo;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getModeloByRef(referencia: string, errorMsg: string): Promise<IModelo | null> {
        try {
            const response = await this.axios.get(`modelos_new/getModeloByReference/${referencia}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} ${referencia}. (Bad response status: ${response.statusText})`)
            }
            return response.data ? response.data as IModelo : null;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getModeloSteps(idModelo: string, idReferencia: string, errorMsg: string): Promise<IStep[]> {
        try {
            const response = await this.axios.get(`modelos_new/getModeloSteps?idTipoModelo=${idModelo}&idReferencia=${idReferencia}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`)
            }
            const modeloSteps = response.data as IStep[];
            modeloSteps.forEach(step => {
                if (step.datosXML) {
                    step.datosXML = decodeHtml(step.datosXML);
                }
            });
            return modeloSteps;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getApartados(idReferencia: string, errorMsg: string,): Promise<IApartado[]> {
        try {
            const response = await this.axios.get(`modelos_new/getApartados/${idReferencia}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`)
            }
            return response.data as IApartado[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getAllConcepts(idTipoModelo: string, ejercicio?: number): Promise<IConcepto[]> {
        try {
            const response = ejercicio !== undefined
                ? await this.axios.get(`modelos_new/getAllConceptos?idTipoModelo=${idTipoModelo}&ejercicio=${ejercicio}`)
                : await this.axios.get(`modelos_new/getAllConceptos?idTipoModelo=${idTipoModelo}`);

            if (response.status !== 200) {
                return []
                //throw new Error(`${errorMsg} ${idTipoApartado}. (Bad response status: ${response.statusText})`) 
            }
            return response.data as IConcepto[];
        } catch (error) {
            return []
            //throw new Error(error as string);
        }
    }

    public async getCamposApartado(
        idTipoApartado: string,
        idTipoModelo: string,
        errorMsg: string,
    ): Promise<ICampo[]> {
        try {

            const response = await this.axios.get(`modelos_new/getCamposApartado?idTipoApartado=${idTipoApartado}&idTipoModelo=${idTipoModelo}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} ${idTipoApartado}. (Bad response status: ${response.statusText})`)
            }
            return response.data as ICampo[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getConceptosPadre(idTipoModelo: string, errorMsg: string, ejercicio?: number): Promise<IConcepto[]> {
        try {
            const response = ejercicio !== undefined
                ? await this.axios.get(`modelos_new/getConceptosPadre?idTipoModelo=${idTipoModelo}&ejercicio${ejercicio}`)
                : await this.axios.get(`modelos_new/getConceptosPadre?idTipoModelo=${idTipoModelo}`);

            if (response.status !== 200) {
                throw new Error(`${errorMsg} ${idTipoModelo}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IConcepto[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getConceptosHijos(idTipoModelo: string, idConceptoPadre: number, errorMsg: string, errorMsg2: string, ejercicio?: number): Promise<IConcepto[]> {
        try {
            const response = ejercicio !== undefined
                ? await this.axios.get(`modelos_new/getConceptosHijos?idTipoModelo=${idTipoModelo}&idConceptoPadre=${idConceptoPadre}&ejercicio${ejercicio}`)
                : await this.axios.get(`modelos_new/getConceptosHijos?idTipoModelo=${idTipoModelo}&idConceptoPadre=${idConceptoPadre}`);

            if (response.status !== 200) {
                throw new Error(`${errorMsg} ${idTipoModelo}/${errorMsg2}: ${idConceptoPadre}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IConcepto[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getConceptoById(idTipoModelo: string, idConcepto: number, errorMsg: string,): Promise<IConcepto> {
        try {
            const response = await this.axios.get(`modelos_new/getConceptoById?idTipoModelo=${idTipoModelo}&idConcepto=${idConcepto}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} ${idTipoModelo}/${idConcepto}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IConcepto;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getTipoModelo(idTipoModelo: string): Promise<ITipoModelo | null> {
        try {
            const response = await this.axios.get(`modelos_new/getTipoModelo/${idTipoModelo}`);
            if (response.status !== 200) {
                return null
            }
            return response.data as ITipoModelo
        } catch (error) {
            throw new Error(error as string);
        }
    }



    /** post  */
    public async postReferencia(errorMsg: string): Promise<string> {
        try {
            const response = await this.axios.post(`modelos_new/createReferencia`);
            //console.log(response.status)
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`)
            }
            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async saveApartado(data: IApartado, errorMsg: string,): Promise<boolean> {
        try {
            //console.log('CALL saveApartado',data)
            data.datosXML = encodeXmlValues(data.datosXML);
            const response = await this.axios.post(`modelos_new/saveApartadoModelo`, data);
            //console.log('saveApartado response',response)
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`${errorMsg} ${data.idTipoApartado}. (Bad response status: ${response.statusText})`)
            }
            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async saveModelo(data: ISaveModelo, errorMsg: string): Promise<string | null> {
        try {
            //console.log(data)
            data.datos = encodeModeloValues(data.datos);
            const response = await this.axios.post(`modelos_new/saveModeloFromWeb`, data);
            //console.log('saveModelo response',response)
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`${errorMsg} ${data.referencia}. (Bad response status: ${response.statusText})`)
            }
            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async updateModeloState(idModelo: string, status: string, errorMsg: string): Promise<boolean> {
        try {
            const body: IUpdateEstadoModelo = { idModelo, estado: status }
            const response = await this.axios.post(`modelos_new/updateEstadoModelo`, body);

            if (response.status !== 200 && response.status !== 201 && response.data === false) {
                throw new Error(`${errorMsg}: ${idModelo}/${status}. (Bad response status: ${response.statusText})`)
            }
            return response.data as boolean;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async confirmarPagoFicticio(idReferencia: string, errorMsg: string,): Promise<IRespuestaConfirmarPagoFict> {
        try {
            // console.log(idReferencia, errorMsg)
            const response = await this.axios.post(`modelos_new/confirmarPagoFicticio/${idReferencia}`);
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`${errorMsg}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IRespuestaConfirmarPagoFict;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getTokenPago(idReferencia: string, errorMsg: string): Promise<string> {
        try {
            const response = await this.axios.get(`modelos_new/getTokenNewPayment/${idReferencia}`);
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`${errorMsg} ${idReferencia}. (Bad response status: ${response.statusText})`)
            }
            return response.data as string
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async asociarDocumentoModeloDelSujeto(idModelo: string): Promise<boolean> {
        const response = await this.axios.post(`modelos_new/asociarDocumentoModeloDelSujeto/${idModelo}`);
        if (response.status !== 200 && response.status !== 201) {
            //console.log('asociarDocumentoModeloDelSujeto: ', false)
            return false
        }
        //console.log('asociarDocumentoModeloDelSujeto: ', response.data)
        return response.data;
    }

    public async getNimaCir(nif: string, errorMsg: string): Promise<{ nima: string | null, cir: string[] | null }> {
        try {
            const response = await this.axios.get(`modelos_new/getNimaCir/${nif}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg}. (Bad response status: ${response.statusText})`)
            }
            return response.data as { nima: string | null, cir: string[] | null };
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getDelegacion(errorMsg: string): Promise<Option[]> {
        try {
            const response = await this.axios.get(`modelos_new/getSelectDelegacion`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg}. (Bad response status: ${response.statusText})`)
            }
            const out = response.data.map((item: { idClave: number, tipo: string, valor: string, orden: number }) => (
                { id: item.idClave.toString(), nombre: item.valor }
            ))
            return out;
        } catch (error) {
            throw new Error(error as string);
        }
    }
    /// 621
    public async validateDatosCenso(nif: string, apellido: string, addCodigo: boolean) {
        try {
            const response = await this.axios.post(`modelos_new/validaSujetoCenso/`, {
                apellido: apellido,
                nif: nif,
                addCodigo: addCodigo
            });
            if (response.status !== 201) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async validateConsultaDGT(matricula: string, apellido: string, nif: string, notificadoDGT: boolean): Promise<IResponse<IDatosVehiculo>> {
        try {
            // Ciframos el payload
            const payload = {
                apellido: apellido,
                matricula: matricula,
                nif: nif,
                notificadoDGT: notificadoDGT
            };
            // const ciphertext = cipher(payload);
            const response = await this.axios.post(`dgt/consultaDGT/`, payload);
            if (response.status !== 201) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }
    public async getListModelos(idMarca: number, tipo: string, cilindrada: number, cvf: number, anyoFabricacion: number, anyoMatriculacion: number): Promise<IResponse<any>> {
        try {
            const response = await this.axios.get(`dgt/getListModelos?idMarca=${idMarca}&tipo=${tipo}&cilindrada=${cilindrada}&cvf=${cvf}&anyoMatriculacion=${anyoMatriculacion}&anyoFabricacion=${anyoFabricacion}`);
            if (response.status !== 200) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }
    public async getMarcaVehiculo(nombre: string, tipo: string): Promise<IMarca> {
        try {
            const response = await this.axios.get(`dgt/getMarcaVehiculo?nombre=${nombre}&tipo=${tipo}`);
            if (response.status !== 200) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getModelosVehiculo(valorDeclarado: number, tipoVehiculo: string, datosVehiculo: IDatosVehiculo, fechaDevengo: Date): Promise<IResponse<any>> {
        try {
            const response = await this.axios.post(`dgt/getModelosVehiculo`,
                {
                    valorDeclarado,
                    tipoVehiculo,
                    datosVehiculo,
                    fechaDevengo
                });
            if (response.status !== 201) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async calcularImporte(marca: string, idModelo: number, cvf: string, tipoVehiculo: string, cilindrada: number, fechaDevengo: Date, fechaMatriculacion: Date, valorDeclarado: number): Promise<IResponse<any>> {
        try {
            const response = await this.axios.post(`dgt/calcularImporte`,
                {
                    marca,
                    idModelo,
                    cvf,
                    tipoVehiculo,
                    cilindrada,
                    fechaDevengo: fechaDevengo.toString(),
                    fechaMatriculacion: fechaMatriculacion.toString(),
                    valorDeclarado
                });
            if (response.status !== 201) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getCodigoCET(fechaDevengo: Date, bastidor: string, nif: string): Promise<IResponse<any>> {
        try {
            const response = await this.axios.post(`dgt/codigoCET`,
                {
                    alt: 'V',
                    anyo: fechaDevengo.getFullYear(),
                    ccaa: 'ATIB',
                    bastidor,
                    nif,
                    vehiculos: 'VEHIC'
                });
            if (response.status !== 201) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getCO2(bastidor: string): Promise<string> {
        try {
            const response = await this.axios.get(`dgt/getCO2?bastidor=${bastidor}`);
            if (response.status !== 200) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getCuota(fechaDevengo: string, fechaMatriculacion: string, matricula: string, categoriaElectrica: string, cilindrada: string, valorDeclarado: string, cvf: string, co2: string, tipoVehiculo: string, descripcion: string): Promise<IDatosCuota> {
        try {
            const response = await this.axios.get(`dgt/getCuota?fechaDeven=${fechaDevengo}&fechaMatri=${fechaMatriculacion}&matricula=${matricula}&categoriaElectrica=${categoriaElectrica}&cilindrada=${cilindrada}&valorDeclarado=${valorDeclarado}&cvf=${cvf}&co2=${co2}&tipoVehiculo=${tipoVehiculo}&descripcion=${descripcion}`);
            if (response.status !== 200) {
                throw new Error(`(Bad response status: ${response.statusText})`)
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async addInformacionNRC_M593(idModelo: string): Promise<boolean> {
        try {
            const response = await this.axios.post(`modelos_new/addInformacionNrcModelo593/${idModelo}`);
            console.log('addInformacionNRC_M593', response.status)
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`Bad response status: ${response.statusText}`)
            }
            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async comprobarExistenciaModelo(data: DatosModeloSujetoLiqTrimestral, errorMsg: string): Promise<IReturnModeloLiqTrimestral> {
        try {
            const body: any = { data }
            const response = await this.axios.post(`modelos/comprobarExistenciaModelo`, body);

            if (response.status !== 200 && response.status !== 201 && response.data === false) {
                throw new Error(`${errorMsg}: ${data}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IReturnModeloLiqTrimestral;
        } catch (error) {
            throw new Error(error as string);
        }
    }



    /** ******************************************************************************************************************************************************************/
    /** MODELOS Asistente * /
    /** ******************************************************************************************************************************************************************/
    public async getMunicipiosPLV (errorMsg:string ) {
        try {
            const response = await this.axios.get(`modelos_new/get-all-municipios-plusvalia`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`) 
            }
            return  response.data as IMunicipioPLV[];
        } catch (error) {
            throw new Error(error as string);
        }
        
    }

    public async getIdMunisPermiten081PLV(errorMsg:string ) {
        try {
            const response = await this.axios.get(`modelos_new/get-all-id-munis-allow-081`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`) 
            }
            return  response.data as string[];
        } catch (error) {
            throw new Error(error as string);
        }
        
    }

    
    public async getModeloAsistenteSteps( tipoAsistente: string, idReferencia: string, errorMsg:string ): Promise<IAsistenteStep[]> {
        try {
            const response = await this.axios.get(`modelos_new/getModeloAsistenteSteps?idTipoModelo=${tipoAsistente}&idReferencia=${idReferencia}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`) 
            }
            return  response.data as IAsistenteStep[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getAllApartadosModeloAsistente( tipoAsistente: string, idReferencia: string): Promise<IAsistentePLVObj> {
        try {
            const response = await this.axios.get(`modelos_new/getAllApartadosModeloAsistenteAsObject?idTipoModelo=${tipoAsistente}&idReferencia=${idReferencia}`);
            if (response.status !== 200) {
                throw new Error('error_get_all_apartados_as_object', {cause:`(Bad response status: ${response.statusText})`}) 
            }
            return  response.data as IAsistentePLV;
        } catch (error) {
            throw new Error(error as string);
        }
    }
    

    public async getBoniTipoTransmByMuni (idMunicipio: string, errorMsg:string, idTipoTransmision?: string): Promise<IBonificacion[]> {
        try {
            const response = await this.axios.get(`modelos_new/get-bonificacion-transmision?idMunicipio=${idMunicipio}&idTipoTransmision=${idTipoTransmision}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`) 
            }
            return response.data as IBonificacion[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getMotivosExencionNosujecion (idMunicipio: string,errorMsg:string,): Promise<IMotivosExencNoSuj> {
        try {
            const response = await this.axios.get(`modelos_new/get-motivos-exencion-nosujecion?idMunicipio=${idMunicipio}`);
            if (response.status !== 200) {
                throw new Error(`${errorMsg} (Bad response status: ${response.statusText})`) 
            }
            return response.data as IMotivosExencNoSuj;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getMotivoExtentoRU(idMunicipio: string): Promise<{id: number, term: string}> {
        try {
            const response = await this.axios.get(`modelos_new/tipo-exencion-rustica?idMunicipio=${idMunicipio}`);
            if (response.status !== 200) {
                throw new Error(`Bad response status: ${response.statusText})`)
            }
            return response.data 
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async checkBonificacion ( bonificacion:TBoniPLV, inmueble: TInmuebleCatastro){
        try {
            const response = await this.axios.post(`modelos_new/check-bonificacion`, {
                bonificacion, inmueble
            });
            if (response.status !== 201) {
                throw new Error(`Bad response status: ${response.statusText}`) 
            }
            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async saveStepAsistente (idRef: string, idTipoApartado: string, orden: number, data: TDatosAsistente){
        try {
            let ref= idRef
            if(idRef === ""){
                ref = await this.postReferencia('create_ref_error');
            }

            const params = {
                idReferencia: ref,
                idTipoApartado: idTipoApartado,
                datos: data,
                orden: orden,
            }
            
            const response = await this.axios.post('modelos_new/saveStepModeloAsistente', params)
            if (response.status !== 200 && response.status !== 201) {
                throw new Error('save_step_error',{cause: `(Bad response status: ${response.statusText})`})
            }
            return {
                ok: response.data,
                idRef: ref
            }
        } catch(err){
            throw err as Error;
        }
    }

    public async getEscrituraPublica (fecha:Date|undefined, anyo: number|undefined, numProtocolo: string|undefined,nifNotario:string|undefined) : Promise<IDocumentoAtib> {
        if(!fecha ||!anyo ||!numProtocolo || !nifNotario){ throw new Error('not_enought_info_consulta_escritura')}
            const params : IConsultaEscrituraPublica= {
            fecha: fecha,
            anyo: anyo,
            numProtocolo: numProtocolo,
            nif: nifNotario
        }
        const response: AxiosResponse = await this.axios.get(`dusti/escritura-publica-no-auth`, {params: params})
        if( !response.data.ok && response.data.error){
            throw new Error (response.data.error.descripcion)
        }

        const out : IDocumentoAtib = {
            IdDocumento: response.data.idDocumento,
            NombreFichero: response.data.NombreFichero,
            TipoFichero: response.data.TipoFichero,
            FechaAlta: response.data.FechaAlta,
            Referencia: response.data.Referencia,
            datosFichero: response.data.datosFichero,
        };
        
        return out;
    }

    public async getEscrituraPublicaByNRD (nrd:string): Promise<{ 
        info: { fecha: Date, anyo: number, protocolo: string, nifNotario: string, notario:string, idEscritura:string} | null, 
        escritura: IDocumentoAtib | null
    }>{
        try {
            const params = {NRD: nrd}
            const response: AxiosResponse = await this.axios.get(`dusti/escritura-publica-by-nrd-no-auth`, {params: params})

            if( !response.data.ok && response.data.error){
                throw new Error (response.data.error.descripcion)
            }
            return({
                info: response.data.info, 
                escritura: response.data.escritura ? 
                    {
                        IdDocumento: response.data.escritura.idDocumento,
                        NombreFichero: response.data.escritura.NombreFichero,
                        TipoFichero: response.data.escritura.TipoFichero,
                        FechaAlta: response.data.escritura.FechaAlta,
                        Referencia: response.data.escritura.Referencia,
                        datosFichero: response.data.escritura.datosFichero,
                    } : null
            })
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async subirModeloDocuemntos (idRefModelo: string, idTipoApartado: string, files: any, source?: TDocSource | TModeloDocSource): Promise<string[]>{
        try {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }
            const response = await this.axios.post(`modelos_new/file-upload?source=${source}&idReferencia=${idRefModelo}&idTipoApartado=${idTipoApartado}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            return response.data

        } catch(error){
            throw new Error(error as string);
        }

    }

    public async deleteDocModelo(idReferencia: string, idDocumento: string, idTipoApartado: string): Promise<boolean> {
        try {
            const response = await this.axios.get(`modelos_new/doc-modelo-delete?idReferencia=${idReferencia}&idDocumento=${idDocumento}&idTipoApartado=${idTipoApartado}`);

            if (response.status !== 200 && response.status !== 201) {
                throw new Error('Documento_no_borrado');
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async checkEstadoAutoliquidacion (
        idReferencia: string,        
        nifSujetoPasivo: string,
        porcentaje: number,
        idTipoDerecho?: string,
    ): Promise<IRespuestaEstadoAuloquidacion> {
        try {
            const response = await this.axios.get(`modelos_new/estado-autoliquidacion?idReferencia=${idReferencia}&idTipoDerecho=${idTipoDerecho}&nifSujetoPasivo=${nifSujetoPasivo}&porcentaje=${porcentaje}`);

            if (response.status !== 200) {
                throw new Error(response.data.error.descripcion)
            }
            return response.data;
        } catch (e) {
            throw e
        }
    }

    public async calcularPlusvalia (data: TCalculo, idRef: string, nifVendedor: string, nifSujetoPasivo:string): Promise<ICalculoPlusvaliaResponse> {
        try {
            const response = await this.axios.post(`modelos_new/calcular-plusvalia?idReferencia=${idRef}&nifVendedor=${nifVendedor}&nifSujetoPasivo=${nifSujetoPasivo}`, data );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error(response.data.error.descripcion)
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async calcularPlusvaliaSimulador (
        data: TCalculoSimulador, datosPrevios: TDatosPrevios
    ): Promise<ICalculoPlusvaliaSimuladorResponse> {
        try {
            const response = await this.axios.post(
                `modelos_new/calcular-plusvalia-simulador`,
                {...data, datosPrevios: datosPrevios} 
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error(response.data.error.descripcion)
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    
    public async finalizarPlusvalia (idRef: string): Promise<IFinPlusvaliaResponse> {
        try {
            const response = await this.axios.post(`modelos_new/finalizar-plusvalia?idReferencia=${idRef}`);

            if (response.status !== 200 && response.status !== 201) {
                throw new Error(response.data.error.descripcion)
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async getUrlPago (idRef: string): Promise<string> {
        try {
            const response = await this.axios.get(`modelos_new/url-pago?idReferencia=${idRef}`);

            if (response.status !== 200 ) {
                throw new Error(response.data.error.descripcion)
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async getInfoStepPago (idRef: string): Promise<IInfoStepPagoModelos[]> {
        try {
            const response = await this.axios.get(`modelos_new/info-step-pago?idReferencia=${idRef}`);

            if (response.status !== 200 ) {
                throw new Error(response.data.error.descripcion)
            }
            
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async confirmarPagoFicticioAsistente(idReferencia: string, errorMsg: string,): Promise<IRespuestaConfirmarPagoFict> {
        try {
            // ID Referencia del Asistente
            const response = await this.axios.post(`modelos_new/confirmarPagoFicticioAsistente/${idReferencia}`);
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`${errorMsg}. (Bad response status: ${response.statusText})`)
            }
            return response.data as IRespuestaConfirmarPagoFict;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getTiposTransmision(): Promise<ITipoTransmision[]> {
        try {
            const response = await this.axios.get('modelos_new/tipos-transmision');
            if (response.status !== 200) {
                throw new Error(`Bad response status: ${response.statusText})`)
            }
            return response.data as ITipoTransmision[];
        } catch (error) {
            throw new Error(error as string);
        }
    }
    
    public async getJustificantePago(idReferencia: string): Promise<Blob> {        
        
        try{
            const response = await this.axios.get(`modelos_new/justificante-pago?idReferencia=${idReferencia}`, {
                responseType: 'blob',
            });
        
            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        }catch(error){
            return {} as Blob
        }
    }

    /** ******************************************************************************************************************************************************************/

}

