import {FC, useContext, useEffect, useMemo, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, withStyles, WithStyles, Button, Grid } from "@material-ui/core";
import { IComprador, TDireccionFiscal, TTipoDirFisc } from "containers/DUTI/steps/types";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import FullLoading from "../full-loading";
import { direccionToText } from "containers/DUTI/utils";
import RadioButtonLabelComponent, { IRadioButtonLCOption } from "./radio-button-labelComponent";
import DireccionRBT from "./direccion-rbt";
import { mdiAccountReactivate } from '@mdi/js';
import Icon from "@mdi/react";
// Logo AEAT - TODO change
import AEAT2 from 'resources/dusti/AEAT_listado2.jpg';

interface IProps extends WithStyles {
    show: boolean,
    sujetoPasivo: IComprador,
    inmubleDirecion: string | null,
    dirFiscal: string,
    onSave: (nif:string, dir:TDireccionFiscal | undefined, changes: boolean) => void
    onClose: () => void
}

const ModalCambioDireccionFiscal: FC<IProps> = ({classes, show, sujetoPasivo, inmubleDirecion, dirFiscal, onSave, onClose}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const [loading, setLoading] = useState(false)
    const [haveChanges, setHaveChanges] = useState(false)

    const [OPTIONS, setOPTIONS] = useState<IRadioButtonLCOption[]>([])
    const [selected, setSelected] = useState<string|undefined>(undefined)


    useEffect(() => {
        if(show){
            setLoading(true)
            const dirSuj= direccionToText(sujetoPasivo.direccion, terms)
            
            const options: IRadioButtonLCOption[]=  [
                {id:'fiscal', label: <DireccionRBT term={'rbt_dir_fiscal'} dir={dirFiscal}/>, value: dirFiscal},
                {id:'sujeto', label: <DireccionRBT term={'rbt_dir_sujeto'} dir={dirSuj}/>, value: dirSuj },
            ]
            if(inmubleDirecion){
                options.push( {id:'inmueble', label: <DireccionRBT term={'rbt_dir_inmueble'} dir={inmubleDirecion}/>, value:inmubleDirecion })
            }

            if(sujetoPasivo.direccionFiscal ){
                const val = options.find(o => o.id === sujetoPasivo.direccionFiscal?.tipo )
                setSelected(val?.id)
                setHaveChanges(false)
            }
            setOPTIONS(options)
            setLoading(false)   
        }
    },[sujetoPasivo, show])
 

    if (!sujetoPasivo) return null;
    
    return (   
        <Dialog open={show} fullWidth maxWidth='md'>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 25, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'title_modal_domFiscal', terms)}</Typography>
                </div>
            </DialogTitle> 

            <DialogContent>
                <div style={{display: "inline-flex", alignItems:'center', padding: '0px 20px 20px 20px'}}>
                    <Icon path={mdiAccountReactivate} color= "#2196f3" size={1.5}/>
                    <Typography style={{paddingLeft: 10}}>{sujetoPasivo.nomApe}</Typography>
                </div>
                
                <Grid container alignItems="center">
                    <Grid item xs={3}>
                        <img width={'100%'} height={'100%'} style={{objectFit:'scale-down'}} src={AEAT2} alt='logo-aeat' />
                    </Grid>
                    <Grid item xs={9}>
                        <RadioButtonLabelComponent
                            fieldTerm='direccionFiscal'
                            value={selected}
                            options={OPTIONS}
                            onChangeValue={ (v: string|undefined, err: boolean) =>{ 
                                setHaveChanges(true)
                                setSelected(v)
                            }}
                        />
                    </Grid>
                </Grid>
                    
               
            </DialogContent>
            
            <DialogActions>
                <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={onClose} 
                        >
                        {translate('DUTI', 'btn_close',terms)}
                    </Button>
                    <Button
                        variant="contained" color="primary"
                        onClick={() => {
                            if(selected){
                                const opt = OPTIONS.find(opt=> opt.id === selected)
                                onSave(sujetoPasivo.nif, opt ? {tipo: opt.id, direccion: opt.value}: undefined, haveChanges) 
                            } 
                        }}
                        disabled={!selected}
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalCambioDireccionFiscal));