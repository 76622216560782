import { inject } from "aurelia-dependency-injection";
import { AxiosInstance, CancelTokenSource } from "axios";
import { Isla, Municipio, Poblacion, Provincia, Sigla } from "./geo.interface";
import { CustomError } from "utils/custom.error";

@inject('axios')
export class GeoGateway {
    private cts: CancelTokenSource | null = null;
    constructor(
        private axios: AxiosInstance,

    ) {

    }

    public async validateCodigoPostal(idPoblacion: string, cp: string): Promise<boolean> {
        const response = await this.axios.get(`geo/validar-cp?idPoblacion=${idPoblacion}&cp=${cp}`);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getMunicipios( idProvincia: string ): Promise<Municipio[]>{
        //console.log('get muni idProvincia', idProvincia)
        const response = idProvincia === '' 
            ? await this.axios.get(`geo/municipios`) 
            : await this.axios.get(`geo/municipios?idProvincia=${idProvincia}`);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getProvincias( idPais: string ): Promise<Provincia[]>{
        const response = idPais === '' 
            ? await this.axios.get(`geo/provincias`) 
            : await this.axios.get(`geo/provincias?idPais=${idPais}`);

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async getPoblaciones(  idProvincia: string, idMunicipio: string ): Promise<Poblacion[]>{
        if(idMunicipio === '') return []
        
        const response = idProvincia === '' 
            ? await this.axios.get(`geo/poblaciones?idMunicipio=${idMunicipio}`) 
            : await this.axios.get(`geo/poblaciones?idProvincia=${idProvincia}&idMunicipio=${idMunicipio}`);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getSiglas(): Promise<Sigla[]>{
        const response = await this.axios.get(`geo/siglas`) 

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async getIslas( ): Promise<Isla[]>{
        const response = await this.axios.get(`geo/islas`) 

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
}