import { Typography, WithStyles, withStyles, Button, IconButton, Grid } from "@material-ui/core"
import { styles } from "../styles"
import { IDocListRN } from "../../cid.interface";
import moment from "moment";
import InfoIcon from "@material-ui/icons/InfoOutlined"



interface IProps extends WithStyles<typeof styles> {
    item: IDocListRN,
    key: number,
    handleMoreInfo: (index: number) => void
}



const ListItem: React.FC<IProps> = ({ classes, item, key, handleMoreInfo }) => {

    const handleEstado = (estado: number) => {
        if (estado == 10) {
            return "PAGADO";
        }
        else if (estado < 10) {
            if (estado >= 6) {
                return "PENDIENTE";
            }
            else {
                let sinRecalculo = (!item.DocumentoValidoHasta || item.DocumentoValidoHasta.getTime() >= new Date().getTime());
                return sinRecalculo ? "NO PAGADO" : "PENDIENTE";
            }
        }
        else {
            // No debería de entrar nunca...
            return "DESCONOCIDO"
        }
    }


    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={1}>
                <Typography className={classes.smallFont}>{item.ReferenciaRecibo}</Typography>
            </Grid>
            <Grid item xs={12} sm={1} style={{ marginRight: '8px' }}>
                <Typography className={classes.smallFont}>{item.nif}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Typography className={classes.smallFont}>{item.Nombre}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography className={classes.smallFont}>{item.ObjetoTributario}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
                <Typography className={classes.smallFont}>{item.Recargo}</Typography>
            </Grid>
            <Grid item xs={12} sm={1} style={{ marginRight: '55px' }}>
                <Typography className={classes.smallFont}>{handleEstado(parseInt(item.Estado))}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
                <Typography className={classes.smallFont}>{item.Periodo ? item.Periodo : " "}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
                <IconButton onClick={() => handleMoreInfo(key)}>
                    <InfoIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}
export default withStyles(styles)(ListItem);