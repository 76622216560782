import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    buttonStyle: {
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        fontSize: 'large',
        textDecoration: 'underline',
        color: 'inherit',
        backgroundSize: '20% 20%'
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill,minmax(50px,1fr))',
        gap: '1px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: '420px',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectedButton: {
        backgroundColor: '#2196f3',
        color: 'white',
        '&:hover': {
            color: 'black'
        }
    }
});

export default styles;