import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import { BLUE, NARANJA_PAGO_CARTA } from "./colors";

const styles = (theme: Theme): StyleRules => ({
  // GENERIC STYLES
  marginBottom5: {
    marginBottom: 5
  },
  marginRight20: {
    marginRight: 20
  },
  marginBottom1: {
    marginBottom: '1rem'
  },
  marginTop1: {
    marginTop: '1rem'
  },
  padding15: {
    padding: 15
  },
  // mobile styles
  root: {
    flexGrow: 1,
  },
  // TODO: este estilo no se utiliza
  appBar: {
    position: "relative",
    padding: "0 !important",
  },
  // TODO: este estilo no se utiliza
  title: {
    flex: 1,
    textAlign: "center",
  },
  // TODO: este estilo no se utiliza
  section: {
    marginBottom: 20,
  },
  text: {
    marginTop: 5,
    marginBottom: 5,
  },
  italicRedText: {
    color: 'red', 
    fontStyle:'italic'},
  bold: {
    fontWeight: "bold",
  },
  mainButton: {
    width: "100%",
    textAlign: "left",
    paddingTop: 15,
    paddingBottom: 15,
  },
  // TODO: este estilo no se utiliza
  conceptoIcon: {
    width: 40,
    height: 40,
    display: "inline-flex",
  },
  iconPC: {
    marginBottom: "-3px",
    marginLeft: 10,
  },
  // TODO: este estilo no se utiliza
  pre: {
    fontFamily: "Courier",
    wordWrap: "break-word",
    display: "block",
    margin: "0 0 10px",
    color: "#333",
    wordBreak: "break-all",
    backgroundColor: "#f5f5f5",
    borderRadius: 4,
    overflow: "auto",
  },
  centerContent: {
    textAlign: "center",
    padding: 50,
  },
  // TODO: este estilo no se utiliza
  pcDatosLabel: {
    margin: "0px 10px",
    textTransform: "uppercase",
    alignItems: "center",
    display: "flex",
  },
  // TODO: este estilo no se utiliza
  pcContainer: {
    display: "flex",
  },
  pcBlueRim: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #006993",
    borderRadius: 5,
    marginBottom: 20,
    padding: 10
  },
  // TODO: este estilo no se utiliza
  pcBlueRimTitle: {
    cursor: "pointer",
    color: "#006993",
    justifyContent: "space-between",
    display: "flex",
  },
  // TODO: este estilo no se utiliza
  pcPagosRealizados: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    height: "20px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      height: "100px",
    },
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    left: "62%",
    [theme.breakpoints.up("md")]: {
      left: "86%",
    },
  },
  // TODO: este estilo no se utiliza
  etiquetaText: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: "bold",
    color: "#004f82",
  },
  selectForm: {
    width: "90%",
    [theme.breakpoints.up("md")]: {
      marginTop: 15,
    },
  },
  selectForm2: {
    display:'flex',
    marginRight:10,
    [theme.breakpoints.up("md")]: {
      marginTop: 15,
    },
  },
  // TODO: este estilo no se utiliza
  selectFormNoMargin: {
    display: 'flex',
  },
  
  textFieldForm: {
    marginRight: 10,
    display: 'flex',
  },
  // TODO: este estilo no se utiliza
  verticallyCentered: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  // TODO: este estilo no se utiliza
  containerActionPrintDomi: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },

  loaderPrint: {
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto"
  },
  containerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  centerTopBottom: {
    top: 0,
    bottom: 0
  },
  informacionDomi: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '1rem'
  },
  containerBtnsDetailCardDomi: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems:'flex-end',
    justifyContent: 'flex-end',
    gap: '1rem'
  },
  // TODO: este estilo no se utiliza
  // pago a cuenta =>pac
  pac_row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // TODO: este estilo no se utiliza
  pac_text_bold: {
    fontWeight: 'bold',
    color: '#004f82'
  },
  // TODO: este estilo no se utiliza
  pac_text: {
    color: '#004f82'
  },
  // TODO: este estilo no se utiliza
  pac_detail: {
    display: 'flex',
    flexDirection: 'column',
  },
  // TODO: este estilo no se utiliza
  pac_detail_result: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },
  // Domiciliacion - card -------------------------------------------------------------------
  blueBorder: {
    border:'solid',
    borderWidth: '1px',
    borderRadius: 10,
    borderColor: BLUE,
    padding: 15,
    marginTop: 10,
    marginBottom: 20,
    position: 'relative'
  },
  orangeBorder: {
    border:'solid',
    borderWidth: '1px',
    borderRadius: 10,
    borderColor: NARANJA_PAGO_CARTA,
    padding: 15,
    marginTop: 10,
    marginBottom: 20,
    position: 'relative'
  },
  divColoredTextBorder: {
      display: 'flex',        /* Activa Flexbox */
      justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
      width: '100%',         /* Asegura que ocupe todo el ancho del Grid */
      position: 'absolute',   /* Posicionamiento absoluto respecto a su contenedor relativo */
      right: 20,            /* Alinea a la derecha */
      top: -20,              /* Alinea al borde superior */
  },
  blueTextBorder: {
    padding: 10,
    color: BLUE,
    backgroundColor: 'white'
  },
  orangeTextBorder: {
    padding: 10,
    color: NARANJA_PAGO_CARTA,
    backgroundColor: 'white'
  },
  blueContainer: {
    backgroundColor: "#D9EDF7",
    flexGrow: 1,
    padding: 15,
    borderRadius: 4
  },
  orangeContainer: {
    backgroundColor: "#f7e6d9",
    padding: 15,
    width: "100%",
    borderRadius: 4
  },
  redContainer: {
    backgroundColor: "#fca9a9",
    paddingLeft: 15, paddingRight: 15,
    paddingTop: 5, paddingBottom: 5,
    borderRadius: 4,
    marginTop: 10,
    width:'fit-content'
  },
  yellowContainer: {
    backgroundColor: "#ffe8a4",
    paddingLeft: 15, paddingRight: 15,
    paddingTop: 5, paddingBottom: 5,
    borderRadius: 4,
    marginTop: 10,
    width:'fit-content'
  },
  tag: {
      fontSize: 12,
      textAlign: 'center',
      //fontWeight: 'bold'
  },
  // ----------------------------------------------------------------------------------------
  fontM: {
    fontSize: 15
  },
  fontS: {
    fontSize: 13
  },
  pcBlueTitle: {
    cursor: "pointer",
    color: "#006993",
  },
});

export default styles;
