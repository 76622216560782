import React,{ FC, useEffect, useState } from "react";
import { mdiCalendarClock } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, Grid, makeStyles } from "@material-ui/core";
import { TMenuItem } from "containers/shared/menu-item-types";

import CitaprevIcon from '../../resources/home/citaprevia/Citaprev.png'
import ProximaCitasIcon from '../../resources/home/citaprevia/ProximaCitas.png'
import MasInfoIcon from '../../resources/home/notificaciones/info.png'
import BoxMenuItem from "components/box-menu-item";
import styles from "containers/shared/container-home";
import ModalAcceso from "components/modal-acceso";


interface IRouteParams {}
type Props = RouteComponentProps<IRouteParams>;

const useStyles = makeStyles(styles);

export const getUrlFestivos = (lang: string) => {
    if (lang === "es") {
        return "https://www.atib.es/DescargaDocs/Resolfest2024_s.pdf";
    }
    return "https://www.atib.es/DescargaDocs/Resolfest2024_c.pdf";
}

const CitaPreviaHome: FC<Props> = ({match, history}) => {
    const classes= useStyles()
    const [pageState, pageDispatcher] = usePage();
    const [showLogin, setShowLogin] = useState(false);
    const isMobile = window.innerWidth <= 590;

    const MENU_ITEMS: TMenuItem[] = [
        { title:{component: "CitaPrevia", text:"gestionCitas"}, img: CitaprevIcon, children : [
            { title: { component:"CitaPrevia" , text:"Pedir Cita" }, link: 'cita-previa/cita', protected: 0},
            { title: {component: "CitaPrevia",text: "Cancelar cita"}, link: '/cita-previa/cancelar-cita', protected: 0},
            { title: {component: "CitaPrevia",text: "Justificante"}, link: '/cita-previa/justificante', protected: 0},
        ] },
        { title: {component:"CitaPrevia",text:"Proximas citas"}, img: ProximaCitasIcon, children : [
            { title: {component: "CitaPrevia",text: "misCitas"}, link: 'cita-previa/mis-citas', protected: 1, idModulo: 17},
        ] },
        { title: {component: "Global", text:"Mas informacion"}, img: MasInfoIcon , children : [
            { title: {component: "Global", text:"dias_festivos"}, link: getUrlFestivos(pageState.lang), isExternLink: true, protected: 0},
        ] }
    ];

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiCalendarClock,
                title: <Term component="Global" text="Cita Previa" />,
                text: <Term component="Global" text="Cita Previa Descripcion" />,

            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return(
        <>
        <Card className={classes.root}>
            <Grid container direction='row' justify="flex-start">
                {MENU_ITEMS.map( (menuitem, idx) => (
                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`cita-previa-${idx}`}>
                        <BoxMenuItem 
                            item={menuitem} 
                            onLogin={(accessLevel) => {
                                if(isMobile){history.push('/acceso', { goBack: history.location.pathname }) } 
                                else {
                                    if(accessLevel === 2){history.push('/acceso/clave', { goBack: history.location.pathname } )}
                                    if(accessLevel === 1){setShowLogin(true)}
                                } 
                            }}  
                        />
                    </Grid>
                    
                ))}
            </Grid>
        </Card>
        <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />
        </>
    )
}

export default withRouter(CitaPreviaHome);

