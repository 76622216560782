import {FC, useContext, useEffect, useMemo, useState} from "react";

import { LiteralsContext } from "containers/shared/literals";


import { makeStyles } from "@material-ui/core";
import duti_styles from "../styles";
import { 
    EMPTY_DT_DATOSOPERACION_M600, EMPTY_DT_DOCTRANSMISION, 
    EMPTY_DT_INFOTRANSMISION, EMPTY_DT_PREVIOS, IResultPaso, TCausaDatosTransmInfoTransm, TCausaDatosTransmPrevios, TDTDatosComprador,  
    TDTDatosOperacionM600, TDTDatosVendedor, TDTDatosVendedorPlusval, 
    TDTInfoTransmision, TDTPrevios, TDUSTIInfo, TEndDUTIcausa, TPasoDatosTransmision 
} from "./types";
import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";
import DatosTransmisionPrevios from "./DatosTransmisionPrevios";
import DatosTransmisionInfoTransmision from "./DatosTransmisionInfoTransmision";
import { IDUTIsubStep } from "gateways/duti.interface";
import DUTIGateway from "gateways/duti.gateway";
import { translate } from "utils/i18n";
import FullLoading from "components/full-loading";
import DatosTransmisionDatosCompVend from "./DatosTransmisionDatosCompVend";
// import DatosTransmisionDocTransmision from "./DatosTransmisionDocTransmision";
import { IDUTIStepStepper, THeaderStepper } from "components/DUTI/stepper-duti";
import DatosVendedorPlusvalia from "./DatosTransmisionDatosVendPlusval";
import DatosTransmisionDatosOperacionM600 from "./DatosTransmisionDatosOperacionM600";
// import DatosTransmisionDatosOperacionPlusval from "./DatosTransmisionDatosOperacionPlusval";
import moment from "moment";
import usePage from "hooks/page.hook";
import { CustomError } from "utils/custom.error";


const useStyles = makeStyles(duti_styles);

interface IDatosTransmision {
    idDeclaracion: number | null // Si es alta está a null hasta finalizar todo el paso del Step
    idPaso: number,
    steps: IDUTIStepStepper[] | null;
    idPasoDeclaracionActivo: number | null;
    setFinished: (idDeclaracion: number, steps: IDUTIStepStepper[] | null) => void,
    onExit: () => void,
    onFirstSave: (v: THeaderStepper) => void,
    setShouldBlock: (v: boolean) => void,
}

const DatosTransmision: FC<IDatosTransmision> = ({ idDeclaracion, idPaso,steps, idPasoDeclaracionActivo, setFinished, onExit, onFirstSave, setShouldBlock }) => {
    const terms = useContext(LiteralsContext);
    const classes= useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const [loading, setLoading] = useState(false)
    
    const [ datosTransmision, setDatosTransmision] = useState<TPasoDatosTransmision>({} as TPasoDatosTransmision)
    const [idDUSTI, setIdDUSTI]= useState<number>(-1)
    // Sub-steps
    const [activeSubStep, setActiveSubStep] = useState(0)
    const [subStepsInfo, setSubStepInfo] = useState<IDUTIsubStep[]>([])

    // Indica si al guardar un step hay que borrar pasos que le siguien
    // Ej hemos guardad hasta DatosComprador - detectamos onBack (guardamos el indice del paso) 
    //    luego un onSave  guardamos el paso y lanzamos clena de los pasos idPasooActual+1 hasta idPasoOnBack
    // Evaluar si requiere reset de datos posteriores
    const [captureBack, setCaptureBack] = useState<number | null>(null)
    const [haveChanges, setHaveChanges] = useState(false)



    const withPlusval = useMemo(() => {
        return( 
            datosTransmision.datosPrevios && 
            datosTransmision.datosPrevios.muniGest.PLUSVAL && (
            datosTransmision.datosPrevios.tramitesGest.PLUSVAL && (
                datosTransmision.datosPrevios.tramitesGest.PLUSVAL.autoliquidacion === true ||
                datosTransmision.datosPrevios.tramitesGest.PLUSVAL.declaracion === true  ||
                datosTransmision.datosPrevios.tramitesGest.PLUSVAL.comunicacion === true 
            ))
        )
    },[datosTransmision.datosPrevios])
    const withM600 = useMemo(() => {
        return( 
            datosTransmision.datosPrevios && 
            datosTransmision.datosPrevios.muniGest.MODELO600 && (
            datosTransmision.datosPrevios.tramitesGest.MODELO600 && (
                datosTransmision.datosPrevios.tramitesGest.MODELO600.autoliquidacion === true ||
                datosTransmision.datosPrevios.tramitesGest.MODELO600.autoliquidacionPresentacion === true  
            ))
        )
    },[datosTransmision.datosPrevios])
    // GESTIÓN sub stepper ----------------------------
    const onNext=() => { 
        //console.log('onNext activeSubStep', activeSubStep, subStepsInfo.length)
        let nextIdx = activeSubStep+1
        //console.log(nextIdx)
        if(!withM600 && subStepsInfo[nextIdx]?.Nombre === 'datosOperacionModelo600' ){
            nextIdx = nextIdx+1
        }
        if( subStepsInfo.length >= nextIdx && !withPlusval && subStepsInfo[nextIdx]?.Nombre === 'datosOperacionPlusvalia' ){
            nextIdx = nextIdx+1
        }
        //console.log('onNext ', nextIdx, subStepsInfo.length )
        if( subStepsInfo.length <= nextIdx ){ // Lenght tiene +1 respecto de maxIndex
            //console.log('setFinished DatosTransm')
            setFinished(idDUSTI, steps);
        } else {
            setActiveSubStep(nextIdx)
        }
    }

    const onBack = () => {
        //console.log('onBack ', activeSubStep, idPasoDeclaracionActivo)
        setCaptureBack(activeSubStep)
        let nextIdx= activeSubStep-1;
        if(subStepsInfo.length >= nextIdx && !withM600 && subStepsInfo[nextIdx].Nombre === 'datosOperacionModelo600'){
            nextIdx = nextIdx-1
        }
        if(subStepsInfo.length >= nextIdx && !withPlusval && subStepsInfo[nextIdx].Nombre === 'datosOperacionPlusvalia'){
            nextIdx = nextIdx-1
        }
        setActiveSubStep(nextIdx);
    }

    // Funciones 
    const getIdPasosToClean = (ini: number,fin: number) => {
        //console.log('getIdPasosToClean',ini, fin)
        const ids: number []= []
        let idx = ini
        while (idx<=fin) {
            ids.push(subStepsInfo[idx].id)
            idx++
        }
        return ids
    }
    
    const saveStep = async (data: TPasoDatosTransmision, notCleanSteps?: boolean) => {
        try {
            setLoading(true);

            let result: IResultPaso = {saved: true, idDeclaracion: idDUSTI}

            // activeSubStep>captureBack => hemos ido atrás pero no hemos guardado nada - No actualizar
            // notCleanSteps === true => hay cambios pero no requieren de borrar steps futuros
            if(captureBack && (!(notCleanSteps === true) || activeSubStep>captureBack)){
                if(activeSubStep<captureBack ||( captureBack > idPaso)) {
                    const idsToClean = getIdPasosToClean(activeSubStep+1,subStepsInfo.length-1) //( captureBack > idPaso)? (subStepsInfo.length-1): captureBack )
                    // Borrar pasos 
                    result = await dutiGateway.cleanPasos(idDUSTI, idsToClean)
                    //console.log('Borrado ok ', result.saved)
                    if(result.saved){
                        setCaptureBack(null)
                    }
                }

            } else{
                setCaptureBack(null)
            }
                
            const pasoToSave= subStepsInfo[activeSubStep]
            if(result.saved ){
                switch (pasoToSave.Nombre) {
                    case 'datosPrevios':
                        //console.log('datosPrevios', data.datosPrevios)
                        const responseP = await dutiGateway.savePaso<TDTPrevios>( idDUSTI !== -1 ? idDUSTI: null ,pasoToSave.id, data.datosPrevios)
                        if(responseP.saved){
                            if(idDUSTI === -1){ 
                                setIdDUSTI(responseP.idDeclaracion)
                                onFirstSave({
                                    refCat: responseP.infoDeclaracion.DatosTransmision.datosPrevios.infoCatastral?.refCatastral ?? ' - ',
                                    dir: responseP.infoDeclaracion.DatosTransmision.datosPrevios.infoCatastral?.direccion ?? ' - ',
                                    urlCatastro: responseP.infoDeclaracion.DatosTransmision.datosPrevios.infoCatastral?.urlCatastro ?? ' - ',
                                    muni: responseP.infoDeclaracion.DatosTransmision.datosPrevios.municipio?.code
                                })
                            }
                            setDatosTransmision(curr => ({ ...responseP.infoDeclaracion.DatosTransmision}))                            
                        }
                        result = {idDeclaracion: responseP.idDeclaracion, saved: responseP.saved}
                        break;
                    case 'infoTransmision': 
                        //console.log('infoTransmision', data.infoTransmision)
                        const responseI = await dutiGateway.savePaso<TDTInfoTransmision>(idDUSTI ,pasoToSave.id, data.infoTransmision)
                        if(responseI.saved){
                            setDatosTransmision(curr => ({ ...responseI.infoDeclaracion.DatosTransmision}))
                        }
                        result = {idDeclaracion: responseI.idDeclaracion, saved: responseI.saved}
                        break;
                    case 'datosComprador': 
                        //console.log('datosComprador', data.datosComprador)
                        const responseC = await dutiGateway.savePaso<TDTDatosComprador>(idDUSTI,pasoToSave.id, data.datosComprador)
                        if(responseC.saved){
                            setDatosTransmision(curr => ({ ...responseC.infoDeclaracion.DatosTransmision}))
                        }
                        result = {idDeclaracion: responseC.idDeclaracion, saved: responseC.saved}
                        break;
                    case 'datosVendedor': 
                        const responseV = await dutiGateway.savePaso<TDTDatosVendedor>(idDUSTI,pasoToSave.id, data.datosVendedor)
                        result = {idDeclaracion: responseV.idDeclaracion, saved: responseV.saved}
                        if(responseV.saved){
                            setDatosTransmision(curr => ({ ...responseV.infoDeclaracion.DatosTransmision}))
                        }
                        break;
                    case 'datosOperacionModelo600': 
                        //console.log('datosOperacionModelo600', data.datosOperacionModelo600)
                        const responseM600 =await dutiGateway.savePaso<TDTDatosOperacionM600>(idDUSTI, pasoToSave.id,data.datosOperacionModelo600)
                        result = {idDeclaracion: responseM600.idDeclaracion, saved: responseM600.saved}
                        if(responseM600.saved){
                            setDatosTransmision(curr => ({ ...responseM600.infoDeclaracion.DatosTransmision}))
                        }
                        break;
                    case 'datosOperacionPlusvalia': 
                        //console.log('datosOperacionPlusvalia', data.datosOperacionPlusvalia)
                        const responseOP =await dutiGateway.savePaso<TDTDatosVendedorPlusval>(idDUSTI, pasoToSave.id, data.datosOperacionPlusvalia)
                        result = {idDeclaracion: responseOP.idDeclaracion, saved: responseOP.saved}
                        if(responseOP.saved){
                            setDatosTransmision(curr => ({ ...responseOP.infoDeclaracion.DatosTransmision}))
                        }
                        break;
                    default:
                        break;
                }
            }
                
            setLoading(false)

            return result;

        } catch (error) {
            setLoading(false);
            return { saved: false, idDeclaracion: -1, error };
        }        
    }

    const saveStepAndContinue = async (data: TPasoDatosTransmision, requiredSave: boolean, notClean:boolean) => {
        if(requiredSave){
            const result =  await saveStep(data, notClean);
            if(result.saved){
                onNext();
            } else if (result.error instanceof CustomError && result.error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_save_paso', terms),
                        variant: "error"
                    }
                })
            }   
        } else{
            onNext()
        }
    }

    const handleCancel = async ( causa: TEndDUTIcausa, data: TPasoDatosTransmision ) => {
        try{
            const paso= subStepsInfo[activeSubStep]
            let result: {idDeclaracion: number; saved: boolean;infoDeclaracion: TDUSTIInfo|null}= {idDeclaracion: idDUSTI, saved: false, infoDeclaracion: null}
            if(causa === 'continuar-mas-tarde'){
                //guardamos
                if(haveChanges){
                    result = await dutiGateway.savePaso(idDUSTI, paso.id, data)
                } else {
                    result={...result, saved: true}
                }
                
            } else {
                // change info dusti - status CANCEL
                const isCanceled = await dutiGateway.cancelDeclaracion(idDUSTI,paso.id, causa)
                result={...result, saved: isCanceled}
            }
            
            if( result.saved ){ 
                setHaveChanges(false)
                onExit()
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_cancel_dusti', terms).concat(` id:${datosTransmision.datosPrevios.idDeclaracion}, paso: ${paso.Nombre}, motivo: ${causa}`),
                        variant: "error"
                    }
                })
            }

        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI', 'error_save_paso', terms),
                        variant: "error"
                    }
                });
            }
        }  
    }
    

    
    useEffect(() => {
        (async() => {
            // inicializamos componente
            setLoading(true)

            try {
                let activeSubStep = 0
                const sub_steps = await dutiGateway.getPasosByIdPasoPadre(idPaso) as IDUTIsubStep[]
                setSubStepInfo(sub_steps as IDUTIsubStep[])
                if(idDeclaracion && idDeclaracion !== -1){
                    //console.log('effect recuperar dusti', idDeclaracion, ' IdPasoDeclaracionActivo:',idPasoDeclaracionActivo);
                    setIdDUSTI(idDeclaracion)
                    // Extraer datos XML
                    const xmlToObject = await dutiGateway.getDetalleDeclaracion(idDeclaracion)
                    setDatosTransmision({
                        datosPrevios: xmlToObject.DatosTransmision?.datosPrevios ? {...xmlToObject.DatosTransmision.datosPrevios}: {...EMPTY_DT_PREVIOS},
                        infoTransmision: xmlToObject.DatosTransmision?.infoTransmision ? {...xmlToObject.DatosTransmision.infoTransmision}: {...EMPTY_DT_INFOTRANSMISION},
                        datosComprador:  xmlToObject.DatosTransmision?.datosComprador ?? [],
                        datosVendedor:  xmlToObject.DatosTransmision?.datosVendedor ?? [],
                        documentacionTransmision: xmlToObject.DatosTransmision?.documentacionTransmision ? {...xmlToObject.DatosTransmision.documentacionTransmision}: {...EMPTY_DT_DOCTRANSMISION},
                        datosOperacionModelo600: xmlToObject.DatosTransmision?.datosOperacionModelo600 ? {...xmlToObject.DatosTransmision.datosOperacionModelo600} :{...EMPTY_DT_DATOSOPERACION_M600},
                        datosOperacionPlusvalia: xmlToObject.DatosTransmision?.datosOperacionPlusvalia ?? [],
                    })
                    onFirstSave({
                        refCat: xmlToObject.DatosTransmision?.datosPrevios.infoCatastral?.refCatastral ?? ' - ', 
                        dir: xmlToObject.DatosTransmision?.datosPrevios.infoCatastral?.direccion ?? ' - ',
                        urlCatastro: xmlToObject.DatosTransmision?.datosPrevios.infoCatastral?.urlCatastro ?? ' - ', 
                        muni: xmlToObject.DatosTransmision?.datosPrevios.municipio?.code, 
                    })
                    if(idPasoDeclaracionActivo){
                        // Si es mayor al maxStep.Orden es que pertenece a un padre posterior.
                        // Si no pertenece a substeps y su id es mayor que el paso actual que renderizamos (PASO: 1)
                        // En ambos casos - hay que renderizar el último paso
                        const maxStep = sub_steps[sub_steps.length -1]
                        const foundChild = sub_steps.find(item => item.id === idPasoDeclaracionActivo)
                        //console.log('maxStep', maxStep, idPasoDeclaracionActivo, idPaso)
                        if(idPasoDeclaracionActivo <= maxStep.id && foundChild!== undefined ){
                            const idx = sub_steps.findIndex( s => (s.id === idPasoDeclaracionActivo))
                            //console.log('idx', idx) 
                            activeSubStep=idx
                        } else {
                            if(idPasoDeclaracionActivo > idPaso){ // Ejemplo: Paso 2 activo (es superior a PASO 1 por lo que hay que ir al subestep PASO 1 - Ultimo elemento)
                                activeSubStep= sub_steps.length-1
                                const withM600_local = xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.MODELO600 && (
                                    xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.MODELO600.autoliquidacion === true ||
                                    xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.MODELO600.autoliquidacionPresentacion === true  
                                )
                                const withPlusval_local = xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.PLUSVAL && (
                                    xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.PLUSVAL.autoliquidacion === true ||
                                    xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.PLUSVAL.declaracion === true  ||
                                    xmlToObject.DatosTransmision?.datosPrevios.tramitesGest.PLUSVAL.comunicacion === true 
                                )
                                // Comprobamos que no se renderice un paso que no debe mostrarse- El orden ES RELEVANTE
                                if(!withPlusval_local && sub_steps[activeSubStep].Nombre === 'datosOperacionPlusvalia'){
                                    activeSubStep = activeSubStep-1
                                }
                                if(!withM600_local && sub_steps[activeSubStep].Nombre === 'datosOperacionModelo600'){
                                    activeSubStep = activeSubStep-1
                                }
                            } else {
                                activeSubStep=0
                            }
                            
                            //console.log('sub_steps establecer activeSubStep como máximo ', sub_steps.length-1)
                        }
                    }
                    //console.log(sub_steps, 'activeSubStep =>', activeSubStep)
                    setActiveSubStep(activeSubStep)
                } else {
                    // es alta
                    setDatosTransmision({
                        datosPrevios: {...EMPTY_DT_PREVIOS},
                        infoTransmision:{...EMPTY_DT_INFOTRANSMISION},
                        datosComprador:  [],
                        datosVendedor: [],
                        documentacionTransmision:{...EMPTY_DT_DOCTRANSMISION},
                        datosOperacionModelo600:{...EMPTY_DT_DATOSOPERACION_M600},
                        datosOperacionPlusvalia :[],

                    })
                    setActiveSubStep(0)
                }
                setHaveChanges(false);
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_save_paso', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        })()
       
    }, [idDeclaracion, idPasoDeclaracionActivo])


    return (
        <>
        <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}} />
        {!loading && subStepsInfo.length>0 && Object.keys(datosTransmision).length >0 && 
            <div className={classes.componenteContainer}>
                {subStepsInfo[activeSubStep].Nombre === 'datosPrevios' &&
                    <DatosTransmisionPrevios 
                        initialDTPrevios={datosTransmision.datosPrevios} 
                        handleContinuar={(prev: TDTPrevios, haveChanges) => saveStepAndContinue({...datosTransmision, datosPrevios: {...prev}}, haveChanges, false)} 
                        handleCancelDUTI={(c:TCausaDatosTransmPrevios, prev: TDTPrevios) =>(
                            datosTransmision.datosPrevios.idDeclaracion 
                            ? handleCancel(c, {...datosTransmision, datosPrevios: {...prev}}) 
                            : onExit()
                        )} 
                        updateStepperRefCat={onFirstSave}
                        setShouldBlock={setShouldBlock}
                    />
                } 
                {subStepsInfo[activeSubStep].Nombre === 'infoTransmision' &&
                    <DatosTransmisionInfoTransmision 
                        idDeclaracion={idDUSTI}
                        codMuni={datosTransmision.datosPrevios.municipio?.code ?? ""}
                        refCatastral={datosTransmision.datosPrevios.infoCatastral?.refCatastral ?? ""}
                        valorRef={datosTransmision.datosPrevios.infoCatastral?.datosEconomicos?.valorReferencia }
                        withPlusval={withPlusval}
                        initialDTInfoTransmision={datosTransmision.infoTransmision ?? {...EMPTY_DT_INFOTRANSMISION}} 
                        handleContinuar={ (trans: TDTInfoTransmision, haveChanges) => saveStepAndContinue({...datosTransmision, infoTransmision: {...trans}},haveChanges, false)} 
                        handleBack={onBack} 
                        handleCancelDUTI={(c: TCausaDatosTransmInfoTransm, trans: TDTInfoTransmision) => handleCancel(c, {...datosTransmision, infoTransmision: {...trans}})}
                        setShouldBlock={setShouldBlock}
                    />
                }

                { subStepsInfo[activeSubStep].Nombre === 'datosComprador' &&
                    <DatosTransmisionDatosCompVend
                        tipo="comprador" 
                        comDomFisc={datosTransmision.datosPrevios.tramitesGest.COM_DOMFISC}
                        tipoTransmitente={datosTransmision.datosPrevios.tipoTransmitente}
                        nifSujeto={datosTransmision.datosPrevios.nifSujeto}
                        direccionInmueble={datosTransmision.datosPrevios.infoCatastral?.direccion ?? ''}
                        initialDatos={datosTransmision.datosComprador}
                        handleContinuar={ (compr,haveChanges, notClean) => {
                            saveStepAndContinue({...datosTransmision, datosComprador: [...compr as TDTDatosComprador]},haveChanges, notClean)
                        }} 
                        handleBack={onBack} 
                        handleCancelDUTI={(c, compr) => handleCancel(c, {...datosTransmision, datosComprador: [...compr as TDTDatosComprador]})}
                        actuaNombrePropio={datosTransmision.datosPrevios.actuaNombrePropio}
                        compradoresList={[]}
                        withM600={datosTransmision.datosPrevios.tramitesGest.MODELO600 && (
                            datosTransmision.datosPrevios.tramitesGest.MODELO600.autoliquidacion === true ||
                            datosTransmision.datosPrevios.tramitesGest.MODELO600.autoliquidacionPresentacion === true

                        )}
                    />
                }
                { subStepsInfo[activeSubStep].Nombre === 'datosVendedor' &&
                    <DatosTransmisionDatosCompVend
                        tipo="vendedor"
                        comDomFisc={datosTransmision.datosPrevios.tramitesGest.COM_DOMFISC}
                        tipoTransmitente={datosTransmision.datosPrevios.tipoTransmitente}
                        nifSujeto={datosTransmision.datosPrevios.nifSujeto}
                        direccionInmueble={datosTransmision.datosPrevios.infoCatastral?.direccion ?? ''}
                        initialDatos={datosTransmision.datosVendedor}
                        handleContinuar={ (vend,haveChanges,notClean) => saveStepAndContinue({...datosTransmision, datosVendedor: [...vend as TDTDatosVendedor]}, haveChanges,notClean)} 
                        handleBack={onBack} 
                        handleCancelDUTI={(c, vend) => handleCancel(c, {...datosTransmision, datosVendedor: [...vend as TDTDatosVendedor]})}
                        actuaNombrePropio={datosTransmision.datosPrevios.actuaNombrePropio}
                        compradoresList={ datosTransmision.datosComprador}
                        withM600={false}
                    />
                }
                { subStepsInfo[activeSubStep].Nombre === 'datosOperacionModelo600' &&
                    <DatosTransmisionDatosOperacionM600
                        idDeclaracion={idDUSTI}
                        ejercicio={ datosTransmision.infoTransmision.fecha ? moment(datosTransmision.infoTransmision.fecha).format('YYYY') : ''}
                        valorDeclarado={datosTransmision.infoTransmision.valorDeclarado}
                        inmueble={{
                            tipoBien: datosTransmision.datosPrevios.infoCatastral?.tipoBien, 
                            uso: datosTransmision.datosPrevios.infoCatastral?.datosEconomicos?.uso
                        }}
                        initialDatosOperacion={datosTransmision.datosOperacionModelo600 ?? {...EMPTY_DT_DATOSOPERACION_M600} }
                        compradores={datosTransmision.datosComprador}
                        handleContinuar={ (oper: TDTDatosOperacionM600, haveChanges) => saveStepAndContinue({...datosTransmision, datosOperacionModelo600: {...oper}},haveChanges, false)} 
                        handleBack={onBack} 
                        handleCancelDUTI={(c, oper: TDTDatosOperacionM600) => handleCancel(c, {...datosTransmision, datosOperacionModelo600: {...oper}})}
                        setShouldBlock={setShouldBlock}
                    />
                }

                { subStepsInfo[activeSubStep].Nombre === 'datosOperacionPlusvalia' &&
                    <DatosVendedorPlusvalia
                        idDeclaracion={idDUSTI}
                        tipoPlusval={datosTransmision.datosPrevios.tramitesGest.PLUSVAL ?
                            datosTransmision.datosPrevios.tramitesGest.PLUSVAL.autoliquidacion === true 
                                ? 'autoliquidacion' 
                                :  datosTransmision.datosPrevios.tramitesGest.PLUSVAL.comunicacion === true 
                                    ? 'comunicacion': datosTransmision.datosPrevios.tramitesGest.PLUSVAL.declaracion === true ? 'declaracion': undefined
                            : undefined}
                        sujetoPasivo={datosTransmision.datosPrevios.tramitesGest.PLUSVAL ? datosTransmision.datosPrevios.tramitesGest.PLUSVAL.sujetoPasivo : undefined}
                        valorDeclarado={datosTransmision.infoTransmision.valorDeclarado}
                        fecha={datosTransmision.infoTransmision.fecha ?? new Date()}
                        vendedores={datosTransmision.datosVendedor}
                        compradores={ datosTransmision.datosComprador.map( c => ({id: c.nif, nombre: `${c.nomApe} - ${c.nif}`}))}
                        initialDatos={datosTransmision.datosOperacionPlusvalia ?? []}
                        handleContinuar={ (vendPlusv,haveChanges) => saveStepAndContinue({...datosTransmision, datosOperacionPlusvalia: [...vendPlusv as TDTDatosVendedorPlusval]},haveChanges, false)} 
                        handleBack={onBack} 
                        handleCancelDUTI={(c, vendPlusv) => handleCancel(c, {...datosTransmision, datosOperacionPlusvalia: [...vendPlusv as TDTDatosVendedorPlusval]})}
                    />
                }
   
  
            </div>
        }
        </>
    )
}

export default DatosTransmision;

