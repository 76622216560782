import { StyleRules, Theme } from "@material-ui/core";

export const styles = (theme: Theme): StyleRules => ({
    card: {
        margin: 10,
        marginLeft: 5,
        flex: 1,
        display: 'flex'
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column'
    },
    docInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flex: 1
    },
    docDetails: {
        marginTop: 40,
        display: 'flex',
        justifyContent: 'flex-start',
        flex: 4
    },
    button: {
        width: 'fit-content',
        height: 'fit-content',
        marginTop: 40,
        marginLeft: 10,
        marginRight: 10
    },
    cardTitle: {

    },
    cardBody: {
        flex: 1,
        display: 'flex',
        marginTop: 10,
        flexDirection: 'column'
    },
    cardDescription: {
        marginTop: 20,
        marginBottom: 20
    },
    cardFields: {
        display: 'flex',
        flexDirection: 'row'
    },
    cardBodyStructure: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    scrolleAble: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: 360
    },
    smallFont: {
        fontSize: '0.75rem'
    },
    centerContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noWrapEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },



})