/* eslint-disable react-hooks/exhaustive-deps */

//LIBRARY IMPORTS
import React, { useCallback, useEffect, useState } from 'react';
import { Card, FormControl, WithStyles, withStyles, TextField, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

//  LOCAL IMPORTS
import { REGEX_DOC_NUM, REGEX_EMAIL, REGEX_PHONE } from '../../../constants/regular-expressions';
import { IParamsCitaPrevia } from 'gateways/citaPrevia.interface';
import { ISujeto } from 'gateways/perfil.interfaces';
import { validateNif } from "utils/validateNif";
import usePage from 'hooks/page.hook';
import Term from 'components/term';

// STYLES
import styles from '../cita-previa.styles';

interface IProps {
    paramsCita: IParamsCitaPrevia | undefined;
    validate: boolean;
    sujeto: ISujeto | null;

    onLastStep: (docNum: string, email: string, phone: string, nombreCompleto: string) => void;
    onChangeDisableButton: (disableButton: boolean) => void;
    onConfirmValidate: (confirm: boolean) => void;
}

type Props = WithStyles<typeof styles> & RouteComponentProps & IProps;

const DatosPersonales: React.FC<Props> = (props) => {
    const {
        classes,
        paramsCita,
        validate,
        sujeto,

        onLastStep,
        onChangeDisableButton,
        onConfirmValidate
    } = props;

    // HOOKS
    const [pageState,] = usePage();

    // PERSONAL DATA
    const [docNum, setDocNum] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailConfirm, setEmailConfirm] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [nombreCompleto, setNombreCompleto] = useState<string>('');

    // ERROR STATES VARS
    const [errorFormatPhone, setErrorFormatPhone] = useState(false);
    const [errorFormatDocNum, setErrorFormatDocNum] = useState(false);
    const [errorEmailFormat, setErrorEmailFormat] = useState(false);
    const [errorEmailConfirm, setErrorEmailConfirm] = useState(false);
    const [errorNombre, setErrorNombre] = useState(false);


    // EFFECTS

    useEffect(() => {
        if (validate) {
            if (handleValidate()) {
                onLastStep(docNum, email, phone, nombreCompleto);
                onConfirmValidate(true);
            } else {
                onConfirmValidate(false);
            }
        }
    }, [validate, docNum, email, phone, nombreCompleto]);

    useEffect(() => {
        onChangeDisableButton(false)
        if (paramsCita && paramsCita.docNum !== undefined) {
            paramsCita.nombreCompleto && setNombreCompleto(paramsCita.nombreCompleto);
            paramsCita.docNum && setDocNum(paramsCita.docNum);
            paramsCita.email && setEmail(paramsCita.email);
            paramsCita.email && setEmailConfirm(paramsCita.email);
            paramsCita.phone && setPhone(paramsCita.phone);
        }
        else if (pageState.jwp !== null) {
            setNombreCompleto(pageState.jwp && pageState.jwp.fullname);
            setDocNum(pageState.jwp && pageState.jwp.nif);
            if (sujeto !== null && sujeto.movil != null) {
                setPhone(sujeto.movil);
            }
            if (sujeto !== null && sujeto.email != null) {
                setEmail(sujeto.email);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // HANDLERS

    const handleDocNum = useCallback((docNum: string) => {
        setErrorFormatDocNum(false);
        const regex = new RegExp(REGEX_DOC_NUM, "g");
        setDocNum(docNum.toUpperCase().replaceAll(regex, ''));
    }, []);

    const handleNombreCompleto = useCallback((nombreCompleto: string) => {
        setErrorNombre(false)
        setNombreCompleto(nombreCompleto);
    }, []);

    const handleEmail = useCallback((email: string) => {
        setErrorEmailFormat(false);
        setEmail(email);
    }, []);


    const handleEmailConfirm = useCallback((emailConfirm: string) => {
        setErrorEmailConfirm(false);
        setEmailConfirm(emailConfirm);
    }, []);

    const handlePhone = useCallback((phone: string) => {
        setErrorFormatPhone(false);
        setPhone(phone);
    }, []);

    const handleValidate = useCallback(() => {
        let result = true;
        if (phone === '' || !REGEX_PHONE.test(phone)) {
            setErrorFormatPhone(true);
            result = false;
        }
        if (docNum === '' || (docNum && !validateNif(docNum))) {
            setErrorFormatDocNum(true);
            result = false;
        }
        if (email && !REGEX_EMAIL.test(email)) {
            setErrorEmailFormat(true);
            result = false;
        }
        if (email !== emailConfirm) {
            setErrorEmailConfirm(true);
            result = false;
        }
        if (email === '') {
            setErrorEmailConfirm(false);
        }
        if (nombreCompleto === '') {
            setErrorNombre(true)
            result = false
        }
        return result;
    }, [phone, REGEX_PHONE, docNum, email, REGEX_EMAIL, emailConfirm]);



    return (
        <>
            <Card className={classes.cardDatosPersonales}>
                <Typography className={classes.titleFormSection}>
                    <Term component="CitaPrevia" text="Datos personales" />
                </Typography>
                <div className={classes.formSection}>
                    <div className={classes.formField}>
                        <FormControl style={{ width: '80%' }} fullWidth>
                            {/* NIF  */}
                            <TextField
                                label="DNI/CIF"
                                value={docNum}
                                onChange={(e) => handleDocNum(e.target.value)}
                                style={{ width: '100%' }}
                                error={errorFormatDocNum}
                                required
                            />
                            {errorFormatDocNum &&
                                <div style={{ color: '#f44336' }}>
                                    <Term component='Global' text='El formato del NIF / CIF no es válido' />
                                </div>
                            }
                        </FormControl>
                    </div>

                    <div className={classes.formField}>
                        <FormControl style={{ width: '80%' }} fullWidth>
                            {/* NOMBRE Y APELLIDOS  */}
                            <TextField
                                label={<Term component="Global" text="Nombre y apellidos" />}
                                value={nombreCompleto}
                                onChange={(e) => handleNombreCompleto(e.target.value)}
                                style={{ width: '100%' }}
                                error={errorNombre}
                                required
                            />
                            {errorNombre &&
                                <div style={{ color: '#f44336' }}>
                                    <Term component='CitaPrevia' text='Error nombre' />
                                </div>
                            }
                        </FormControl>
                    </div>
                </div>

                <Typography className={classes.titleFormSection}>
                    <Term component="CitaPrevia" text="Datos de contacto" />
                </Typography>
                <div className={classes.formSection}>
                    <div className={classes.formField}>
                        <FormControl style={{ width: '80%' }} fullWidth>
                            {/* EMAIL */}
                            <TextField
                                label={<Term component="Global" text="Email" />}
                                value={email}
                                onChange={(e) => handleEmail(e.target.value)}
                                style={{ width: '100%' }}
                                error={errorEmailFormat}
                            />
                            {errorEmailFormat &&
                                <div style={{ color: '#f44336' }}>
                                    <Term component='Global' text='Error formato email' />
                                </div>
                            }
                        </FormControl>
                    </div>

                    <div className={classes.formField}>
                        <FormControl style={{ width: '80%' }} fullWidth>
                            {/* CONFIRM EMAIL */}
                            <TextField
                                label={<Term component="Global" text="Confirmación email" />}
                                value={emailConfirm}
                                onChange={(e) => handleEmailConfirm(e.target.value)}
                                style={{ width: '100%' }}
                                error={errorEmailConfirm}
                                onPaste={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                            />
                            {errorEmailConfirm &&
                                <div style={{ color: '#f44336' }}>
                                    <Term component='CitaPrevia' text='Error email confirmación' />
                                </div>
                            }
                        </FormControl>
                    </div>
                </div>

                <div className={classes.formSection}>
                    <div className={classes.formField}>
                        <FormControl className={classes.singleFormSection} style={{ marginBottom: 20 }} fullWidth>
                            {/* TELÉFONO MÓVIL  */}
                            <TextField
                                label={<Term component="Global" text="Movil" />}
                                value={phone}
                                onChange={(e) => handlePhone(e.target.value)}
                                style={{ width: '100%' }}
                                error={errorFormatPhone}
                                required
                            />
                            {errorFormatPhone &&
                                <div style={{ color: '#f44336' }}>
                                    <Term component='CitaPrevia' text='Error formato móvil' />
                                </div>
                            }
                        </FormControl>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default withRouter(withStyles(styles)(DatosPersonales));