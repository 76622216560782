import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { mdiOpenInApp, mdiSitemap } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Card, Divider, Grid, Typography, makeStyles } from "@material-ui/core";

import AreaPersonaIcon from '../../resources/home/AreaPersonal2.png';
import AtencionPersonalizadaIcon from '../../resources/home/AtencionPersonalizada.png';
import { TMenuItem } from "containers/shared/menu-item-types";
import BoxMenuItem from "components/box-menu-item";
import styles from "containers/shared/container-home";
import Icon from "@mdi/react";
import ModalAcceso from "components/modal-acceso";
import { newPrepareTramitesData } from "containers/tramites/tramites";
import { ITramitesData } from "containers/tramites/tramites.interfaces";
import { TramitesGateway } from "gateways/tramites.gateway";
import IoC from "contexts/ioc.context";
import { LiteralsContext } from "containers/shared/literals";
import FullLoading from "components/full-loading";

import MisNotificaIcon from '../../resources/home/notificaciones/MisNotifica.png'
import MasInfoIcon from '../../resources/home/notificaciones/info.png'
import RepresentacionesIMG from '../../resources/home/tramites/Representaciones.png'
import ConsultaDeuda from '../../resources/home/cartero/ConsultaDeuda.png';
import Domiciliaciones from '../../resources/home/cartero/Domiciliaciones.png';
import PagarDoc from '../../resources/home/cartero/PagarDoc.png';
import MisBienesIcon from '../../resources/bienesSujeto/BienesSuj.png';
import ValoracionesIcon from '../../resources/bienesSujeto/Valoraciones.png';
import AutonomicosIcon from '../../resources/home/tributos/Autonomicos.png'
import LocalesIcon from '../../resources/home/tributos/Locales.png'
import PresentacionesIMG from '../../resources/home/tramites/Presentaciones.png'
import DutiIMG from '../../resources/home/tramites/iconoDusti.png'
import CitaprevIcon from '../../resources/home/citaprevia/Citaprev.png'
import ProximaCitasIcon from '../../resources/home/citaprevia/ProximaCitas.png'
import { getUrlFestivos } from "containers/cita-previa/cita-previa-home";
import { link } from "fs";
import { SHOW_DUSTI } from "constants/dusti";







interface IRouteParams { }
type Props = RouteComponentProps<IRouteParams>;

const useStylesCustom = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: 30,
        paddingBottom: 10,
        marginRight: 15,
        marginLeft: 15,
        width: '100%'
    },
    title: {
        fontSize: 24,
        color: '#004f84',
        fontWeight: 500,
        textTransform: 'uppercase',
        marginLeft: 5,
        marginBottom: 0,
        paddingRight: 10,
        paddingLeft: 10,
        display: 'flex',
        flex: 1,
    },
    divider: {
        height: 1,
        backgroundColor: '#004f84',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        color: '#000',
        flex: 1,
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        marginRight: 30,
    },
    textDisabled: {
        textAlign: 'justify',
        color: 'grey',
        flex: 1,
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        marginRight: 30,
    },
}));

const useStyles = makeStyles(styles);


const MapaWeb: FC<Props> = ({ match, history }) => {
    const classes = useStyles()
    const classesCustom = useStylesCustom()
    const terms = useContext(LiteralsContext)
    const [pageState, pageDispatcher] = usePage();

    const ioc = useContext(IoC);
    const tramitesGateway: TramitesGateway = ioc.get(TramitesGateway);

    const [showLogin, setShowLogin] = useState(false);
    const isMobile = window.innerWidth <= 590;
    const [TRAMITES_MENU_ITEMS, setTRAMITESMENUITEMS] = useState<TMenuItem[]>([]);
    const [loading, setLoading] = useState(false)

    const handleOpenModalRepresentar = useCallback(() => {
        pageDispatcher({ type: 'open-modal-representar' })
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            setLoading(true)
            let response: ITramitesData[] = await tramitesGateway.getAllTramites();
            setTRAMITESMENUITEMS(newPrepareTramitesData(response, terms));
            setLoading(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terms]);

    const CARERO_VIRTUAL_MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "CarteroVirtual", text: "ConsultaDeudas" }, img: ConsultaDeuda, children: [
                { title: { component: "CarteroVirtual", text: "Deuda pagada" }, link: 'cartero-virtual/deuda-pagada', protected: 1, idModulo: 1 },
                { title: { component: "CarteroVirtual", text: "Deuda pendiente" }, link: 'cartero-virtual/deuda-pendiente', protected: 1, idModulo: 2 },
                { title: { component: "CarteroVirtual", text: "PagoTelematicoDocIngreso" }, link: '/cartero-virtual/pagoTelematico', protected: 0 },
            ]
        },
        {
            title: { component: "CarteroVirtual", text: "DomiciliacionTributos" }, img: Domiciliaciones, children: [
                { title: { component: "Domiciliaciones", text: "tributos_domiciliados" }, link: 'cartero-virtual/domiciliaciones/domiciliados/domiciliados', protected: 1, idModulo: 3 },
                { title: { component: "Domiciliaciones", text: "Tributos domiciliables" }, link: 'cartero-virtual/domiciliaciones/domiciliables', protected: 1, idModulo: 4 },
                { title: { component: "Domiciliaciones", text: "Pago a la carta" }, link: 'cartero-virtual/domiciliaciones/domiciliados/pago-carta', protected: 1, idModulo: 5 },
            ]
        },
        {
            title: { component: "Global", text: "Informacion" }, img: MasInfoIcon, children: [
                { title: { component: "CarteroVirtual", text: "formas_acceso_cartero_virtual" }, link: 'cartero-virtual/contenidos', protected: 0 },
                { title: { component: "CarteroVirtual", text: "calendario_fiscal" }, link: `https://www.atib.es/TL/CalendarioFiscal.aspx?lang=`, isExternLink: true, protected: 0 },
                { title: { component: "CarteroVirtual", text: "fechas_gestion_domiciliacion" }, link: pageState.lang === 'ca' ? `https://www.atib.es/TL/domiciliacion/PREVISIO%20DOMI%202024b_c.pdf` : `https://www.atib.es/TL/domiciliacion/PREVISIO%20DOMI%202024b_s.pdf`, isExternLink: true, protected: 0 },
                { title: { component: "CarteroVirtual", text: "aplicacion_movil" }, link: `https://sede.atib.es/cva/app-redirect.html`, isExternLink: true, protected: 0 },
                { title: { component: "CarteroVirtual", text: "preguntas_frecuentes" }, link: 'faqs/5', protected: 0 },
            ]
        },
    ];
    const PERFIL_MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "Perfil", text: "MisDatos" }, img: AreaPersonaIcon, children: [
                { title: { component: "Perfil", text: "ConsultaDatos" }, link: 'perfil/mi-perfil', protected: 1, idModulo: 18 },
            ]
        },
        {
            title: { component: "Global", text: "Mis notificaciones" }, img: MisNotificaIcon, children: [
                { title: { component: "Global", text: "mis_notificaciones_descripcion" }, link: 'perfil/notificaciones/mis-notificaciones', protected: 2, idModulo: 6 },
                { title: { component: "Global", text: "dias_cortesia_descripcion" }, link: 'perfil/notificaciones/dias-cortesia', protected: 2, idModulo: 7 },
                { title: { component: "Global", text: "mas_info_descripcion" }, link: 'perfil/contenidos', protected: 0 },
            ]
        },
        {
            title: { component: "Global", text: "Representaciones" }, img: RepresentacionesIMG, children: [
                { title: { component: "Perfil", text: "Representar" }, link: '', protected: 2, onClick: handleOpenModalRepresentar },
                { title: { component: "Perfil", text: "ConsultaRepresentantes" }, link: 'perfil/representaciones/gestion-de-representaciones', protected: 1, idModulo: 10 },
            ]
        },
        {
            title: { component: "Perfil", text: "AtencionPersonalizada" }, img: AtencionPersonalizadaIcon, children: [
                { title: { component: "Perfil", text: "ConsultaSugerencias" }, link: 'perfil/atencion-personalizada/consultas', protected: 0 },
            ]
        },
    ];
    const BIENES_SUJETO_MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "Global", text: "BienesSujeto" }, img: MisBienesIcon, children: [
                { title: { component: "Global", text: "Inmuebles" }, link: 'bienes-sujeto/inmuebles', protected: 2, idModulo: 8 },
                { title: { component: "Global", text: "Vehiculos" }, link: 'bienes-sujeto/vehiculos', protected: 2, idModulo: 9 },

            ]
        },
        {
            title: { component: "Global", text: "ValoracionesBienesSujeto" }, img: ValoracionesIcon, children: [
                { title: { component: "BienesSujeto", text: "valoracion_bienes" }, link: `https://www.atib.es/TA/valoraciones/default.aspx?lang=`, isExternLink: true, protected: 0 },
            ]
        }
    ]
    const TRIBUTOS_MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "Tributos", text: "Tributos Autonomicos" }, img: AutonomicosIcon, children: [
                { title: { component: "Tributos", text: "Tasas administrativas" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Pago con documento de ingreso" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuestos sobre transmisiones patrimoniales" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre sucesiones y donaciones" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Canon saneamiento de aguas" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Tasa fiscal sector del juego" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre estancias turísticas" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Canon sobre el vertido y la incineración de residuos" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre el depósito de residuos en vertederos, la incineración y la coincineración de residuos" }, link: 'tributos/tributos-autonomicos', protected: 0 },
            ]
        },
        {
            title: { component: "Tributos", text: "Tributos Locales" }, img: LocalesIcon, children: [
                { title: { component: "Tributos", text: "Tasas administrativas" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Pago con documento de ingreso" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre vehículos de tracción mecánica" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre construcciones, instalaciones y obras" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre el incremento de valor de terrenos de naturaleza urbana (Plusvalía municipal)" }, link: 'tributos/tributos-locales', protected: 0 },
            ]
        },
        {
            title: { component: "Global", text: "Mas informacion" }, img: MasInfoIcon, children: [
                { title: { component: "Tributos", text: "plazos_declaracion" }, link: 'tributos/contenidos', protected: 0 },
                { title: { component: "Global", text: "ConsultaPreguntasFrecuentes" }, link: '/faqs', protected: 0 },
            ]
        },
        {
            title: { component: "Global", text: "Presentaciones" }, img: PresentacionesIMG, children: [
                { title: { component: "Global", text: "Presentacion de expedientes" }, link: '/tributos/presentaciones', protected: 2 },
            ]
        },
        {
            title: { component: "Global", text: "DUSTI" }, img: DutiIMG, children: [
                { title: { component: "Global", text: "DUSTIAlta" }, link: 'tributos/DUSTI/alta', protected: 2 },
                { title: { component: "Global", text: "DUSTIConsulta" }, link: 'tributos/DUSTI/consulta', protected: 2 },
                { title: { component: "Global", text: "DUSTIMasInfo" }, link: 'tributos/DUSTI/contenidos', protected: 0 },
            ]
        }
    ];



    const CITA_PREVIA_MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "CitaPrevia", text: "gestionCitas" }, img: CitaprevIcon, children: [
                { title: { component: "CitaPrevia", text: "Pedir Cita" }, link: 'cita-previa/cita', protected: 0 },
                { title: { component: "CitaPrevia", text: "Cancelar cita" }, link: '/cita-previa/cancelar-cita', protected: 0 },
                { title: { component: "CitaPrevia", text: "Justificante" }, link: '/cita-previa/justificante', protected: 0 },
            ]
        },
        {
            title: { component: "CitaPrevia", text: "Proximas citas" }, img: ProximaCitasIcon, children: [
                { title: { component: "CitaPrevia", text: "misCitas" }, link: 'cita-previa/mis-citas', protected: 1, idModulo: 17 },
            ]
        },
        {
            title: { component: "Global", text: "Mas informacion" }, img: MasInfoIcon, children: [
                { title: { component: "Global", text: "dias_festivos" }, link: getUrlFestivos(pageState.lang), isExternLink: true, protected: 0 },
            ]
        }
    ];


    const MODULE: { term: string, menuItems: TMenuItem[], link?: string }[] = [
        { term: 'AreaPersonalNotificaciones', menuItems: PERFIL_MENU_ITEMS },
        { term: 'CarpetaVirtual', menuItems: CARERO_VIRTUAL_MENU_ITEMS },
        { term: 'TRIBUTOS', menuItems: TRIBUTOS_MENU_ITEMS },
        { term: 'TRAMITES', menuItems: TRAMITES_MENU_ITEMS },
        { term: 'BienesSujeto', menuItems: BIENES_SUJETO_MENU_ITEMS },
        { term: 'Cita Previa', menuItems: CITA_PREVIA_MENU_ITEMS },
        { term: 'Oficinas y servicios', menuItems: [], link: 'oficinas' },
        { term: 'preguntasFrecuentes', menuItems: [], link: 'faqs' }
    ]


    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiSitemap,
                title: <Term component="Global" text="MapaWeb" />,
                text: <Term component="Global" text="MapaWebDescription" />,
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return (
        <>
            <FullLoading loading={loading} />
            <Card className={classesCustom.container /*classes.root*/}>
                <Grid container direction="column" justify="flex-start">
                    {!loading &&
                        MODULE.map(m => (
                            <div>
                                <Grid container direction="row" justify="space-between" style={{ paddingRight: 10, paddingLeft: 10, width: '100%' }}>
                                    <Grid item>
                                        <Typography className={classesCustom.title}>
                                            <Term component="Global" text={m.term} />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {m.link &&
                                            <Button key={'btn' + m.term}
                                                component={"button"}
                                                onClick={() => m.link && history.push(m.link)}
                                                style={{ textTransform: 'unset', width: '100%' }}
                                            >
                                                <Icon size={0.8} path={mdiOpenInApp} color={'#004f84'} />
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>

                                <div className={classesCustom.divider} style={{ marginBottom: m.link ? 45 : 0 }}></div>
                                {m.menuItems.length > 0 &&
                                    <Grid container direction="row" justify="flex-start" style={{ paddingRight: 10, paddingLeft: 10 }}>
                                        {
                                            m.menuItems.map((menuitem, idx) => (
                                                (menuitem.title.text === "DUSTI" && !SHOW_DUSTI)
                                                    ? null
                                                    :
                                                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`area-personal-${idx}`}>
                                                        <BoxMenuItem
                                                            item={menuitem}
                                                            onLogin={(accessLevel) => {
                                                                if (isMobile) { history.push('/acceso', { goBack: history.location.pathname }) }
                                                                else {
                                                                    if (accessLevel === 2) { history.push('/acceso/clave', { goBack: history.location.pathname }) }
                                                                    if (accessLevel === 1) { setShowLogin(true) }
                                                                }
                                                            }}
                                                        />
                                                    </Grid>

                                            ))
                                        }
                                    </Grid>
                                }
                            </div>
                        )

                        )

                    }
                </Grid>
            </Card>
            <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />
        </>
    )
}

export default withRouter(MapaWeb);

