import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import ContactForm, { EMPTY_CONTACTO, EMPTY_CONTACTO_ERRORs, TContacto, TContactoFlags } from "./contact-form";

import { translate } from "utils/i18n";
import DirectionFormATIB, { EMPTY_DIRECTION, EMPTY_DIRECTION_ERRORs, TDirectionATIB, TDirectionFlags } from "./direction-form-ATIB";
import InputNumber from "./input-number";
import InputText from "./input-text";
import TypedInputNumber from "./typed-input-number";
import { IPersonaTransmitente, TDireccionFiscal, TTipoTransmitente } from "containers/DUTI/steps/types";
import CheckBox from "./check-box";
import { isCIF, validateNif } from "utils/validateNif";
import { TExtraValidation } from "./types";
import { AlertsContext } from "contexts/alerts.context";
import { Parent } from "aurelia-dependency-injection";
import InputDate from "./input-date";
import { validateCP } from "utils/validateCP";

export type TLocalData =  IPersonaTransmitente & {viviendaHabitual?: boolean, numOperacionesAdquisicion?: number }

interface IProps extends WithStyles {
    asModal: boolean
    asDetail: boolean
    withM600: boolean
    tipo: TTipoTransmitente
    tipoTransmitente: TTipoTransmitente
    allowChargeLogged: boolean
    chargeableUserInfo: IPersonaTransmitente
    NIFsCompradores: string[] | null,
    NIFsCurrentData: string[]
    initialInfo: TLocalData|undefined,
    onSave: (action: 'add' | 'edit', item:TLocalData) => {ok: boolean, msgErr: undefined|string}
    onEnd: () => void
}
const CompradorVendedorForm : FC<IProps>= ({
    classes, initialInfo,
    asDetail, asModal, allowChargeLogged, chargeableUserInfo,
    withM600,tipo,tipoTransmitente,
    NIFsCompradores, NIFsCurrentData,
    onSave, onEnd
}) => {

    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    
    // Datos del formualario transmitente comprador/vendedor
    const [localNif, setLocalNif] = useState<string|undefined>(undefined);
    const [localNifErr, setLocalNifErr] = useState(false);
    const [localApeNom, setLocalApeNom] = useState<string|undefined>(undefined);
    const [localApeNomErr, setLocalApeNomErr] = useState(false);
    const [localFechaNacimiento, setLocalFechaNacimiento] = useState<Date| undefined>(undefined);
    const [localFechaNacimientoErr,setLocalFechaNacimientoErr] = useState(false);
    const [localPorcentaje, setLocalPorcentaje] = useState<number>(0);
    const [localPorcentajeErr, setLocalPorcentajeErr] = useState(false);
    const [localDir, setLocalDir] = useState<TDirectionATIB>({...EMPTY_DIRECTION});
    const [localDirErrors, setLocalDirErrors] = useState<TDirectionFlags>({...EMPTY_DIRECTION_ERRORs});
    const [localContact, setLocalContact] = useState<TContacto>({...EMPTY_CONTACTO});
    const [localContactErrors, setLocalContactErrors] = useState<TContactoFlags>({...EMPTY_CONTACTO_ERRORs});
    const [localEsHabitual, setLocalEsHabitual] = useState(false)
    const [localNumOperacionesAdquisicion, setLocalNumOperacionesAdquisicion] = useState(1);
    const [localNumOperacionesAdquisicionError, setLocalNumOperacionesAdquisicionError] = useState(false);
    const [localDireccionFiscal, setLocalDireccionFiscal] = useState<TDireccionFiscal|undefined>(undefined)
    const [esPersFisica, setEsPersFisica] = useState<boolean>(false)
    const ready = useMemo(() => (
        (allowChargeLogged && initialInfo === undefined && chargeableUserInfo && localNif && localNif!=='') || !allowChargeLogged 
        ? true 
        : false
    ),[allowChargeLogged, chargeableUserInfo, localNif])



    const resetLocalErorsData = () => {
        setLocalNifErr(false); 
        setLocalApeNomErr(false);
        setLocalFechaNacimientoErr(false);
        setLocalPorcentajeErr(false);
        setLocalDirErrors({...EMPTY_DIRECTION_ERRORs});
        setLocalContactErrors({...EMPTY_CONTACTO_ERRORs});
    }
    const onChargeLocalData = (data?: TLocalData ) => {
        setLocalNif(data?.nif ?? undefined);
        setLocalApeNom(data?.nomApe ?? undefined);
        setLocalFechaNacimiento(data?.fechaNacimiento ?? undefined);
        setLocalPorcentaje(data?.porcentaje ?? 0);
        setLocalDir(data?.direccion ?? {...EMPTY_DIRECTION});
        setLocalContact(data?.contacto ?? {...EMPTY_CONTACTO});
        setLocalEsHabitual(data?.viviendaHabitual ?? false)
        setLocalNumOperacionesAdquisicion(data?.numOperacionesAdquisicion ?? 1)
    }
    const resetLocalDataAndErrors = () => {
        onChargeLocalData()
        resetLocalErorsData()
    }

    //Validaciones 
    const field_validateNIF = (nif: string) => {
        const isValid = validateNif(nif)
        setEsPersFisica(isCIF(nif) ? false : true)
        setLocalNifErr(!isValid)
        let result: TExtraValidation = { 
            error: isValid ? false: true, 
            error_msg: translate('DUTI', 'nif_error_format',terms)
        }
        return result
    }

    const onValidateTransmitente = (action: 'add'|'edit') => {
        let err: boolean=false;
        let errRepeated = false
        let msgExist: string|undefined = undefined
        let newItem: TLocalData = {
            nif: localNif ?? '',
            nomApe: localApeNom ?? '',
            fechaNacimiento: tipo === 'comprador' && esPersFisica && tipo === tipoTransmitente && withM600 && (localEsHabitual=== true) ? localFechaNacimiento: undefined ,
            direccion: {...localDir},
            contacto: {...localContact},
            porcentaje: localPorcentaje,
            viviendaHabitual: ( tipo === 'comprador' )? localEsHabitual: undefined, 
            direccionFiscal: localDireccionFiscal ? {...localDireccionFiscal} : undefined,
            numOperacionesAdquisicion: ( tipo === 'vendedor' )? localNumOperacionesAdquisicion: undefined
        }

        // validaciones Direccion
        let newError={...localDirErrors}
        const cpErr =( newItem.direccion.cp === undefined|| !newItem.direccion.cp )
            ? {error: true, msg:'required_err'} 
            : validateCP(newItem.direccion.cp, newItem.direccion.pais.id,newItem.direccion.provincia?.id)
        newError = {
            ...newError,
            cp: cpErr.error,
            provincia: newItem.direccion.provincia === undefined,
            municipio: newItem.direccion.municipio === undefined,
            nombreVia: newItem.direccion.nombreVia === undefined|| !newItem.direccion.nombreVia,
            numero: newItem.direccion.numero === undefined|| !newItem.direccion.numero,
            tipoVia: newItem.direccion.tipoVia === undefined || !newItem.direccion.tipoVia.id,
            pais: newItem.direccion.pais === undefined || !newItem.direccion.pais.id
        }
       
        err = Object.values(newError).includes(true)
        const formatNif = field_validateNIF(newItem.nif) 
        // Validaciones comunes
        if( newItem.nomApe !== ''&& newItem.nif !== '' && !formatNif.error
            && ((esPersFisica && tipo === 'comprador' && tipo === tipoTransmitente && withM600 && !localFechaNacimientoErr && localFechaNacimiento )|| !localEsHabitual || !esPersFisica || !withM600 || tipo=='vendedor')
            && newItem.porcentaje > 0 
            && ( 
                ( tipo === 'comprador') 
                || (tipo === 'vendedor' && newItem.numOperacionesAdquisicion !==undefined && newItem.numOperacionesAdquisicion>=1)
            )
            && !err
        ){
            if( 
                (NIFsCurrentData.includes(newItem.nif) && action==='add')
                || NIFsCompradores && NIFsCompradores.includes(newItem.nif)
            ){
                errRepeated = true
                msgExist= NIFsCurrentData.includes(newItem.nif) 
                    ? `err_add_repeated_${tipo}`
                    : `err_included_in_compradores`           
            }

        } else {
            err=true
            setLocalApeNomErr(newItem.nomApe === '')
            setLocalNifErr(newItem.nif === '' || formatNif.error)
            setLocalFechaNacimientoErr(localFechaNacimientoErr ||  (esPersFisica && tipo === 'comprador' && tipo === tipoTransmitente && withM600 && localEsHabitual && !localFechaNacimiento) )
            setLocalPorcentajeErr(newItem.porcentaje === 0 )
            setLocalNumOperacionesAdquisicionError( tipo === 'vendedor' && localNumOperacionesAdquisicion < 1)
            setLocalDirErrors( d => ({...newError}))
        }

        if(!err && !errRepeated){
            // is valid
            const {ok, msgErr} = onSave(action, newItem)
            if(ok){
                onEnd() 
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: msgErr,
                        variant: 'error',
                    }
                })

            }
            
        } else {
            if(errRepeated){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI', msgExist ?? `err_generic_exist`, terms, [newItem.nomApe, newItem.nif]),
                        variant: 'error',
                    }
                })
            }
        }
    }
    
    useEffect(() => {
        if(allowChargeLogged && initialInfo === undefined && chargeableUserInfo){
            setEsPersFisica(isCIF(chargeableUserInfo.nif) ? false : true)
            onChargeLocalData(chargeableUserInfo)
        } else{
            if(initialInfo?.nif){
                setEsPersFisica(isCIF(initialInfo.nif) ? false : true)
            }
            onChargeLocalData(initialInfo)
        }
    },[initialInfo, allowChargeLogged, chargeableUserInfo])

    

    return (
        <div className={classes.column}>
                {!asModal && !asDetail &&
                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'flex-start',paddingBottom: 10}}>    
                    <Button variant="contained" onClick={resetLocalDataAndErrors}
                       disabled={chargeableUserInfo && localNif === chargeableUserInfo.nif && (allowChargeLogged || asDetail) && tipo === tipoTransmitente}
                    >
                        {translate('DUTI', 'btn_borrar',terms)} {translate('DUTI',`DT_TipoTransmitente_radioBtn_${tipo}`,terms)}
                    </Button>
                    
                </div>
            }   
            {ready ? 
                <div  className={classes.formularioComprVendContainer} >
                    <div className={classes.row}style={{flexWrap: 'wrap'}} >
                        <InputText key='nif'
                            fieldTerm='nif'
                            value={localNif}
                            disabled={
                                chargeableUserInfo && localNif === chargeableUserInfo.nif && (allowChargeLogged || asDetail) && tipo === tipoTransmitente
                            }
                            onChangeValue={(v:string|undefined, err: boolean) => {
                                setLocalNif(v)
                                setLocalNifErr(err)
                            }}
                            required={true}
                            forceUpperCase={true}
                            extraValidation={field_validateNIF}
                            error={localNifErr}
                        />

                        <InputText key='nomApe'
                            fieldTerm='nomApeRazonSoc'
                            value={localApeNom}
                            disabled={chargeableUserInfo && localApeNom === chargeableUserInfo.nomApe && (allowChargeLogged || asDetail)}
                            onChangeValue={(v:string|undefined, err: boolean) => {
                                setLocalApeNom(v)
                                setLocalApeNomErr(err)
                            }}
                            required
                            error={localApeNomErr}
                            className={classes.input360}
                            forceUpperCase={true}
                        />

                    </div>

                    {/* {tipo === 'vendedor' && tipoTransmitente === 'comprador'  ? null :   } */}
                    <DirectionFormATIB key='direccion'
                        direction={localDir}
                        setDirection={setLocalDir} 
                        dirErrors={localDirErrors} 
                        setDirErrors={setLocalDirErrors} 
                    />
                    
                    {/* { tipo === 'vendedor' && tipoTransmitente === 'comprador'  ?   null :  }  */}
                    <ContactForm key='contacto'
                        contact={localContact} 
                        setContact={setLocalContact} 
                        contactErrors={localContactErrors} 
                        setContactErrors={setLocalContactErrors}
                    />
                
                    
                    <div className={classes.row}>
                        <TypedInputNumber key='percentaje'
                            type="percentaje"
                            fieldTerm={ tipo === 'comprador' ? "porcentajeAdquisicion" : "porcentajeTitularidad" }
                            value={localPorcentaje}
                            onChangeValue={(v,err) => {
                                setLocalPorcentaje(v)
                                setLocalPorcentajeErr(err)
                            }}
                            required
                            allowZero={false}
                            maxDecimal={2}
                            error={localPorcentajeErr}
                            moreInfo={tipo === 'comprador' ? undefined : {template: 'info_field_porcentajeTitularidad', size: 'xs'}}
                        />
                        {tipo === 'comprador' && withM600 && esPersFisica && tipo === tipoTransmitente &&
                        
                            <div style={{marginLeft: 5, display:'inline-flex'}}>
                            
                                <CheckBox key='viviendaHabitual'
                                    fieldTerm={'viviendaHabitual'}
                                    value={localEsHabitual}
                                    onChangeValue={(val: boolean) => {
                                        setLocalEsHabitual(val)
                                        if(val ==false){
                                            setLocalFechaNacimiento(undefined)
                                            setLocalFechaNacimientoErr(false)
                                        }
                                    }}
                                />
                                
                                    <InputDate key="fechaNacimiento"
                                        fieldTerm="fechaNacimiento"
                                        value={localFechaNacimiento}
                                        minDate={undefined}
                                        maxDate={new Date()}
                                        onChangeValue={(v: Date| undefined, err:boolean) =>{ 
                                            console.log(v)
                                            setLocalFechaNacimiento(v)
                                            setLocalFechaNacimientoErr(err)
                                        }}
                                        disabled={!localEsHabitual}
                                        required={esPersFisica && tipo === 'comprador' && tipo === tipoTransmitente && withM600 && localEsHabitual }
                                        error={localFechaNacimientoErr}
                                        moreInfo={{template: 'info_field_fechaNacimiento', size: 'xs'}}
                                    />
                                

                        
                            </div>
                        }
                        {tipo === 'vendedor' &&
                            <InputNumber key='numOperacionesAdquisicion'
                                fieldTerm={"numOperacionesAdquisicion"}
                                value={localNumOperacionesAdquisicion}
                                onChangeValue={(v: number,err: boolean) => {
                                    setLocalNumOperacionesAdquisicion(v)
                                    setLocalNumOperacionesAdquisicionError(err)
                                }}
                                required
                                min={1}
                                maxDecimal={0}
                                error={localNumOperacionesAdquisicionError}
                                moreInfo={{template: 'info_field_numOperacionesAdquisicion', size: 'xs'}}
                                className={classes.input360}
                            />
                        }
                    
                    </div> 
                    
                    <div className={classes.row} style={{alignSelf: 'flex-end'}}>
                        {/**Acciones sobre formulario */}
                        {/** Añadimos uno más a la lista */}
                        {asModal &&  
                            <div className={classes.row} style={{alignSelf: 'flex-end'}}>
                                <Button variant="contained" style={{marginRight: 10}}
                                    onClick={ () => { 
                                        resetLocalDataAndErrors()
                                        onEnd() //setOpenModal(false)
                                    }}
                                >
                                    {translate('DUTI','btn_cancel',terms)}
                                </Button>
                                <Button variant="contained" style={{marginRight: 10}}
                                    onClick={ () => { resetLocalDataAndErrors() }} 
                                >
                                    {translate('DUTI', 'btn_borrar',terms)} {translate('DUTI',`DT_TipoTransmitente_radioBtn_${tipo}`,terms)}
                                </Button>
                            </div>
                        }
                        {/** Editamos uno de la lista */}
                        { asDetail &&  
                            <div className={classes.row} style={{alignSelf: 'flex-end'}}>
                                <Button variant="contained" style={{marginRight: 10}}
                                    onClick={ () => { 
                                        resetLocalDataAndErrors()
                                        onEnd()
                                    }}
                                >
                                    {translate('DUTI','btn_cancel',terms)}
                                </Button>
                                <Button variant="contained" color="primary" onClick={() =>{ 
                                        onValidateTransmitente('edit') 
                                    }}>
                                    { translate('DUTI', 'btn_save',terms)}      
                                </Button>
                            </div>
                        }

                        {!asDetail && 
                            <Button variant="contained" color="primary" 
                                onClick={() => {
                                    onValidateTransmitente('add')
                                    
                                }}
                            >
                                { translate('DUTI', tipo === tipoTransmitente ? 'btn_confirmar': 'btn_añadir',terms)+'  '+translate('DUTI',`DT_TipoTransmitente_radioBtn_${tipo}`,terms) }
                            </Button>
                        }
                    </div>
                </div>
            : <CircularProgress/>
            }
              
        </div>
            
    )
}

export default withLiterals(['DUTI'])(withStyles(duti_styles)(CompradorVendedorForm));
