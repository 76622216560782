// LIBRARY IMPORTS
import { FC, useEffect, useRef, useState } from "react";
import { Typography, useTheme, makeStyles, Card, CardContent } from "@material-ui/core";
// LOCAL IMPORTS
import { DIAS_SELECCIONABLES_VOLUNTARIA } from "containers/tributos-autonomicos-locales/constants";
import { IDiaCortesiaDetalle } from "gateways/notificaciones.interfaces";
import { ITributo } from "gateways/tributos.interfaces";
import { dateFormat} from "utils/dates";
import useLoadFechaSeleccionadaVoluntaria from "hooks/domiciliaciones/fecha-seleccionada-voluntaria.hook";
import useLoadDiasVoluntaria from "hooks/domiciliaciones/dias-voluntaria.hook";
import Calendar from "containers/shared/calendar";
import Term from "components/term";
// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

export interface IProps {
    tributos: ITributo[];
    fechaDomiciliacionIncompatible: string | null;
    onDateVoluntariaChange: (fecha: string) => void;
}

const CalendarioFechasVoluntaria: FC<IProps> = (props) => {
    const { tributos, fechaDomiciliacionIncompatible, onDateVoluntariaChange } = props;
    // HOOKS
    const theme = useTheme();
    const classes = useStyles(theme);
    const { diasVoluntaria, availableYearMonth } = useLoadDiasVoluntaria({ tributos });
    const {
        fechaPrevistaEnvioVoluntaria,
        fechaPorDefectoDomiVol,
        diaSeleccionado,
        handleDiaSeleccionado,
        setDiaSeleccionado,
        setFechaPrevistaEnvioVoluntaria
    } = useLoadFechaSeleccionadaVoluntaria({ tributos, diasVoluntaria, fechaDomiciliacionIncompatible });
    // STATES
    const limiteDiasSeleccionables = DIAS_SELECCIONABLES_VOLUNTARIA;
    const diasVoluntariaRef = useRef<IDiaCortesiaDetalle[]>(null);
    const [itemsSeleccionado, setItemsSeleccionado] = useState<HTMLDivElement>();

    const handleDiasModificados = (countDiasMod: any) => {
        if (countDiasMod > 0) return;

        setFechaPrevistaEnvioVoluntaria(undefined);
        if (!fechaPorDefectoDomiVol) return;

        handleDiaSeleccionado(fechaPorDefectoDomiVol);
    }

    const handleDiaCalendarioSeleccionado = (item: HTMLDivElement, fecha: string) => {
        const countDiasSeleccionados = diasVoluntaria?.dias.filter(d => d.seleccionada && d.modificable);

        if (countDiasSeleccionados && countDiasSeleccionados.length > limiteDiasSeleccionables) {
            const diaSeleccionadoCortesia = diasVoluntaria?.dias.find(dia => dia.fecha === diaSeleccionado);

            if (!diaSeleccionadoCortesia && !itemsSeleccionado) return;

            else if (diaSeleccionadoCortesia && !itemsSeleccionado) diaSeleccionadoCortesia.seleccionada = !diaSeleccionadoCortesia.seleccionada;

            else if (diaSeleccionadoCortesia && itemsSeleccionado) {
                // Día por defecto
                itemsSeleccionado.style.backgroundColor = 'white';
                itemsSeleccionado.style.color = 'black';
                diaSeleccionadoCortesia.seleccionada = !diaSeleccionadoCortesia.seleccionada;
                // Nuevo día seleccionado
                item.style.backgroundColor = '#2196F3';
                item.style.color = 'white';
            }
        }

        setItemsSeleccionado(item);
        setDiaSeleccionado(fecha);
        setFechaPrevistaEnvioVoluntaria(fecha);
        onDateVoluntariaChange(fecha);
    };

    return (
        <div className={classes.marginDomiciliacionesFechaCargo}>
            <Card className={classes.paddingFechaCargo}>
                <CardContent className={classes.fechaCargo}>

                    <Typography className={[classes.titleText, classes.marginB15].join(' ')}>
                        <Term component='Domiciliaciones' text='Seleccionar fecha concreta cargo en cuenta' />
                    </Typography>
                      
                    {/* Leyenda */}
                    {(diasVoluntaria && diasVoluntaria.dias.length > 0) &&
                        <>
                            <div className={classes.containerLeyendaDiasVoluntaria}>
                                {/* Día seleccionado */}
                                <div className={classes.containerExplicacionLeyenda}>
                                    <span className={[classes.leyendaDiasVoluntaria, classes.colorDiaSeleccionado].join(' ')} />
                                    <div className={classes.fechaPrevista}>
                                        <Term component='Domiciliaciones' text='Fecha prevista domiciliación voluntaria' params={[fechaPrevistaEnvioVoluntaria ? dateFormat(fechaPrevistaEnvioVoluntaria) : fechaPorDefectoDomiVol ? dateFormat(fechaPorDefectoDomiVol) : '']} />
                                        {(!fechaPorDefectoDomiVol && !fechaPrevistaEnvioVoluntaria) &&
                                            <p style={{ fontWeight: 'bold', margin: 0 }}><Term component='Global' text='Sin especificar' /></p>
                                        }
                                    </div>
                                </div>
                                {/* Días habilitados */}
                                <div className={classes.containerExplicacionLeyenda}>
                                    <span className={[classes.leyendaDiasVoluntaria, classes.colorDiasHabilitados].join(' ')} />
                                    <Term component='Domiciliaciones' text='Leyenda días habilitados domiciliación voluntaria' />
                                </div>
                                {/* Días deshabilitados */}
                                <div className={classes.containerExplicacionLeyenda}>
                                    <span className={[classes.leyendaDiasVoluntaria, classes.colorDiasDeshabilitados].join(' ')} />
                                    <Term component='Domiciliaciones' text='Leyenda días deshabilitados domiciliación voluntaria' />
                                </div>
                            </div>
                        </>
                    }
                </CardContent>
                {!diasVoluntaria || diasVoluntaria.dias.length < 1
                    ?
                        <div className={classes.paddingFechaCargo}>
                            <Term component='Domiciliaciones' text='Días voluntaria no disponibles' />
                        </div>
                    :
                        <div className={[classes.calendarFechCargo, classes.rootCard].join(' ')} >
                            {
                                availableYearMonth.map(yearMonth => {
                                    return yearMonth.months.map(m => {
                                        const firstDate = new Date(yearMonth.year, m, (new Date().getDay() + 2));
                                        return (
                                            <Typography component="div" key={m} className={classes.calendar}>
                                                <Calendar
                                                    key={m}
                                                    firstDate={firstDate}
                                                    diasCortesia={diasVoluntaria ? diasVoluntaria.dias : []}
                                                    diasCortesiaRef={diasVoluntariaRef ? diasVoluntariaRef : []}
                                                    limiteDiasSeleccionables={limiteDiasSeleccionables}
                                                    fechaPorDefecto={fechaPorDefectoDomiVol}
                                                    setDiasModificables={handleDiasModificados}
                                                    setDiaSeleccionado={handleDiaCalendarioSeleccionado}
                                                />
                                            </Typography>
                                        );
                                    });
                                })
                            }
                        </div>

                }
            </Card>
        </div>
    );
};

export default CalendarioFechasVoluntaria;
