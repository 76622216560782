import { useEffect, FC, useState, useMemo, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import usePage from 'hooks/page.hook';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import IoC from 'contexts/ioc.context';

import { makeStyles } from '@material-ui/core/styles';
import styles from './tributos-autonomicos.styles';
import { Card, Button, Grid, CardContent, TextField, Typography, CircularProgress, Dialog, DialogContent } from '@material-ui/core';

import { mdiAlphaACircleOutline } from '@mdi/js';
import ModelGateway from '../../gateways/model.new.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { IStep, ITipoModelo } from 'gateways/model.new.interface';
import Term from 'components/term';
import { translate } from 'utils/i18n';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import Conceptos from './componentes/conceptos';
import { GenericInitialObject, Option, getXML } from './utils';
import { ModelosActionContext } from 'contexts/modelos-action.context';
import { Autocomplete } from '@material-ui/lab';
import { MODELOS_ASISTENTE } from './constants';



const useStyles = makeStyles(styles);

interface IParams {
    idTipoModelo: string,
    textTipoTributo: 'autonomicos' | 'locales'
};


const ModelosAction: FC<RouteComponentProps<IParams>> = ({ match, history }) => {
    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const classes = useStyles()
    const [ref, setRef] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [tipoModeloInfo, setTipoModeloInfo] = useState<ITipoModelo | null>(null);
    //Gatways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    // PopUP ask for login ?
    const TIPO_MODELOS_ASK_AUTH: string[] = ['plusvalia']

    const [Info060, setInfo060] = useState('');
    const [step, setStep] = useState<IStep | null>(null);
  
    const [modelosAction, modelosActionDispatcher] = useContext(ModelosActionContext);
    const [ejercicio, setEjercicio]=useState<Option | undefined>(undefined)
    const [ejercicioErr, setEjercicioErr]=useState<boolean>(false)
    
    const selectEjercicioOPTs: Option[] = useMemo( () => {
        if(match.params.idTipoModelo === '593'){
            const startYear = 2023;
            const currentYear = new Date().getFullYear(); // Obtiene el año actual
            const years=[]
            for (let year = startYear; year <= currentYear; year++) {
                years.push({id: year.toString(), nombre:year.toString()}); // Añade el año actual al array
            }
            return years
        } else { 
            return [] as Option[]
        }
    },[match.params.idTipoModelo])

    const handleNuevo = () => {
        if(match.params.idTipoModelo === '593'){
            if(ejercicio === undefined){
                setEjercicioErr(true)
            }else{
                const newUrl= match.url.slice(0, -1)
                const numFinalEjercicio = ejercicio.id.slice(-1)
                const numFinalModelo = Number(numFinalEjercicio) > 4 ? '4': numFinalEjercicio;
                
                history.push(`${newUrl+numFinalModelo}/nuevo`)
            }
        } else{
            history.push(`${match.url}/nuevo`)
        }
    }
    
    const handleRecover = async () => {
        try {
            setLoading(true)
            if (ref !== undefined && ref !== "") {
                const modelo = await modelGateway.getModeloByRef(ref, translate('Tributos', 'GetModeloByRefError', terms))
                const steps = await modelGateway.getApartados(ref, translate('Tributos', 'GetModeloStepsError', terms));

                if (modelo && modelo.idModelo && modelo.idEstado !== 'ERR') {
                    if (modelo.idTipoModelo === match.params.idTipoModelo
                    || (match.params.textTipoTributo === 'locales' && MODELOS_ASISTENTE[modelo.idModelo]  === match.params.idTipoModelo)
                    ) {
                        // Modelo finalizado - Ir a pago
                        setLoading(false)
                        history.push(`${match.url}/${ref}/pago/${modelo.idModelo}`)
                    } else {
                        setLoading(false)
                        throw new Error(`${translate('Tributos', 'NotCorrectModelRecoverAction', terms)}: ${translate('Tributos', 'Modelo', terms)} ${modelo?.idTipoModelo}`);
                    }
                } else if (!modelo && steps && steps.length > 0) {
                    const tipoModelo = steps[0].idTipoApartado.substring(0, 3)
                    if (tipoModelo === match.params.idTipoModelo 
                        || (tipoModelo ==='594' && match.params.idTipoModelo ==='593' )
                        ||  (match.params.textTipoTributo === 'locales' &&  MODELOS_ASISTENTE[tipoModelo]  === match.params.idTipoModelo)
                        ) {
                        // Modelo no finalizado - Ir al stepper
                        setLoading(false)
                        if(tipoModelo ==='594' && match.params.idTipoModelo ==='593' ){
                            const newUrl= match.url.slice(0, -3)
                            history.push(`${newUrl+tipoModelo}/${ref}`)
                        } else{
                            history.push(`${match.url}/${ref}`)
                        }
                    } else {
                        setLoading(false)
                        const tipoM = MODELOS_ASISTENTE[tipoModelo] ? translate('Plusvalias', MODELOS_ASISTENTE[tipoModelo], terms) : tipoModelo
                        throw new Error(`${translate('Tributos', 'NotCorrectModelRecoverAction', terms)}: ${translate('Tributos', 'Modelo', terms)} ${tipoM}`);
                    }

                } else {
                    setLoading(false)
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tributos', 'NotFoundReference', terms),
                            variant: 'warning',
                        }
                    });
                }


            } else {
                //console.log('terms', terms)
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos', 'InsertReference', terms),
                        variant: 'warning',
                    }
                });

            }
        } catch (error) {
            //console.log('ERROR -- getModeloByRef ', error);
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });
        }

    }

    const handleGoClave = () => {
        const returnPath = document.location.pathname + document.location.search + document.location.hash;
        const clavePath = '/acceso/clave?returnUrl=' + encodeURIComponent(returnPath);
        alertsDispatch({ type: "hide-alert" });
        history.push(clavePath);
    }

    // Pasos previos - recuperables en modelo si se llega a crear ---------------------------------------
    const saveConceptoAsXml = async (data: GenericInitialObject, tipoApartado: string) => {
        const xml = getXML([data], '', tipoApartado, false)
        //console.log('modelosActionDispatcher', xml)
        modelosActionDispatcher({ type: 'saveConceptoXml', payload: xml })
        return true
    }

    //---------------------------------------------------------------------------------------------------

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const textAskForAuth = await contenidoGateway.getContent('info_modal_ask_for_auth', {})
                const text060 = await contenidoGateway.getContent('info_modelo_060', {})
                const modelInfo = await modelGateway.getModeloInfo(match.params.idTipoModelo, translate('Tributos', 'GetModeloInfoError', terms))
                setInfo060(text060[0].contenido)
                setTipoModeloInfo(
                    modelInfo
                        ? { ...modelInfo, valor: translate('Modelos', `${match.params.idTipoModelo}`, terms), }
                        : null
                );
                // Datos para uso Pasos previos
                if (match.params.idTipoModelo === '046' || match.params.idTipoModelo === '060') {
                    const steps = await modelGateway.getModeloSteps(match.params.idTipoModelo, '', translate('Tributos', 'GetModeloStepsError', terms));
                    const myStep = steps.find(step => step.webComponent === 'DatosPrevios')
                    myStep && setStep(myStep)
                }
                if(!pageState.jwp && TIPO_MODELOS_ASK_AUTH.includes(match.params.idTipoModelo)){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: textAskForAuth[0].contenido,
                            isHtml: true,
                            variant: 'warning',
                            defaultCloseActionTemplateName: translate('Global', 'btnContinuarNOAuth', terms),
                            actionTemplateName: translate('Global', 'btnContinuarAuth', terms) ,
                            hasCustomAction: true,
                            handleCustomAction: handleGoClave
                        }
                    });
                }
                setLoading(false)
            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                });
            }
        })();
    }, []); //match.params.idTipoModelo, translate]);

    useEffect(() => {
        let numModelo;
        switch (match.params.idTipoModelo) {
            case '621':
                numModelo = '620'
                break;
            case '047':
                numModelo = '043-E'
                break;
            case '594':
                numModelo = '593'
                break;
            default:
                numModelo = match.params.idTipoModelo
                break;
        }

        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiAlphaACircleOutline,
                title: numModelo === 'plusvalia'
                    ? `${translate("Plusvalias", "Asistente", terms)}: ${translate("Plusvalias", numModelo, terms)}`
                    : `${translate('Tributos', 'Modelo', terms)} ${numModelo}: ${tipoModeloInfo?.valor ? tipoModeloInfo.valor : ''}`,
                // text: <Term text="" />
                moreInfoTemplate: numModelo === 'plusvalia' ? 'mas_info_page_asistente_plusvalias' : undefined,
                moreInfoTemplateSize: numModelo === 'plusvalia' ? 'lg' : 'md'
            },
            menu: true,
        });
    }, [ /*pageDispatcher,*/ tipoModeloInfo]);



    

    return (
        <div className={classes.container}>
            {match.params.idTipoModelo === '060' &&
                <Card className={classes.cardContainerModAction}>
                    <CardContent>
                        <div dangerouslySetInnerHTML={{ __html: Info060 }} style={{ textAlign: "justify", textJustify: "inter-word", fontSize: 16 }} />
                    </CardContent>
                </Card>
            }

            <Card className={classes.cardContainerModAction}>
                <Grid container direction='row' spacing={5} alignItems='baseline' justify='center'>
                    <Grid item xs={6}>
                        <Typography>
                            {translate('Tributos', 'CreateModelTextInfo', terms)}
                        </Typography>
                        <div style={{display: 'inline-flex', alignItems:'center'}}>
                            {   match.params.idTipoModelo === '593' && 
                                <Autocomplete
                                    value={ ejercicio ? selectEjercicioOPTs.find(item =>  (item.id === ejercicio.id)): undefined }
                                    getOptionLabel={(option: Option) => option.nombre}
                                    renderOption={(option: Option) => option.nombre}
                                    onChange={(event: any, newValue: Option | null) => {
                                        setEjercicioErr(false)
                                        setEjercicio(newValue ? newValue: undefined)
                                        if(newValue){
                                            modelosActionDispatcher({ type: 'saveEjercicio', payload: newValue.id })
                                        }
                                    }}
                                    options={selectEjercicioOPTs}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label={translate('Global', 'Ejercicio', terms)}
                                            variant="outlined"
                                            required={true}
                                            autoFocus={true}
                                            error={ejercicioErr}
                                            helperText={ejercicioErr
                                                ? translate('Tributos', 'RequiredField', terms)
                                                : ""
                                            }
                                            FormHelperTextProps={{
                                                style: { position: 'absolute', bottom: '-20px' }
                                            }}
                                            className={classes.selectEjercicio}
                                            style={{
                                                borderTop: '4px solid transparent'
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    backgroundColor: 'white'
                                                }
                                            }}
                                        />
                                    )
                                    }
                                />
                            }
                            <Button style={{ width: 'fit-content', margin: 10 }} variant='contained' onClick={handleNuevo}>
                                {translate('Tributos', 'btnNew', terms)}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6} className={classes.column}>
                        <Typography style={{ textAlign: 'justify' }} >
                            {translate('Tributos', 'RecoverModelTextInfo', terms)}
                        </Typography>
                        <div className={classes.row}>
                            <TextField
                                variant='outlined'
                                margin='dense'
                                onChange={(e) => setRef(e.target.value)}
                                label={<Term text='referencia' />}
                                value={ref || ""}
                                className={classes.resizeRevocerButton}
                            />
                            <Button style={{ width: 'fit-content', margin: 10 }} variant='contained' onClick={handleRecover}>
                                {translate('Tributos', 'btnRecover', terms)}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>

            {step &&
                <Card className={classes.cardContainerModAction}>
                    <CardContent>
                        {/** Pasos previos - recuperables en modelo 046 y 060 */}
                        {(match.params.idTipoModelo === '046' || match.params.idTipoModelo === '060') &&
                            <div>
                                <Typography variant='h5'>{translate('Tributos', 'CargaPreviaConceptosTitulo', terms)}</Typography>
                                <Conceptos
                                    setFinished={(newValue: boolean) => {
                                        if (newValue) {
                                            if (modelosAction.conceptoXml && (match.params.idTipoModelo === '046' || match.params.idTipoModelo === '060')) {
                                                history.push(`${match.url}/nuevo`)
                                            }
                                        } else {
                                            modelosActionDispatcher({ type: 'saveConceptoXml', payload: null })
                                        }
                                        //setFinishedPrevios(newValue)
                                    }}
                                    handleSave={(value: GenericInitialObject) => saveConceptoAsXml(value, step.idTipoApartado)}
                                    datosXml={null}
                                    datosXmlFromModelosAction={null}
                                    idTipoApartado={step.idTipoApartado}
                                    idTipoModelo={match.params.idTipoModelo}
                                    labelBtn='btnIniModelo'
                                />
                            </div>
                        }
                        {/** TODO NEUS Pasos previos - recuperables en modelo 600 */}
                    </CardContent>
                </Card>
            }

            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withRouter(withLiterals(['Tributos', 'Modelos','Plusvalias','Global'])(ModelosAction));

