import { Theme } from "@material-ui/core";
import { green, orange, yellow } from "@material-ui/core/colors";
import { StyleRules } from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => ({
    root: {
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: -100,
        transition: 'all .5s ease 0s',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 600px)'
        }
    },
    rootWidth100: {
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: -100,
        transition: 'all .5s ease 0s',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 300px)'
        }
    },
    display: {
        bottom: 0,
        [theme.breakpoints.up('md')]: {
            left: 300,
        }
    },
    displayNone: {
        display: 'none'
    },
    priceInfo: {
        borderTop: '2px solid #e0e0e0',
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
    },
    payContainer: {
        borderTop: '2px solid #d3c45b',
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    totalPriceSelected: {
        color: '#000',
        fontWeight: 'bold'
    },
    totalPrice: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 22
    },
    iconPay: {
    },
    payText: {
        color: 'blue',
        fontWeight: 'bold',
        fontSize: 15
    },
    btnPay: {
        height: '100%',
        width: '100%'
    },
    wrapper: {
        margin: '15px',
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonPagoACuenta: {
        backgroundColor: orange[500],// green[500],
        '&:hover': {
            backgroundColor: orange[700], // green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    rootCarrito: {
        borderTop: '2px solid #e0e0e0',
        backgroundColor: '#fff',
        transition: 'all .5s ease 0s',
        margin: 5,
        width: '97%',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 300px)',
            margin: 0,
        }

    },
});

export default styles;