// LIBRARY IMPORTS
import { FC } from "react";
import { Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, ListItem, makeStyles, Select, TextField, useTheme } from "@material-ui/core";
import { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { mdiMagnify, mdiBriefcaseOutline } from '@mdi/js';
import { RouteComponentProps } from "react-router-dom";
import { Icon } from '@mdi/react';
import InfiniteScroll from "react-infinite-scroller";

// LOCAL IMPORTS
import { IRecibo, SituacionCobroEnum } from "gateways/recibo.interfaces";
import { LiteralsContext } from "containers/shared/literals";
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { TramitesGateway } from "gateways/tramites.gateway";
import { IMunicipio } from "gateways/municipio.interface";
import { AlertsContext } from "contexts/alerts.context";
import { GeoGateway } from 'gateways/geo.gateway';
import { useShowDeudaFilters } from "./hooks/useShowDeudaFilter";
import { useNifValidationError } from "./hooks/useNifValidation";
import { RecibosGateway } from "gateways/recibo.gateway";
import { SelectionContext } from "containers/cartero-virtual/selection.context";
import { translate } from "utils/i18n";
import { download } from "utils/download";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { BLUE } from "containers/shared/colors";
import SelectionReducer, { ISelectionState } from "containers/cartero-virtual/selection.reducer";
import Term from "components/term";
import usePage from "hooks/page.hook";
import IoC from 'contexts/ioc.context';
import Carrito from "containers/cartero-virtual/carrito";
import ReciboCard from "containers/cartero-virtual/recibo.card";
import ListContador from "components/list-contador";
import NoContent from 'components/no-content';
import image from '../../../resources/no-deuda.png';

// STYLES
import { styles } from './consultas-externas.styles';

const useStyles = makeStyles(styles);

type Props = RouteComponentProps;

const ConsultasExternas: FC<Props> = ({ history }) => {
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const tramitesGateway: TramitesGateway = ioc.get(TramitesGateway);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const recibosGateway: RecibosGateway = ioc.get(RecibosGateway);
    const geoGateway: GeoGateway = ioc.get(GeoGateway);
    const [, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(false);
    const [queryFilters, setQueryFilters] = useState<any>({
        nif: '',
        referencia: '',
        matricula: '',
        deudaType: '',
        municipio: ''
    })
    const errorDni = useNifValidationError(queryFilters.nif);
    const showDeudaFilters = useShowDeudaFilters(queryFilters);
    const [showDeudaList, setShowDeudaList] = useState<boolean>(false);
    const [municipiosOptions, setMunicipiosOptions] = useState<IMunicipio[]>([]);
    const [deudaList, setDeudaList] = useState<any[]>([]);
    const [recibosCount,] = useState<number>();
    const [hasMore,] = useState<boolean>(false)
    const [showSelectAllOption, setShowSelectAllOption] = useState<boolean>(true);
    const [showUnSelectAllOption, setShowUnSelectAllOption] = useState<boolean>(false);
    const [isSelectedPendiente, setIsSelectedPendiente] = useState<boolean>(false);
    const [customListTitle, setCustomListTitle] = useState<any>();
    const [showPrintJustificanteTodoPagado, setShowPrintJustificanteTodoPagado] = useState<boolean>(false);
    const [showPendientesOption, setShowPendientesOption] = useState<boolean>(false);
    const [showAllOption, setShowAllOption] = useState<boolean>(false);
    const [showOnlyPendientes, setShowOnlyPendientes] = useState<boolean>(false);
    const [showSearchConfirmDialog, setShowSearchConfirmDialog] = useState<boolean>(false);
    const [body, setBody] = useState('');
    const [bodyAvisoPagoCartaPalma, setBodyAvisoPagoCartaPalma] = useState('');

    const handleChangeFilters = useCallback((filter: string, value: any) => {
        setQueryFilters((prev: any) => {
            let filters = Object.assign({}, prev);
            filters[filter] = value.toString().toUpperCase();
            if (filter == 'deudaType') {
                filters['matricula'] = '';
                filters['referencia'] = '';
                filters['municipio'] = '';
                setDeudaList([]);
                setShowDeudaList(false);
                handleDeudaSelectOptionShow([]);
            }
            return filters;
        })
    }, []);

    const handleDeudaSelectOptionShow = useCallback((listaRecibos: IRecibo[]) => {
        setShowUnSelectAllOption(false);
        setShowAllOption(false);
        setShowOnlyPendientes(false);
        handleUnSelect();
        if (listaRecibos.length >= 1) {
            const deudaPendiente = listaRecibos.filter((recibo: IRecibo) => recibo.desglose.pendiente > 0);
            const deudaPagada = listaRecibos.filter((recibo: IRecibo) => recibo.desglose.pendiente == 0);
            if (listaRecibos.length == deudaPagada.length) {
                setCustomListTitle(<span>{deudaPagada.length} <Term component="Tramites" text="RecibosPagados" /></span>);
            } else {
                setCustomListTitle(<span>{deudaPagada.length} <Term component="Tramites" text="RecibosPagados" /> {' / ' + deudaPendiente.length} <Term component="Tramites" text="RecibosPendientes" /></span>);
            }
            setShowSelectAllOption(deudaPendiente.length > 0);
            setShowPendientesOption(deudaPendiente.length > 0);
            setShowPrintJustificanteTodoPagado(deudaPendiente.length == 0);
        }
    }, []);

    const handleSearchDeuda = useCallback(async () => {
        closeSearchConfirmDialog();
        if (queryFilters.matricula.length > 0 || queryFilters.referencia.length > 0) {
            setLoading(true);
            const filters = convertQueryFilters(queryFilters);
            const response = await recibosGateway.findAllRecibos({ ...filters });
            setDeudaList(response);
            setShowDeudaList(true);
            handleDeudaSelectOptionShow(response);
            setLoading(false);
        } else {
            setDeudaList([]);
            setShowDeudaList(false);
            handleDeudaSelectOptionShow([]);
        }
    }, [queryFilters]);

    const convertQueryFilters = (filters: any) => {
        const objFilter = filters.deudaType == '5' ? filters.referencia.replace(/REF-CAD|-/g, "").trim() : filters.matricula.replace(/-/g, "").trim();
        handleChangeFilters(filters.deudaType == '5' ? 'referencia' : 'matricula', objFilter)
        return {
            nif: filters.nif,
            concepto: filters.deudaType,
            municipio: filters.municipio.length > 0 ? filters.municipio : undefined,
            objTributario: objFilter
        }
    };

    const handleSelectRecibo = useCallback((selectionState: ISelectionState<IRecibo>, recibosToSelected: IRecibo | IRecibo[]) => {
        if (selectionState.selected.length == 0 && !('splice' in recibosToSelected)) {
            setIsSelectedPendiente(recibosToSelected.desglose.pendiente > 0);
        }
        const recibosArray = 'splice' in recibosToSelected ? recibosToSelected : [recibosToSelected];
        const recibosNoSeleccionados: IRecibo[] = recibosArray.filter(x => selectionState.selected.findIndex(y => y.id === x.id) === -1);

        if ('splice' in recibosToSelected) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'info',
                    message: `Se han seleccionado ${selectionState.selected.length + recibosNoSeleccionados.length} recibos`,
                }
            });
        } else {
            if (recibosToSelected.situacionCobro !== SituacionCobroEnum.Cobrable && recibosToSelected.desglose.pendiente > 0) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <div>
                            <p>
                                <Term isHtml component="CarteroVirtual" text={'ErrorAnyadirPago_' + recibosToSelected.situacionCobro} />
                            </p>
                            {
                                recibosToSelected.situacionCobro === SituacionCobroEnum.Domiciliado
                                    && recibosToSelected.domiciliacion.fechaPrevistaCargo
                                    && recibosToSelected.estadoEtiqueta !== "Pago a la carta"
                                    ? <p>
                                        <Term component="CarteroVirtual" text="fechaPrevistaCargo" /> {recibosToSelected.domiciliacion.fechaPrevistaCargo}
                                    </p>
                                    : null
                            }
                        </div>,
                        variant: 'warning',
                    }
                });
            }
        }
        if (selectionState.selected.length > 0) {
            if (!('splice' in recibosToSelected) && ((recibosToSelected.desglose.pendiente == 0 && isSelectedPendiente) || (recibosToSelected.desglose.pendiente > 0 && !isSelectedPendiente))) {
                selectionDispatch({ type: 'unselect', item: recibosToSelected })
            }
        }
        return recibosNoSeleccionados;
    }, [alertsDispatch, contenidoGateway, isSelectedPendiente]);

    const selectionRe = SelectionReducer<IRecibo>({ isSelectable: handleSelectRecibo });
    const selectionReducer = useReducer(selectionRe, { selected: [] });
    const [, selectionDispatch] = selectionReducer;

    const handleSelectPendientes = useCallback(async () => {
        let allRecibos: IRecibo[] = deudaList;
        if (hasMore) {
            const filters = convertQueryFilters(queryFilters);
            const result = await recibosGateway.findAll(filters);
            if (result) {
                setDeudaList(result);
                allRecibos = result;
            }
        }
        setShowSelectAllOption(false);
        setShowUnSelectAllOption(true);
        setIsSelectedPendiente(true);
        selectionDispatch({ type: 'select', item: allRecibos.filter((deuda: IRecibo) => deuda.desglose.pendiente > 0) });
    }, [recibosCount, deudaList, hasMore, selectionDispatch, queryFilters, recibosGateway, isSelectedPendiente]);

    const handleUnSelect = useCallback(() => {
        setShowSelectAllOption(true);
        setShowUnSelectAllOption(false);
        selectionDispatch({ type: 'clear' });
    }, []);

    const handlePrintJustificanteAllPagado = useCallback(async () => {
        setLoading(true)
        let response: Blob;
        try {
            const direccion = deudaList.sort((a: IRecibo, b: IRecibo) => a.id > b.id ? -1 : 1)[0].direccionTributaria;
            response = await recibosGateway.printJustificanteRecibosPagados(queryFilters.nif, queryFilters.deudaType, queryFilters.deudaType == '5' ? queryFilters.referencia : queryFilters.matricula, direccion);
            download(response, alertsDispatch, translate('Tramites', 'justificanteRecibosPagadosError', terms), 'JustificanteRecibosPagados')
            setLoading(false)
        }
        catch {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:
                        <Term component="Tramites" text="justificanteRecibosPagadosError" />,
                    variant: 'warning',
                }
            })
        }
        setLoading(false);
    }, [alertsDispatch, queryFilters, deudaList]);

    const handleShowOnlyPendientes = () => {
        setShowOnlyPendientes(true);
        setShowAllOption(true);
        setShowPendientesOption(false);
    }

    const handleShowAll = () => {
        setShowOnlyPendientes(false);
        setShowAllOption(false);
        setShowPendientesOption(true);
    }

    const openSearchConfirmDialog = async () => {
        const bodyAux = await contenidoGateway.getContent(
            queryFilters.matricula ?
                'TextoConfirmacionConsultaVehiculo' : 'TextoConfirmacionConsultaInmueble',
            queryFilters.matricula ?
                {
                    nif: queryFilters.nif,
                    matricula: queryFilters.matricula,
                } :
                {
                    nif: queryFilters.nif,
                    referencia: queryFilters.referencia,
                }
        );
        setBody(bodyAux[0].contenido);
        setShowSearchConfirmDialog(true);
    };

    const closeSearchConfirmDialog = async () => {
        setShowSearchConfirmDialog(false);
    }

    const onLoadAvisoPagoCartaPalma = async () => {
        const bodyAux = await contenidoGateway.getContent(
            "AvisoPagoCartaPalma",
            {}
        );
        setBodyAvisoPagoCartaPalma(bodyAux[0].contenido)
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                moreInfoTemplate: "mas_info_consulta_deudas_usuarios_externos",
                title: <Term component="Global" text="ConsultaDeudasUsuariosExternos" />,
                icon: mdiBriefcaseOutline,
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    useEffect(() => {
        const loadMunicipios = async () => {
            setLoading(true);
            try {
                const municipios: IMunicipio[] = await geoGateway.getMunicipios('');
                setMunicipiosOptions(municipios);
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
        loadMunicipios();
    }, [geoGateway, pageDispatcher]);

    useEffect(() => {
        const consultarPermisoUsuario = async () => {
            const usuarioPermitido = await tramitesGateway.getUsuarioPermitidoConsultasExternas();
            if (!usuarioPermitido) {
                handleInfoDispatch(infoDispatch, 'show-info', 'aviso_no_permitido_consultas_externas');
                history.push('/tramites');
            }
        }
        consultarPermisoUsuario();
    }, [infoDispatch])

    useEffect(() => {
        (async () => {
            await onLoadAvisoPagoCartaPalma();
        })();
    }, []);

    return (
        <div className={classes.root}>
            <Card key="firstFilters" className={classes.cardMargin}>
                <CardContent>
                    <div className={classes.explanationText}>
                        <span>
                            <Term component="Tramites" text="TextoExplicativoConsultaDeudasUsuariosExternos" />
                        </span>
                    </div>
                    <div className={classes.firstFiltersDiv}>
                        <FormControl className={classes.smallFomrControl}>
                            <TextField
                                label={translate('Tramites', 'LabelNifCifNie', terms)}
                                value={queryFilters.nif}
                                onChange={(e) => handleChangeFilters('nif', e.target.value)}
                                inputProps={{ maxLength: 9 }}
                            />
                            {errorDni &&
                                <div className={classes.errorTip}>
                                    <Term component='Global' text='El formato del NIF / CIF no es válido' />
                                </div>
                            }
                        </FormControl>
                        <FormControl className={classes.smallFomrControl}>
                            <InputLabel key="label-tipo-deuda" htmlFor="select-multiple-chip" error={false}>
                                <Term component="Tramites" text="concepto" />
                            </InputLabel>
                            <Select
                                native={true}
                                value={queryFilters.deudaType}
                                autoWidth={true}
                                onChange={(e) => handleChangeFilters('deudaType', e.target.value)}
                            >
                                <option key={0} value={''}></option>
                                <option key={1} value={'5'}>{translate('Tramites', 'TipoDeudaInmueble', terms)}</option>
                                <option key={2} value={'3'}>{translate('Tramites', 'TipoDeudaVehiculo', terms)}</option>
                            </Select>
                        </FormControl>
                    </div>
                </CardContent>
            </Card>
            {
                showDeudaFilters ?
                    <>
                        <Card key="secondFilters" className={classes.cardMargin}>
                            <CardContent className={classes.contentInline}>
                                <FormControl className={classes.smallFomrControl} fullWidth>
                                    <TextField
                                        label={(queryFilters.deudaType == '5') ? <Term component="Global" text="ref_catastral" /> : queryFilters.deudaType == '3' ? <Term component="Global" text="matricula" /> : null}
                                        value={queryFilters.matricula || queryFilters.referencia}
                                        onChange={(e) => handleChangeFilters(queryFilters.deudaType == '5' ? 'referencia' : 'matricula', e.target.value)}
                                    />
                                </FormControl>
                                {
                                    queryFilters.deudaType == '5' ?
                                        <FormControl className={classes.fomrControl} fullWidth>
                                            <InputLabel htmlFor="municipio-native-simple">
                                                <Term component="Tramites" text="municipio" />
                                            </InputLabel>
                                            <Select
                                                native={true}
                                                value={queryFilters.municipio}
                                                onChange={(e) => handleChangeFilters('municipio', e.target.value)}
                                                className={classes.selectField}
                                                inputProps={{
                                                    name: 'municipio',
                                                    id: 'municipio-native-simple',
                                                }}
                                            >
                                                <option key="muni" value="" />
                                                {
                                                    municipiosOptions !== null ?
                                                        municipiosOptions.map(x => (
                                                            <option key={x.idMunicipio} value={x.idMunicipio}>{x.nombre}</option>
                                                        )) : null
                                                }
                                            </Select>
                                        </FormControl>
                                        : null
                                }
                                <Button className={classes.searchButton} variant="outlined" color="primary" onClick={openSearchConfirmDialog}>
                                    <Icon path={mdiMagnify} title="Buscar" color={BLUE} size={1} />
                                    <span><Term component="Global" text={'Buscar'} /></span>
                                </Button>
                                {loading && (
                                    <div key="progress" className={classes.progressContainer}><CircularProgress className={classes.progress} size={30} /></div>
                                )}
                            </CardContent>
                        </Card>
                    </>
                    : null
            }
            {
                showDeudaList && deudaList.length > 0 ?
                    <>
                        <SelectionContext.Provider value={selectionReducer}>
                            <ListContador
                                showShowPendientesOption={showPendientesOption} showShowAllOption={showAllOption}
                                showSelectOption={showSelectAllOption} showUnSelectOption={showUnSelectAllOption} showPrintJustificante={showPrintJustificanteTodoPagado}
                                customTitle={customListTitle} onSelect={handleSelectPendientes} onUnSelect={handleUnSelect} onPrintJustificante={handlePrintJustificanteAllPagado}
                                onShowOnlyPendientes={handleShowOnlyPendientes} onShowAll={handleShowAll} />
                            <InfiniteScroll
                                pageStart={0}
                                initialLoad={false}
                                loadMore={() => console.log('get more')}
                                hasMore={hasMore}
                                loader={<div key="infiniteProgress" style={{ display: false ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress className={classes.progress} /></div>}
                            >
                                {
                                    !showPrintJustificanteTodoPagado ?
                                        deudaList.map((recibo: IRecibo, index: number) => (
                                            showOnlyPendientes && recibo.desglose.pendiente > 0 ?
                                                <ReciboCard
                                                    key={'Recibo_' + index}
                                                    recibo={recibo}
                                                    selectable={recibo.desglose.pendiente > 0}
                                                    mensajeAclaratorio={""}
                                                    isFromConsultasExternas={true}
                                                    bodyAvisoPagoCartaPalma={bodyAvisoPagoCartaPalma}
                                                />
                                                :
                                                !showOnlyPendientes &&
                                                <ReciboCard
                                                    key={'Recibo_' + index}
                                                    recibo={recibo}
                                                    selectable={recibo.desglose.pendiente > 0}
                                                    mensajeAclaratorio={""}
                                                    isFromConsultasExternas={true}
                                                    bodyAvisoPagoCartaPalma={bodyAvisoPagoCartaPalma}
                                                />
                                        ))
                                        :
                                        <ReciboCard
                                            key={'Recibo_0'}
                                            recibo={deudaList.sort((a: IRecibo, b: IRecibo) => a.id > b.id ? -1 : 1)[0]}
                                            selectable={false}
                                            mensajeAclaratorio={""}
                                            isFromConsultasExternas={true}
                                            bodyAvisoPagoCartaPalma={bodyAvisoPagoCartaPalma}
                                        />
                                }
                            </InfiniteScroll>
                            {
                                <Carrito items={selectionReducer[0].selected} visible={selectionReducer[0].selected.length > 0} pendiente={isSelectedPendiente} fullWidth={true} isFromConsultasExternas={true} />
                            }
                        </SelectionContext.Provider>
                    </>
                    : showDeudaList ?
                        <NoContent
                            image={image}
                            visible={deudaList.length == 0}
                            component='Tramites'
                            text={'NoRecibosEncontrados'}
                        />
                        :
                        null
            }
            <Dialog
                open={showSearchConfirmDialog}
                fullScreen={false}
                maxWidth={"md"}
                fullWidth={true}
                onClose={closeSearchConfirmDialog}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle>
                    <Term component="CarteroVirtual" text="AVISO" />
                </DialogTitle>

                <DialogContent className={classes.emailDialogContent}>
                    <div>
                        <DialogContentText id="alert-dialog-description">
                            <div dangerouslySetInnerHTML={{ __html: body }} />
                        </DialogContentText>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color="default" variant="outlined" onClick={closeSearchConfirmDialog}>
                            <Term component="Perfil" text="Cancelar" />
                        </Button>
                        <Button className={classes.marginLeft10} color="primary" variant="outlined" onClick={handleSearchDeuda}>
                            <Term component="Perfil" text="Aceptar" />
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default ConsultasExternas;