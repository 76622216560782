import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  ListItem,
  makeStyles,
  MenuItem,
  TextField,
  useTheme,
} from "@material-ui/core";
import Term from "components/term";
import { ContenidosGateway } from "gateways/contenido.gateway";
import usePage from "hooks/page.hook";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FC } from "react";
import IoC from "contexts/ioc.context";
import { RecibosGateway } from "gateways/recibo.gateway";
import { IDatosIngreso, InfoSancionCompleta } from "gateways/recibo.interfaces";
import NumberFormatCustom from "./number-format.custom";
import { AlertsContext } from "contexts/alerts.context";
import { RouteComponentProps } from "react-router-dom";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { useLocation } from "react-router";
import { mdiLaptop } from "@mdi/js";
import imagetarjeta from '../../resources/payment/tarjeta.png';
import imagebancaElectronica from '../../resources/payment/bancaElectronica.png';
import imageBizum from '../../resources/payment/Bizum2.png';
import { IBaseResponse } from "gateways/base.response.interfaces";
import { PagoTelematicoMulta } from "containers/domiciliaciones/components/pago-telematico-multa";

const useStyles = makeStyles((theme) => ({
  base: {
    margin: "0px 40px",
    width: '100%',
    display:'flex',

    gap:'20px',
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: 50,
      marginRight: 10,
      marginLeft: 10,
    },
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  
  iconoImage: {
    width: 70,
    height: 70,
    // [theme.breakpoints.down('md')]: {
    //     iconoImage: {
    //         width: 60,
    //         height: 60
    //     },
    // },
  },

  formContainer: {
    margin: "50px 40px 50px",
    height: '80%'
  },

  [theme.breakpoints.up('md')]: {
    rootTexto: {
      width: "100%",
      flex: 1,
  
    },
    rootDatos: {
      width: "100%",
      flex: 2,
  
    },
  },
  [theme.breakpoints.down('md')]: {
      rootDatos: {
        width: "100%",

      },
      rootTexto: {
        width: "100%",

      },
  },

  formControl: {
    marginTop: 20,
    display: "inline",
    marginRight: 50,

    "& > button": {
      width: "100px",
    },

    [theme.breakpoints.up("xs")]: {
      "& > div": {
        width: "calc(100% - 20%)",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& > div": {
        width: "calc(100% - 62%)",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& > div": {
        width: "calc(100% - 60%)",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& > div": {
        width: "calc(100% - 82%)",
      },
    },
  },
  button: {
    width: 200,
    margin: 50,
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "unset",
    },
  },
}));


type Props = RouteComponentProps;

const PagoTelematico: FC<Props> = ({ history }) => {
  const ioc = useContext(IoC);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [{ lang }, pageDispatcher] = usePage();
  const [, infoDispatch] = useContext(MoreInfoContext);
  const [loading, setLoading] = useState(false);
  const [datosMulta, setDatosMulta] = useState<InfoSancionCompleta | null>(null)
  
  
  const [multaIdentificada, setMultaIdentificada] = useState(false)
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const emisoraQuery= search.get("emisora");
  const referenciaQuery = search.get("referencia");
  const identificacionQuery = search.get("identificacion");
  const importeQuery = search.get("importe");
  const idReferenciaGrupo = search.get("idReferenciaGrupo");
  const [nif, setNif] = useState<string>()
  const [nombre, setNombre] = useState<string>()

  const [emisora, setEmisora] = useState(emisoraQuery !== null ? emisoraQuery : "");
  const [referencia, setReferencia] = useState(referenciaQuery !== null ? referenciaQuery : "");
  const [identificacion, setIdentificacion] = useState(identificacionQuery !== null ? identificacionQuery : "");
  const [importe, setImporte] = useState(importeQuery !== null ? importeQuery.replace('.', '').replace(',', '.') : "");
  const [open, setOpen] = useState(false);
  

  const recibosGateway: RecibosGateway = ioc.get(RecibosGateway);
  const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
  const [body, setBody] = useState("");
  const [errorEmisora, setErrorEmisora] = useState(false);
  const [errorImporte, setErrorImporte] = useState(false);
  const [errorIdentificacion, setErrorIdentificacion] = useState(false);
  const [errorReferencia, setErrorReferencia] = useState(false);
  const [, alertsDispatch] = useContext(AlertsContext);

  const handleShowMasInfo = useCallback((templateName: string) => {
    handleInfoDispatch(infoDispatch, 'show-info',templateName )
  },[infoDispatch])

  useEffect(() => {
    loadTemplate();
    pageDispatcher({
      type: "setHeader",
      header: {
        moreInfoTemplate: "mas_info_pago_documento",
        title: (
          <Term
            component="CarteroVirtual"
            text="Pago con documento de ingreso"
          />
        ),
        icon: mdiLaptop,
        right: <MenuItem button onClick={() => handleShowMasInfo("mas_info_pago_documento")}><Term component="Global" text="Mas informacion" /></MenuItem>
                    
      },
      menu: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDispatcher]);

  const loadTemplate = useCallback(async () => {
    const bodyAux = await contenidoGateway.getContent("pago_documento", {});
    setBody(bodyAux[0].contenido);
  }, [contenidoGateway]);

  useEffect(() => {
    loadTemplate();
  }, [loadTemplate, lang]);

  const handleSetImporte = useCallback((x: string) => {
    setImporte(x);
    setErrorImporte(false);
  }, []);

  const handleSetIdentificacion = useCallback((x: string) => {
    setIdentificacion(x);
    setErrorIdentificacion(false);
  }, []);

  const handleSetReferencia = useCallback((x: string) => {
    setReferencia(x);
    setErrorReferencia(false);
  }, []);

  const handleSetEmisora = useCallback((x: string) => {
    setEmisora(x);
    setErrorEmisora(false);
  }, []);

  const handleRedireccion = useCallback(
    (url: string) => {
      history.push(`/pasarela-pago/pago/${url}`);
    },
    [history]
  );
  const handleCloseModal = () => {
    setOpen(false)
    setDatosMulta(null)
    setMultaIdentificada(false)
    
  }
  const handleValidate = useCallback(() => {
    let valido = true;
    if (!emisora || emisora.length !== 6) {
      setErrorEmisora(true);
      valido = false;
    }

    if (!importe || parseFloat(importe) <= 0) {
      setErrorImporte(true);
      valido = false;
    }

    if (!referencia || referencia.replace(/\s/g, "").length !== 12) {
      setErrorReferencia(true);
      valido = false;
    }
    if (!identificacion || identificacion.length !== 10) {
      setErrorIdentificacion(true);
      valido = false;
    }
    return valido;
    
  }, [emisora, referencia, identificacion, importe]);

  const handleMulta = useCallback(async (response: InfoSancionCompleta) => {
    if(response.Multa.ImportePendiente !== 0){
        setDatosMulta(response);
        if(response.Multa.DeudorNif !== ''){
          setNif(response.Multa.DeudorNif)
          setNombre(response.Multa.DeudorNombre)
        }
        setMultaIdentificada(response.Multa.DeudorNif !== '');
        setOpen(true);
    }
    else{
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: (
              <Term component="CarteroVirtual" text={'model_generation_error9'} />
            ),
            variant: "error",
          },
        })
    }
  },[alertsDispatch])

  const handleLocalizaPropuesta = useCallback(async (response: string) => {
    
    try {
          handleRedireccion(response);
      } catch (e) {
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: (
              <Term component="CarteroVirtual" text="Error pago telemático" />
            ),
            variant: "error",
          },
        });
      }
      
  }, [handleRedireccion, contenidoGateway, alertsDispatch]);

  const handleContinuarModal = useCallback(async () => {
    
    const datos: IDatosIngreso = {
      emisora: emisora,
      referencia: referencia,
      identificacion: identificacion,
      importe: parseFloat(importe),
      idReferenciaGrupo: idReferenciaGrupo,
    };
    
    if(datosMulta && nif && nombre){
      const response = await recibosGateway.completarMultaPagoTelematico(datos, {
        ...datosMulta, 
        Multa: {
            ...datosMulta?.Multa, 
            DeudorNif: nif,
            DeudorNombre: nombre
        },
     });
      if(response.ok){
        handleRedireccion(response.entidad);
      }
      else{
        const bodyAux = await contenidoGateway.getContent(response.mensaje, {});
          
        bodyAux.length > 0 && bodyAux[0].contenido ?
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: (
                bodyAux[0].contenido
              ),
              variant: "error",
              isHtml: true
            },
          })
          : 
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: (
                <Term component="CarteroVirtual" text={response.mensaje} />
              ),
              variant: "error",
            },
          })

      }
    }
    else{

          alertsDispatch({
            type: "show-alert",
            payload: {
              message: (
                <Term component="Global" text={"Error"} />
              ),
              variant: "error",
            },
          })     
     
    }
    
  },[alertsDispatch, contenidoGateway, datosMulta, emisora, handleRedireccion, idReferenciaGrupo, identificacion, importe, nif, nombre, recibosGateway, referencia])

  const handleContinuar = useCallback(async () => {
    const datos: IDatosIngreso = {
      emisora: emisora,
      referencia: referencia,
      identificacion: identificacion,
      importe: parseFloat(importe),
      idReferenciaGrupo: idReferenciaGrupo,
    };
    setLoading(true)
    if (handleValidate()) {
      
      const response = await recibosGateway.checkTipoIdentificador(datos);
      if(response.ok){
        switch(response.entidad.redirect){  
          case 1:
            handleLocalizaPropuesta(response.entidad.data)
            break;
            case 2:
              handleMulta(response.entidad.data)
              break;
            }
      }
      else{
        const bodyAux = await contenidoGateway.getContent(response.mensaje, {});
          
        bodyAux.length > 0 && bodyAux[0].contenido ?
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: (
                bodyAux[0].contenido
              ),
              variant: "error",
              isHtml: true
            },
          })
          : 
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: (
                <Term component="CarteroVirtual" text={response.mensaje} />
              ),
              variant: "error",
            },
          })      }
      }
      setLoading(false)
  }, [emisora, handleLocalizaPropuesta, handleMulta, handleValidate, idReferenciaGrupo, identificacion, importe, recibosGateway, referencia])

  return (
    <>
        <div className={classes.base} style={{height: 'auto'}}>
          <Card className={classes.rootTexto} >
          <div className={classes.formContainer}>

            <div
                  dangerouslySetInnerHTML={{ __html: body }}
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                />
              <div style={{display: "flex", justifyContent: "center"}}>
                  <img src={imageBizum} alt='Bizum' className={classes.iconoImage} />
                  <img src={imagebancaElectronica} alt='Banca' className={classes.iconoImage} />
                  <img src={imagetarjeta} alt='Trajeta' className={classes.iconoImage} />
              </div>
            </div>
          </Card>
          <Card className={classes.rootDatos} >
            <div className={classes.formContainer}>
              <div style={{ fontWeight: "bold", marginBottom: 15 }}>
                    <Term component="CarteroVirtual" text="DatosPagoInternet" />
              </div>
              <div style={{ marginBottom: 5 }}>
                    <Term component="CarteroVirtual" text="PagoTelematicoInfo" />
              </div>
              <div style={{display: 'flex', flexDirection: 'column', height:'100%', justifyContent:'space-between'}}>
              <div>
              <FormControl
                key="emisora"
                className={classes.formControl}
                fullWidth
                >
                <TextField
                  key="emisora"
                  label={<Term component="CarteroVirtual" text="Emisora" />}
                  onChange={(x) => {
                    handleSetEmisora(x.target.value);
                  }}
                  error={errorEmisora}
                  helperText={
                    errorEmisora ? (
                      <Term component="CarteroVirtual" text="Error emisora" />
                      ) : null
                    }
                    inputProps={{ maxLength: 6 }}
                    defaultValue={emisora}
                    />
              </FormControl>
              <FormControl
                key="referencia"
                className={classes.formControl}
                fullWidth
                >
                <TextField
                  key="referencia"
                  label={<Term component="CarteroVirtual" text="Referencia" />}
                  onChange={(x) => {
                    handleSetReferencia(x.target.value);
                  }}
                  error={errorReferencia}
                  helperText={
                    errorReferencia ? (
                      <Term
                      component="CarteroVirtual"
                      text="Error referencia"
                      />
                      ) : null
                    }
                    inputProps={{ maxLength: 13 }}
                    defaultValue={referencia}
                    />
              </FormControl>
              <FormControl
                key="identificacion"
                className={classes.formControl}
                fullWidth
                >
                <TextField
                  key="identificacion"
                  label={<Term component="Global" text="Identificación" />}
                  onChange={(x) => {
                    handleSetIdentificacion(x.target.value);
                  }}
                  error={errorIdentificacion}
                  helperText={
                    errorIdentificacion ? (
                      <Term
                      component="CarteroVirtual"
                      text="Error identificación"
                      />
                      ) : null
                    }
                    inputProps={{ maxLength: 10 }}
                    defaultValue={identificacion}
                    />
              </FormControl>
              <FormControl
                key="importe"
                className={classes.formControl}
                fullWidth
                >
                <TextField
                  key="importe"
                  label={<Term component="CarteroVirtual" text="Importe" />}
                  onChange={(x) => {
                    handleSetImporte(x.target.value);
                  }}
                  error={errorImporte}
                  helperText={
                    errorImporte ? (
                      <Term component="CarteroVirtual" text="Error importe" />
                      ) : null
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    defaultValue={importe}
                    />
              </FormControl>
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.button}
                  color="primary"
                  onClick={handleContinuar}
                  disabled={loading}
                  >{
                    loading
                      ?
                      <CircularProgress size={20} />
                      :
                      null
                  }
                  <Term component="Global" text="Continuar" />
                </Button>
              </div>
            </div>
            </div>
          </Card>
        </div>
        <PagoTelematicoMulta 
          open={open} 
          identificada={multaIdentificada}
          onClose={handleCloseModal} 
          continuar={handleContinuarModal} 
          data={datosMulta} 
          nif={nif}
          nombre={nombre}
          setNif={setNif}
          setNombre={setNombre}
        ></PagoTelematicoMulta>
        </>
      )}
    
export default PagoTelematico;