// LIBRARY IMPORTS
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
// LOCAL IMPORTS
import { ITributo } from "gateways/tributos.interfaces";
import { TributosGateway } from "gateways/tributos.gateway";
import { IDiasCortesia } from "gateways/notificaciones.interfaces";
import { getMomentFromDate } from "utils/dates";
import { FORMAT_CON_GUIONES } from "constants/moment";
import IoC from "contexts/ioc.context";

interface IProps {
    tributos: ITributo[];
    diasVoluntaria: IDiasCortesia | undefined;
    fechaDomiciliacionIncompatible: string | null
}

const useLoadFechaSeleccionadaVoluntaria = (props: IProps) => {
    const { tributos, diasVoluntaria, fechaDomiciliacionIncompatible } = props;
    // Services 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // States
    const [fechaPrevistaEnvioVoluntaria, setFechaPrevistaEnvioVoluntaria] = useState<string | undefined>();
    const [fechaPorDefectoDomiVol, setFechaPorDefectoDomiVol] = useState<string | undefined>();
    const [diaSeleccionado, setDiaSeleccionado] = useState<string>();

    useEffect(() => {
        if (tributos.length !== 1 || !diasVoluntaria || !tributos[0] || !tributos[0].domiciliacion) return;

        if (!tributos[0].domiciliacion.fechaPrevistaEnvio) {
            (async () => {
                const fechaPorDefectoVol = await gateway.getFechaPorDefectoDomiVoluntaria(tributos[0].idRecibo);

                if (!fechaPorDefectoVol) return;

                setFechaPorDefectoDomiVol(moment(fechaPorDefectoVol).format(FORMAT_CON_GUIONES));
                handleDiaSeleccionado(moment(fechaPorDefectoVol).format(FORMAT_CON_GUIONES));
            })();
            return;
        }
        setFechaPrevistaEnvioVoluntaria(tributos[0].domiciliacion.fechaPrevistaEnvio);
        setFechaPorDefectoDomiVol(moment(tributos[0].domiciliacion.fechaPrevistaEnvio).format(FORMAT_CON_GUIONES));
        handleDiaSeleccionado(moment(tributos[0].domiciliacion.fechaPrevistaEnvio).format(FORMAT_CON_GUIONES));
    }, [tributos, diasVoluntaria]);

    const handleDiaSeleccionado = (fecha: string) => {
        const diaSeleccionado = diasVoluntaria?.dias.find(dia => dia.fecha === fecha);
        let diaIncompatible;

        if (!diaSeleccionado && !fechaDomiciliacionIncompatible) return;
        if (diaSeleccionado && !fechaDomiciliacionIncompatible) {
            diaSeleccionado.seleccionada = true;
            setDiaSeleccionado(diaSeleccionado.fecha);
            return;
        }
        if (fechaDomiciliacionIncompatible) {
            //? Se le substrae un día porque la fecha incompatible es el día exacto en el que se dejan de gestionar los tributos
            const fechaAnteriorFechaDomiciliacionIncompatible = getMomentFromDate(fechaDomiciliacionIncompatible).subtract(1, 'days').format(FORMAT_CON_GUIONES);
            diaIncompatible = diasVoluntaria?.dias.find(dia => dia.fecha === fechaAnteriorFechaDomiciliacionIncompatible);

            if (!diaIncompatible) return;

            diaIncompatible.seleccionada = true;
        }

        if (diaIncompatible && diaSeleccionado) {
            new Date(diaIncompatible.fecha) > new Date(diaSeleccionado.fecha)
                ? setDiaSeleccionado(diaSeleccionado.fecha)
                : setDiaSeleccionado(diaIncompatible.fecha);
        }
    }

    return {
        fechaPrevistaEnvioVoluntaria,
        fechaPorDefectoDomiVol,
        diaSeleccionado,
        handleDiaSeleccionado,
        setDiaSeleccionado,
        setFechaPrevistaEnvioVoluntaria
    }
}

export default useLoadFechaSeleccionadaVoluntaria;
