import { ChangeEvent, FC, useContext, useState } from "react";
import { Button, IconButton, Typography } from "@material-ui/core";
import { mdiEyeCircleOutline, mdiPaperclip, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { BLUE } from "containers/shared/colors";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { DocumentosGateway } from "gateways/documentos.gateway";
import ModelGateway from "gateways/model.new.gateway";
import { IDocumentoAtib, TDocSource } from "gateways/documentos.interface";
import { TModeloDocSource } from "gateways/model.new.interface";
import { DocumentsUtils } from "utils/documents";
import { translate } from "utils/i18n";
import usePage from "hooks/page.hook";
import FullLoading from "./full-loading";

interface IAttachFileProps {
    label: string
    docs: IDocumentoAtib[]
    docSource: TDocSource | TModeloDocSource;
    saveDocsAttached: (docs: IDocumentoAtib[]) => void
    allowedExtensions?: string[];
    multiple?: boolean
    allowDownload: boolean | (() => boolean);
    allowDelete: boolean
    // if source -> TModeloDocSource
    idReferencia?: string,
    idTipoApartado?:string,
}

const AttachFileComponent : FC<IAttachFileProps> = ({
    label,
    docs,
    docSource,
    saveDocsAttached, 
    allowedExtensions = [".jpeg", ".jpg", ".png", ".gif", ".doc", ".docx", ".pdf", ".odt", ".rtf", ".txt"], 
    multiple=false, 
    allowDownload,
    allowDelete,
    idReferencia="",
    idTipoApartado=""
})=> {

    const [pageState, ] = usePage();
    const [,alertsDispatch] = useContext(AlertsContext)
    const terms = useContext(LiteralsContext);
    
    const ioc = useContext(IoC);
    const documentGateway = ioc.get(DocumentosGateway) as DocumentosGateway;
    const modelGateway = ioc.get(ModelGateway) as ModelGateway;



    const [loading, setLoading] = useState(false);

    const recuperarDocumentos = async( ids: string[]) => {
        try {
            const attachedDocs = await Promise.all(ids.map( async (id) => {
                return await documentGateway.getDocAtib(id,)
            }))
            saveDocsAttached(attachedDocs);
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_get_doc', terms),
                    variant: 'error',
                }
            });
        }
    }

    const handleAttach = async (files: FileList) => {
        try {
            setLoading(true);
            const ext = Array.from(files).map( (f, idx) => allowedExtensions.includes(f.name.substring(files[idx].name.lastIndexOf(".")).toLowerCase()) )

            if(ext.includes(false)){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Perfil', 'extension_not_allow', terms),
                        variant: 'error',
                    }
                });
            } else {
                let guids: string[];
                if(docSource === 'sede-plusvalias'){
                    guids = await modelGateway.subirModeloDocuemntos(idReferencia, idTipoApartado,files, docSource);
                } else {
                    guids = await documentGateway.subirDocumentos(files, docSource);
                }
                if( guids.length === 0){
                    throw new Error ('No GUIDs');
                }
                await recuperarDocumentos(guids);
            }
        } catch (err) { 
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_attach_doc', terms),
                    variant: 'error',
                }
            });
        }
        finally {
            setLoading(false);
        }
    }

    const onDelete = async( id: string) => {
        try {
            setLoading(true)
            let result= false
            if(docSource === 'sede-plusvalias'){
                result= await modelGateway.deleteDocModelo(idReferencia, id, idTipoApartado);
            } else {
                result= await documentGateway.deleteDocAtib(id)
            }
           
            if(result){
                const newArr = docs.filter(d => d.IdDocumento !== id)
                saveDocsAttached(newArr)
            }
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_delete_doc', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const showDoc = async( id: string) => {
        try {
            setLoading(true)
                DocumentsUtils.downloadDoc(id);
                setLoading(false)
        } catch (error) {

            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_show_attached_doc', terms),
                    variant: 'error',
                }
            });
        }finally {
            setLoading(false);
        }
               
    }



    return (
        <div style={{display:'flex', flexDirection: 'row'}}>
        <FullLoading loading={loading}/>
        <Typography style={{fontWeight:'bolder', marginTop:5 }}>{label.toUpperCase().concat(': ')}</Typography>

        {docs.length === 0 ?
            <div style={{display:'flex', flexDirection: 'row', marginTop:5, marginLeft: 20}}>
                <input
                    style={{ display: 'none' }}
                    id={`file-field`}
                    type="file"
                    multiple={multiple}
                    onChange={ (event: ChangeEvent<HTMLInputElement>) => (
                        event.target.files && event.target.files.length>0 && handleAttach(event.target.files)
                    )}
                />
                <label htmlFor={`file-field`}>
                    <Button component="span" variant="outlined" 
                        color='primary'
                        size="small"
                        startIcon={ <Icon path={mdiPaperclip} size={1} color={BLUE} /> }
                    >
                        {translate('DUTI','btn_attach',terms)}
                    </Button>
                </label>    
            </div>   
        :            
            <>
            {docs.map( (att, idx) => (
                <div key={att.IdDocumento} style={{display:'flex', flexDirection: 'row', alignItems:'flex-start'}}>
                    <Typography variant="body1" style={{marginRight: 10, marginLeft: 10,marginTop:5}}>{att.NombreFichero}</Typography>
                    {/**Permitimos descargar siempre que estemos logueados y allowDownload lo permita */}
                    {(pageState.jwp !== null) && 
                        ((typeof allowDownload === 'boolean' && allowDownload) || typeof allowDownload ==='function' ) && 
                        <IconButton 
                            onClick={() => { 
                                const ok= typeof allowDownload ==='function' ? allowDownload() : true
                                if(ok){
                                    showDoc(att.IdDocumento)
                                }
                            }} 
                            style={{padding:5, marginRight: 5, marginLeft:5}}
                        >
                            <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                        </IconButton>
                    }
                    
                  
                    {allowDelete && 
                        <IconButton onClick={ () => onDelete(att.IdDocumento) } style={{padding:5, marginRight: 5, marginLeft:5}}>
                            <Icon path={mdiTrashCan} size={1} color='red'  />
                        </IconButton>
                    }
                </div>
            ))}
                
            </>
        }   
        </div>
    )
}

export default withLiterals(['Global','DUTI', 'Perfil'])(AttachFileComponent);
