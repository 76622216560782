import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Step, StepConnector, StepLabel, Stepper, Typography, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import Term from "components/term";
import { Icon } from '@mdi/react';
import styles from "./perfil.styles";
import { mdiHomeCityOutline, mdiAt, mdiCellphone } from '@mdi/js';
import DireccionStepValidacion from "./validación-steps/direccion-step.validacion";
import EmailStepValidacion from "./validación-steps/email-step.validacion";
import PhoneStepValidacion from "./validación-steps/phone-step.validacion";
import { ISujeto } from "gateways/perfil.interfaces";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { NotificacionesGateway } from "gateways/notificaciones.gateway";
import IoC from "contexts/ioc.context";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { GeoGateway } from "gateways/geo.gateway";
import { IDireccionData, IErroresValidacionDireccion, TErroresValidacionDireccion } from "gateways/direccion.interfaces";
import usePage from "hooks/page.hook";
import { isAlphanumeric, isNumberOrString } from "utils/validators/profile-validators";

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 30,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    }
})(StepConnector);

const useStyles = makeStyles(styles);

interface IProps {
    sujeto: ISujeto | null;
    onClose: (isSuccess: boolean) => void;
}

const ValidacionStepper: FC<IProps> = ({ sujeto, onClose }) => {
    // Servicios
    const ioc = useContext(IoC);
    const perfilG = ioc.get(SujetosGateway) as SujetosGateway;
    const gateway = ioc.get(NotificacionesGateway) as NotificacionesGateway;
    const geoGateway = useMemo(() => ioc.get(GeoGateway) as GeoGateway, [ioc]);
    // Common
    const theme = useTheme();
    const classes = useStyles(theme);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(false);
    // Stepper
    const [activeStep, setActiveStep] = useState(0);
    // Email
    const [email, setEmail] = useState('');
    const [emailConfirmation, setEmailConfirmation] = useState('');
    const [validationMsgEmail, setValidationMsgEmail] = useState<string | null>(null);
    const [validationMsgEmailConfirmation, setValidationMsgEmailConfirmation] = useState<string | null>(null);
    // Phone 
    const [phone, setPhone] = useState('');
    const [phoneConfirmation, setPhoneConfirmation] = useState('');
    const [countryDialCodePhone, setCountryDialCodePhone] = useState('');
    const [validationMsgPhone, setValidationMsgPhone] = useState<string | null>(null);
    const [validationMsgPhoneConfrimation, setValidationMsgPhoneConfrimation] = useState<string | null>(null);
    // Dirección
    const [direccion, setDireccion] = useState<IDireccionData>();
    const [erroresValidacionDireccion, setErroresValidacionDireccion] = useState<IErroresValidacionDireccion>({
        provinciaError: null,
        municipioError: null,
        poblacionError: null,
        siglasError: null,
        calleError: null,
        codigoPostalError: null,
        numeroError: null,
        kmError: null,
        portalError: null,
        bloqueError: null,
        escaleraError: null,
        toponimiaError: null,
        pisoError: null,
        puertaError: null,
    });

    // ---------- Icons Stepper ----------

    const setIconStepOne = () => {
        return (
            <Button onClick={() => handleNextStep(0)}>
                <div
                    className={[classes.iconBox, activeStep === 0 ? classes.activeIconBox : classes.nonActiveIconBox].join(' ')}
                    style={activeStep >= 0 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiHomeCityOutline}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        );
    };

    const setIconStepTwo = () => {
        return (
            <Button onClick={() => handleNextStep(1)}>
                <div
                    className={[classes.iconBox, activeStep === 1 ? classes.activeIconBox : classes.nonActiveIconBox].join(' ')}
                    style={activeStep > 0 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiAt}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        );
    };

    const setIconStepThree = () => {
        return (
            <Button onClick={() => handleNextStep(2)}>
                <div
                    className={[classes.iconBox, activeStep === 2 ? classes.activeIconBox : classes.nonActiveIconBox].join(' ')}
                    style={activeStep > 1 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiCellphone}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        );
    };

    // ---------- Title and subtitle display ----------

    const handleTitleSectionStep = () => {
        if (activeStep === 0) {
            return (
                <div className={classes.paddingContainerTitle}>
                    <Typography className={classes.title}>
                        <Term component="Global" text="Dirección" />
                    </Typography>
                    <Typography>
                        <Term isHtml component="Global" text="Domicilio de referencia" />
                    </Typography>
                </div>
            );
        }
        else if (activeStep === 1) {
            return (
                <div className={classes.paddingContainerTitle}>
                    <Typography className={classes.title}>
                        <Term component="Global" text="Email" />
                    </Typography>
                    <Typography>
                        <Term component="Global" text="Correo electronico" />
                    </Typography>
                </div>
            );
        }
        else if (activeStep === 2) {
            return (
                <div className={classes.paddingContainerTitle}>
                    <Typography className={classes.title}>
                        <Term component="Global" text="Número de teléfono" />
                    </Typography>
                </div>
            );
        }
    };

    // ---------- Stepper handling ----------

    const handleOpenClose = () => {
        pageDispatcher({ type: "close-modificacion-datos-sujeto" });
    };

    const handleNextStep = (numberNextStep?: number) => {
        if (numberNextStep !== undefined && numberNextStep < activeStep) {
            handleNextStepBack(numberNextStep);
            return;
        }
        setErroresValidacionDireccion({
            provinciaError: null,
            municipioError: null,
            poblacionError: null,
            siglasError: null,
            calleError: null,
            codigoPostalError: null,
            numeroError: null,
            kmError: null,
            portalError: null,
            bloqueError: null,
            escaleraError: null,
            toponimiaError: null,
            pisoError: null,
            puertaError: null,
        });
        handleNextStepAhead(numberNextStep);
    }

    const handleNextStepBack = async (numberNextStep: number) => {
        setActiveStep(numberNextStep);
    }

    const handleNextStepAhead = async (numberNextStep?: number) => {
        let result: boolean = true;
        // Validate
        if (activeStep === 0)
            result = await validateDireccion();
        else if (activeStep === 1)
            result = await validateEmail();
        else if (activeStep === 2)
            result = validatePhone();

        // Increment active step
        if (activeStep < 2 && result)
            numberNextStep
                ? setActiveStep(numberNextStep)
                : setActiveStep(activeStep + 1);
        else if (result) await updateDatosSujeto();
    }

    // ---------- Save Data ----------

    const updateDatosSujeto = async () => {
        try {
            setLoading(true);
            if (!direccion) return;
            const response = await perfilG.updateDatosSujeto(direccion, email, phone);
            onClose(response);
        } catch (e) {
            console.log('Error: ', e);
            onClose(false);
        }
        finally {
            setLoading(false);
        }
    }

    // ---------- Validation ----------

    const validateDireccion = async () => {
        let errorProvincia: string | null = null;
        let errorMunicipio: string | null = null;
        let errorPoblacion: string | null = null;
        let errorSiglas: string | null = null;
        let errorCalle: string | null = null;
        let errorCodigoPostal: string | null = null;
        let errorNumero: string | null = null;
        let errorKm: string | null = null;
        let errorPortal: string | null = null;
        let errorBloque: string | null = null;
        let errorEscalera: string | null = null;
        let errorToponimia: string | null = null;
        let errorPiso: string | null = null;
        let errorPuerta: string | null = null;

        if (!direccion) return false;

        if (!direccion.provincia || direccion.provincia === '') {
            errorProvincia = translate('Perfil', 'El campo no puede estar vacío', terms);
        };
        if (!direccion.municipio || direccion.municipio === '') {
            errorMunicipio = translate('Perfil', 'El campo no puede estar vacío', terms);
        };
        if (!direccion.poblacion || direccion.poblacion === '') {
            errorPoblacion = translate('Perfil', 'El campo no puede estar vacío', terms);
        };
        if (!direccion.siglas || direccion.siglas === '') {
            errorSiglas = translate('Perfil', 'El campo no puede estar vacío', terms);
        };
        if (!direccion.calle || direccion.calle === '') {
            errorCalle = translate('Perfil', 'El campo no puede estar vacío', terms);
        }
        else if (!direccion.idCalle && !direccion.calle) {
            errorCalle = translate('Perfil', 'El campo no puede estar vacío', terms);
        };
        if (!direccion.codigoPostal || direccion.codigoPostal === '') {
            errorCodigoPostal = translate('Perfil', 'El campo no puede estar vacío', terms);
        }
        else if (!await geoGateway.validateCodigoPostal(direccion.poblacion, direccion.codigoPostal)) {
            errorCodigoPostal = translate('Perfil', 'error_cp_poblacion', terms);
        };
        if (direccion.numero &&
            (
                !/^[0-9]+$/.test(direccion.numero) ||
                !Number.isInteger(Number(direccion.numero)))) {
            errorNumero = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.km && (typeof direccion.km !== 'number' || !Number.isInteger(direccion.km) || direccion.km < 0)) {
            errorKm = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.portal && !isNumberOrString(direccion.portal)) {
            errorPortal = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.bloque && !isNumberOrString(direccion.bloque)) {
            errorBloque = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.escalera && !isNumberOrString(direccion.escalera)) {
            errorEscalera = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.toponimia && (typeof direccion.toponimia !== 'string' || !/^[A-Za-z ]+$/.test(direccion.toponimia))) {
            errorToponimia = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.piso && !isNumberOrString(direccion.piso)) {
            errorPiso = translate('Perfil', 'Formato inválido', terms);
        }
        if (direccion.puerta && !isAlphanumeric(direccion.puerta)) {
            errorPuerta = translate('Perfil', 'Formato inválido', terms);
        }

        if (errorProvincia || errorMunicipio || errorPoblacion || errorProvincia || errorSiglas || errorCalle || errorCodigoPostal || errorNumero || errorKm || errorPortal || errorBloque || errorEscalera || errorToponimia || errorPiso || errorPuerta) {
            setErroresValidacionDireccion({
                provinciaError: errorProvincia,
                municipioError: errorMunicipio,
                poblacionError: errorPoblacion,
                siglasError: errorSiglas,
                calleError: errorCalle,
                codigoPostalError: errorCodigoPostal,
                numeroError: errorNumero,
                kmError: errorKm,
                portalError: errorPortal,
                bloqueError: errorBloque,
                escaleraError: errorEscalera,
                toponimiaError: errorToponimia,
                pisoError: errorPiso,
                puertaError: errorPuerta,
            });
            return false;
        }

        return true;
    };

    const validateEmail = async () => {
        let errorEmail: string | null = null;
        let errorEmailConfirmation: string | null = null;

        // Email
        if (email === '' && emailConfirmation === '') {
            return true;
        }
        else if (!await gateway.validarEmail(email)) {
            errorEmail = translate('Global', 'Mail erroneo', terms);
        };

        // Email confirmation validation
        if (email !== emailConfirmation) {
            errorEmailConfirmation = translate('Perfil', 'Email debe coincidir', terms);
        }

        // Validation result
        if (errorEmail || errorEmailConfirmation) {
            setValidationMsgEmail(errorEmail)
            setValidationMsgEmailConfirmation(errorEmailConfirmation);
            return false
        };
        return true;
    };

    const validatePhone = () => {
        let errorPhone: string | null = null;
        let errorPhoneConfirmation: string | null = null;

        // Phone validation
        if (phone === '' && phoneConfirmation === '' && email === '' && emailConfirmation === '')
            errorPhone = 'Teléfono o email obligatorios';
        else if (email !== '' && phone === '' && phoneConfirmation === '')
            return true
        else if (phone !== '' && ((phone.length - countryDialCodePhone.length) < 7 || (phone.length - countryDialCodePhone.length) > 15))
            errorPhone = translate('Perfil', 'No hemos podido verficar el teléfono', terms);

        // Phone confirmation validation 
        else if (phone !== phoneConfirmation)
            errorPhoneConfirmation = 'El teléfono es el mismo';

        // Validation result
        if (errorPhone || errorPhoneConfirmation) {
            setValidationMsgPhone(errorPhone);
            setValidationMsgPhoneConfrimation(errorPhoneConfirmation);
            return false
        }
        return true;
    };

    // ---------- Dirección section ----------

    const handleDireccion = (direccion: IDireccionData, errorToReset?: TErroresValidacionDireccion) => {
        setDireccion(direccion);

        if (!errorToReset) return;

        let erroresValidacionDireccionTemp = erroresValidacionDireccion;
        erroresValidacionDireccionTemp[errorToReset] = null;

        // Debido a que si el autoComplete es "true" el codigo Postal se rellena automáticamente cuando la calle es del tipo selector
        if (errorToReset === "calleError") erroresValidacionDireccionTemp.codigoPostalError = null;

        setErroresValidacionDireccion(erroresValidacionDireccionTemp);
    };

    // ---------- Email section ----------

    const handleEmail = (email: string) => {
        setValidationMsgEmail(null);
        setEmail(email);
    };

    const handleEmailConfirmation = (emailConfirmation: string) => {
        setValidationMsgEmailConfirmation(null);
        setEmailConfirmation(emailConfirmation);
    };

    // ---------- Phone section ----------

    const handlePhone = (phone: string, countryDialCodePhone: string) => {
        setValidationMsgPhone(null);
        setCountryDialCodePhone(countryDialCodePhone);
        if (phone === countryDialCodePhone) setPhone('');
        else setPhone(phone);
    };

    const handlePhoneConfirmation = (phoneConfirmation: string, countryDialCodePhoneConfirmation: string) => {
        setValidationMsgPhoneConfrimation(null);
        if (phoneConfirmation === countryDialCodePhoneConfirmation) setPhoneConfirmation('');
        else setPhoneConfirmation(phoneConfirmation);
    };

    // ---------- UseEffects ----------

    useEffect(() => {
        if (sujeto && sujeto.movil) {
            setPhone(sujeto.movil);
            setPhoneConfirmation(sujeto.movil);
        }
        if (sujeto && sujeto.email) {
            setEmail(sujeto.email);
            setEmailConfirmation(sujeto.email);
        }
    }, [sujeto]);

    return (
        <>
            <Dialog
                open={true}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth="lg"
            >
                <DialogTitle>
                    <Stepper className={classes.stepsStepperCard} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        <Step>
                            <StepLabel StepIconComponent={setIconStepOne} >
                                <div className={activeStep === 0 ? classes.boldText : classes.lightText}>
                                    <Term component="Global" text="Dirección" />
                                </div>
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={setIconStepTwo}>
                                <div className={activeStep === 1 ? classes.boldText : classes.lightText}>
                                    <Term component="Global" text="Email" />
                                </div>
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={setIconStepThree}>
                                <div className={activeStep === 2 ? classes.boldText : classes.lightText}>
                                    <Term component="Global" text="Movil" />
                                </div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </DialogTitle>

                <DialogContent className={classes.contaierContent}>

                    {handleTitleSectionStep()}

                    {activeStep === 0 &&
                        <DireccionStepValidacion
                            sujeto={sujeto}
                            direccionBackUp={direccion}
                            validacionDireccion={erroresValidacionDireccion}
                            onChangeDireccion={handleDireccion}
                        />
                    }

                    {activeStep === 1 &&
                        <EmailStepValidacion
                            emailBackUp={email}
                            emailConfirmationBackUp={emailConfirmation}
                            validationMsgEmail={validationMsgEmail}
                            validationMsgEmailConfirmation={validationMsgEmailConfirmation}
                            onChangeEmail={handleEmail}
                            onChangeEmailConfirmation={handleEmailConfirmation}
                        />
                    }

                    {activeStep === 2 &&
                        <PhoneStepValidacion
                            phoneBackUp={phone}
                            phoneConfirmationBackUp={phoneConfirmation}
                            validationMsgPhone={validationMsgPhone}
                            validationMsgPhoneConfirmation={validationMsgPhoneConfrimation}
                            onChangePhone={handlePhone}
                            onChangePhoneConfirmation={handlePhoneConfirmation}
                        />
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleOpenClose} disabled={loading}>
                        <Term component="Global" text="Cerrar" />
                    </Button>
                    <Button color="primary" onClick={() => handleNextStep()} disabled={loading} style={{ position: 'relative' }}>
                        <Term component="Global" text="Guardar y continuar" />
                        {loading &&
                            <CircularProgress size={20} className={classes.circularProgressCentered} />
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ValidacionStepper;