import React, { useState, useEffect, FC, useContext, useCallback } from 'react';
import { WithStyles, FormControl, withStyles, Grid, Hidden, IconButton, CardContent, Card, MenuItem } from '@material-ui/core';
import Term from 'components/term';
import { IPaymentMethod } from 'gateways/payment.method.interfaces';
import IoC from 'contexts/ioc.context';
import * as paymentGateway from 'gateways/payment.gateway';
import usePage from 'hooks/page.hook';
import { RouteComponentProps } from 'react-router-dom';
import { IModelGateway } from 'gateways/modelGateway.interface';
import { translate } from 'utils/i18n';
import styles from './pago.styles';
import { IParams } from './params.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { Icon } from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { AlertsContext } from 'contexts/alerts.context';

import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import EntidadBancaria from 'components/entidad.bancaria';

const BancaElectronica: FC<WithStyles<typeof styles> & RouteComponentProps<IParams>> = ({ classes, match }) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [modelos, setmodelos] = useState<IModelGateway[]>([]);
    const [showDeuda, setShowDeuda] = useState(false);
    const [, setLoading] = useState(false);


    // Global states
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    // Services 
    const ioc = useContext(IoC);
    const gateway = ioc.get(paymentGateway.PaymentGateway) as paymentGateway.PaymentGateway;

    // Entidades bancarias
    const banks = paymentMethods.filter((p: { tipoPago: number; }) => p.tipoPago === 9);

    const handlePay = async (paymentMethodSelected: string) => {
        setLoading(true);
        const result = await gateway.pagarTributos(paymentMethodSelected, modelos);
        if (result.ok) {
            window.location.href = result.entidad;
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('PasarelaPago', 'No es posible realizar el pago a través de banca electrónica', terms),
                    variant: 'warning',
                }
            });
        }
        setLoading(false);
    };

    const handleHideDeuda = () => {
        setShowDeuda(false);
    };
    
    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info',templateName )
    },[infoDispatch])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: null,
                title: <Term component="PasarelaPago" text="SELECCIONAR FORMA DE PAGO" />,
                moreInfoTemplate: 'mas_info_banca_electronica',
                right: (
                    <>
                        <IconButton color="inherit" onClick={() => setShowDeuda(true)}>
                            <Icon path={mdiInformation} title="Información" size={1} />
                        </IconButton>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_banca_electronica')}><Term component="Global" text="Mas informacion" /></MenuItem>
                    </>
                )
            },
            menu: true,
        });

        const fetchPaymentMethodsData = async () => {
            const result = await gateway.getEntidades();
            setPaymentMethods(result);
        };

        fetchPaymentMethodsData();
    }, [gateway, pageDispatcher, terms]);

    useEffect(() => {
        const fetchmodelosData = async () => {
            const result = await gateway.getModelosAPagar(match.params.token);
            if (result.ok) {
                setmodelos(result.entidades);
            }
        };

        fetchmodelosData();
    }, [match.params.token, gateway]);

    return (
        <EntidadBancaria
            classes={classes}
            banks={banks}
            handlePay={handlePay}
            modelos={modelos}
            showDeuda={showDeuda}
            showTotalPagar={true}
            handleHideDeuda={handleHideDeuda}
        />
    );
}

export default withStyles(styles)(BancaElectronica);