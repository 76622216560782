import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Button, Typography, WithStyles, withStyles } from "@material-ui/core";
import  WarningIcon  from "@material-ui/icons/Warning";
import { cloneDeep } from "lodash";

import usePage from "hooks/page.hook";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import DUTIGateway from "gateways/duti.gateway";

import { IParamStepper, TSujeto } from "../shared/types";
import { TVendedor /**, TComplementariaVendedor */} from "./types";
import plv_styles from './styles';
import StepSchema from "../shared/stepSchema";
import ExpandPanel from "components/DUTI/expand-panel";
import SujetoForm from "components/Modelos/componentes/sujetoForm";
//import extraFieldsVendedor from "components/Modelos/componentes/extraFieldsVendedor";
import Modal from "components/modal";
import FullLoading from "components/full-loading";


interface IVendedoresProps extends IParamStepper, WithStyles<typeof plv_styles> {
    apartado: string;
    orden: number;
}
const Vendedores: FC<IVendedoresProps> = ({
    classes,apartado, orden, idRef,isFirst,isLast, handleBack,handleExit,handleNext
}) => {
    const componentMounted = useRef(true);

    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatch] = usePage()
    const [, alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const [vendedores, setVendedores]= useState<TVendedor[]>([])
    // const [defaultVendedor, setDefaultVendedor] = useState<TVendedor| undefined>(undefined)
    const [nifExclusivoVendedor, setNifExclusivoVendedor] = useState<string| undefined>(undefined)

    const [loading, setLoading] = useState(false)
    const [onEditVendIdx, setOnEditVendIdx] = useState(-1);
    //const [extraInfo, setExtraInfo] = useState<TComplementariaVendedor | undefined>()

    const [showModal, setShowModal] = useState(false);
    const [haveChanges, setHaveChanges] = useState(false);

    const [comprAsNotAllowedNIFs, setComprAsNotAllowedNIFs] = useState([] as string[])
    const [allowChargeLogged, setAllowChargeLogged] = useState(false);
    const [allowChargeRepreLogged, setAllowChargeRepreLogged] = useState(false);

    // MEMOS ------------------------------------------------------------------------------------------------------------
    const vendedoresNIFs = useMemo(() => {
        const nifs = vendedores.length >0 ? vendedores.map(v => v.nif) : [] as string[]
        return nifs
    },[vendedores])

    const isIncluded = useMemo(() => {
        if(nifExclusivoVendedor){
            return vendedoresNIFs.includes(nifExclusivoVendedor)
        } else {
            return false
        }
    },[vendedoresNIFs, nifExclusivoVendedor])

    // const disabledAdd = useMemo(() => {
    //     const suma = vendedores.reduce((acumulador, vend) => acumulador + vend.porcentaje, 0);
    //     return ( suma < 100 ) ? false : true
    // },[vendedores])

    const disabledNext = useMemo(() => {
        // Si no hay vendedores  --- > No aplica DWA-15917 => validar suman 100%
        return (vendedores.length=== 0 /*|| !disabledAdd*/ ) ? true : false
    },[vendedores/*, disabledAdd*/])
    // ------------------------------------------------------------------------------------------------------------------

    // GESTION STEPPER --------------------------------------------------------------------------------------------------

    const onExit = async  (cause:'continuar-mas-tarde'|'force')  => {
        try {
            // validate if pending changes - MSG - save info ¿?
            if(haveChanges && cause === 'continuar-mas-tarde'){
                await modelosGateway.saveStepAsistente(idRef, apartado, orden, {listVendedores: vendedores})
            }
            handleExit()
        } catch(err) {

            alertsDispatch({
                type: "show-alert",
                payload: {
                    message: translate('Plusvalias','error_save_continuar_mas_tarde', terms),
                    variant: "warning",
                    hasCustomAction: true,
                    defaultCloseActionTemplateName: translate('Global','no',terms),
                    actionTemplateName: translate('Global','si',terms),
                    handleCustomAction: () => {
                        alertsDispatch({ type: 'hide-alert' })
                        handleExit()
                    }
                },
            });
        }
    }

    const onBack = () => {
        handleBack()
    }

    const onNext = async() => {
        try {
            setLoading(true)
            const result = haveChanges ? await modelosGateway.saveStepAsistente(idRef, apartado, orden, {listVendedores: vendedores}) : {ok:true, idRef: idRef}
            if(result.ok){
                setLoading(false)
                setHaveChanges(false)
                // si todo OK
                console.log('goNext ---- > from Vendedores')
                handleNext()
            }
        } catch(err) {
            setLoading(false)
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                message: errMSG.concat(`.${cause}`),
                variant: "error",
                },
            });
        }
    }
    // ------------------------------------------------------------------------------------------------------------------
    // FUNCIONES --------------------------------------------------------------------------------------------------------
    /* const validarExtraFields = (info: TComplementariaVendedor|undefined) => {
        if(info !== undefined){
            const err = (info.porcentaje === 0) || (info.numOperaciones === 0)
            const msg =  (info.porcentaje === 0 )
                ? translate('Plusvalias','err_porcentaje', terms)
                : (info.numOperaciones === 0)
                    ? translate('Plusvalias','err_num_operaciones', terms)
                    : ''
            return ({err:err , msg:msg})
        } else {
            return ({err: true, msg: translate('Global','err_check_required_fields',terms) })
        }
    }*/

    const addEditSujeto = (action: 'new' | 'edit', item: TSujeto /*, extraInfo: TComplementariaVendedor*/) => {
        let out: {ok: boolean, msgErr: undefined|string}={ok: true, msgErr: undefined}
        const v = cloneDeep(vendedores)
        if(action === 'new'){
            v.push({...item}) //, ...extraInfo
        } else {
            v[onEditVendIdx]={...item} //, ...extraInfo
        }

        // console.log(v)
        // const sumaPorcentaje = v.reduce((acumulador, compr) => acumulador + Number(compr.porcentaje), 0)
        // console.log(sumaPorcentaje)
        // if( sumaPorcentaje > 100){
        //     out={ ok:false, msgErr: translate('Plusvalias','err_suma_porcentaje', terms) }
        // }

        if(out.ok){
            setVendedores(v)
            setHaveChanges(true)
        }

        return out
    }

    // ------------------------------------------------------------------------------------------------------------------
    // EFFECT -----------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                // Calls - await
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idRef)

                let vendNif;
                if(prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.actuaNombrePropio !== undefined && pageState.jwp!== null){
                    if(prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.actuaNombrePropio !== undefined && pageState.jwp!== null){
                        if(prevInfo.preguntasPlusvalia.actuaNombrePropio === true ){
                            if(pageState.jwp.representante){
                                // si esta representando
                                vendNif=pageState.jwp.nifRepresentante
                            } else {  // si esta representando
                                vendNif=pageState.jwp.nif
                            }
                        } else {
                            vendNif=pageState.jwp.nif
                        }
                    }
                    // vend = nif ? await dutiGateway.getSujeto(nif) : undefined
                }
                if(componentMounted.current){
                    //setters
                    if(prevInfo?.vendedores){
                        setVendedores(prevInfo?.vendedores)
                    }
                    if(prevInfo.compradores){
                        const nifs = prevInfo.compradores.length>0 ? prevInfo.compradores.map(c => c.nif): []
                        setComprAsNotAllowedNIFs(nifs)
                    }
                    if(prevInfo?.preguntasPlusvalia?.actuaNombrePropio !== undefined && pageState.jwp!== null){
                        if(prevInfo.preguntasPlusvalia.actuaNombrePropio === true ){
                            setAllowChargeLogged(true)
                        }else{
                            setAllowChargeRepreLogged(true)
                        }
                    }
                    if(vendNif){
                        setNifExclusivoVendedor(vendNif)

                    }
                }

            } catch (err){
                const msg= (err as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias', msg, terms),
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false)
            }

        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])


    return(
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} onNext={onNext} onBack={onBack} onExit={onExit} disabledNext={disabledNext}>
            <FullLoading loading={loading}/>
            {vendedores.length>0 &&
                <>
                    {vendedores.map(
                        (vend, idx) => {
                            return(
                                <ExpandPanel
                                    key={vend.nif}
                                    withDelete={true}
                                    onDelete={() => {
                                        const v= vendedores.filter(cmp => cmp.nif !== vend.nif)
                                        setVendedores(v)
                                        onEditVendIdx === idx && setOnEditVendIdx(-1)
                                        setHaveChanges(true)
                                    }}
                                    renderHeader={() => (
                                        <div className={classes.row} style={{flexGrow: 1, alignItems:'center', justifyItems:'space-between'}}>
                                            <div className={classes.row} style={{flexGrow: 1}} >
                                                {/* <Typography style={{marginRight: 10, marginLeft: 5}}>{translate('Plusvalias','vendedor ',terms)+' '+ (idx+1)+ ': '}</Typography> */}
                                                <Typography style={{marginLeft: 10}}>{idx+1}</Typography>
                                                <Typography style={{marginRight: 5, marginLeft: 5}}>{'-'}</Typography>
                                                <Typography>{vend.nomApe}</Typography>
                                                <Typography style={{marginRight: 5, marginLeft: 5}}>{'-'}</Typography>
                                                <Typography>{vend.nif}</Typography>
                                            </div>
                                            { onEditVendIdx === idx  &&
                                                <div style={{marginRight:20, marginLeft:20}}>
                                                    <WarningIcon/>
                                                </div>
                                            }
                                        </div>
                                    )}
                                    renderContent={() =>{
                                        const suj: TSujeto={
                                            contacto: {...vend.contacto},
                                            direccion: {...vend.direccion},
                                            nif: vend.nif,
                                            nomApe: vend.nomApe,
                                            personaFisica: vend.personaFisica

                                        }
                                        // const addInfo: TComplementariaVendedor={
                                        //     numOperaciones: vend.numOperaciones,
                                        //     porcentaje: vend.porcentaje,
                                        // }
                                        return(
                                        <SujetoForm
                                            renderType={onEditVendIdx === idx ? 'form': 'detail'}
                                            action='edit'
                                            allowChargeLogged={allowChargeLogged && !isIncluded}
                                            allowChargeRepresentadoLoggued = {allowChargeRepreLogged && !isIncluded}
                                            currentInListNIFs={[]} // vendedoresNIFs}  // Permite repetir vendedores
                                            repeatedMsgErr={""} // translate('Plusvalias','sujeto_repetido',terms)} // Permite repetir vendedores
                                            notAllowedNIFs={comprAsNotAllowedNIFs}
                                            notAllowedNIFsMsgErr={translate('Plusvalias','sujeto_included_in_compr',terms)}
                                            initialInfo={suj}
                                            onSave={addEditSujeto}
                                            onEdit={() => setOnEditVendIdx(idx)}
                                            onEnd={()=> {
                                                setOnEditVendIdx(-1)
                                            }} // id modal close modal
                                            // additionalInfo={addInfo}
                                            // AdditionalInfoRender={extraFieldsVendedor}
                                            // validateAditionalInfo={(info:TComplementariaVendedor) => validarExtraFields(info)}
                                        />
                                    )}}
                                />
                            )
                        }
                    )}

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={()=> {
                            setShowModal(true)
                        }}
                        //disabled={disabledAdd}
                    >
                        {translate('Global', 'btnAdd', terms)}
                    </Button>
                    <Modal fullWidth maxWidth='md'
                        title={translate('Plusvalias', 'vendedor', terms)}
                        open={showModal}
                        renderContent={
                            () => (
                                <SujetoForm
                                    renderType='modal'
                                    action='new'
                                    allowChargeLogged={allowChargeLogged && !isIncluded}
                                    allowChargeRepresentadoLoggued = {allowChargeRepreLogged && !isIncluded}
                                    currentInListNIFs={[]} // vendedoresNIFs}  // Permite repetir vendedores
                                    repeatedMsgErr={""} // translate('Plusvalias','sujeto_repetido',terms)} // Permite repetir vendedores
                                    notAllowedNIFs={comprAsNotAllowedNIFs}
                                    notAllowedNIFsMsgErr={translate('Plusvalias','sujeto_included_in_compr',terms)}
                                    initialInfo={undefined}
                                    onSave={addEditSujeto}
                                    onEnd={() => setShowModal(false)}
                                    // additionalInfo={undefined}
                                    // AdditionalInfoRender={extraFieldsVendedor}
                                    // validateAditionalInfo={(info:TComplementariaVendedor) =>validarExtraFields(info)}
                                />
                            )
                        }
                    />
                </>

            }

            {vendedores.length===0 && !loading &&
                <SujetoForm
                    renderType='form'
                    action='new'
                    allowChargeLogged={allowChargeLogged}
                    allowChargeRepresentadoLoggued = {allowChargeRepreLogged}
                    currentInListNIFs={[]} // vendedoresNIFs}  // Permite repetir vendedores
                    repeatedMsgErr={""} // translate('Plusvalias','sujeto_repetido',terms)} // Permite repetir vendedores
                    notAllowedNIFs={comprAsNotAllowedNIFs}
                    notAllowedNIFsMsgErr={translate('Plusvalias','sujeto_included_in_compr',terms)}
                    initialInfo={undefined}
                    onSave={addEditSujeto}
                    onEnd={ ()=> {} }
                    // additionalInfo={undefined}
                    // AdditionalInfoRender={extraFieldsVendedor}
                    // moreInfoNumOp={{template: 'plusvalias_info_field_numOperaciones', size: 'xs'}}
                    // validateAditionalInfo={(info:TComplementariaVendedor) =>validarExtraFields(info)}
                />
            }
        </StepSchema>
    )
}

export default withLiterals(["Plusvalias","Global"])(withStyles(plv_styles)(Vendedores))