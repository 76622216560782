import React, { useEffect, FC, useState, useMemo, useContext } from "react";
import { RouteComponentProps } from "react-router";
import usePage from "hooks/page.hook";
import Icon from "@mdi/react";
import {
  mdiAlphaACircleOutline,
  mdiAlphaLCircleOutline,
  mdiFileDocumentMultipleOutline
} from '@mdi/js';
import {
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  Checkbox,
  FormControlLabel,
  DialogContent,
  CircularProgress,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { translate } from "utils/i18n";
import {
  formatXMLMultipletoObject,
  formatXMLtoObject,
  GenericInitialObject,
  GenericObject,
  getEmptyObject,
  getXML,
  getXMLValueByFormula,
  handleCopyRef,
  TipoTributo,
} from "./utils";

import { LiteralsContext, withLiterals } from "containers/shared/literals";

import styles from "./tributos-autonomicos.styles";

import ModelGateway from "../../gateways/model.new.gateway";
import { SujetosGateway } from "../../gateways/sujetos.gateway";
import { ICampo, IModeloVehiculo, IStep, ITipoModelo } from "gateways/model.new.interface";
import { ISujeto } from "gateways/perfil.interfaces";

import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";
import { ModelosActionContext } from "contexts/modelos-action.context";
import { TInfoTemplateSize } from "contexts/more_info.reducer";

import StepperModels, { TStepsContent } from "../../components/Modelos/stepper-models";

import Term from "components/term";
import SujetoDeclarante from "./componentes/sujeto-declarante";
import Autoliquidacion from "./componentes/autoliquidacion";
import FechaFinalizacion from "./componentes/fecha-finalizacion";
import SujetoLogueado from './componentes/sujeto-logueado';
import FixedApartadoMultiple from './componentes/fixed-apartado-multiple';
import Conceptos from "./componentes/conceptos";
import Identificativo from "./componentes/identificativo";
import DeclaracionTrimestral from "./componentes/declaracion-trimestral";
import Liquidacion8xx from "./componentes/liquidacion-8xx";
import ApartadoMultiple from "./componentes/apartado-multiple";
import Datos from "./componentes/datos";
import PreviosTecnicos from "./componentes/previos-tecnicos";
import Liquidacion621 from "./componentes/liquidacion621";
import { TIPOS_ASISTENTE } from "./constants";
import Datos656D from "./componentes/datos656D";

const useStyles = makeStyles(styles);

export type TDataPopUp = {
  campos: ICampo[];
  data: GenericInitialObject | null;
  dataMultiple: GenericInitialObject[] | null;
  apartado: string;
};

interface IParams {
  textTipoTributo: TipoTributo;
  idTipoModelo: string;
  action: "nuevo" | string;
}
type Props = RouteComponentProps<IParams>;

const ModelosTributos: FC<Props> = ({ match, history }) => {
  const classes = useStyles();
  const [pageState, pageDispatcher] = usePage();
  const isLogged = pageState.jwp !== null;
  const [, alertsDispatch] = useContext(AlertsContext);
  const terms = useContext(LiteralsContext);
  //States - Context
  const [modelosAction, modelosActionDispatcher] = useContext(ModelosActionContext);
  //Gatways
  const ioc = useContext(IoC);
  const modelGateway: ModelGateway = useMemo(
    () => ioc.get(ModelGateway),
    [ioc]
  );
  const sujetosGateway: SujetosGateway = useMemo(
    () => ioc.get(SujetosGateway),
    [ioc]
  );
  const [sujeto, setSujeto] = useState<ISujeto | null>(null);
  const [tipoModeloInfo, setTipoModeloInfo] =
    useState<ITipoModelo | null>(null);

  const [idReferencia, setIdReferencia] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  //Stepper
  const [steps, setSteps] = useState<IStep[]>([]);
  const [stepsContent, setStepsContent] = useState<TStepsContent[]>([]);
  const [stepFinish, setStepFinish] = useState<Record<string, boolean>>({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [xmlPrevios, setXmlPrevios] = useState<GenericObject>({});

  // PopUp envio formulario
  const [openCreateModelo, setOpenCreateModelo] = useState(false);
  const [conforme, setConforme] = useState(false);
  const [showNeedConforme, setShowNeedConforme] = useState(false);
  const [dataPopUp, setDataPopUp] = useState<null | TDataPopUp[]>(null);
  // Apartados con info - content es mas_info_AparatdoDelModelo. EJ: mas_info_810D
  const moreInfoTemplates = ['593_D', '593_E', '593_F']
  const moreInfoTemplateSize: Record<string, TInfoTemplateSize> = {
    // default size: ms
    '593_D': 'md',
    '593_E': 'md',
    '593_F': 'md'
  };
  // 621
  const [modeloSelected, setModeloSelected] = useState<number | null>(null);
  const [notificadoDGT, setNotificadoDGT] = useState<boolean>(false);
  const [tipoMotorSelected, setTipoMotorSelected] = useState<string | null>(null);
  const [modelosVehiculo, setModelosVehiculo] = useState<IModeloVehiculo[]>([]);
  const [modeloVehiculo, setModeloVehiculo] = useState<IModeloVehiculo | null>(null);
  const [showModelos, setShowModelos] = useState<boolean>(false);
  const [modeloBase, setModeloBase] = useState<string>('');
  const [etiquetaEco, setEtiquetaEco] = useState<string>('');

  const redirectToPago = (localizador: string) => {
    alertsDispatch({
      type: "hide-alert",
    });
    const url = match.url.replace("/nuevo", `/${idReferencia}`);
    history.push(`${url}/pago/${localizador}`);
  };

  //Functions
  const handleSetFinish = (value: boolean, stepIndex: number) => {
    setStepFinish({ ...stepFinish, [steps[stepIndex].idTipoApartado]: value });
  };
  const handleNextStep = () => {
    if (stepFinish[steps[activeStep].idTipoApartado]) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBackStep = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep)
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinish = async () => {
    try {
      setLoading(true)
      let error = false
      //Get data
      const popUpData = await Promise.all(
        steps.map(async step => {
          const campos = await modelGateway.getCamposApartado(step.idTipoApartado, match.params.idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
          const multiple = stepsContent.find(item => item.name === step.webComponent)?.isMultiple
          const fixedMultiple = stepsContent.find(item => item.name === step.webComponent)?.isFixedMultiple;
          let emptyData = getEmptyObject(campos);
          const camposRepetidos: ICampo[] = [];
          if (fixedMultiple) {
            const xmlPrevio = stepsContent.find(item => item.name === step.webComponent)?.xmlPrevio;
            const repeatNumberByFormula = getXMLValueByFormula(step.formulaRepeticiones, xmlPrevio);
            const repeatNumber = Number(repeatNumberByFormula) > 0 ? Number(repeatNumberByFormula) : 1;
            if (repeatNumber >= 1) {
              for (let i = 0; i < repeatNumber; i++) {
                campos.forEach((campoApartado: ICampo) => {
                  const newCampo = Object.assign({}, campoApartado);
                  newCampo.casilla = newCampo.casilla + i;
                  newCampo.idCampo = i > 0 ? newCampo.idCampo + i + 4 : newCampo.idCampo;
                  newCampo.codigo = newCampo.codigo.substring(0, newCampo.codigo.indexOf('c') + 1) + newCampo.casilla
                  newCampo.renderGroup = i;
                  camposRepetidos.push(newCampo);
                });
              }
            }
            emptyData = getEmptyObject(camposRepetidos);
          }
          const data = !multiple && step.datosXML ? formatXMLtoObject(step.datosXML, emptyData) : null
          const dataMultiple = multiple && !fixedMultiple && step.datosXML ? formatXMLMultipletoObject(step.datosXML, emptyData, false) : !multiple && fixedMultiple && step.datosXML ? formatXMLMultipletoObject(step.datosXML, emptyData, true) : [] //null
          if (data === null && dataMultiple === null) {
            error = true
          }
          return !fixedMultiple ? { campos, data, dataMultiple, apartado: step.idTipoApartado } : { campos: camposRepetidos, data, dataMultiple, apartado: step.idTipoApartado }
        })
      )
      //console.log('popUpData', popUpData);
      if (error) {
        setDataPopUp(null)
        throw new Error('SomeStepHaveDatosXmlNull')
      } else {
        //Mostrar popup resumen datos xml modelo
        setDataPopUp(popUpData)
        setOpenCreateModelo(true)
      }
      setLoading(false)
    } catch (error) {
      //console.log('ERROR -- getCampoApartado ', error)
      const result = (error as Error).message;
      setLoading(false)
      alertsDispatch({
        type: 'show-alert',
        payload: {
          message: result,
          variant: 'error',
        }
      });
    }
  }

  //Save apartado to BBDD
  const saveApartado = async (data: GenericInitialObject | null, step: IStep, xmlPrev: GenericObject, dataMultiple: GenericInitialObject[] | null = null, isMultiple: boolean = false) => {
    try {
      

      if (data !== null || dataMultiple !== null) {
        setLoading(true)
        let idRef: string | null = null
        if (Number(step.webOrder) === 0 && !idReferencia) {
          idRef = await modelGateway.postReferencia(translate('Tributos', 'PostReferenciaError', terms));
          //console.log('GET Referencia ', idRef);
          setIdReferencia(idRef)
        }

        if (step.idTipoApartado === '621_B') {
          xmlPrev['621_0'] = ""
          xmlPrev['621_C'] = ""
          xmlPrev['621_D'] = ""
          xmlPrev['621_E'] = ""
          xmlPrev['621_F'] = ""
          setTipoMotorSelected(null)
          setModeloSelected(null)
          setModelosVehiculo([])
          setModeloBase("")
          setEtiquetaEco("")
        }
        const datafinal = (isMultiple && dataMultiple) ? dataMultiple : data ? [data] : [] //as GenericInitialObject[]
        // console.log('datafinal', datafinal, 'xmlPrev ', xmlPrev)
        const xml = getXML(datafinal, Object.values(xmlPrev).join(''), step.idTipoApartado, isMultiple)
        // console.log('xml', xml)
        if (!xml) { throw new Error(translate('Tributos', 'xmlApartadoError', terms)) }
        const xmlAsPrevios = xml?.replaceAll(`<A_${match.params.idTipoModelo}_${step.letra}>`, "")
          .replaceAll(`</A_${match.params.idTipoModelo}_${step.letra}>`, "")
          .replaceAll(`<C_`, `<${match.params.idTipoModelo}_${step.letra}.`)
          .replaceAll(`</C_`, `</${match.params.idTipoModelo}_${step.letra}.`);

        setXmlPrevios({ ...xmlPrevios, [step.idTipoApartado]: xmlAsPrevios });

        if (!idRef && !idReferencia) { throw new Error(translate('Tributos', Number(step.webOrder) === 0 ? 'NeedReferenciaCreate' : 'NeedReferencia', terms)) }

        const refAparm = idReferencia
          ? idReferencia
          : idRef ? idRef : '';
        const savedOk = await modelGateway.saveApartado(
          { idReferencia: refAparm, idTipoApartado: step.idTipoApartado, orden: step.orden, datosXML: xml },
          translate('Tributos', 'SaveApartadoError', terms)
        )

        if (!savedOk) { throw new Error(translate('Tributos', 'StepNotSaved', terms)) }
        const newsteps: IStep[] = refAparm ? await modelGateway.getModeloSteps(match.params.idTipoModelo, refAparm, translate('Tributos', 'GetModeloStepsError', terms)) : [];
        setSteps(newsteps);
        if (step.webComponent === 'DatosPrevios' && modelosAction.conceptoXml) {
          //console.log('from saveApartado -> step ', step.idTipoApartado)
          //console.log('clear modelosAction')
          modelosActionDispatcher({ type: "saveConceptoXml", payload: null })
        }
        if (step.webComponent === 'DeclarTrimestral' && modelosAction.ejercicio) {
          modelosActionDispatcher({ type: "saveEjercicio", payload: undefined })
        }
        setLoading(false);
        return true;
      } else {
        throw new Error(translate("Tributos", "StepNotSavedNoData", terms));
      }
    } catch (error) {
      //console.log(' ERROR - saveApartado---> ', error)
      const result = (error as Error).message;
      setLoading(false);
      alertsDispatch({
        type: "show-alert",
        payload: {
          message: result,
          variant: "error",
        },
      });
      return false;
    };
  }


  const saveModelo = async (datos: TDataPopUp[]) => {
    try {
      if (idReferencia) {
        setLoading(true);
        const tipoTributo = match.params.textTipoTributo === "autonomicos" ? "AU" : "RE";
        const idModeloInserted = await modelGateway.saveModelo(
          {
            datos: datos,
            referencia: idReferencia,
            idTipoModelo: match.params.idTipoModelo,
            tipoTributo: tipoTributo,
          },
          translate("Tributos", "SaveModeloNotOK", terms)
        );
        if (idModeloInserted && idModeloInserted !== "") {
          handleReset();
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: translate("Tributos", "SaveModeloOK", terms),
              variant: "success",
              hideButtonAction: true,
            },
          });
          setLoading(false);

          setTimeout(() => redirectToPago(idModeloInserted), 2000);
        } else {
          setLoading(false);
          const modelo = await modelGateway.getModeloByRef(
            idReferencia,
            translate("Tributos", "GetModeloByRefError", terms)
          );
          if (modelo && modelo.idModelo) {
            const s = await modelGateway.updateModeloState(
              modelo.idModelo,
              "ERR",
              translate("Tributos", "UpdateModeloStateError", terms)
            );
          }
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: `${translate(
                "Tributos",
                "SaveModeloNotOK",
                terms
              )} ${idReferencia}`,
              variant: "error",
            },
          });
        }
      }
    } catch (error) {
      //console.log(' ERROR - saveModelo ---> ', error)
      setLoading(false);
      const result = (error as Error).message;
      alertsDispatch({
        type: "show-alert",
        payload: {
          message: result,
          variant: "error",
        },
      });
    }
  };

  const getStepsContent = (stepsList: IStep[], xmls: GenericObject) => {
    //console.log('stepsList', stepsList)
    const out: TStepsContent[] = stepsList.map((step, index) => {
      switch (step.webComponent) {
        case 'DatosPrevios': // DADES PRÈVIES
          return {
            name: 'DatosPrevios',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Conceptos
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXml={step.datosXML}
                  datosXmlFromModelosAction={modelosAction.conceptoXml}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              )
          }
        case 'DeclarTrimestral': // DADES PRÈVIES - Declaració trimestral
          return {
            name: 'DeclarTrimestral',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <DeclaracionTrimestral
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXml={step.datosXML}
                  datosXmlFromModelosAction={modelosAction.ejercicio}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              )
          }
        case 'SujetoPasivo': //Declarant / Subjecte Passiu
          return {
            name: 'SujetoPasivo',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <SujetoDeclarante
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  loggedSujeto={sujeto}
                  datosXml={step.datosXML}
                  datosXmlApartadosPrevios={xmls}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        //break
        case "Identificativo": // Datos identificativos
          return {
            name: "Identificativo",
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component: () => (
              <Identificativo
                setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                loggedSujeto={sujeto}
                datosXmlApartadosPrevios={xmls}
                datosXml={step.datosXML}
                idTipoApartado={step.idTipoApartado}
                idTipoModelo={match.params.idTipoModelo}
                notificadoDGTExternal={notificadoDGT}
                setNotificadoDGTExternal={setNotificadoDGT}
              />
            ),
          };
        //break;
        
        case 'Autoliquidacion': //AUTOLIQUIDACIÓ
          return {
            name: 'Autoliquidacion',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Autoliquidacion
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                  withEjercicioPeriodo={false}
                />
              ),
          }
        case 'AutoliqEjerPeriod': //AUTOLIQUIDACIÓ - Condicoinada a un Periodo del ejercicio
          return {
            name: 'AutoliqEjerPeriod',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Autoliquidacion
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                  withEjercicioPeriodo={true}
                />
              ),
          }
        //break;
        case 'Liquidacion8xx': //AUTOLIQUIDACIÓ
          return {
            name: 'Liquidacion8xx',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Liquidacion8xx
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        //break;
        case 'FechaFinalizacion': //DATA I FINALITZACIÓ - Auto save datos apartado
          return {
            name: 'FechaFinalizacion',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <FechaFinalizacion
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        case 'ApartadoMultiple':
          return {
            name: 'ApartadoMultiple',
            isMultiple: true,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <ApartadoMultiple
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject[]) => saveApartado(null, step, xmls, value, true)}
                  datosXmlApartadosPrevios={''}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        case 'Datos':
          return {
            name: 'Datos',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Datos
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                  withEjercicioPeriodo={false}

                />
              ),
          }
        case 'DatosEjerPeriod': //DATOS - Condicoinado a un Periodo del ejercicio
          return {
            name: 'Datos',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Datos
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                  withEjercicioPeriodo={true}
                />
              ),
          }
        case 'DatosContacto':
          return {
            name: 'DatosContacto',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Datos
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  loggedSujeto={sujeto}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                  withEjercicioPeriodo={false}

                />
              )
          }
        case 'Datos656D':
          return {
            name: 'Datos656D',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <Datos656D
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        case 'SujetoLogueado':
          return {
            name: 'SujetoLoguado',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <SujetoLogueado
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject) => saveApartado(value, step, xmls)}
                  datosXmlApartadosPrevios={''}
                  loggedSujeto={sujeto}
                  datosXml={step.datosXML}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        case 'FixedApartadoMulti':
          return {
            name: 'FixedApartadoMulti',
            isMultiple: false,
            isFixedMultiple: true,
            formulaRepeticiones: step.formulaRepeticiones,
            xmlPrevio: xmls,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component:
              () => (
                <FixedApartadoMultiple
                  setFinished={(newValue: boolean) => handleSetFinish(newValue, index)}
                  handleSave={(value: GenericInitialObject[]) => saveApartado(null, step, xmls, value, true)}
                  datosXmlApartadosPrevios={xmls}
                  datosXml={step.datosXML}
                  formulaRepeticiones={step.formulaRepeticiones}
                  idTipoApartado={step.idTipoApartado}
                  idTipoModelo={match.params.idTipoModelo}
                />
              ),
          }
        case "PreviosTecnicos": // DADES PRÈVIES - Datos técnicos
          return {
            name: "PreviosTecnicos",
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            component: () => (
              <PreviosTecnicos
                setFinished={(newValue: boolean) =>
                  handleSetFinish(newValue, index)
                }
                handleSave={(value: GenericInitialObject) =>
                  saveApartado(value, step, xmls)
                }
                datosXml={step.datosXML}
                datosXmlFromModelosAction={modelosAction.conceptoXml}
                datosXmlApartadosPrevios={xmls}
                idTipoApartado={step.idTipoApartado}
                idTipoModelo={match.params.idTipoModelo}
                modeloSelectedExternal={modeloSelected}
                setModeloSelectedExternal={setModeloSelected}
                tipoMotorSelectedExternal={tipoMotorSelected}
                setTipoMotorSelectedExternal={setTipoMotorSelected}
                modelosVehiculoExternal={modelosVehiculo}
                setModelosVehiculoExternal={setModelosVehiculo}
                modeloVehiculoExternal={modeloVehiculo}
                setModeloVehiculoExternal={setModeloVehiculo}
                showModelosExternal={showModelos}
                setShowModelosExternal={setShowModelos}
                modeloBase={modeloBase}
                setModeloBase={setModeloBase}
                etiquetaEcoExternal={etiquetaEco}
                setEtiquetaEcoExternal={setEtiquetaEco}
              />
            ),
          };
        case "Liquidacion621": //AUTOLIQUIDACIÓ
          return {
            name: "Liquidacion621",
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado)
              ? `mas_info_${step.idTipoApartado}`
              : undefined,
            moreInfoTemplateSize:
              moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component: () => (
              <Liquidacion621
                setFinished={(newValue: boolean) =>
                  handleSetFinish(newValue, index)
                }
                handleSave={(value: GenericInitialObject) =>
                  saveApartado(value, step, xmls)
                }
                datosXmlApartadosPrevios={xmls}
                datosXml={step.datosXML}
                idTipoApartado={step.idTipoApartado}
                idTipoModelo={match.params.idTipoModelo}
              />
            ),
          };
        default:
          return {
            name: 'NotDataFound',
            isMultiple: false,
            isFixedMultiple: false,
            formulaRepeticiones: null,
            xmlPrevio: null,
            moreInfoTemplate: moreInfoTemplates.includes(step.idTipoApartado) ? `mas_info_${step.idTipoApartado}` : undefined,
            moreInfoTemplateSize: moreInfoTemplateSize[step.idTipoApartado] ?? undefined,
            component: () =>
              <div>
                <span>
                  {translate("Tributos", "GetConceptosHijosError", terms)}
                </span>
              </div>,
          }
      };
    });
    return out;
  };

  useEffect(() => {
    // La ruta es coincidente para Modelos / Asistente - Bloquear que se ejecute este effect si estamo en un ruta de asistente
    if(!TIPOS_ASISTENTE.includes(match.params.idTipoModelo)){
      let numModelo;
      switch (match.params.idTipoModelo) {
        case '621':
          numModelo = '620'
          break;
        case "047":
          numModelo = "043-E";
          break;
        case '594':
          numModelo = '593'
          break;
        default:
          numModelo = match.params.idTipoModelo;
          break;
      }
      
      pageDispatcher({
        type: "setHeader",
        header: {
          icon: match.params.textTipoTributo === 'autonomicos'? mdiAlphaACircleOutline: mdiAlphaLCircleOutline,
          title: `${translate("Tributos", "Modelo", terms)} ${numModelo}: ${tipoModeloInfo?.valor ? tipoModeloInfo.valor : ""}`,
          text: (
            <div>
              {idReferencia ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title={translate("Tributos", "copyRef", terms)}>
                    <Button
                      onClick={() => handleCopyRef(idReferencia)}
                      className={classes.buttonCopyRef}
                    >
                      <Icon
                        path={mdiFileDocumentMultipleOutline}
                        size={1}
                        className={classes.iconCopy}
                      ></Icon>
                    </Button>
                  </Tooltip>
                  <Typography variant="body1">
                    <Term text="Referencia" />
                  </Typography>
                  <Typography variant="body1" style={{ marginRight: 5 }}>
                    :
                  </Typography>
                  <span>{idReferencia}</span>
                </div>
              ) : null}
            </div>
          ),
          //moreInfoTemplate: 'mas_info_',
        },
        menu: true,
      });
    }
  }, [pageDispatcher, idReferencia, tipoModeloInfo]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const modelInfo = await modelGateway.getModeloInfo(
          match.params.idTipoModelo,
          translate("Tributos", "GetModeloInfoError", terms)
        );
        
        let numModeloModelInfo;
        switch (match.params.idTipoModelo) {
          case '594':
            numModeloModelInfo = '593'
            break;
          default:
            numModeloModelInfo = match.params.idTipoModelo;
            break;
        }

        setTipoModeloInfo(
          modelInfo ? {
            ...modelInfo,
            valor: translate(
              "Modelos",
              `${numModeloModelInfo}`,
              terms
            ),
          }
            : null
        );
        if (isLogged) {
          const sujeto = await sujetosGateway.getDatosSujeto();
          setSujeto(sujeto);
          //console.log('sujeto <<<<<<<<< ', sujeto)
        }
        let idRef = "";
        if (match.params.action !== "nuevo") {
          idRef = match.params.action;
        }
        setIdReferencia(idRef);
        const steps = await modelGateway.getModeloSteps(
          match.params.idTipoModelo,
          idRef,
          translate("Tributos", "GetModeloStepsError", terms)
        );
        //console.log('steps <<<<<<<<< ', steps)
        setSteps(steps);
        const getXmlPrevios = steps.reduce((obj, step) => {
          let xml: string = step.datosXML ? step.datosXML : "";
          xml = xml
            .replaceAll(`<A_${match.params.idTipoModelo}_${step.letra}>`, "")
            .replaceAll(`</A_${match.params.idTipoModelo}_${step.letra}>`, "")
            .replaceAll(`<C_`, `<${match.params.idTipoModelo}_${step.letra}.`)
            .replaceAll(
              `</C_`,
              `</${match.params.idTipoModelo}_${step.letra}.`
            );
          return { ...obj, [step.idTipoApartado]: xml };
        }, {});
        //console.log('getXmlPrevios <<<<<<<<< ', getXmlPrevios)
        setXmlPrevios(getXmlPrevios);
        // Indica el estado del step - finalizado o no
        const finished = steps.reduce((obj, item) => {
          return {
            ...obj,
            [item.idTipoApartado]: false,
          };
        }, {});
        setStepFinish(finished);
        setLoading(false);
      } catch (error) {
        //console.log(' ERROR - UseEffect modelos-tributos ---> ', error)
        const result = (error as Error).message;
        setLoading(false);
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: result,
            variant: "error",
          },
        });
      }
    })();
  }, [match.params.idTipoModelo]);

  useEffect(() => {
    if (steps && steps.length > 0) {
      setStepsContent(getStepsContent(steps, xmlPrevios));
    }
  }, [steps, xmlPrevios]);

  // ----------------------------------------------------------------------------------
  
  return (
    <>
      {steps && steps.length > 0 && stepsContent && stepsContent.length > 0 && (
        <StepperModels
          steps={steps}
          stepsContent={stepsContent}
          handleNext={handleNextStep}
          handlePrev={handleBackStep}
          handleFinish={handleFinish}
          activeStep={activeStep}
          activeStepFinished={stepFinish[steps[activeStep].idTipoApartado]}
        />
      )}
      {/** Loading */}
      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress size={35} />
        </DialogContent>
      </Dialog>
      {/** Modal conformidad */}
      <Dialog open={openCreateModelo} maxWidth="lg" fullWidth>
        <DialogTitle>
          {translate("Tributos", "UstedEnviaLaSiguienteInformacion", terms)}
        </DialogTitle>
        <DialogContent>
          {dataPopUp && dataPopUp.length > 0 ? (
            <div className={classes.resumContainer}>
              {dataPopUp.map((item) => {
                let items: (JSX.Element | null)[] = [];
                if (item.campos && item.data) {
                  // campos impresos en función del nº de casilla
                  const sortedCampos = item.campos.sort((a, b) =>
                    (a.orderWeb ?? a.casilla) > (b.orderWeb ?? b.casilla)
                      ? 1
                      : -1
                  );
                  items = sortedCampos.map((campo: ICampo) =>
                    campo.visible ? (
                      <div
                        style={{
                          display: "inline-flex",
                          flexGrow: 1,
                          textAlign: "left",
                          width: "100%",
                        }}
                        key={campo.codigo}
                      >
                        <Typography
                          style={{ width: "30%" }}
                        >{`[${campo.casilla}] ${campo.campoDescription}`}</Typography>
                        <Typography style={{ maxWidth: "70%", flexGrow: 1 }}>
                          {item.data && item.data[campo.codigo].value}
                        </Typography>
                      </div>
                    ) : null
                  );
                  //console.log('items', item.campos)
                } else if (item.campos && item.dataMultiple) {
                  item.dataMultiple.map((data, index) => {
                    //console.log(data)
                    //console.log(item.campos)
                    const itemsAux: (JSX.Element | null)[] = item.campos.map(
                      (campo: ICampo) =>
                        campo.visible ? (
                          <div
                            style={{
                              display: "inline-flex",
                              flexGrow: 1,
                              textAlign: "left",
                              width: "100%",
                            }}
                            key={campo.codigo + index}
                          >
                            <Typography style={{ width: "30%" }}>{`[${campo.casilla
                              }_${index + 1}] ${campo.campoDescription
                              }`}</Typography>
                            <Typography
                              style={{ maxWidth: "70%", flexGrow: 1 }}
                            >
                              {data && data[campo.codigo].value}
                            </Typography>
                          </div>
                        ) : null
                    );
                    //console.log('itemsAux',itemsAux)
                    items = items.concat(itemsAux);
                  });
                }
                //console.log('items',items)
                return items.filter((i) => i !== null);
              })}
            </div>
          ) : (
            <Typography>
              {translate(
                "Tributos",
                "UstedEnviaLaSiguienteInformacionError",
                terms
              )}
            </Typography>
          )}
          <div className={classes.columnAlignLeft}>
            <Typography className={classes.marginBottom10}>
              {translate(
                "Tributos",
                "UstedEnviaLaSiguienteInformacionReglamento",
                terms
              )}
            </Typography>
            <Typography>
              {translate(
                "Tributos",
                "UstedEnviaLaSiguienteInformacionConformidad",
                terms
              )}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between", padding: 20 }}>
          <div className={classes.row}>
            <FormControlLabel
              label={translate("Tributos", "checkConforme", terms)}
              control={
                <Checkbox
                  color="primary"
                  checked={conforme}
                  onChange={(e) => {
                    setConforme(e.target.checked);
                    setShowNeedConforme(false);
                  }}
                />
              }
            />
            {showNeedConforme && (
              <Typography style={{ fontStyle: "italic", color: "red" }}>
                {translate("Tributos", "checkConformeRequired", terms)}
              </Typography>
            )}
          </div>
          <div>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => {
                setOpenCreateModelo(false);
                setConforme(false);
              }}
            >
              {translate("Tributos", "btnClose", terms)}
            </Button>
            {dataPopUp && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  conforme ? saveModelo(dataPopUp) : setShowNeedConforme(true)
                }
              >
                {translate("Tributos", "btnAcept", terms)}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withLiterals(["Tributos", "Modelos"])(
  ModelosTributos
);
