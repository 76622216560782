import React, { FC, useContext, useMemo, useEffect, useState } from 'react';
import { withStyles, WithStyles, Grid, Typography, Button } from "@material-ui/core";
import { INotificacion } from 'gateways/notificaciones.interfaces';
import styles from '../shared/notificaciones.styles';
import Term from 'components/term';
import moment from 'moment';
import { GridDirection } from '@material-ui/core/Grid';
import IoC from 'contexts/ioc.context';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import imageNotIncompareciente from '../../resources/NotIncompareciente.png';
import imageNotNotificada from '../../resources/NotNotificada.png';
import imageNotPendiente from '../../resources/NotPendiente.png';

interface IProps extends WithStyles<typeof styles> {
    notificacion: INotificacion;
    notificacionRef: any;
    direccion1: GridDirection;
    direccion2: GridDirection;
    moreInfo: (notificacion: INotificacion) => void;
    documentosNotificacion: (notificacion: INotificacion) => void;
    firmaNotificacion: (notificacion: INotificacion) => void;
    handleNotificacionesPendientes: () => void;
}

const NotificacionGrid: FC<IProps> = ({ notificacion, notificacionRef, direccion1, direccion2, classes, moreInfo, documentosNotificacion, firmaNotificacion, handleNotificacionesPendientes }) => {
    // gateways
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);

    // local states
    const [state, setState] = useState<INotificacion>();
    
    // effects
    useEffect(() => {
        notificacionRef.current = state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    // handles
    const handleSelect = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!notificacion.fechaNotificacion) {
            firmaNotificacion(notificacion);
        } else if (!notificacion.leido) {
            notificacion.leido = true;
            setState(notificacion);
            handleNotificacionesPendientes();
            await gateway.updateMensajeLeido(notificacion.idMensaje);            
            documentosNotificacion(notificacion);
        } else {
            documentosNotificacion(notificacion);
        }
    };

    const handleMoreInfo = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        moreInfo(notificacion);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item>
                <div className={classes.imagenContainer}>
                    {
                        notificacion.incomparecencia
                        ?
                        <img src={imageNotIncompareciente} alt="notificación" />
                        :
                        notificacion.fechaNotificacion
                        ?
                        <img src={imageNotNotificada} alt="notificación" />
                        :
                        
                        <img src={imageNotPendiente} alt="notificación" />
                    }
                </div>
            </Grid>
            <Grid container direction={direccion1}>
                <Grid item className={classes.grow}>
                    <Grid container className={classes.contentRoot}>
                        <Grid className={[classes.grow, classes.width0].join(' ')}>
                            <Typography className={classes.highlightText}>
                                {notificacion.concepto}
                            </Typography>
                            {
                                notificacion.incomparecencia
                                ?
                                <Typography className={[classes.highlightText, classes.highRed].join(' ')}>
                                    <Term component="Notificaciones" text="Notificada incompareciente" />
                                </Typography>
                                :
                                notificacion.fechaNotificacion
                                ?
                                <Typography className={[classes.highlightText, classes.highGray].join(' ')}>
                                    <Term component="Notificaciones" text="Notificada" />
                                </Typography>
                                :
                                <Typography className={[classes.highlightText, classes.highBlue].join(' ')}>
                                    <Term component="Notificaciones" text="Notificacion pendiente" />
                                </Typography>
                            }                          
                        </Grid>
                    </Grid>                
                </Grid>
                <Grid item className={classes.grow}>
                    <Grid container className={classes.contentRoot}>
                        <Grid className={[classes.grow, classes.width015].join(' ')}>
                            {/* <Typography className={classes.normalText}>
                                <Term component="Notificaciones" text="Titular" /> - <b>{notificacion.nombreTitular}</b>
                            </Typography>                  */}
                            {
                                notificacion.nombreDestinatario
                                ?
                                <Typography className={classes.normalText}>
                                    <Term component="Notificaciones" text="Destinatario" /> - <b>{notificacion.nombreDestinatario}</b>
                                </Typography>  
                                :
                                null
                            }
                            {
                                notificacion.refExpediente
                                ?
                                <Typography className={classes.normalText}>
                                    <Term component="Notificaciones" text="RefExpediente" /> - <b>{notificacion.refExpediente}</b>
                                </Typography>  
                                :
                                null
                            }         
                        </Grid>
                    </Grid>                
                </Grid>
                <Grid item className={classes.grow}>
                    <Grid container className={classes.contentRoot}>
                        <Grid className={[classes.grow, classes.width015].join(' ')}>
                            <Grid container direction={direccion2} spacing={2}>
                                <Grid item>
                                    <Typography className={classes.normalText}>
                                        <Term component="Notificaciones" text="Fecha de emision" />
                                    </Typography>
                                    <Typography className={classes.highlightText}>
                                        {notificacion.fechaVisible  ? moment(notificacion.fechaVisible).format('DD/MM/YYYY'): null}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                {
                                    notificacion.incomparecencia && notificacion.fechaNotificacion
                                        ?
                                        <>
                                        <Typography className={[classes.normalText, notificacion.incomparecencia ? classes.red : ''].join(' ')}>
                                            <Term component="Notificaciones" text="Notificada" />
                                        </Typography>
                                        <Typography className={[classes.highlightText, notificacion.incomparecencia ? classes.red : ''].join(' ')}>
                                            {moment.utc(notificacion.fechaNotificacion).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>
                                        </>                                        
                                        :
                                        notificacion.fechaNotificacion
                                        ?
                                        <>
                                        <Typography className={classes.normalText}>
                                            <Term component="Notificaciones" text="Fecha firma" />
                                        </Typography>
                                        <Typography className={classes.highlightText}>
                                            {moment.utc(notificacion.fechaNotificacion).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>
                                        </>
                                        :
                                        null
                                }
                                </Grid>
                                <div className={classes.bannerBtn}>
                                {
                                    !notificacion.fechaNotificacion && ! notificacion.incomparecencia
                                    ?
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.btnNotificacion}
                                            onClick={handleSelect}>
                                            <Term component="Notificaciones" text="Firmar notificacion" />
                                        </Button>
                                        :
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.btnNotificacion}
                                            onClick={handleSelect}>
                                            <Term component="Notificaciones" text="Ver Documentos" />
                                        </Button>                                     
                                    }
                                    <Button variant="contained" color="default" size="small" className={[ classes.btnMoreInfo].join(' ')} onClick={handleMoreInfo}>
                                        <Term component="Notificaciones" text="+ INFO" />
                                    </Button>
                                </div>
                            </Grid>                            
                        </Grid> 
                    </Grid>                
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(NotificacionGrid);