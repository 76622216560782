import React, { FC, useContext, useMemo, useState } from 'react';
import { withStyles, WithStyles, Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import ConceptoIcon from '../../components/concepto.icon';
import Term from '../../components/term';
import TextNumber from '../../components/text-number';
import { IModelGateway } from 'gateways/modelGateway.interface';
import styles from './pago.styles';
import IoC from 'contexts/ioc.context';
import * as paymentGateway from 'gateways/payment.gateway';
import { TributosAutonomicosGateway } from 'gateways/tributos.autonomicos.gateway';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { download } from 'utils/download';
import { AlertsContext } from 'contexts/alerts.context';
import { esConceptoVivienda } from 'utils/concepto.vivienda';


interface IProps extends WithStyles<typeof styles> {
    modeloRecibo: IModelGateway;
    showPrint: boolean;
}

const ModeloReciboGrid: FC<IProps> = ({ modeloRecibo, showPrint, classes }) => {
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);

    // Services 
    const ioc = useContext(IoC);
    const gateway = ioc.get(paymentGateway.PaymentGateway) as paymentGateway.PaymentGateway;
    const tributosAutonomicosGateway = ioc.get(TributosAutonomicosGateway) as TributosAutonomicosGateway;

    // local states
    const [loadingPrint, setLoadingPrint] = useState(false);

    const getDomicilio = () => {
        return (
            <>{(modeloRecibo.recibo.siglas ? modeloRecibo.recibo.siglas + ' ' : '') +
                modeloRecibo.recibo.domicilio + ' ' +
                (modeloRecibo.recibo.numero ? 'Nº ' + modeloRecibo.recibo.numero + ' ' : '') +
                (modeloRecibo.recibo.piso ? 'Piso ' + modeloRecibo.recibo.piso + ' ' : '') +
                (modeloRecibo.recibo.puerta ? 'Pta. ' + modeloRecibo.recibo.puerta + ' ' : '')}
            </>
        );
    }

    const getEstadoPago = () => {
        if (!modeloRecibo.validado) {
            return (
                <span className={classes.red} >
                    <Term component="PasarelaPago" text="PENDIENTE CONFIRMACIÓN" />
                </span>)
        }
    }

    const handlePrint = async () => {
        setLoadingPrint(true);
        if (modeloRecibo.modelo.idTipoModelo === '090') {
            const result = await gateway.getJustificanteModeloPago(modeloRecibo.modelo.id);
            download(result, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar el justificante de pago', terms),
                translate('Global', 'BloqueoPantalla', terms));
        } else {
            //Resto de modelos (incl. pago ficticio)
            const result = await tributosAutonomicosGateway.print(
                modeloRecibo.modelo.idReferencia,
                modeloRecibo.modelo.idTipoModelo,
                modeloRecibo.modelo.id
            )
            download(result, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar el justificante', terms),
                translate('Global', 'BloqueoPantalla', terms));
        }
        setLoadingPrint(false);
    };

    const isPagoCuenta = useMemo(() => {
        if (modeloRecibo.recibo && modeloRecibo.recibo.importe !== modeloRecibo.modelo.importeAIngresar) {
            return true
        } else {
            return false
        }
    }, [modeloRecibo])

    return (
        <Grid container className={classes.rootGrid}>
            <Grid item className={classes.imageGrid}>
                <div className={classes.imagenContainer}>
                    <ConceptoIcon concepto={modeloRecibo.recibo ? modeloRecibo.recibo.idConcepto : null} />
                </div>
            </Grid>
            <Grid item className={classes.grow}>
                <Grid container className={classes.contentRoot}>
                    {modeloRecibo.recibo &&
                        <Grid className={classes.grow}>
                            <Typography className={classes.highlightText}>
                                {modeloRecibo.recibo.municipio}
                            </Typography>
                            <Typography className={classes.highlightText}>
                                {modeloRecibo.recibo.descripcion}
                            </Typography>
                        </Grid>
                    }
                    <Grid>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {isPagoCuenta &&
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography component="span" className={[classes.price, classes.priceLabel].join(' ')}>
                                        <Term component="PasarelaPago" text="Importe Principal" />
                                    </Typography>
                                    <TextNumber
                                        component="span"
                                        className={classes.price}
                                        value={modeloRecibo.recibo.importe}
                                        options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                                </div>
                            }
                            
                            {modeloRecibo.modelo.importeAIngresar > 0  &&
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography component="span" className={
                                        isPagoCuenta
                                            ? [classes.price, classes.priceLabel, classes.priceBold].join(' ')
                                            : [classes.price, classes.priceLabel].join(' ')
                                    }
                                    >
                                        <Term component="PasarelaPago" text={isPagoCuenta ? "Importe Pagado" : "Importe Principal"} />
                                    </Typography>
                                    <TextNumber
                                        component="span"
                                        className={[classes.price, classes.priceBold].join(' ')}
                                        value={modeloRecibo.modelo.importeAIngresar}
                                        options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                                </div>
                            }

                        </div>
                    </Grid>
                </Grid>

                {modeloRecibo.recibo &&
                    <Typography className={classes.detailText}>
                        {esConceptoVivienda(modeloRecibo.recibo.idConcepto) ?
                            modeloRecibo.recibo.direccionObjetoTributario
                            :
                            getDomicilio()
                        }
                    </Typography>
                }
                <Typography className={classes.detailText}>
                    <Term component="Global" text="Nif" />: <span className={classes.bold}>{modeloRecibo.recibo ? modeloRecibo.recibo.nif : modeloRecibo.modelo.nifSujetoPasivo}</span>
                </Typography>
                {
                    modeloRecibo.recibo &&
                    <Typography className={classes.detailText}>
                        <Term component="Global" text="Nombre" />: <span className={classes.bold}>{modeloRecibo.recibo.nombre}</span>
                    </Typography>
                }
                <Typography className={classes.detailText}>
                    <Term component="Global" text="Referencia" />: <span className={classes.bold}>{modeloRecibo.recibo ? modeloRecibo.recibo.referencia : modeloRecibo.modelo.idReferencia}</span>
                </Typography>
                <Typography className={classes.detailText}>
                    {getEstadoPago()}
                </Typography>
                { showPrint &&
                <Button variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.btnAddPay}
                    onClick={handlePrint}
                    disabled={!modeloRecibo.validado || loadingPrint}>
                    <Term component="PasarelaPago" text="Imprimir" />
                
                    {loadingPrint && <CircularProgress size={24} className={classes.buttonProgressList} />}
                </Button>
                }
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(ModeloReciboGrid);