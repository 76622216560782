import React, { FC } from "react";
import FormasPago from './formas.pago';
import VueltaEntidad from './vuelta.entidad';
import BancaElectronica from './banca.electronica';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { withLiterals } from "containers/shared/literals";


const PasarelaPagoIndex: FC<RouteComponentProps> = ({ match }) => {
    
    return (
        <>
            <Route path={`${match.path}/pago/:token`} component={FormasPago} />
            <Route path={`${match.path}/bancaElectronica/:token`} component={BancaElectronica} />
            <Route path={`${match.path}/vueltaEntidad/:token`} component={VueltaEntidad} />
        </>
    );
}

export default withLiterals(['Global','PasarelaPago'])(withRouter(PasarelaPagoIndex));