import { FC, useEffect, useMemo } from "react";
import usePage from "hooks/page.hook";
import { withLiterals } from "containers/shared/literals";
import { makeStyles, useTheme, Grid } from "@material-ui/core";
import {  mdiArrowLeftDropCircle, mdiArrowRightDropCircle } from '@mdi/js';
import { RouteComponentProps } from "react-router-dom";
import { BoxHomeOptions, TBoxHomeOptions /*, InicioOptions*/  } from "./helper/inicio.helper";
import Banner from "components/banner";


import APP_cat from '../../resources/home/banner/APP_cat.jpg'
import APP_es from '../../resources/home/banner/APP_es.jpg'
import Ayuda_cat from '../../resources/home/banner/Ayuda_cat.jpg';
import Ayuda_es from '../../resources/home/banner/Ayuda_es.jpg';
import Cartero_cat from '../../resources/home/banner/Cartero_cat.jpg';
import Cartero_es from '../../resources/home/banner/Cartero_es.jpg';
import DUTI_es from '../../resources/home/banner/Duti_es.jpg';
import DUTI_cat from '../../resources/home/banner/Duti_cat.jpg';




import BoxHome from "components/box-home";
import Icon from "@mdi/react";
import PageSearch from "components/page-search";
import { SHOW_DUSTI } from "constants/dusti";

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft:100,
            marginRight: 100,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft:350,
            marginRight: 350, // espacio que ocupa el drawer
        },
    },
    firstElement: {
        marginTop: '75px!important'
    },
    main: {
        marginTop: '-57px',
        justifyContent: 'center',
    },
    marginList: {
        marginTop: 30,
        '& > .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    marginLink: {
        marginLeft: 10,
        color: 'black',
    },
    widget: {
        marginTop: 10,
        marginBottom: 10,
    },
    [theme.breakpoints.up('md')]: {
        main: {
            paddingTop: 0,
            marginTop: '0px'
        },
        marginList: {
            marginLeft: 40,
        },
        firstElement: {
            marginTop: '10px!important'
        },
    },
    [theme.breakpoints.down('sm')]: {
        main: {
            paddingTop: 80,
            marginTop: '0px'
        },
        marginList: {
            marginLeft: 40,
        },
        firstElement: {
            marginTop: '10px!important'
        },
    },
    containerBanner: {
        display:'flex', 
        flexDirection:'column',
        justifyContent: 'center',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10
    },
    search: {
        width: '80%',
    }
}));

type Props = RouteComponentProps;

const Inicio: FC<Props> = ({ history }) => {
    const [pageState, pageDispatcher] = usePage();
    const theme = useTheme();
    const classes = useStyles(theme);
    const options = BoxHomeOptions; 
    const isMobile = window.outerWidth <= 950;
    // InicioOptions;
    //const isLogged = state.jwp !== null;
    // Gateways
    //const perfilG = ioc.get(SujetosGateway) as SujetosGateway;
    // Check datos validados
    //const [isOpen, setOpen] = useState(false);
    const IMGs = useMemo(() => {
        if(pageState.lang === 'ca'){
            const CAT= [
                {src: APP_cat, alt: 'Second IMG' , goToURL: 'https://sede.atib.es/cva/app-redirect.html' },
                {src: Cartero_cat, alt: 'Third IMG', goTo: '/cartero-virtual/deuda-pendiente'},
                {src: Ayuda_cat, alt: 'Fourth IMG', goTo:'/faqs'}
            ]
            if(SHOW_DUSTI){
                CAT.unshift({src: DUTI_cat, alt: 'First IMG' , goTo: 'tributos/DUSTI/alta' },)
            }
            
            return CAT
        } else {
            const ES = [
                {src: APP_es , alt: 'Second IMG' , goToURL: 'https://sede.atib.es/cva/app-redirect.html' },
                {src: Cartero_es , alt: 'Third IMG', goTo: '/cartero-virtual/deuda-pendiente'},
                {src: Ayuda_es , alt: 'Fourth IMG', goTo:'/faqs'}
            ]
            if(SHOW_DUSTI){
                ES.unshift({src: DUTI_es, alt: 'First IMG' , goTo: 'tributos/DUSTI/alta' },)
            }

            return ES
        }
    }, [pageState.lang])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: null,// mdiHome,
                title: "", //<Term component="Global" text="INICIO"  />,
                hideAtibLogo: true,
                homeSearch: true,
            },
            menu: false,
            footer: true,
        });
        sessionStorage.removeItem('errorConnectionApi');
    }, [pageDispatcher]);
   

    return (
        <div className={classes.root} >
            <Grid container className={classes.main}>
                {!isMobile &&
                    <div className={classes.search}><PageSearch /></div>
                }
                <div className={classes.containerBanner}>
                    <Banner 
                        img={IMGs}
                        controls={true} // Habilita click drch/izq para mover el banner
                        nextIcon={<Icon path={mdiArrowRightDropCircle} color='white' size={2} />} // No muestra icono - vemos el icomo de la imagen y el click sobre él está activo gracias a controls
                        prevIcon={<Icon path={mdiArrowLeftDropCircle} color='white' size={2} />} // No muestra icono - vemos el icomo de la imagen y el click sobre él está activo gracias a controls
                    />
                </div>
                
                
                 {
                    options && options.map((optionItem: TBoxHomeOptions, index: number) => {
                        return (
                            <Grid className={[classes.widget, classes.firstElement].join(' ')} item xs={12} md={6} xl={4} key={`optionItem${index}`}>
                                <BoxHome item={optionItem} key={`box_optionItem${index}`} />
                            </Grid>
                        )
                    })
                }  
            </Grid>
        </div>
    );
};

export default withLiterals(['Global', 'Representaciones', 'CitaPrevia', 'FAQS'])(Inicio);

