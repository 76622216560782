import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import { RouteComponentProps, StaticContext, useHistory, withRouter } from "react-router";
import { Card, MenuItem, WithStyles, withStyles } from "@material-ui/core";
import { useTheme } from "styled-components";

import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import duti_styles from "./styles";

import { TDustiConsultaAction } from "components/DUTI/dusti-item-list";
import { IDeclaracion } from "gateways/duti.interface";
import DUTIComponent from "./DUTI-component";
import FullLoading from "components/full-loading";
import { mdiFileAccount } from "@mdi/js";
import Term from "components/term";
import { LiteralsContext } from "containers/shared/literals";
import { MoreInfoContext } from "contexts/more_info.context";
import usePage from "hooks/page.hook";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";

type IState = { 
    action: TDustiConsultaAction,
    declaracion: IDeclaracion
 }
type Props = RouteComponentProps<{}, StaticContext, IState>& WithStyles<typeof duti_styles>;

const DUTIConsultaAction: FC<Props> = ({classes,match, history}) => {
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);



    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [loading, setLoading] = useState(false)

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileAccount,
                title: <Term component="DUTI" text="DUSTIAlta" />,
                text:  <Term component="DUTI" text="DUSTIAltaDescripcion" />,
                moreInfoTemplate: 'mas_info_duti_alta',
                moreInfoTemplateSize: 'lg',
                moreInfoTemplateComponentIG: 'duti-ayuda',
                right: (
                    <>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_duti_alta')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);


    return(
        <div style={{display:'flex', flexGrow:1}} /*className={classes.rootContainer}*/>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            {(history.location.state.action === 'continuar' || history.location.state.action === 'reiniciar' ||  history.location.state.action ==='consultar' ) && 
                <DUTIComponent DUSTI={history.location.state.declaracion}/>
            }
            
        </div>
        
    )
}




export default withRouter(withStyles(duti_styles)(DUTIConsultaAction));