// LIBRARY IMPORTS
import React, { ReactElement, useState, FC, useContext, useMemo, useEffect, useCallback } from 'react';
import { Checkbox, FormControlLabel, makeStyles, CardContent, Card, Hidden, useTheme, MenuItem, Select, InputLabel, FormControl, Button, Typography, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { mdiBank } from '@mdi/js';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
// LOCAL IMPORTS
import { TributosGateway } from 'gateways/tributos.gateway';
import { LiteralsContext } from 'containers/shared/literals';
import { IConvenioPlazos, IDomiciliacionCompFVol, IPagoCartaMod, IResultadoDomiciliacion, ITributo, ITributoRelacionado } from 'gateways/tributos.interfaces';
import { AlertsContext } from 'contexts/alerts.context';
import { DialogDomiciliacionAltaGreatBritain } from './components/dialogs/dialog-domiciliacion-alta-great-britain';
import { IDatosDomiciliacion, IDatosDomiciliacionGB } from 'gateways/datos.domiciliacion.interfaces';
import { IBaseResponse } from 'gateways/base.response.interfaces';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { DialogCheckConservarDiaVoluntaria } from './components/dialogs/dialog-check-conservar-dia-voluntaria';
import { DialogDomiciliacionIncompatibleModal } from './components/dialogs/dialog-domiciliacion-incompatible';
import { DialogSeleccionRegistrosPorFechaVoluntaria } from './components/dialogs/dialog-seleccion-registros-por-fecha-voluntaria';
import { BLUE } from 'containers/shared/colors';
import { PanelTributos } from './components/panel-tributos-drawer';
import { PagoCartaDetail } from './components/pago-carta-detail';
import { CarritoDomiciliaciones } from './components/carrito-domiciliaciones';
import { TextField } from 'components/text-field';
import * as ibantools from 'ibantools';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import usePage from 'hooks/page.hook';
import useSetHeader from 'hooks/set-header.hook';
import useLoadDiasVoluntaria from 'hooks/domiciliaciones/dias-voluntaria.hook';
import useLoadFechaSeleccionadaVoluntaria from 'hooks/domiciliaciones/fecha-seleccionada-voluntaria.hook';
import useLoadFechasVoluntariaIncompatibles from 'hooks/domiciliaciones/fechas-voluntaria-incompatibles';
import useCheckFechaDomiciliable from 'hooks/domiciliaciones/check-fecha-domiciliable.hook';
import useLoadDomiciliacionesIncompatibles from 'hooks/domiciliaciones/domiciliaciones-incompatibles.hook';
import CalendarioFechasVoluntaria from './components/calendario-fechas-voluntaria/calendario-fechas-voluntaria';
// STYLES IMPORTS
import styles from './domiciliacion.styles';
import { IStateHistory } from 'hooks/domiciliaciones/altas-domiciliacion.hook';
import { StaticContext } from 'react-router';

export interface IErrorDomiciliacion {
    iban?: ReactElement | string;
    nif?: ReactElement | string;
    nombre?: ReactElement | string;
    plazo?: ReactElement | string;
    datosGB?: IDatosDomiciliacionGB;
}

const useStyles = makeStyles(styles);

const DomiciliacionAlta: FC<RouteComponentProps<{},StaticContext,IStateHistory>> = ({ location, history }) => {
    const resultadoSolicitud =  location.state;
    const tributos: ITributo[] = resultadoSolicitud.tributos;
    const domiciliables: boolean = resultadoSolicitud.domiciliables;
    const domiciliadoPc: boolean = resultadoSolicitud.domiciliadoPc;
    const pagoCarta: boolean = resultadoSolicitud.pagoCarta;
    const modifFechaVol: boolean = resultadoSolicitud.modifFechaVol;
    // HOOKS
    const [pageState,] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    // Calendario Fechas Voluntaria Component
    const [diaSeleccionado, setDiaSeleccionado] = useState<string | undefined>();
    const [fechaPrevistaEnvioVoluntaria, setFechaPrevistaEnvioVoluntaria] = useState<string | undefined>();
    // DOMICILIACIONES HOOKS
    const { diasVoluntaria } = useLoadDiasVoluntaria({ tributos });
    const { fechaDomiciliacionIncompatible, isLiquidacionInTributos } = useLoadFechasVoluntariaIncompatibles({ tributos })
    // TODO: investigar porque este hook no refresca los valores cuando estos son alterados en el child component (CalendarioFechasVoluntaria)
    const { fechaPorDefectoDomiVol /*, fechaPrevistaEnvioVoluntaria, diaSeleccionado*/ } = useLoadFechaSeleccionadaVoluntaria({ tributos, diasVoluntaria, fechaDomiciliacionIncompatible });
    const { isOpenModalIncompatible } = useCheckFechaDomiciliable({ diasVoluntaria, fechaDomiciliacionIncompatible })
    const { domiciliacionesCompatibles, loadingDomiciliacionesCompatibles } = useLoadDomiciliacionesIncompatibles({ tributos, diaSeleccionado })
    // SERVICES 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // STATES
    const [loading, setLoading] = useState(false);
    const [plazos, setPlazos] = useState<IConvenioPlazos[]>()
    const [plazosError, setPlazosError] = useState(false);
    const [iban, setIban] = useState('');
    const [ibanError, setIbanError] = useState(false);
    const [nif, setNif] = useState('');
    const [nifError, setNifError] = useState(false);
    const [nombre, setNombre] = useState('');
    const [periodo, setPeriodo] = useState<number | string>('');
    const [nombreError, setNombreError] = useState(false);
    const [datosPrecargados, setDatosPrecargados] = useState(true);
    const [errors, setErrors] = useState<IErrorDomiciliacion | null>();
    const [tributosSimilares, setTributosSimilares] = useState<ITributoRelacionado[]>([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [openPC, setOpenPC] = useState(false);
    const [datosGB, setDatosGB] = useState<IDatosDomiciliacionGB | null>(null)
    const isMobile = window.innerWidth <= 768;
    // Fecha Prevista Envio Voluntaria
    const [isOpenModalRegistrosDiasVoluntaria, setIsOpenModalRegistrosDiasVoluntaria] = useState(false);
    const [isOpenCloseModalConservarDiaVoluntaria, setOpenCloseModalConservarDiaVoluntaria] = useState(false);
    const [opcionConservarFechaVoluntario, setOpcionConservarFechaVoluntario] = useState(false);
    // HEADER
    useSetHeader({
        title: domiciliables ? <Term component="Domiciliaciones" text="Alta domiciliación" /> : <Term component="Domiciliaciones" text="Modificación datos domiciliación" />,
        icon: mdiBank,
        menu: true,
        moreInfoTemplate: "mas_info_domiciliacion_alta",
        right: <MenuItem button onClick={() => handleShowMasInfo("mas_info_domiciliacion_alta")}><Term component="Global" text="Mas informacion" /></MenuItem>
    });

    useEffect(() => {
        setOpcionConservarFechaVoluntario(tributos.some(tributo => tributo.domiciliacion?.fechaPrevistaEnvio));
    }, [tributos]);

    useEffect(() => {
        precargarDatos();
        if (pagoCarta) {
            cargarPlazos();
        }
    }, [pagoCarta]);

    useEffect(() => {
        if (!tributos[0] || !tributos[0].domiciliacion) return;
        if (modifFechaVol && tributos.length > 0) setIban(tributos[0].domiciliacion.iban);
    }, [modifFechaVol]);

    useEffect(() => {
        setIbanError(errors && errors.iban ? errors.iban !== '' : false);
        setNifError(errors && errors.nif ? errors.nif !== '' : false);
        setNombreError(errors && errors.nombre ? errors.nombre !== '' : false);
        setPlazosError(errors && errors.plazo ? errors.plazo !== '' : false);
    }, [errors]);

    const handleCalendarioFechasVoluntariaComp = (fecha: string) => {
        setFechaPrevistaEnvioVoluntaria(fecha);
        setDiaSeleccionado(fecha);
    }

    const handleSetGB = (datosGb: IDatosDomiciliacionGB) => {
        setDatosGB(datosGb)
    }

    const handleSetIban = (ibanLocal: string) => {
        setIban(ibanLocal.toUpperCase());
        setErrors({ iban: '' });
        setIbanError(false);
    }
    const handleSetNif = (nifLocal: string) => {
        setNif(nifLocal);
        setErrors({ nif: '' });
        setNifError(false);
    }
    const handleSetNombre = (nombreLocal: string) => {
        setNombre(nombreLocal);
        setErrors({ nombre: '' });
        setNombreError(false);
    }

    const handlePeriodo = useCallback((value: unknown) => {
        setPeriodo(value as number)
        setErrors({ plazo: '' })
        setPlazosError(false)
    }, [])

    const cargarPlazos = async () => {
        const plazosData = await gateway.getConveniosRecibo(tributos[0].idRecibo)
        setPlazos(plazosData)
    }

    const cargarSimilaresPC = async () => {
        const tributosSimilares = await gateway.getSimilaresPC(tributos[0].idRecibo, periodo)
        setTributosSimilares(tributosSimilares)
    }

    const precargarDatos = async () => {
        if (pageState.jwp != null) {
            setNombre(pageState.jwp.fullname);
            setNif(pageState.jwp.nif);
        }
    }

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (datosPrecargados) {
            setNombre('');
            setNif('');
        } else {
            precargarDatos();
        }
        setDatosPrecargados(!datosPrecargados);
    };

    const validate = () => {
        if ((!iban || !ibantools.isValidIBAN(iban)) && !modifFechaVol) {
            setErrors({
                iban: <Term component='Domiciliaciones' text='El código IBAN es obligatorio' />,
            });
            return false;
        }
        if (!nif) {
            setErrors({
                nif: <Term component='Domiciliaciones' text='El NIF del titular es obligatorio' />,
            });
            return false;
        }
        if (!nombre) {
            setErrors({
                nombre: <Term component='Domiciliaciones' text='El nombre del titular es obligatorio' />
            });
            return false;
        }
        if (pagoCarta && !domiciliadoPc) {
            if (!periodo || periodo === '') {
                setErrors({
                    plazo: <Term component='Domiciliaciones' text='Seleccione una opcion plazos' />
                });
                return false;
            }
        }
        return true;
    }

    const handleClosePC = () => {
        setOpenPC(false)
        setDatosGB(null)
    }

    const handleContinuarCarrito = () => {
        if ((domiciliables || (!domiciliables && modifFechaVol)) && fechaPrevistaEnvioVoluntaria && validate()) {
            modifFechaVol
                ? handleModificarFechaVolutnariaDomiciliacion()
                : handleOpenCloseModalRegistrosDiasVoluntaria();
            return;
        }

        if (isGBIBAN()) {
            handleOpenCloseModal();
            return;
        }

        if (pagoCarta && !domiciliadoPc) {
            handleOpenPC();
            return;
        }

        if (pagoCarta) {
            modPagoCarta(null);
            return;
        }

        if (!validate()) return;

        opcionConservarFechaVoluntario
            ? handleCloseModalConservarDiasVoluntaria()
            : domiciliarPagoLocal();
    }

    const handlePagoCartaGBFin = (datosDireccionGB: IDatosDomiciliacionGB) => {
        setDatosGB(datosDireccionGB);
        handleOpenCloseModal();

        if (!domiciliadoPc) handleOpenPC();
        else modPagoCarta(datosDireccionGB);
    }

    const handleOpenPC = async () => {
        setLoading(true)
        if (validate() && await handleValidarPagoCarta()) {
            await cargarSimilaresPC()
            setOpenPC(true)
        }
        setLoading(false)
    }

    const isGBIBAN = useCallback(() => {
        const ibanCode = iban.slice(0, 2);

        if (ibanCode.toUpperCase() === 'GB') return true;
        return false;
    }, [iban]);

    const domiciliarPagoLocal = async (conservarFechaVoluntaria: boolean = false) => {
        try {
            setLoading(true);
            const result = await gateway.domiciliar({
                idRecibos: tributos.map(x => x.idRecibo),
                iban,
                nif,
                nombre,
                domiciliables,
                conservarFechaVoluntaria: domiciliables ? false : conservarFechaVoluntaria
            });
            setLoading(false);

            if (result.ok) {
                history.push({
                    pathname: '/cartero-virtual/domiciliaciones/domiciliables/confirmacion',
                    state: {
                        idOrden: result.entidad.idOrden,
                        tributos: result.entidad.tributos,
                        isSelectedAllActivated: resultadoSolicitud.isSelectedAllActivated,
                        domiciliables:false,
                        domiciliadoPc:false,
                        modifFechaVol: false,
                        pagoCarta: false,
                    }
                });
            } else {
                handleCheckResultados(result)
            }
        }
        catch (e) {
            setLoading(false);
            Sentry.captureException(e);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component='Domiciliaciones' text='Error inesperado' />,
                    variant: 'error',
                }
            });
        }
    };

    const handleOpenCloseModal = useCallback(() => {
        setIsOpenModal(!isOpenModal);
    }, [isOpenModal]);

    const handleCheckResultados = useCallback((result: IBaseResponse<IResultadoDomiciliacion>) => {
        switch (result.estado) {
            case 'iban':
                setErrors({
                    iban: <Term component='Domiciliaciones' text='El código IBAN no es válido' />,
                });
                break;
            case 'same-iban':
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component='Domiciliaciones' text='El código IBAN es el mismo' />,
                        variant: 'error',
                    }
                });
                break;
            case 'dni':
                setErrors({
                    nif: <Term component='Domiciliaciones' text='El DNI introducido no es válido' />,
                });
                break;
            case 'nie':
                setErrors({
                    nif: <Term component='Domiciliaciones' text='El NIE introducido no es válido' />,
                });
                break;
            case 'cif':
                setErrors({
                    nif: <Term component='Domiciliaciones' text='El CIF introducido no es válido' />,
                });
                break;
            case 'errorDocumento':
                setErrors({
                    nif: <Term component='Domiciliaciones' text='El documento introducido no es válido' />,
                });
                break;
            default:
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component='Domiciliaciones' text='No ha sido posible domiciliar los objetos tributarios seleccionados' />,
                        variant: 'error',
                    }
                });
                break;
        }
    }, [alertsDispatch, terms])

    const handleValidarPagoCarta = useCallback(async () => {
        const result = await gateway.valdiarPagoCarta({
            idRecibos: tributos.map(x => x.idRecibo),
            iban,
            nif,
            nombre,
            domiciliables,
            pagoCarta: true
        });

        if (!result.ok) {
            handleCheckResultados(result)
        }
        return result.ok
    }, [domiciliables, gateway, handleCheckResultados, iban, nif, nombre, tributos])

    const altaPagoCarta = useCallback(async (seleccionados: ITributoRelacionado[]) => {
        let convenio: IConvenioPlazos|undefined = plazos?.find(x => x.ID_CONV === periodo);
        const tributosToSend :ITributoRelacionado[]= [
            {
                idRecibo: tributos[0].idRecibo,
                DireccionTributaria: tributos[0].direccionTributaria,
                Obj: tributos[0].objeto,
                descri: tributos[0].concepto.nombre,
                idTipo: tributos[0].concepto.idTipo,
                muni: tributos[0].organismo.nombre,
                idMuni: Number(tributos[0].organismo.codigo)
            },
            ...seleccionados]

        history.push({
            pathname: '/cartero-virtual/domiciliaciones/domiciliables/pagoCartaAlta',
            state: {
                domiciliables: false,
                domiciliadoPc: false,
                isSelectedAllActivated: false,
                modifFechaVol: false,
                pagoCarta: true,
                tributos: tributos,
                pagoCartaInfo: {
                    tributos: tributosToSend,
                    convenio,
                    iban,
                    nombre,
                    nif,
                    isGB: datosGB !== null,
                    datosGB: datosGB
                } 
            }
        });
    }, [plazos, history, tributos, iban, nombre, nif, datosGB, periodo]);

    const modPagoCarta = useCallback(async (datosDireccionGB: IDatosDomiciliacionGB | null) => {
        if (!validate()) return;

        const isValid = await handleValidarPagoCarta();
        if (!isValid) return;

        try {
            setLoading(true);
            let body: IPagoCartaMod = {
                idRecibos: tributos.map((x) => {
                    return x.idRecibo
                }),
                iban: iban,
                isGB: datosDireccionGB !== null,
                datosGB: datosDireccionGB
            }

            const result = await gateway.modPagoCarta(body);

            if (!result.ok && result.mensaje === "IBAN repetido") throw new Error(result.mensaje);
            if (!result.ok) throw new Error;

            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component='Domiciliaciones' text='Modificado pago a la carta' />,
                    variant: 'success',
                }
            });
            history.push('/cartero-virtual/domiciliaciones/domiciliados/');
        }
        catch (e: any) {
            Sentry.captureException(e);

            if (e.message === "IBAN repetido") {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component='Domiciliaciones' text='Error IBAN repetido' />,
                        variant: 'error',
                    }
                });
                return;
            }

            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component='Domiciliaciones' text='Error inesperado' />,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datosGB, gateway, iban, tributos])

    const handleShowMoreInfoPlazosPC = useCallback(() => {
        infoDispatch({
            type: 'show-info',
            payload: {
                templateName: 'mas_info_plazos_pago_carta',
                size: 'lg'
            }
        });
    }, [infoDispatch]);

    const handleDomiciliarDomiVoluntaria = async (domisSeleccionadas: IDomiciliacionCompFVol[]) => {
        try {
            const idRecibosList: number[] = [];
            tributos.forEach(tributo => idRecibosList.push(tributo.idRecibo));
            domisSeleccionadas.forEach(domi => idRecibosList.push(domi.idRecibo));

            if (!modifFechaVol) handleOpenCloseModalRegistrosDiasVoluntaria();
            if (!validate()) return;

            setLoading(true);
            const result = await gateway.domiciliar({
                idRecibos: idRecibosList,
                iban,
                nif,
                nombre,
                domiciliables,
                fechaPrevistaEnvioVoluntaria,
                modifFechaVol
            });
            setLoading(false);

            if (result.ok) {
                
                history.push({
                    pathname: '/cartero-virtual/domiciliaciones/domiciliables/confirmacion',
                    state: {
                       domiciliables:false,
                       domiciliadoPc:false,
                       isSelectedAllActivated: result.entidad.isSelectedAllActivated,
                       modifFechaVol: false,
                       pagoCarta: false, 
                       tributos:result.entidad.tributos,
                       idOrden: result.entidad.idOrden,
                    }

                });
            } else {
                handleCheckResultados(result)
            }
        }
        catch (e) {
            setLoading(false);
            Sentry.captureException(e);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component='Domiciliaciones' text='Error inesperado' />,
                    variant: 'error',
                }
            });
        }
    };

    const handleModificarFechaVolutnariaDomiciliacion = async () => {
        try {
            if (!tributos[0] || !tributos[0].domiciliacion) return;
            if (!fechaPrevistaEnvioVoluntaria) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component='Domiciliaciones' text='' />,
                        variant: 'warning',
                    }
                });
                return;
            }
            if (fechaPorDefectoDomiVol === moment(fechaPrevistaEnvioVoluntaria).format('YYYY-MM-DD')) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component='Domiciliaciones' text='Warning cambio fecha voluntaria' />,
                        variant: 'warning',
                    }
                });
                return;
            }
            if (tributos.length > 1) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component='Domiciliaciones' text='Warning selección de más de un recibo para fecha voluntaria' />,
                        variant: 'warning',
                    }
                });
                return;
            }

            setLoading(true);
            const result = await gateway.updateFechaVoluntariaDomiciliacion({
                idTributo: tributos[0].idRecibo,
                idOrden: tributos[0].domiciliacion.idDomiciliacion,
                fechaVoluntaria: fechaPrevistaEnvioVoluntaria
            });
            setLoading(false);

            if (result.ok) {
                history.push({
                    pathname: '/cartero-virtual/domiciliaciones/domiciliables/confirmacion',
                    state: { 
                        domiciliables:false,
                        domiciliadoPc:false,
                        isSelectedAllActivated: result.entidad.isSelectedAllActivated,
                        modifFechaVol: false,
                        pagoCarta: false, 
                        tributos:result.entidad.tributos,
                        idOrden: result.entidad.idOrden,
                    }
                });
            } else {
                handleCheckResultados(result)
            }
        }
        catch (e) {
            setLoading(false);
            Sentry.captureException(e);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component='Domiciliaciones' text='Error inesperado' />,
                    variant: 'error',
                }
            });
        }
    }

    const handleOpenCloseModalRegistrosDiasVoluntaria = useCallback(() => {
        setIsOpenModalRegistrosDiasVoluntaria(!isOpenModalRegistrosDiasVoluntaria);
    }, [isOpenModalRegistrosDiasVoluntaria]);

    const handleVisibilidadCarrito = (): boolean => {
        if (!domiciliables && modifFechaVol && (!diasVoluntaria || diasVoluntaria.dias.length < 1)) {
            return false;
        }
        else if (tributos.length > 0) {
            return true;
        }
        return false;
    }

    const handleCloseModalConservarDiasVoluntaria = useCallback(() => {
        setOpenCloseModalConservarDiaVoluntaria(!isOpenCloseModalConservarDiaVoluntaria);
    }, [isOpenCloseModalConservarDiaVoluntaria]);

    const handleRedirect = () => {
        history.goBack();
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.marginAlta}>
                    <Card>
                        <CardContent>
                            <div className={classes.rootCard}>
                                <Typography className={[classes.titleTextCard, classes.margin15].join(' ')}>
                                    <Term component="Domiciliaciones" text="Datos de la domiciliación" />
                                </Typography>
                                 <Grid container direction='column' spacing={1} style={{margin: 15}}>
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <TextField
                                            id="iban"
                                            label="IBAN "
                                            fullWidth
                                            required
                                            focused={!modifFechaVol}
                                            variant='outlined'
                                            margin='dense'
                                            value={iban }
                                            error={ibanError}
                                            disabled={modifFechaVol}
                                            helperText={ ibanError && errors 
                                                ? errors.iban  
                                                : <Term component='Domiciliaciones' text='LongitudIBAN' /> }
                                            onChange={(event) => handleSetIban(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction='row' alignItems='center' spacing={2}>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <TextField
                                                    id="nifTitular"
                                                    variant='outlined'
                                                    margin='dense'
                                                    fullWidth
                                                    label={<Term component='Domiciliaciones' text='NIF titular required' />}
                                                    value={nif}
                                                    error={nifError}
                                                    helperText={ nifError ? errors?.nif : undefined}
                                                    disabled={datosPrecargados}
                                                    
                                                    onChange={(event) => handleSetNif(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <TextField
                                                    id="nombreTitular"
                                                    variant='outlined'
                                                    margin='dense'
                                                    fullWidth
                                                    label={<Term component='Domiciliaciones' text='Nombre titular required' />}
                                                    value={nombre}
                                                    error={nombreError}
                                                    disabled={datosPrecargados}
                                                    
                                                    onChange={(event) => handleSetNombre(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                {!modifFechaVol &&
                                                    <FormControlLabel
                                                        className={classes.margin0}
                                                        control={
                                                            <Checkbox
                                                                checked={datosPrecargados}
                                                                onChange={handleChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<Term component="Domiciliaciones" text="Datos titular" />}
                                                    />
                                                }
                                            </Grid>                                            
                                        </Grid>
                                    </Grid>
                                    
                                </Grid> 
                            </div>
                            {
                                pagoCarta && !domiciliadoPc ?
                                    <>
                                        <div className={classes.containerPlazosPC}>
                                            <Typography className={[classes.titleTextCard, classes.margin15].join(' ')}>
                                                <Term component="Domiciliaciones" text="Opciones segun convenio del municipio de" />{" " + tributos[0].organismo.nombre}
                                            </Typography>
                                            <Button variant="outlined" color="primary" className={classes.buttonInfoPC} onClick={handleShowMoreInfoPlazosPC} size="small">
                                                <Term component="Global" text="Más información" />
                                            </Button>
                                        </div>
                                        <div style={{margin: 15}} >
                                        <FormControl className={[classes.selectForm].join(' ')}>
                                            <InputLabel>
                                                <Term component="Global" text="Opciones" />
                                            </InputLabel>
                                            <Select
                                                id="provincia-select"
                                                value={periodo}
                                                onChange={(event) => handlePeriodo(event.target.value)}
                                                error={plazosError}
                                            >
                                                {plazos?.map((plazo) => {
                                                    return (
                                                        <MenuItem key={plazo.ID_CONV} value={plazo.ID_CONV} >
                                                            <p style={isMobile
                                                                ? { maxWidth: window.innerWidth, whiteSpace: 'normal' }
                                                                : undefined}
                                                            >
                                                                {plazo.PLAZOS}
                                                            </p>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {errors && errors.plazo 
                                                ? <span className={[classes.danger, classes.margin15].join(' ')}>{errors.plazo}</span>
                                                : null
                                            }
                                        </FormControl>
                                        </div>
                                    </>
                                    : null
                            }

                        </CardContent>
                    </Card>
                </div>

                {
                    (!pagoCarta && !domiciliadoPc && tributos.length <= 1 && (modifFechaVol || domiciliables)) && !isLiquidacionInTributos &&
                    <CalendarioFechasVoluntaria
                        tributos={tributos}
                        fechaDomiciliacionIncompatible={fechaDomiciliacionIncompatible}
                        onDateVoluntariaChange={handleCalendarioFechasVoluntariaComp}
                    />
                }

                <div className={classes.fixedBottom}>
                    <CarritoDomiciliaciones
                        items={tributos}
                        visible={handleVisibilidadCarrito()}
                        literal={"Continuar"}
                        alta={true}
                        loading={loading}
                        onSelect={handleContinuarCarrito}
                    />
                </div>
            </div>
            <div className={classes.drawer}>
                <Hidden smDown implementation="css">
                    <PanelTributos tributos={tributos} />
                </Hidden>
            </div>

            <DialogDomiciliacionAltaGreatBritain
                open={isOpenModal}
                history={history}
                tributos={tributos}
                iban={iban}
                nif={nif}
                nombre={nombre}
                domiciliables={domiciliables}
                isPagoCarta={pagoCarta}
                onClose={handleOpenCloseModal}
                setDatosGB={handleSetGB}
                handlePagoCartaGBFin={handlePagoCartaGBFin}
            />

            <DialogSeleccionRegistrosPorFechaVoluntaria // TODO - REVISAR estilos
                open={isOpenModalRegistrosDiasVoluntaria}
                domiciliacionesCompatibles={domiciliacionesCompatibles}
                loadingDomiciliacionesCompatibles={loadingDomiciliacionesCompatibles}
                onContinuar={handleDomiciliarDomiVoluntaria}
                onClose={handleOpenCloseModalRegistrosDiasVoluntaria}
            />

            <DialogCheckConservarDiaVoluntaria 
                open={isOpenCloseModalConservarDiaVoluntaria}
                onClose={domiciliarPagoLocal}
                onCloseModal={handleCloseModalConservarDiasVoluntaria}
            />

            <DialogDomiciliacionIncompatibleModal
                open={isOpenModalIncompatible}
                onCloseModal={handleRedirect}
            />

            <PagoCartaDetail
                datosDomi={{
                    idRecibos: [tributos[0].idRecibo],
                    iban,
                    nif,
                    nombre,
                    domiciliables,
                } as IDatosDomiciliacion}
                tributo={tributos[0]}
                tributosRelated={tributosSimilares}
                open={openPC}
                onClose={handleClosePC}
                continuar={altaPagoCarta}
            />
        </>
    );
}

export default DomiciliacionAlta;