import React, { useMemo } from 'react';
import imageLogo from '../../resources/logo.png';
import { Paper, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { BLUE_BORRADOR } from 'containers/shared/colors';
import { translate } from "utils/i18n"
import { renderApartado, renderThreeColumns, renderTwoColumns } from './shared';
import HeaderJustificanteBorrador from 'components/DUTI/header-justificante-borrador';
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';

type TApartadosM080 = 'MXX_D_PLUSV'| 'M080_A'|'M080_B'|'M080_C'|'M080_D'|'M080_E'|'M08X_E_OP';

type TApartadosCALC ={ calculo: TValuesMxx, desglose: {operacion: TValuesMxx , objetivo: TValuesMxx, real: TValuesMxx }[], liquidacion: TValuesMxx}
type TApartadosM081 = 'M081_A'|'M081_B'|'M081_C'|'MXX_D_PLUSV'|'M081_D'|'M081_E'|'M081_E_OP';
type TApartadosM082 = 'MXX_D_PLUSV'| 'M082_A' |'M082_D';

type TValuesMxx =  {term: string, value: any, casilla: number| null, fullWidth?:boolean }[]

export type TBorradorModelo080 = Record<TApartadosM080,TValuesMxx|TValuesMxx[]> 
export type TBorradorModelo081 =  Record<Exclude<TApartadosM081,'M081_E_OP'>,TValuesMxx|TValuesMxx[]> &  { M081_E_OP : TApartadosCALC }
// export type TBorradorModelo081 =  Record<TApartadosM081,TValuesMxx|TValuesMxx[]>
export type TBorradorModelo082 =   Record<TApartadosM082,TValuesMxx>

type TModelo = '080'| '081'| '082'

/** Render from ARRAY */
export const renderM08x = <T extends TModelo>(
    M08x:  T extends '081' 
            ? TBorradorModelo081[] 
            : T extends '080' 
                ? TBorradorModelo080[] 
                : TBorradorModelo082[], 
    modelo: TModelo ,
    terms:any, 
    componentTerms: string 
) => {
    

    return(
        /**Render ARRAY modelos */
        M08x.map( (item_modelo, idx) => (
            <Paper elevation={5} style={{margin: 20, paddingBottom: 60}}>
                <Grid container direction='column'  style={{padding: 20}}>
                    { M08x.length > 1 && 
                        <Grid item container direction='row' justify='flex-end'>
                            {/* <Typography style={{fontSize: 15, marginLeft: 30, fontWeight: 'bolder'}}>{`${idx+1}/${M08x.length}`}</Typography> */}
                            <Typography style={{fontSize: 15, marginLeft: 30, fontWeight: 'bolder'}}>{`${translate(componentTerms,'borr_modelo_declarante',terms)} ${idx+1}`}</Typography>
                        </Grid>
                    }
                    <HeaderJustificanteBorrador
                        componentTerms={componentTerms}
                        docTypeTerm="borr_justif_dusti"
                        terms={terms}
                        titleTerm={`borr_M${modelo}_title`}
                    />
                    {/* <Grid item container direction='row' alignItems='center'>
                        <Grid item xs={2} style={{paddingLeft: 20}} > {/**Logo atib /}
                            <img width={180} height={'auto'} src={imageLogo} alt='logo' />
                        </Grid>
                        <Grid item xs={2}>{/**Tipo modelo /}
                            <Typography style={{fontSize: 20, alignSelf: 'center'}}>{translate(componentTerms,'borr_modelo',terms)} {modelo}</Typography>
                        </Grid>
                        <Grid item xs={8} container direction='column'> {/**Tipo modelo descripcion/}
                        { translate(componentTerms,`borr_M${modelo}_title`,terms).split('/').map( val => (
                            val !== '/' && 
                            <Typography style={{fontSize: 19, marginLeft: 30, fontWeight: 'bolder'}}>{val}</Typography>)
                        )}
                        </Grid>
                    </Grid> */}
                    <Grid item container direction='column'>
                        { (Object.keys(item_modelo) as (keyof typeof item_modelo)[]).map( (apartado) => {  
                            return(
                                <Grid item>
                                { item_modelo[apartado] !== null &&
                                        <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',marginTop: 10, padding: '2px 5px 2px 5px',display:'flex', flex:1}}>
                                            {translate(
                                                'DUTI',
                                                !apartado.includes('PLUSV') && ['A', 'B', 'C','D'].includes(apartado.split('_')[1]) 
                                                    ? `M08X_${apartado.split('_')[1]}_title`
                                                    : `${apartado}_title`,
                                                terms
                                            ).toUpperCase()}
                                        </Typography>
                                    }
                                    { [
                                        "MXX_D_PLUSV",
                                        "M081_A", "M081_B", "M081_C", "M081_D",
                                        "M080_A", "M080_B", "M080_C","M080_D",
                                        "M082_A", "M082_D", 
                                    ].includes(apartado) 
                                        && item_modelo[apartado] !== null && 
                                        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                            {renderApartado(item_modelo[apartado] as {term: string, value:any, casilla: number}[], modelo,terms, componentTerms)}
                                        </div>
                                    }
                                    
                                    {["M081_E","M080_E"].includes(apartado) && 
                                        <Grid item container direction='column'>
                                            {renderApartado(item_modelo[apartado] as {term: string, value:any, casilla: number}[], modelo,terms, componentTerms)}
                                        </Grid>
                                    }

                                    {["M08X_E_OP"].includes(apartado) && 
                                        <Grid item container direction='column'>
                                           {item_modelo[apartado].map( op => (
                                                <Grid item container direction='column'>
                                                    {renderThreeColumns(op as {term: string, value:any, casilla: number}[], modelo,terms, componentTerms, false)}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    }
                                   

                                    {'M081_E_OP' in item_modelo && ["M081_E_OP"].includes(apartado) && 
                                        <Grid item container direction='column'>
                                                <Grid item container direction='column' style={{borderColor: '#8fbae1',borderBottomStyle:'solid'}}>
                                                    <Grid item>
                                                        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                            {renderApartado(item_modelo["M081_E_OP"].calculo as TValuesMxx, modelo,terms, "DUTI") }
                                                        </div>
                                                    </Grid>
                                                    {item_modelo["M081_E_OP"].desglose.length>0 && 
                                                    <Grid item style={{backgroundColor: '#e0eaf4',borderRadius: 5, marginTop:10 , marginLeft: 25, marginBottom:5, padding: 5}}>
                                                        <Typography style={{paddingRight: 5 ,paddingLeft: 5}}>
                                                            {translate('Plusvalias','operaciones',terms).toUpperCase()}
                                                        </Typography>
                                                    
                                                        {item_modelo["M081_E_OP"].desglose.map( (desgl, idx) => (
                                                            <Grid item style={{borderColor: '#8fbae1',borderTopStyle:'solid', marginTop: 5}}>
                                                                <div style={{paddingTop: 5}}>
                                                                    {renderThreeColumns(desgl.operacion as TValuesMxx, modelo,terms, "DUTI", false)}
                                                                </div>
                                                                <div style={{paddingLeft: 15, marginTop: 3}}>
                                                                    <div style={{ paddingBottom: 5}}>
                                                                        <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',paddingLeft: 5}}>
                                                                            {translate('Plusvalias','metodoObjetivo',terms)}
                                                                        </Typography>
                                                                        {renderThreeColumns(desgl.objetivo as TValuesMxx, modelo,terms, "DUTI", false)}
                                                                    </div>
                                                                    { desgl.real.length>0 && 
                                                                    <div style={{ paddingBottom: 5}}>
                                                                        <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',paddingLeft: 5 }}>
                                                                            {translate('Plusvalias','metodoReal',terms)}
                                                                        </Typography>
                                                                        {renderThreeColumns(desgl.real as TValuesMxx, modelo,terms, "DUTI", false)}
                                                                    </div>}
                                                                </div>
                                                                
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    }
                                                    {/* {item_modelo["M081_E_OP"].liquidacion.length>0 && 
                                                    <Grid item style={{backgroundColor: '#e0eaf4',borderRadius: 5, marginTop:10 , marginLeft: 25, marginBottom:5 ,padding: 5}}>
                                                        <Typography style={{paddingRight: 5 ,paddingLeft: 5}}>
                                                            {translate('Plusvalias','liquidacion',terms).toUpperCase()}
                                                        </Typography>
                                                        <div style={{paddingRight: 5 ,paddingLeft: 5, paddingBottom: 5 }}>
                                                            {renderTwoColumns(item_modelo["M081_E_OP"].liquidacion as TValuesMxx, modelo,terms, "DUTI", false) }
                                                        </div>
                                                    </Grid>
                                                    } */}
                                                </Grid>
                                        </Grid>

                                    }
                                   
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Paper>
        ))
    )
}