import React, { FC, useContext } from 'react';
import { Grid, makeStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, useTheme, Divider, FormControl } from "@material-ui/core";
import { EstablecimientoWithEjercicio } from 'gateways/tramites.interfaces';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import LabelField from 'components/label-field';
import moment from 'moment';
import Term from 'components/term';
import { TipoEstablecimiento } from '../../values.enum';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';

const useStyles = makeStyles((theme) => ({
    rounded: {
        borderRadius: 8,
        margin: '16px 0'
    },
    main: {
        width: '90%',
        marginLeft: '5%'
    },
    botonIdentificacion: {
        display: 'flex',
        alignItems: 'center',
    }
}));

interface IProps {
    establecimiento: EstablecimientoWithEjercicio;
    children: any;
}


const EstablecimientoGrid: FC<IProps> = ({ establecimiento, children }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const terms = useContext(LiteralsContext);


    /* FUNCIONES */
    const handleChange = () => {
        const expandedTemp = expanded;
        setExpanded(!expandedTemp);
    }


    const handleEstEnum = (key: string) => {
        if (key in TipoEstablecimiento) {
            return translate("Global", TipoEstablecimiento[key as keyof typeof TipoEstablecimiento], terms)
        }
    }





    return (
        <>
            <ExpansionPanel className={[classes.rounded].join(' ')} square expanded={expanded} onChange={handleChange}>
                <ExpansionPanelSummary
                    aria-controls="panel-multa-content"
                    id="panel-multa-header"
                    expandIcon={
                        <Icon path={mdiChevronDown}
                            size={1}
                            color='#000'
                        />
                    }
                >

                    <Grid container>
                        <Grid item xs={12} sm={1}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="matricula" />}
                                    text={establecimiento.matricula}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="DenominaciónComercial" />}
                                    text={establecimiento.denominacionComercial}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="FechaAlta" />}
                                    text={establecimiento.fechaAlta ? moment(establecimiento.fechaAlta).format("DD-MM-YYYY") : "--"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="TipoEstablecimiento" />}
                                    text={handleEstEnum(establecimiento.tipoEstablecimiento)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="NoPlazas" />}
                                    text={establecimiento.plazasEstablecimiento}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </ExpansionPanelSummary>
                <Divider
                    className={classes.main} />
                <ExpansionPanelDetails>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </>
    );
}

export default EstablecimientoGrid;