import { IDomiciliacion } from "./tributos.interfaces";
import { IFilterOption } from "utils/interfaces";
import { ISujeto } from "./perfil.interfaces";
import { IConcepto } from "./concepto.interfaces";
import { IOrganismo } from "./organismo.interfaces";

export interface IDesglose {
    principal: number;
    recargo: number;
    intereses: number;
    costas: number;
    pendiente: number;
    pagado: number;
}

export enum SituacionCobroEnum {
    Cobrable = 1,
    Domiciliado = 2,
    Ejecutiva = 3,
}

export interface IDatosPlazos {
    FECHA: Date
    PLAZO: string
    REFBANC: string
    IBAN: string
    IMPORTE: number
    ESTADO: 'P' | 'E' | 'D'
}

export interface IDatosTotal {
    DENVIOS: string;
    ENVIOS: number;
    DIMPORTE: string;
    IMPORTE: number;
    DBONIF: string;
    BONIF: string;
    DPEND: string;
    PEND: number;
}

export interface IDatosDeudaPagoCarta {
    plazos: IDatosPlazos[]
    Bonif: number;
    proximo: number
    total: IDatosTotal
}

export interface IRecibo {
    fechaPrevistaCargo: string | null;
    id: number;
    referencia: string;
    sujeto: ISujeto;
    organismo: IOrganismo;
    direccionTributaria: string;
    concepto: IConcepto;
    descripcion: string;
    fechaInicio: Date;
    fechaFin: Date | null;
    desglose: IDesglose;
    ejercicio: string;
    domiciliacion: IDomiciliacion;
    situacionCobro: SituacionCobroEnum;
    periodo: string;
    estadoEtiqueta: string;
    objeto: string;
    rehabilitado: boolean;
    fechaDevolucion?: Date | null;
    concursalVigente: boolean;
    pagoCarta?: IPagoCartaDto;
    fPago: Date;
    domiciliable: boolean;
}

export interface IFilterData {
    conceptos?: Array<IFilterOption<number>>;
    ejercicios?: Array<IFilterOption<number>>;
    situaciones?: Array<IFilterOption<string>>;
    organismos?: Array<IFilterOption<string>>;
    modelos?: Array<IFilterOption<string>>;
    objTributario?: IFilterOption<string>;
    dirTributaria?: IFilterOption<string>;
    fPago?: IFilterOption<Date>;
}

export interface IFindAllQuery {
    pendiente?: boolean;
    skip?: number;
    take?: number;
    concepto?: number[];
    ejercicio?: number[];
    situacion?: string[];
    municipio?: string[];
    domiciliados?: boolean;
    domiciliables?: boolean;
    domiciliadosPC?: boolean;
}

export interface IDatosIngreso {
    emisora: string;
    referencia: string;
    identificacion: string;
    importe: number;
    idReferenciaGrupo: string | null;
}

export interface IDatosSancionEIngreso {
    datosIngreso: IDatosIngreso;
    datosSancion: InfoSancionCompleta;
}

export interface IPagoCartaDto {
    DomiciliablePC: boolean;
    DomiciliadoPC: boolean;
    Ejercicio: string;
}

export interface IDetalleRecibo {
    idRecibo: number;
    Muni: string;
    Peri: string;
    ConC: string;
    NRec: string;
    Refe: string;
    Ref1: string;
    idCargoD: number;
    idSujeto: number;
    DF_Nif: string;
    DF_Nom: string;
    DF_Siglas: string;
    DF_Dom: string;
    DF_Pob: string;
    DF_NUM: string;
    DF_ESC: string;
    DF_PIS: string;
    DF_PTA: string;
    DF_BLO: string;
    Obj: string;
    Estado: string;
    SitEsp: string;
    Importe: number;
    FFinVol: Date;
    FInicio: Date;
    Ntit: number;
    ImporteREC: number;
    DomBANC: string;
    DomCTA: string;
    idOrdenDomi: number;
}

export interface IResultPagosAcuenta {
    id: number;
    idEnv: number | null;
    idOrden: number;
    importe: number;
    fAlta: string;
    fechaEnvio: string;
}

export interface IPreciodadImporte {
    fecha: string;
    htmlItem: string | undefined;
    importe: string;
}

export interface MultaInfo {
    ImportePendiente: number;
    NumeroBoletin: string;
    DeudorNif: string;
    DeudorNombre: string;
    IdMunicipio: number;
    Municipio: string;
    DescripcionES: string;
    DescripcionCA: string;
}

export interface ReciboMulta {
    LugarDenuncia: string;
    FechaMulta: Date;
    Gravedad: string;
    Matricula: string;
    Marca: number;
    Modelo: string;
    Articulo: string;
}

export interface InfoSancionCompleta {
    Multa: MultaInfo;
    Recibo: ReciboMulta;
}

export interface TipoIdentificador {
    redirect: number;
    data: any;
}

export interface IReciboDetallePagoBonificado {
    importeRecibo: number;
    importeDomiciliado: number;
    importeBonificado: number;
    porcentajeBonificacion: number
}