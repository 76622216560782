import {FC, useContext, useEffect, useMemo, useState} from "react";

import { Button, Grid, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core";


import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { translate } from "utils/i18n";


import IoC from "contexts/ioc.context";


import { TExtraValidation } from "components/DUTI/types";


import FullLoading from "components/full-loading";
import InputDate from "components/DUTI/input-date";
import InputSelect from "components/DUTI/input-select";
import TypedInputNumber from "components/DUTI/typed-input-number";
import InputText from "components/DUTI/input-text";
import InputSearch from "components/DUTI/input-search";
import ModalNotarios from "components/DUTI/modal-notarios";
import { getTiposDocTransmsisionByTipoAsistente, getTiposTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import { TipoAsistente } from "containers/tributos-autonomicos-locales/utils";
import { TSelect } from "containers/DUTI/utils";
import ModalSearchByNrd, { IInfo } from "./modalSearchByNrd";
import AttachFileComponent from "components/attach-file-component";
import { IDocumentoAtib } from "gateways/documentos.interface";

import { TInfoTransmPlusvalia } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import CheckBoxGroup from "components/DUTI/check-box-group";
import { TTipoInmueble } from "gateways/catastro.interface";
import ModelGateway from "gateways/model.new.gateway";




type TInfoTransmisionFlags = Record<keyof TInfoTransmPlusvalia, boolean>

const EMPTY_ERRORS : TInfoTransmisionFlags = {
    tipoDocTransmision: false,
    valorDeclarado: false,
    porcentaje: false,
    fecha: false,
    anyo: false,
    numeroProtocolo: false,
    notario: false,
    idDoc: false,
    docDescripcion: false,
    nrd: false,
    infoRegistral: false,
    exento: false,
    noSujeto: false,
    observaciones: false,
    motivo: false,
    fechaDoc: false
}
interface IInfoTransmisionProps extends WithStyles<typeof styles> {
    tipoAsistente: TipoAsistente;
    tipoTransmision: TSelect|undefined
    tipoTransmisionBoni: number |undefined
    tipoTransmisionDescrip: string | undefined
    initialValues: TInfoTransmPlusvalia;
    idTipoApartado: string, 
    idReferencia: string,
    motivoRU: TSelect | undefined,
    noSujOPTIONS: TSelect[]
    exentoOPTIONS: TSelect[]
    onChange:(value: TInfoTransmPlusvalia) => void;
    doc: IDocumentoAtib |'notFound' | undefined;
    onChangeDoc: (d: IDocumentoAtib |'notFound' | undefined) => void;
    tipoBien?: TTipoInmueble
    componentIG?:string,
    origenDocumento: number,
    onChangeOrigenDoc: (v: number) => void,
    setShowMsgNoEncontrado: (v: boolean) => void,
}
const InfoTransmisionForm: FC<IInfoTransmisionProps> = ({ 
   classes, tipoAsistente, tipoTransmision, tipoTransmisionBoni, tipoTransmisionDescrip, 
   initialValues, idTipoApartado, idReferencia, motivoRU, noSujOPTIONS, exentoOPTIONS,
   onChange, doc, onChangeDoc, tipoBien, componentIG, origenDocumento, onChangeOrigenDoc, setShowMsgNoEncontrado
}) => {
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway),[ioc]);
    
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<TInfoTransmisionFlags>(EMPTY_ERRORS)
   // const [localDoc, setLocalDoc] = useState<IDocumentoAtib |'notFound' | undefined>(undefined);
    const [showModalNotarios, setShowModalNotarios]= useState(false)
    const [showModalNRD, setShowModalNRD] = useState(false)
    const [tiposTransmision, setTiposTransmision] = useState<TSelect[]>([]);



    const disabledExenciones = useMemo(() => {
        if(tipoBien && tipoBien === 'RU') return true 
        return false
    }, [tipoBien])
    
    /**Format SELECT options */    
    const fieldTipoDocTransmision_OPTIONS = getTiposDocTransmsisionByTipoAsistente(tipoAsistente, terms)
    const fieldMotivo_OPTIONS = useMemo(() => {
       if (!initialValues.noSujeto && !initialValues.exento){
            return []
       } else {
            return initialValues.noSujeto? noSujOPTIONS : exentoOPTIONS
       }
    },[initialValues.noSujeto, initialValues.exento,noSujOPTIONS ,exentoOPTIONS])

    const updateField =  <K extends keyof TInfoTransmPlusvalia>(key: K, value: TInfoTransmPlusvalia[K], clean: (keyof TInfoTransmPlusvalia)[]) => {
        let newVal= {...initialValues, [key]: value}
        clean.forEach(k => {
            newVal={...newVal, [k]: undefined}
            
        });
        /**Check if clean doc => adjuntado por ususario o escritura buscada  */
        if( clean.includes("idDoc") ){            
            onChangeDoc(undefined);            
        }
        if(!newVal.exento && !newVal.noSujeto){
            setErrors(prev => ({...prev, observaciones: false}))
        }
        onChange({...newVal})
    };

    const validateNumProtocolo = ( num: string) => {
        let result: TExtraValidation={ error: false, error_msg: undefined}
        const numN = Number(num)
        if(isNaN(numN)){
            result={error: true, error_msg: translate('DUTI', 'numeroProtocolo_error_num',terms)}
        } else{
            if(numN > 99999){
                result={error: true, error_msg: translate('DUTI', 'numeroProtocolo_error',terms)}
            }
        }
        
       return result
    }

    useEffect(() => {
        (async() => {
            const tiposTransmision = await modelGateway.getTiposTransmision();
            setTiposTransmision(getTiposTransmsisionByTipoAsistente(tiposTransmision, terms));
        })();
    }, []);
    
    useEffect(() => {
        if(tipoBien && tipoBien ==="RU" && !initialValues.exento){
            onChange({...initialValues, exento: true , motivo: motivoRU})
        }
    }, [tipoBien, exentoOPTIONS, motivoRU])

    return (
        <Grid container direction='column' className={classes.componenteContainer}>
            <FullLoading loading={ loading }  />       

            <Grid item container direction="row" alignItems="center">
                <Grid item xs={6} md={6} lg={3} >
                    <InputDate 
                        componentIG={componentIG??'Plusvalias'}
                        fieldTerm="fecha"
                        value={initialValues?.fecha}
                        onChangeValue={(v: Date| undefined, err:boolean) =>{ 
                            updateField('fecha',v, ['anyo'])
                            setErrors(prev => ({...prev, fecha: err}))
                        }}
                        maxDate={ new Date() }
                        disabled={false}
                        required={true}
                        error={errors.fecha}
                        className={classes.inputFullWidth}
                    />
                </Grid>
                <Grid item xs={6} md={6} lg={2} >
                    <InputSelect 
                        componentIG={'Global'}
                        fieldTerm='tipoTransmision'
                        value={tipoTransmision}
                        options={tiposTransmision}
                        onChangeValue={(v: TSelect, err: boolean) => {}}
                        disabled
                        required
                        className={classes.inputFullWidth}
                    />
                </Grid>
                    {(tipoTransmisionBoni ||tipoTransmisionDescrip ) && 
                        <Grid item xs={6} md={6} lg={1}>
                            <InputText
                                componentIG={componentIG ??'Plusvalias'}
                                fieldTerm={tipoTransmisionBoni ? 'bonificacion': 'tipoTransmisionDescripcion'}
                                value={tipoTransmisionBoni ?? tipoTransmisionDescrip}
                                onChangeValue={(v:string|undefined, err: boolean) =>{}}
                                disabled
                                className={classes.inputFullWidth}
                                //className={classes.inputSmall}
                            />
                        </Grid>
                    }
              

                <Grid item xs={6} md={6} lg={3}>
                    <InputSelect 
                        componentIG={'Global'}
                        fieldTerm='tipoDocTransmision'
                        value={initialValues?.tipoDocTransmision}
                        options={fieldTipoDocTransmision_OPTIONS}
                        onChangeValue={(v: TSelect, err: boolean) => {                            
                            updateField('tipoDocTransmision', v, ['anyo', 'numeroProtocolo','notario','idDoc','docDescripcion','nrd','fechaDoc']);
                            onChangeOrigenDoc(v.id === 'publico-notarial' ? 1 : 2);
                            setErrors(prev => ({...prev, tipoDocTransmision: err }))
                        }}
                        required
                        className={classes.inputFullWidth}
                    />
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <InputDate 
                        componentIG={'Plusvalias'}
                        fieldTerm="fechaDoc"
                        value={initialValues?.fechaDoc}
                        onChangeValue={(v: Date| undefined, err:boolean) =>{ 
                            updateField('fechaDoc',v, [])
                            setErrors(prev => ({...prev, fecha: err}))
                        }}
                        maxDate={ new Date() }
                        disabled={false}
                        required={true}
                        error={errors.fecha}
                        className={classes.inputFullWidth}
                    />
                </Grid>
            </Grid>

            {initialValues && initialValues.tipoDocTransmision !== undefined &&
                <Grid item container direction="column" >
                    {initialValues.tipoDocTransmision.id === 'publico-notarial' &&
                        <Grid item container direction="row" alignItems="center">
                            <Grid item xs={6} md={6} lg={3}>
                                <TypedInputNumber
                                    type="year"
                                    fieldTerm={'anyo'}
                                    value={initialValues?.anyo}
                                    onChangeValue={(v:number|undefined, err:boolean) =>{ 
                                        updateField('anyo',v,['idDoc', 'nrd'])
                                        setErrors(prev => ({...prev, anyo:err}))
                                    }}
                                    error={errors.anyo}
                                    required
                                    className={classes.inputFullWidth}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>
                                <InputText
                                    componentIG={componentIG ??'DUTI'}
                                    fieldTerm={'numeroProtocolo'}
                                    value={initialValues?.numeroProtocolo}
                                    onChangeValue={(v:string|undefined, err: boolean) =>{ 
                                        updateField('numeroProtocolo',v,['idDoc', 'nrd'])
                                        setErrors(prev => ({...prev, numeroProtocolo:err}))
                                    }}
                                    required={true}
                                    error={errors.numeroProtocolo}
                                    extraValidation={validateNumProtocolo}
                                    className={classes.inputFullWidth}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={4}>
                                <InputSearch
                                    componentIG={componentIG ??'DUTI'}
                                    fieldTerm='notario'
                                    value={initialValues?.notario?.nomApe}
                                    required
                                    disabledOnlyInput={true}
                                    error={errors.notario}
                                    errorMsgExterno= {errors.notario ? translate('DUTI','error_required', terms) : undefined}
                                    onChangeValue={ (v:string|undefined, err: boolean) => {}}
                                    onSearch={ () => setShowModalNotarios(true)}
                                    className={classes.inputFullWidth} 
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} style={{textAlign:'right'}}>
                                <Button style={{ marginRight: 10}}
                                    variant="outlined" color="primary" 
                                    onClick={() => {
                                        setShowModalNRD(true)
                                    }}
                                >
                                    {translate('DUTI','btn_search_nrd',terms)}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    { (initialValues.tipoDocTransmision.id !== 'publico-notarial' || origenDocumento === 2) &&
                        <Grid item container direction="row" alignItems="flex-start">
                            <Grid item xs={6} md={6} lg={6}>
                                <InputText
                                    componentIG={componentIG ??'DUTI'}
                                    fieldTerm={'docDescripcion'}
                                    value={initialValues?.docDescripcion}
                                    onChangeValue={(v:string|undefined, err: boolean) =>{ 
                                        updateField('docDescripcion',v,[])
                                    }}
                                    className={classes.inputFullWidth}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} style={{marginTop:5}}>
                                <AttachFileComponent 
                                    label={translate('Plusvalias','attachDoc', terms)}
                                    docs={doc && doc !== 'notFound' ? [doc] : []}
                                    saveDocsAttached={ (docs: IDocumentoAtib[]) =>{ 
                                        if(docs.length>0 ){
                                            updateField('idDoc', docs[0].IdDocumento, []);
                                            onChangeDoc(docs[0]);                                            
                                        } else {
                                            updateField('idDoc', undefined, ["idDoc"]);
                                        }
                                        onChangeOrigenDoc(2);
                                        setShowMsgNoEncontrado(false);
                                    }}
                                    multiple={false}
                                    allowDelete
                                    allowDownload
                                    docSource="sede-plusvalias"
                                    idTipoApartado={idTipoApartado}
                                    idReferencia={idReferencia}
                                />
                            </Grid>
                        </Grid>
                    }                
                    
                    <Grid item container direction="row" alignItems="flex-start">
                        <Grid item xs={6} md={6} lg={3}>
                            <TypedInputNumber 
                                type="percentaje"
                                allowZero={false}
                                maxDecimal={2}
                                fieldTerm={'porcentajeTransmision'}
                                value={initialValues.porcentaje ?? 0}
                                onChangeValue={(v: number, err: boolean) => {
                                    updateField('porcentaje', v,[])
                                    setErrors(prev => ({...prev,porcentaje:err}))
                                }}
                                required={true} 
                                error={errors.porcentaje}
                                className={classes.inputFullWidth}
                                moreInfo={{
                                    template: 'plusvalias_info_field_porcentajeTransmision'
                                }}
                            /> 
                        </Grid>
                        <Grid item xs={6} md={6} lg={3}>
                            <TypedInputNumber
                                type="money"
                                allowZero={true}
                                min={0}
                                componentIG="Plusvalias"
                                fieldTerm={'importeTransmision'}
                                value={initialValues.valorDeclarado}
                                onChangeValue={(v: number|undefined, err: boolean) => {
                                    updateField('valorDeclarado', v ?? 0,[])
                                    setErrors(prev => ({...prev,valorDeclarado:err}))
                                }}
                                required={false} 
                                error={errors.valorDeclarado}
                                className={classes.inputFullWidth}
                                moreInfo={{
                                    template: 'plusvalias_info_field_valorDeclarado'
                                }}
                            /> 
                        </Grid>
                    </Grid>

                    <Grid item container direction="row" alignItems="flex-start">
                        <Grid item xs={6} md={6} lg={6}>
                            <div className={classes.row} style={{marginRight: 15}}>
                                <div className={classes.border} >
                                    <CheckBoxGroup 
                                        disabled={disabledExenciones}
                                        fieldTerm=''
                                        labelAs='notLabel'
                                        row
                                        value= {initialValues.exento === true
                                            ? 'exento'
                                            : initialValues.noSujeto === true ? 'noSujeto': undefined
                                        }
                                        options={[
                                            {id: 'noSujeto', term:'noSujeto', moreInfo: {template: 'plusvalias_info_field_nosujecion', size: 'md'} },
                                            {id: 'exento', term:'exento', moreInfo: {template: 'plusvalias_info_field_exencio', size: 'md'} }
                                        ]}
                                        onChangeValue={(key:string,v:boolean) => {
                                            const clean: ( "exento" | "noSujeto" | "observaciones"| "motivo")[]=[]
                                            if(key === 'exento'){
                                                clean.push('noSujeto')
                                            }
                                            if(key === 'noSujeto'){
                                                clean.push('exento')
                                            }
                                            if((key === 'exento'? v : false) === false && (key === 'noSujeto' ? v : false) === false ){
                                                clean.push('observaciones')
                                            }
                                            clean.push('motivo')
                                            updateField(key as "exento" | "noSujeto" | "observaciones"| "motivo", v , clean)
                                            if(v=== true){
                                                setErrors(prev => ({...prev, observaciones: true}))
                                            }                                       
                                        }}
                                    />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} >
                            {/**Selector  */}
                            <InputSelect 
                                fieldTerm='observaciones'
                                value={initialValues.motivo}
                                options={  disabledExenciones? [ motivoRU] : fieldMotivo_OPTIONS}
                                onChangeValue={(v: TSelect, err: boolean) => {
                                    updateField('motivo', v, ['observaciones'])
                                    setErrors(prev => ({...prev, motivo: err}))
                                }}
                                disabled={ (!initialValues.noSujeto && !initialValues.exento) || disabledExenciones}
                                required ={(initialValues.noSujeto || initialValues.exento)}
                                className={classes.inputFullWidth}
                            />
                            {initialValues.motivo && (
                                initialValues.motivo.nombre.toLowerCase().includes('otros') 
                                || initialValues.motivo.nombre.toLowerCase().includes('altres') 
                            )?
                                <InputText
                                    fieldTerm='observaciones'
                                    value={initialValues.observaciones}
                                    multiline
                                    rows={2}
                                    required={true}
                                    onChangeValue={ (v:string,err: boolean) => {
                                        updateField('observaciones', v, [])
                                        setErrors(prev => ({...prev,observaciones: err}))
                                    }}
                                    fullWidth
                                    error={errors.observaciones}
                                />
                                : null
                            }
                        </Grid>
                    </Grid>
                </Grid>    
            }

            <ModalSearchByNrd
                show={showModalNRD && !loading}
                info={{
                    fecha: initialValues?.fecha,
                    fechaDoc: initialValues?.fechaDoc,
                    anyo: initialValues?.anyo,
                    idDocEscritura: initialValues?.idDoc,
                    nifNotario: initialValues?.notario?.nif,
                    notario: initialValues?.notario?.nomApe,
                    nrd: initialValues?.nrd,
                    numeroProtocolo:initialValues?.numeroProtocolo,
                } as IInfo}
                onConfirm={(escr: IDocumentoAtib|undefined, newInfo: IInfo, nrd:string|undefined) => {
                    onChangeDoc(escr);
                    if (newInfo.idDocEscritura){
                        onChange({...initialValues,
                            anyo: newInfo.anyo,
                            fechaDoc: newInfo.fecha,
                            numeroProtocolo: newInfo.numeroProtocolo,
                            idDoc: newInfo.idDocEscritura,
                            notario:{
                                nif: newInfo.nifNotario,
                                nomApe: newInfo.notario,
                            },
                            nrd: nrd,
                        });
                    }
                    setShowModalNRD(false);
                    setShowMsgNoEncontrado(false);
                }}
                onClose={() =>{ 
                    setShowModalNRD(false);
                }}
                onChangeOrigenDoc={onChangeOrigenDoc}
            />

            <ModalNotarios
                show={showModalNotarios}
                currentNotario={initialValues?.notario?.nomApe}
                currentNifNotario={initialValues?.notario?.nif}
                onSelected= { (nifNotario: string, notario: string) => { 
                    updateField('notario', {nif:nifNotario ,nomApe: notario}, ['idDoc', 'nrd']);
                    setErrors(curr => ({...curr, nifNotario:false, notario:false}));
                    onChangeDoc(undefined);
                    onChangeOrigenDoc(0);
                    setShowModalNotarios(false);
                }}          
                onClose={() =>{ 
                    setShowModalNotarios(false)
                }}         
            />
        </Grid>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    column: {
        display: 'flex',
        flexDirection:  'column',
        alignItems: 'center',
        justifyItems: 'flex-start'
    },
    row:{
        display:' flex',
        flexDirection:'row', 
        alignItems: 'center',
    },
    grow: {flexGrow: 1},
    inputFullWidth: { // Small: puerta, esc
        width: '100%'
    },
    inputSmall: {
        width: 150
    },
    border:{
        border:'solid',
        borderWidth: '1px',
        borderRadius: 5,
        borderColor: '#004f82',
        padding: '5px 10px 5px 20px',
        height: 45, 
        marginTop: 4,
        display:'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexGrow: 1
    }


})

export default withLiterals(['Global','DUTI','Plusvalias'])(withStyles(styles)(InfoTransmisionForm));

