import { CircularProgress, DialogContent, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, makeStyles, MenuItem, Select, TextField, Typography, useTheme } from "@material-ui/core";
import Term from "components/term";
import { LiteralsContext } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import { ICalle } from "gateways/calle.interface";
import { IDireccion, IDireccionData, IErroresValidacionDireccion, TErroresValidacionDireccion } from "gateways/direccion.interfaces";
import { IMunicipio } from "gateways/municipio.interface";
import { ISujeto } from "gateways/perfil.interfaces";
import { IPoblacion } from "gateways/poblacion.interface";
import { IProvincia } from "gateways/provincia.interface";
import { ISiglas } from "gateways/siglas.interface";
import { SujetosGateway } from "gateways/sujetos.gateway";
import usePage from "hooks/page.hook";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { InputChangeHandler, SelectChangeHandler } from "utils/events";
import { transformNullForEmptyStrings } from "utils/response";
import styles from "../perfil.styles";
import { Autocomplete } from "@material-ui/lab";

interface IProps {
    sujeto: ISujeto | null;
    direccionBackUp: IDireccionData | undefined;
    validacionDireccion: IErroresValidacionDireccion;
    onChangeDireccion: (direccionData: IDireccionData, errorToDeactivate: TErroresValidacionDireccion | undefined) => void;
}

const defaultDireccion = {
    provincia: '07',
};

const useStyles = makeStyles(styles);

const DireccionStepValidacion: FC<IProps> = ({ sujeto, direccionBackUp, validacionDireccion, onChangeDireccion }) => {
    const theme = useTheme();
    const terms = useContext(LiteralsContext);
    const classes = useStyles(theme);
    const [, pageDispatcher] = usePage();

    const direccion: IDireccion = transformNullForEmptyStrings(Object.assign(defaultDireccion, sujeto?.direccionNotificacion));
    // Services
    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);
    // Loaders
    const [loading, setLoading] = useState(false);
    const [loadingMunicipios, setLoadingMunicipios] = useState(false);
    const [loadingPoblaciones, setLoadingPoblaciones] = useState(false);
    const [loadingCallejero, setLoadingCallejero] = useState(false);
    // Params
    const [direccionData, setDireccionData] = useState<IDireccionData>({
        idDireccion: null,
        municipio: '',
        poblacion: '',
        provincia: '',
        siglas: '',
        idCalle: null,
        calle: '',
        numero: '',
        km: null,
        codigoPostal: '',
        bloque: '',
        escalera: '',
        portal: '',
        piso: '',
        puerta: '',
        toponimia: ''
    });
    const [errorToDeactivate, setErrorToDeactivate] = useState<TErroresValidacionDireccion | undefined>()
    // Options selectors
    const [siglasOptions, setSiglasOptions] = useState<ISiglas[]>([]);
    const [provinciasOptions, setProvinciasOptions] = useState<IProvincia[]>([]);
    const [municipiosOptions, setMunicipiosOptions] = useState<IMunicipio[]>([]);
    const [poblacionOptions, setPoblacionOptions] = useState<IPoblacion[]>([]);
    const [callejeroOptions, setCallejeroOptions] = useState<ICalle[]>([]);
    // Others
    const CALLEJERO_BY_MUNI = {
        PALMA: '040',
        MARRATXI: '036', 
        ALCUDIA: '003'
    }

    const handleChangeProvincia: SelectChangeHandler = (event) => {
        setDireccionData({
            ...direccionData,
            provincia: event.target.value,
            municipio: '',
            poblacion: '',
            siglas: '',
            idCalle: '',
            calle: '',
            numero: '',
            km: null,
            codigoPostal: '',
            bloque: '',
            escalera: '',
            portal: '',
            piso: '',
            puerta: '',
            toponimia: ''
        });
        setErrorToDeactivate("provinciaError");
    };

    const handleChangeMunicipio: SelectChangeHandler = (event) => {
        setDireccionData({
            ...direccionData,
            municipio: event.target.value,
            poblacion: '',
            siglas: '',
            idCalle: '',
            calle: '',
            codigoPostal: ''


        });
        setErrorToDeactivate("municipioError");
    };

    const handleChangePoblacion: SelectChangeHandler = (event) => {
        setDireccionData({
            ...direccionData,
            poblacion: event.target.value,
            siglas: '',
            idCalle: '',
            calle: '',
            codigoPostal: ''
        });
        setErrorToDeactivate("poblacionError");
    };

    const handleChangeSiglas: SelectChangeHandler = (event) => {
        setDireccionData({
            ...direccionData,
            siglas: event.target.value,
            idCalle: '',
            calle: '',
            codigoPostal: ''
        });
        setErrorToDeactivate("siglasError");
    };

    const handleChangeCalle: SelectChangeHandler = (event) => {
        const calleSeleccionada = callejeroOptions.find(calle => calle.idCalle === parseInt(event.target.value));
        if (!calleSeleccionada) return;

        setDireccionData({
            ...direccionData,
            idCalle: calleSeleccionada.idCalle,
            calle: calleSeleccionada.calle
        });
        setErrorToDeactivate("calleError");
    };
    const handleCalleChange = (calleSeleccionada: ICalle | null) => {
        if (!calleSeleccionada)
            return
        setDireccionData({
            ...direccionData,
            idCalle: calleSeleccionada.idCalle,
            calle: calleSeleccionada.calle
        });
        setErrorToDeactivate("calleError");
    }



    const filterOptions = (options: ICalle[], { inputValue }: { inputValue: string }) => {
        return options.filter(option =>
            option.calle.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const handleChangeNumero: InputChangeHandler = (event) => {
        const { value } = event.target;
        setDireccionData({
            ...direccionData,
            numero: value.length > 4 ? value.substring(0, 4) : value
        })
    };

    const handleChangeKm: InputChangeHandler = (event) => {
        if (event.target.value.length > 10) return;
        const kmValueValidation = (event.target.value && !isNaN(parseInt(event.target.value)));
        setDireccionData({
            ...direccionData,
            km: kmValueValidation ? parseInt(event.target.value) : null
        });
    };

    const handleChangeCodigoPostal: InputChangeHandler = (event) => {
        if (event.target.value.length > 10) return;
        setDireccionData({
            ...direccionData,
            codigoPostal: event.target.value
        });
        setErrorToDeactivate("codigoPostalError");
    };
    const handleChangeBloque: InputChangeHandler = (event) => {
        if (event.target.value.length > 10) return;
        setDireccionData({
            ...direccionData,
            bloque: event.target.value
        });
    };

    const handleChangeEscalera: InputChangeHandler = (event) => {
        if (event.target.value.length > 5) return;
        setDireccionData({
            ...direccionData,
            escalera: event.target.value
        });
    };

    const handleChangePortal: InputChangeHandler = (event) => {
        if (event.target.value.length > 5) return;
        setDireccionData({
            ...direccionData,
            portal: event.target.value
        });
    };

    const handleChangePiso: InputChangeHandler = (event) => {
        if (event.target.value.length > 5) return;
        setDireccionData({
            ...direccionData,
            piso: event.target.value
        });
    };

    const handleChangePuerta: InputChangeHandler = (event) => {
        if (event.target.value.length > 10) return;
        setDireccionData({
            ...direccionData,
            puerta: event.target.value
        });
    };

    const handleChangeToponimia: InputChangeHandler = (event) => {
        if (event.target.value.length > 500) return;
        setDireccionData({
            ...direccionData,
            toponimia: event.target.value
        });
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const provincias: IProvincia[] = await perfilG.getProvincias();
                const siglasProv: ISiglas[] = await perfilG.getSiglas();
                setProvinciasOptions(provincias);
                setSiglasOptions(siglasProv);
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
        })();
        setLoading(false)
    }, [perfilG, pageDispatcher]);

    useEffect(() => {
        if (!direccionData.provincia) return;
        (async () => {
            setLoadingMunicipios(true);
            try {
                const municipios: IMunicipio[] = await perfilG.getMunicipios(direccionData.provincia);
                setMunicipiosOptions(municipios);
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingMunicipios(false);
            }
        })();
    }, [direccionData.provincia, perfilG, pageDispatcher]);

    useEffect(() => {
        if (!direccionData.provincia || !direccionData.municipio) return;
        (async () => {
            setLoadingPoblaciones(true);
            try {
                const poblaciones: IPoblacion[] = await perfilG.getPoblacion(direccionData.provincia, direccionData.municipio);
                setPoblacionOptions(poblaciones);
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingPoblaciones(false);
            }
        })();
    }, [direccionData.provincia, direccionData.municipio, perfilG, pageDispatcher]);

    useEffect(() => {
        if (!direccionData.poblacion) return;
        (async () => {
            setLoadingCallejero(true);
            try {
                let callejero: ICalle[] = [];
                if(Object.values(CALLEJERO_BY_MUNI).includes(direccionData.municipio)){
                    callejero = await perfilG.getCallejeroByMuni(direccionData.municipio);
                } else {
                    callejero = await perfilG.getCallejero(direccionData.poblacion);
                }
                
                const callejeroOptionsFiltered = callejero.filter(x => x.calle !== null)
                setCallejeroOptions(callejeroOptionsFiltered.sort((a, b) => a.calle.localeCompare(b.calle)));
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingCallejero(false);
            }
        })();
    }, [direccionData.idCalle, direccionData.provincia, direccionData.municipio, direccionData.poblacion, perfilG, pageDispatcher]);

    useEffect(() => {
        if (!direccion) return;
        direccionBackUp
            ? setDireccionData(direccionBackUp)
            : setDireccionData({
                idDireccion: direccion.idDireccion,
                municipio: direccion.codigoMunicipio,
                poblacion: direccion.idPoblacion + '',
                provincia: direccion.provincia,
                codigoPostal: direccion.codigoPostal,
                siglas: direccion.sigla,
                idCalle: direccion.idVia ? direccion.idVia : '',
                calle: direccion.idVia ? direccion.via : '',
                numero: direccion.numero,
                km: direccion.km,
                portal: direccion.portal,
                bloque: direccion.bloque,
                escalera: direccion.escalera,
                piso: direccion.piso,
                puerta: direccion.puerta,
                toponimia: direccion.toponimia
            });
    }, []);

    useEffect(() => {
        onChangeDireccion(direccionData, errorToDeactivate);
    }, [direccionData]);

    return (
        <>
            {loading
                ?
                <div className={classes.centerContent}>
                    <CircularProgress size={30} />
                </div>
                :
                <DialogContent>
                    <Grid container={true} className={classes.section}>
                        <Grid item={true} xs={12} md={4}>
                            <FormControl className={classes.selectField}>
                                <InputLabel
                                    shrink={direccionData.provincia ? true : false}
                                    htmlFor="provincia-native-simple" error={validacionDireccion.provinciaError !== null}
                                >
                                    <Term component="Global" text="Provincia" />
                                </InputLabel>
                                <Select
                                    native={true}
                                    error={validacionDireccion.provinciaError !== null}
                                    displayEmpty={false}
                                    value={direccionData.provincia}
                                    onChange={handleChangeProvincia}
                                    className={classes.selectField}
                                    inputProps={{
                                        name: 'provincia-label',
                                        id: 'provincia-native-simple',
                                    }}
                                >
                                    {
                                        provinciasOptions !== null ?
                                            provinciasOptions.map(x => (
                                                <option key={x.idProvincia} value={x.idProvincia}>{x.nombre}</option>
                                            )) : null
                                    }
                                </Select>
                                {
                                    validacionDireccion.provinciaError !== null
                                        ? <FormHelperText error={true}>{validacionDireccion.provinciaError}</FormHelperText>
                                        : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <FormControl className={classes.selectField}>
                                <InputLabel
                                    shrink={direccionData.municipio ? true : false}
                                    htmlFor="municipio-native-simple"
                                    error={validacionDireccion.municipioError !== null}
                                >
                                    <Term component="Global" text="Municipio" />
                                </InputLabel>
                                <Select
                                    native={true}
                                    error={validacionDireccion.municipioError !== null}
                                    disabled={loadingMunicipios}
                                    value={direccionData.municipio}
                                    onChange={handleChangeMunicipio}
                                    className={classes.selectField}
                                    inputProps={{
                                        name: 'municipio',
                                        id: 'municipio-native-simple',
                                    }}
                                >
                                    <option key="muni" value="" />
                                    {
                                        municipiosOptions !== null ?
                                            municipiosOptions.map(x => (
                                                <option key={x.idMunicipio} value={x.idMunicipio}>{x.nombre}</option>
                                            )) : null
                                    }
                                </Select>
                                {loadingMunicipios ? <LinearProgress className={classes.linearCharge} /> : null}
                                {
                                    validacionDireccion.municipioError !== null
                                        ? <FormHelperText error={true}>{validacionDireccion.municipioError}</FormHelperText>
                                        : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <FormControl className={classes.selectField}>
                                <InputLabel
                                    shrink={direccionData.poblacion ? true : false}
                                    htmlFor="poblacion-native-simple"
                                    error={validacionDireccion.poblacionError !== null}
                                >
                                    <Term text="Poblacion" />
                                </InputLabel>
                                <Select
                                    native={true}
                                    error={validacionDireccion.poblacionError !== null}
                                    disabled={loadingPoblaciones}
                                    value={direccionData.poblacion}
                                    onChange={handleChangePoblacion}
                                    className={classes.selectField}
                                    inputProps={{
                                        name: 'poblacion',
                                        id: 'poblacion-native-simple',
                                    }}
                                >
                                    <option key="pobl" value="" />
                                    {
                                        poblacionOptions !== null ?
                                            poblacionOptions.map(x => (
                                                <option key={x.idPoblacion} value={x.idPoblacion}>{x.nombre}</option>
                                            )) : null
                                    }
                                </Select>
                                {loadingPoblaciones ? <LinearProgress className={classes.linearCharge} /> : null}
                                {
                                    validacionDireccion.poblacionError !== null
                                        ? <FormHelperText error={true}>{validacionDireccion.poblacionError}</FormHelperText>
                                        : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <FormControl className={classes.selectField}>
                                <InputLabel
                                    shrink={direccionData.siglas ? true : false}
                                    htmlFor="siglas-simple"
                                    error={validacionDireccion.siglasError !== null}
                                >
                                    <Term component="Global" text="Siglas" />
                                </InputLabel>
                                <Select
                                    error={validacionDireccion.siglasError !== null}
                                    value={direccionData.siglas}
                                    onChange={handleChangeSiglas}
                                    className={classes.selectField}
                                    displayEmpty
                                    inputProps={{
                                        name: 'siglas',
                                        id: 'siglas-simple',
                                    }}
                                >
                                    {
                                        siglasOptions !== null ?
                                            siglasOptions.map(x => (
                                                <MenuItem key={x.idSiglas} value={x.idSiglas}>
                                                    <Term component="Global" text={x.nombre} />
                                                </MenuItem>
                                            ))
                                            : null
                                    }
                                </Select>
                                {
                                    validacionDireccion.siglasError !== null
                                        ? <FormHelperText error={true}>{validacionDireccion.siglasError}</FormHelperText>
                                        : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={8}>
                            <FormControl className={classes.selectField}>
                                <Autocomplete
                                    value={callejeroOptions.find(option => option.idCalle === direccionData.idCalle) || null}
                                    disabled={loadingCallejero}
                                    options={callejeroOptions}
                                    getOptionLabel={(option) => option.calle || ''}
                                    onChange={(event, newValue) => {
                                        handleCalleChange(newValue ? newValue : null);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={<Term component="Global" text="Calle"></Term>}
                                            variant="standard"
                                        />
                                    )}
                                />
                                {loadingCallejero ? <LinearProgress className={classes.linearCharge} /> : null}
                                {
                                    validacionDireccion.calleError !== null
                                        ? <FormHelperText error={true}>{validacionDireccion.calleError}</FormHelperText>
                                        : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-numero-input"
                                label={<Term component="Global" text="Número" />}
                                value={direccionData.numero}
                                className={classes.textFieldDireccion}
                                onChange={handleChangeNumero}
                                margin="normal"
                            />
                            {
                                validacionDireccion.numeroError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.numeroError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-km-input"
                                label="Km"
                                value={direccionData.km}
                                className={classes.textFieldDireccion}
                                onChange={handleChangeKm}
                                margin="normal"
                                type="number"
                            />
                            {
                                validacionDireccion.kmError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.kmError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-codigo-postal-input"
                                label={<Term component="Global" text="Código postal" />}
                                error={validacionDireccion.codigoPostalError !== null}
                                helperText={validacionDireccion.codigoPostalError}
                                value={direccionData.codigoPostal}
                                className={classes.textFieldDireccion}
                                onChange={handleChangeCodigoPostal}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-bloque-input"
                                label={<Term component="Global" text="Bloque" />}
                                value={direccionData.bloque}
                                className={classes.textFieldDireccion}
                                onChange={handleChangeBloque}
                                margin="normal"
                            />
                            {
                                validacionDireccion.bloqueError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.bloqueError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-escalera-input"
                                label={<Term component="Global" text="Escalera" />}
                                value={direccionData.escalera}
                                className={classes.textFieldDireccion}
                                onChange={handleChangeEscalera}
                                margin="normal"
                            />
                            {
                                validacionDireccion.escaleraError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.escaleraError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-portal-input"
                                label={<Term component="Global" text="Portal" />}
                                value={direccionData.portal}
                                className={classes.textFieldDireccion}
                                onChange={handleChangePortal}
                                margin="normal"
                            />
                            {
                                validacionDireccion.portalError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.portalError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-piso-input"
                                label={<Term component="Global" text="Piso" />}
                                value={direccionData.piso}
                                className={classes.textFieldDireccion}
                                onChange={handleChangePiso}
                                margin="normal"
                            />
                            {
                                validacionDireccion.pisoError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.pisoError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-puerta-input"
                                label={<Term component="Global" text="Puerta" />}
                                value={direccionData.puerta}
                                className={classes.textFieldDireccion}
                                onChange={handleChangePuerta}
                                margin="normal"
                            />
                            {
                                validacionDireccion.puertaError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.puertaError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                id="outlined-toponimia-input"
                                label={<Term component="Global" text="Toponimia" />}
                                value={direccionData.toponimia}
                                className={classes.textFieldDireccion}
                                onChange={handleChangeToponimia}
                                margin="normal"
                            />
                            {
                                validacionDireccion.toponimiaError !== null
                                    ? <FormHelperText error={true}>{validacionDireccion.toponimiaError}</FormHelperText>
                                    : null
                            }
                        </Grid>
                    </Grid>
                </DialogContent >
            }
        </>
    )
};

export default DireccionStepValidacion;