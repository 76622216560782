import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";

import IoC from "contexts/ioc.context";
import { GeoGateway } from "gateways/geo.gateway";

import { LiteralsContext } from "containers/shared/literals";
import {  Grid, WithStyles, withStyles } from "@material-ui/core";
import InputText from "./input-text";
import duti_styles from "containers/DUTI/styles";
import InputNumber from "./input-number";


import { Municipio, Provincia, Sigla} from 'gateways/geo.interface';
import { REGEX_CP, TSelect, TSelectDUTI, formatSelectDUTIOptions, formatSelectOptions } from "containers/DUTI/utils";



import InputSelect from "./input-select";
import { IDUTIMunicipio } from "gateways/duti.interface";
import DUTIGateway from "gateways/duti.gateway";
import { translate } from "utils/i18n";
import { TExtraValidation } from "./types";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { IPais } from "gateways/pais.interface";
import { validateCP } from "utils/validateCP";

export type TDirectionFlags = Record<keyof TDirectionATIB, boolean>
export type TDirectionATIB = {
    pais: TSelect,
    provincia: TSelect | undefined,
    municipio: TSelect | undefined,
    cp: string | undefined,
    tipoVia: TSelect| undefined,
    nombreVia: string| undefined,
    //numero: number| undefined,
    numero: string | undefined,
    letra: string| undefined,
    escalera: string| undefined,
    piso: string| undefined,
    puerta: string| undefined,
}

export const EMPTY_DIRECTION: TDirectionATIB = {
    cp: undefined,
    escalera: undefined,
    letra: undefined,
    municipio: undefined,
    nombreVia: undefined,
    numero: undefined,
    piso: undefined,
    provincia: {
        id: "07",
        nombre: "ILLES BALEARS"
    },
    puerta: undefined,
    tipoVia: undefined,
    pais: {
        id: "00",
        nombre: "ESPAÑA"
    }
}

export const EMPTY_DIRECTION_ERRORs: TDirectionFlags = {
    cp: false, escalera: false, letra: false, municipio: false, nombreVia: false, numero: false, piso: false, provincia: false, puerta: false, tipoVia: false,
    pais: false
}
export const DEFAULT_REQUIRED_DIRECTION: TDirectionFlags = {
    pais: true,
    provincia: true,
    municipio: true,
    tipoVia: true,
    nombreVia: true,
    numero: true,
    piso: false,
    puerta: false,
    escalera: false,
    letra: false,
    cp: true,
}


type ObtainKeys<T, V> = keyof {
    [K in keyof T as T[K] extends V ? K : never]: any
}
type ObtainKeysSelect ='pais'| 'provincia' | 'municipio'| 'tipoVia'

export const ALL_DIRECTION_DISABLED: Record<keyof TDirectionATIB, boolean>= {
    cp: true,
    pais:true,
    provincia: true,
    municipio: true,
    tipoVia: true,
    nombreVia: true,
    numero: true,
    letra: true,
    escalera: true,
    piso: true,
    puerta: true
}
export const EMPTY_DISABLED: TDirectionFlags= {
    cp: false,
    pais: false,
    provincia: false,
    municipio: false,
    tipoVia: false,
    nombreVia: false,
    numero: false,
    letra: false,
    escalera: false,
    piso: false,
    puerta: false
}
interface IDirectionForm extends WithStyles {
    direction: TDirectionATIB;
    setDirection: (d:TDirectionATIB) => void;
    dirErrors: TDirectionFlags;
    setDirErrors: (dErr: TDirectionFlags) => void;
    disabled?: TDirectionFlags
    required?: TDirectionFlags

};

const DirectionFormATIB : FC<IDirectionForm> = ({classes, direction, setDirection,dirErrors, setDirErrors, disabled, required={...DEFAULT_REQUIRED_DIRECTION}}) => {

    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    const sujetosGateway = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);

    const componentMounted = useRef(true);
    // Local 
    const [loading, setLoading] = useState(false)

    const [options, setOptions] = useState<Record<ObtainKeysSelect,TSelect[]>>({
        pais: [],
        municipio: [],
        provincia: [],
        tipoVia: []
    })

    
    const handleChangeTextInput = (value: string| undefined, field: ObtainKeys<TDirectionATIB, string | undefined>, err:boolean) => {
        //console.log('handleChangeTextInput => ', field, ': ', value, ' ERR:', err)
        setDirErrors({...dirErrors, [field]: err })
        setDirection({...direction, [field]: value});
    }

    const handleChangeNumberInput = (value: number| undefined, field: ObtainKeys<TDirectionATIB, number | undefined>, err:boolean) => {
        //console.log('handleChangeNumberInput => ', field, ': ', value, ' ERR:', err)
       
        setDirErrors({...dirErrors, [field]: err})
        setDirection({...direction, [field]: value});
    }

    const handleChangeSelectInput = (value: TSelect | undefined, field: ObtainKeysSelect, err:boolean) => {
        let newDirErr = {...dirErrors, [field]: err}
        let newDir= {...direction, [field]: {...value}}
        if(field === 'provincia'){
            newDir= {...newDir, municipio: undefined}
        }
        if(field === 'pais'){
            newDir= {...newDir,
                provincia:undefined, 
                municipio: undefined,
                cp: undefined
            }
            newDirErr={...newDirErr, 
                provincia:false, 
                municipio: false,
                cp: false
            }
        }
        setDirErrors({...newDirErr})
        setDirection({...newDir}); //direction, [field]: {...value}

    }

    const validateCPLocal = (cp:string) => {
        const result= validateCP(cp, direction.pais.id,direction.provincia?.id)

        return({error: result.error, error_msg: result.msg ? translate('DUTI','dir_cp_prov_error', terms) : ""} as TExtraValidation)
    }
    
    useEffect(() => {
        (async () => {
            const optionsPaises = await sujetosGateway.getPaises()
            const PAIS = direction?.pais?.id ? optionsPaises.find(p =>( p.idPais === direction?.pais?.id || p.idPais === '00')): undefined
            const optionsProvincias = await geoGateway.getProvincias(PAIS?.idPais ?? '')
            const PROV = direction?.provincia?.id ? optionsProvincias.find(p =>( p.idProvincia === direction?.provincia?.id || p.idProvincia === '07')): undefined
            const optionsTipoViaBBDD= await geoGateway.getSiglas()
            const optionsTipoVia= optionsTipoViaBBDD.map(item => ({...item, nombre: translate('GLOBAL',item.nombre,terms)}))
            const optionsMunicipios= PROV ? await geoGateway.getMunicipios(PROV.idProvincia): []
            if(componentMounted.current){
                setOptions(opt => ({...opt, 
                    pais: formatSelectOptions<IPais>('idPais','nombre',optionsPaises), 
                    provincia: formatSelectOptions<Provincia>('idProvincia','nombre',optionsProvincias), 
                    municipio: formatSelectOptions<Municipio>('idMunicipio','nombre',optionsMunicipios),
                    tipoVia: formatSelectOptions<Sigla>('idSiglas','nombre', optionsTipoVia)
                }))
            }

        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }

    }, [])
   

    useEffect(() => {
        (async () => {
            const optionsMunicipios= direction.provincia && direction.provincia.id  
                ? await geoGateway.getMunicipios(direction.provincia.id )
                : [];
                
            
            setOptions(curr => ({...curr, 
                municipio: optionsMunicipios.length>0 ? formatSelectOptions<Municipio>('idMunicipio','nombre',optionsMunicipios): []
            }))
           
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [direction.provincia])

    useEffect(() => {
        if(direction.tipoVia !== undefined && options.tipoVia.length>0){
            const exist= options.tipoVia.find(tv => tv.id === direction.tipoVia?.id)
            if(exist === undefined){
                setDirection({...direction, tipoVia: undefined})
            } else {
                setDirection({...direction, tipoVia: exist})
            }
        }
    },[ direction.tipoVia , options.tipoVia ])

    return(
        <Grid container direction="column" spacing={0}>
            <Grid item container direction="row">
                <InputSelect 
                    fieldTerm='dir_pais'
                    value={direction.pais}
                    options={options.pais}
                    onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'pais', err)}
                    required={required.pais}
                    disabled={disabled?.pais}
                    //className={classes.input360}
                    error={dirErrors.pais}
                />
                {direction?.pais && direction.pais.id === '00' 
                    ? 
                    <>
                        <InputSelect 
                            fieldTerm='dir_provincia'
                            value={direction.provincia || !disabled?.pais}
                            options={options.provincia}
                            onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'provincia', err)}
                            required={required.provincia}
                            disabled={disabled?.provincia}
                            className={classes.input360}
                            error={dirErrors.provincia}
                        />
                        <InputSelect 
                            disabled={!direction.provincia || disabled?.municipio}
                            fieldTerm='dir_municipio'
                            value={direction.municipio}
                            options={options.municipio}
                            onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'municipio', err)}
                            required={required.municipio}
                            error={dirErrors.municipio}
                        />
                    </>
                    :
                    <>
                        <InputText 
                            fieldTerm='dir_provincia'
                            value={direction.provincia?.nombre}
                            onChangeValue={(v: string, err: boolean) => handleChangeSelectInput(v!== '' ? {id: '-1', nombre: v} : undefined ,'provincia',err)} 
                            required={required.provincia}
                            disabled={disabled?.provincia || !direction?.pais}
                            className={classes.input360}
                            error={dirErrors.provincia}
                            forceUpperCase={true}
                        />
                        <InputText 
                            disabled={!direction.provincia || disabled?.municipio}
                            fieldTerm='dir_municipio'
                            value={direction.municipio?.nombre}
                            onChangeValue={(v: string, err: boolean) => handleChangeSelectInput( v!== '' ? {id: '-1', nombre: v} : undefined, 'municipio', err)}
                            required={required.municipio}
                            error={dirErrors.municipio}
                            forceUpperCase={true}
                        />
                    </>

                }
                

            </Grid>
            <Grid item container direction="row" alignItems="flex-start">
                <InputSelect 
                    fieldTerm='dir_tipoVia'
                    value={direction.tipoVia}
                    options={options.tipoVia}
                    onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'tipoVia', err)}
                    //className={classes.input160}
                    disabled={disabled?.tipoVia}
                    error={dirErrors.tipoVia}
                    required={required.tipoVia}
                />
                <InputText 
                    fieldTerm='dir_nombreVia' 
                    value={direction.nombreVia} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'nombreVia',err)} 
                    required={required.nombreVia}
                    disabled={disabled?.nombreVia}
                    className={classes.input360}
                    error={dirErrors.nombreVia}
                    forceUpperCase={true}
                />
                {/* <InputNumber 
                    fieldTerm='dir_numero' 
                    value={direction.numero} 
                    onChangeValue={(v: number, err:boolean) => handleChangeNumberInput(v,'numero',err)} 
                    required={required.numero}
                    disabled={disabled?.numero}
                    min={0}
                    maxDecimal={0}
                    className={classes.input100}
                    error={dirErrors.numero}
                /> */}
                <InputText 
                    fieldTerm='dir_numero' 
                    value={direction.numero} 
                    onChangeValue={(v: string, err:boolean) => handleChangeTextInput(v,'numero',err)} 
                    required={required.numero}
                    disabled={disabled?.numero}
                    className={classes.input100}
                    error={dirErrors.numero}
                />

                <InputText 
                    fieldTerm='dir_escalera' 
                    value={direction.escalera} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'escalera',err)} 
                    disabled={disabled?.escalera}
                    className={classes.input100}
                    error={dirErrors.escalera}
                    required={required.escalera}
                />
                <InputText 
                    fieldTerm='dir_letra' 
                    value={direction.letra} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'letra',err)} 
                    disabled={disabled?.letra}
                    className={classes.input70}
                    error={dirErrors.letra}
                    required={required.letra}
                />
                <InputText 
                    fieldTerm='dir_piso' 
                    value={direction.piso} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'piso',err)} 
                    disabled={disabled?.piso}
                    className={classes.input70}
                    error={dirErrors.piso}
                    required={required.piso}
                />
                <InputText 
                    fieldTerm='dir_puerta' 
                    value={direction.puerta} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'puerta',err)} 
                    disabled={disabled?.puerta}
                    className={classes.input100}
                    error={dirErrors.puerta}
                    required={required.puerta}
                />

                <InputText 
                    fieldTerm='dir_cp' 
                    value={direction.cp} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'cp',err)} 
                    disabled={disabled?.cp}
                    className={classes.input160}
                    extraValidation={validateCPLocal}
                    error={dirErrors.cp}
                    required={required.cp}
                />
                
                
            </Grid>

        </Grid>
    );

}

export default withStyles(duti_styles)(DirectionFormATIB)