// LIBRARY IMPORTS
import { FC, useEffect, useState } from "react";
import { Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from 'react-router';
import moment from "moment";

// LOCAL IMPORTS
import { IDatosIban, ITributo } from "gateways/tributos.interfaces";
import { dateFormat } from "utils/dates";
import useAltasDomicilaicion, { IStateHistory } from "hooks/domiciliaciones/altas-domiciliacion.hook";
import Term from "components/term";

// STYLES IMPORTS
import styles from './../../../../shared/tributo.detail.styles';

const useStyles = makeStyles(styles);
interface ITributoCardProps {
    domiciliacion: IDatosIban;
    tributo: ITributo;
    loadingPrint: boolean;
    domiciliables?: boolean;
    esPagoACuenta?: boolean;
    type: 'info' | 'gestion';

    onPrint: (idDomiciliacion: number) => void;
    onHandleFechaCargo: (tributo: ITributo) => void;
}

const DomiciliacionCard: FC<ITributoCardProps> = (props) => {
    // PROPS
    const { domiciliacion, tributo, loadingPrint, domiciliables, type, esPagoACuenta, onPrint, onHandleFechaCargo } = props;

    // HOOKS
    const classes = useStyles();
    const history = useHistory<IStateHistory>();
    const { onAltaDomiciliaciones } = useAltasDomicilaicion({ history, domiciliables: domiciliables || false });

    // LOCAL STATES
    const [idDomiToPrint, setIdDomiToPrint] = useState<number>();
    const [domiciliacionCerrada, setDomiciliacionCerrada] = useState(false)
    const [envioDomicilicionCerrado, setEnvioDomicilicionCerrado] = useState(false);
    const [loadingControlFechaVoluntaria, setLoadingControlFechaVoluntaria] = useState(false);



    // HANDLERS 

    const handleFechaPrevistaCargoDomi = () => {
        if (!tributo.domiciliacion) return;
        else if (domiciliacion.fechaPrevistaEnvio) return dateFormat(domiciliacion.fechaPrevistaEnvio);
        else if (tributo.domiciliacion.fechaPrevistaEnvio) return dateFormat(tributo.domiciliacion.fechaPrevistaEnvio);
        return <Term component="Global" text="No disponible" />;
    }

    const handlePrintDomi = (idDomiciliacion: number) => {
        setIdDomiToPrint(idDomiciliacion);
        onPrint(idDomiciliacion);
    }



    // USE EFFECTS

    useEffect(() => {
        (async () => {
            setLoadingControlFechaVoluntaria(true);

            if (domiciliacion.fechaCierrePeriodo) setDomiciliacionCerrada(moment().isAfter(moment(domiciliacion.fechaCierrePeriodo)));
            if (domiciliacion.fechaPrevistaEnvio) setEnvioDomicilicionCerrado(moment().isAfter(moment(domiciliacion.fechaPrevistaEnvio)));

            setLoadingControlFechaVoluntaria(false);
        })();
    }, [tributo]);



    return (
        <>
            {/* Detail Vigencia y Fecha Prevista Envio */}
            <Grid container direction='column' className={classes.blueBorder} >
                <div className={classes.divColoredTextBorder}>
                    <Typography className={classes.blueTextBorder}>{domiciliacion.vigencia}</Typography>
                </div>

                {/* Datos titular e IBAN  */}

                <Grid item container direction='row' alignItems="baseline" justify="space-between" style={{ padding: 15 }} >
                    <Grid item>
                        <Typography component="p" className={[classes.marginBottom5, classes.marginRight20].join(' ')}>
                            <Term component="Domiciliaciones" text="Datos titular" />: <strong>{[domiciliacion.nombreTitular, domiciliacion.nifTitular].join(' - ')}</strong>
                        </Typography>
                        <Typography component="p" className={[classes.marginRight20].join(' ')}>
                            <Term component="Domiciliaciones" text="IBAN" />: <strong>{domiciliacion.iban}</strong>
                        </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                        {type === 'gestion' &&
                            <Button variant="outlined" color="primary" style={{ backgroundColor: '#fff' }}
                                disabled={domiciliacionCerrada}
                                onClick={() => onAltaDomiciliaciones([tributo])} >
                                <Term component="Domiciliaciones" text={"Modificar cuenta"} />
                            </Button>
                        }
                    </Grid>
                </Grid>

                {/* Detalle recuadro azul */}

                <Grid item className={classes.blueContainer} container direction='row' alignItems="baseline" justify="space-between" >
                    <Grid item>
                        <Typography component="p" className={[classes.marginBottom5, classes.marginRight20].join(' ')}>
                            {tributo.tipo === 'L'
                                ? <Term component="Domiciliaciones" text="VigenciaDomiciliacionLiquidacion" />
                                : <>
                                    <Term component="Domiciliaciones" text="Domiciliación aplicada para el ejercicio" />: <strong>{domiciliacion.vigencia}</strong>
                                </>
                            }
                        </Typography>
                        <Typography component="p" className={[classes.marginBottom5, classes.marginRight20].join(' ')}>
                            {!esPagoACuenta && domiciliacion.fechaPrevistaEnvio && tributo.tipo !== "L" &&
                                <div className={[classes.marginBottom5, classes.marginRight20].join(' ')}>
                                    <Term component='Domiciliaciones' text='Fecha cargo cuenta sin parámetro' />: <strong>{handleFechaPrevistaCargoDomi()}</strong>
                                </div>
                            }
                        </Typography>
                        {!domiciliacion.fechaCierrePeriodo && !domiciliacionCerrada && type === 'gestion' &&
                            <Grid item className={classes.yellowContainer}>
                                <Typography component="p" className={[classes.tag].join(' ')}>
                                    <Term component="Domiciliaciones" text="Periodo domiciliación no abierto" />
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                        {type === 'info' &&
                            <Grid item>
                                <Button onClick={() => handlePrintDomi(domiciliacion.idDomiciliacion)} color="primary" variant="contained" disabled={loadingPrint}>
                                    <Term component="Domiciliaciones" text="Descargar justificante" />
                                    {
                                        loadingPrint && idDomiToPrint === domiciliacion.idDomiciliacion &&
                                        <CircularProgress size={24} className={[classes.buttonProgress, classes.loaderPrint, classes.centerTopBottom].join(' ')} />
                                    }
                                </Button>
                            </Grid>
                        }
                        {type === 'gestion' && tributo.domiciliacion?.idDomiciliacion && !tributo.domiciliacion.pagoCarta && tributo.tipo !== "L" &&
                            <Grid item>
                                <Button variant="contained" color="primary" className={[classes.minWidth90, classes.btnAddPay, classes.btnDomi].join(' ')}
                                    disabled={envioDomicilicionCerrado || loadingControlFechaVoluntaria}
                                    onClick={() => onHandleFechaCargo(tributo)}
                                >
                                    <Term component="Domiciliaciones" text="Seleccionar fecha cargo" />
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                {/* Etiqueta domiciliación cerrada */}

                {domiciliacionCerrada && type === 'gestion' && domiciliacion.fechaCierrePeriodo &&
                    <Grid item className={classes.redContainer}>
                        <Typography component="p" className={[classes.tag].join(' ')}>
                            <Term component="Domiciliaciones" text="Periodo domiciliación cerrado" /> <strong>{moment(domiciliacion.fechaCierrePeriodo).startOf('day').format('DD/MM/YYYY')}</strong>
                        </Typography>
                    </Grid>
                }

                {/* Etiqueta envio domiciliación cerrado */}

                {envioDomicilicionCerrado && type === 'gestion' && domiciliacion.fechaPrevistaEnvio &&
                    <Grid item className={classes.redContainer}>
                        <Typography component="p" className={[classes.tag].join(' ')}>
                            <Term component="Domiciliaciones" text="Periodo envío domiciliación cerrado" /> <strong>{moment(domiciliacion.fechaPrevistaEnvio).startOf('day').format('DD/MM/YYYY')}</strong>
                        </Typography>
                    </Grid>
                }
            </Grid>
        </>
    );
}

export { DomiciliacionCard };