// LIBRARY IMPORTS
import { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, Grid, makeStyles } from "@material-ui/core";
import { mdiFileAccount } from "@mdi/js";

// LOCAL IMPORTS
import { TMenuItem } from "containers/shared/menu-item-types";
import { SHOW_DUSTI } from "constants/dusti";
import PresentacionesIMG from '../../resources/home/tramites/Presentaciones.png'
import AutonomicosIcon from '../../resources/home/tributos/Autonomicos.png'
import MasInfoIcon from '../../resources/home/notificaciones/info.png'
import DutiIMG from '../../resources/home/tramites/iconoDusti.png'
import LocalesIcon from '../../resources/home/tributos/Locales.png'
import BoxMenuItem from "components/box-menu-item";
import usePage from "hooks/page.hook";
import Term from "components/term";

// STYLES
import styles from "containers/shared/container-home";


interface IRouteParams { };
type Props = RouteComponentProps<IRouteParams>;

const useStyles = makeStyles(styles);

const TributosHome: FC<Props> = ({ match, history }) => {
    // HOOKS
    const classes = useStyles()
    const [, setShowLogin] = useState(false);
    const [, pageDispatcher] = usePage();

    // CONSTANTS
    const isMobile = window.innerWidth <= 590;
    const MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "Tributos", text: "Tributos Autonomicos" }, img: AutonomicosIcon, children: [
                { title: { component: "Tributos", text: "Tasas administrativas" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Pago con documento de ingreso" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuestos sobre transmisiones patrimoniales" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre sucesiones y donaciones" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Canon saneamiento de aguas" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Tasa fiscal sector del juego" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre estancias turísticas" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Canon sobre el vertido y la incineración de residuos" }, link: 'tributos/tributos-autonomicos', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre el depósito de residuos en vertederos, la incineración y la coincineración de residuos" }, link: 'tributos/tributos-autonomicos', protected: 0 },
            ]
        },
        {
            title: { component: "Tributos", text: "Tributos Locales" }, img: LocalesIcon, children: [
                { title: { component: "Tributos", text: "Tasas administrativas" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Pago con documento de ingreso" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre vehículos de tracción mecánica" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre construcciones, instalaciones y obras" }, link: 'tributos/tributos-locales', protected: 0 },
                { title: { component: "Tributos", text: "Impuesto sobre el incremento de valor de terrenos de naturaleza urbana (Plusvalía municipal)" }, link: 'tributos/tributos-locales', protected: 0 },
            ]
        },
        {
            title: { component: "Global", text: "Mas informacion" }, img: MasInfoIcon, children: [
                { title: { component: "Tributos", text: "plazos_declaracion" }, link: 'tributos/contenidos', protected: 0 },
                { title: { component: "Global", text: "ConsultaPreguntasFrecuentes" }, link: '/faqs', protected: 0 },
            ]
        },
        {
            title: { component: "Global", text: "Presentaciones" }, img: PresentacionesIMG, children: [
                { title: { component: "Global", text: "Presentacion de expedientes" }, link: '/tributos/presentaciones', protected: 2 },
            ]
        },
        {
            title: { component: "Global", text: "DUSTI" }, img: DutiIMG, children: [
                { title: { component: "Global", text: "DUSTIAlta" }, link: '/tributos/DUSTI/alta', protected: 2 },
                { title: { component: "Global", text: "DUSTIConsulta" }, link: '/tributos/DUSTI/consulta', protected: 2 },
                { title: { component: "Global", text: "DUSTIMasInfo" }, link: '/tributos/DUSTI/contenidos', protected: 0 },
            ]
        },
    ]



    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileAccount,
                title: <Term component="Global" text="TRIBUTOS" />,
                text: <Term text={"DescripcionTributos"} component="Global" />,
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);


    
    return (
        <>
            <Card className={classes.root}>
                <Grid container direction='row' justify="flex-start">
                    {MENU_ITEMS.map((menuitem, idx) => (
                        (menuitem.title.text === "DUSTI" && !SHOW_DUSTI)
                            ? null
                            :
                            <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`tributos-${idx}`}>
                                <BoxMenuItem
                                    item={menuitem}
                                    onLogin={
                                        (accessLevel) => {
                                            if (isMobile) { history.push('/acceso', { goBack: history.location.pathname }) }
                                            else {
                                                if (accessLevel === 2) { history.push('/acceso/clave', { goBack: history.location.pathname }) }
                                                if (accessLevel === 1) { setShowLogin(true) }
                                            }
                                        }
                                    }
                                />
                            </Grid>
                    ))}
                </Grid>
            </Card>
        </>
    )
}

export default withRouter(TributosHome);

