import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { FormControl, FormHelperText, IconButton, WithStyles, withStyles } from "@material-ui/core";
import { DatePickerProps, KeyboardDatePicker } from "@material-ui/pickers";
import styles, { colors } from "./styles";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import moment from "moment";

import { MoreInfoContext } from "contexts/more_info.context";
import { Icon } from 'atomic/atoms/icon/icon';
import { translate } from "utils/i18n";


export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined
}

type TMSGError = 'err_required' | 'err_maxDate' | 'err_minDate' | 'err_invalid_date'
type TInput = Pick<DatePickerProps, 'inputVariant'|'disabled'|'className'|'minDate'|'maxDate'|'margin'|'variant'|'size'|'format'|'error'> & WithStyles
interface IGenericInputDate extends TInput {
    componentIG: string
    labelTerm: string,
    value: Date | undefined,
    required: boolean,
    onChangeValue: (v:Date | undefined, err:boolean) => void,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    errorMsgExterno?:string,
    extraValidation?: (v: string) => TExtraValidation

};

const GenericInputDate: FC<IGenericInputDate> = ({
    classes,componentIG,labelTerm,value, onChangeValue,required=false, moreInfo, extraValidation, error,errorMsgExterno,
    maxDate, minDate, margin='dense', variant='inline',size="small", format="dd/MM/yyyy",
    className,disabled
}) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    
    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string|undefined>(undefined)
    

    const onValidate = (date: Date | null | undefined) => {
        let err: boolean = false
        let msg : TMSGError|undefined = undefined
        let params: string[]=[]
        if((date === null || date === undefined) && required){
            err= true
            msg='err_required'
        }
        if(date && !(moment(date).isValid())){
            err= true
            msg='err_invalid_date'
        } else{

            if(maxDate && date &&  moment(date).format('YYYY-MM-DD').valueOf() > moment(maxDate).format('YYYY-MM-DD').valueOf() ){
                err= true
                msg='err_maxDate'
                params=[moment(maxDate).format('DD/MM/YYYY')]
            }
            if(minDate && date && moment(date).format('YYYY-MM-DD').valueOf() < moment(minDate).format('YYYY-MM-DD').valueOf() ){
                err= true
                msg='err_minDate'
                params=[moment(minDate).format('DD/MM/YYYY')]
            }
        }
        
        setErrMsg(msg ? translate("GenericComponents", msg, terms, params): '' )
        setErr(err)
        return err
    }

    const handleFecha = (date: Date | null) => {
        const err = onValidate(date);
        //setErr(false)
        if (date !== null) {
            onChangeValue(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())), err);
        } else {
            onChangeValue(undefined, err);
        }
    }

    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.template ?? '',
                    size: moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])

    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
            if(errorMsgExterno && error ===true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){onValidate(value)}
    }, [error, errorMsgExterno, value])

    return (
        <div style={{display:'flex', flexDirection: 'row', alignItems:'flex-start', marginRight:10}}>
            <FormControl component="fieldset" style={{flexGrow: 1}} >
                <KeyboardDatePicker
                    disabled={disabled}
                    disableToolbar
                    variant={variant}
                    size={size}
                    margin={margin}
                    format={format}
                    error={err}
                    helperText={ err ? (errMsg ?? ' ') : ' '}
                    label={translate(componentIG, labelTerm, terms).concat(required ? ' *': '')}
                    value={value ?? null}
                    onChange={(date, value) => {
                            setErr(false)
                            handleFecha(date)
                        }
                    }
                    minDate={minDate}
                    maxDate={maxDate}
                    className={className ?? classes.inputDefault} 
                    inputVariant="outlined"       
                    InputLabelProps={{classes: {
                        root: classes.inputLabel,
                    }}}  
                    onBlur={()=>{ 
                        const err = onValidate(value ?? null)
                        err && onChangeValue(value, err)
                    }} 
                />              
            </FormControl>
            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo}>
                    <Icon name={'info-outline'} size={1} color={colors.blueIconInfo}/>
                </IconButton>
            }
        </div>
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericInputDate));