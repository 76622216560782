import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Card, Grid, makeStyles } from '@material-ui/core';
import Term from "components/term";
import { mdiWalletTravel} from '@mdi/js';
import usePage, { getLang } from "hooks/page.hook";
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { ITramitesData } from './tramites.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { TMenuItem, TSubMenuItem } from 'containers/shared/menu-item-types';

import BoxMenuItem from 'components/box-menu-item';
import styles from 'containers/shared/container-home';

import CertificacionesIMG from '../../resources/home/tramites/Certificaciones.png'
import ConsultasIMG from '../../resources/home/tramites/Consultas.png'
import IdentificacionesIMG from '../../resources/home/tramites/Identificaciones.png'
import PresentacionesIMG from '../../resources/home/tramites/Presentaciones.png'
import RepresentacionesIMG from '../../resources/home/tramites/Representaciones.png'
import SolicitudesIMG from '../../resources/home/tramites/Solicitudes.png'
import AjutsCovidIMG from '../../resources/home/tramites/AjutsCovid.png'

import MasInfoIcon from '../../resources/home/notificaciones/info.png'
import { RouteComponentProps } from 'react-router-dom';
import ModalAcceso from 'components/modal-acceso';

export const getImgIcon = (name: string): string|undefined => {
    switch (name) {
        case 'Certificaciones':
            return CertificacionesIMG;
        case 'Consultas':
            return ConsultasIMG;
        case 'Identificaciones':
            return IdentificacionesIMG;
        case 'Presentaciones':
            return PresentacionesIMG;
        case 'Representaciones':
            return RepresentacionesIMG;
        case 'Solicitudes':
            return SolicitudesIMG;
        case 'AjutsCovid':
            return AjutsCovidIMG;
        case 'Informacion':
            return MasInfoIcon;
        default:
            return undefined;
    }
};

export const newPrepareTramitesData = (tramites: ITramitesData[], terms: any) => {
    const tramitesOut: TMenuItem[]=[];
    let oldIdCategoria = -1;
    tramites.forEach(function callback(element,index){    
        if(oldIdCategoria != element.idCategoria ){   
            const f = tramites.filter(t => t.idCategoria === element.idCategoria)
            const fchildren: TSubMenuItem[] = f.map(x =>({
                    isExternLink: x.sede, // x.url.includes('https://www.atib.es/'),
                    link: x.url,
                    protected: x.protected === '1' 
                                ? 1 
                                : x.protected === '2' ? 2 : 0,
                    idModulo: x.idModulo,
                    title: {component: 'Global', text: x.nombre},
                })
            )

            // nuevo título
            tramitesOut.push({
                title:{component: 'Global', text: element.categoriaLabel },
                icon: element.icon,
                img: undefined,
                children: fchildren
            });
        }
        oldIdCategoria = element.idCategoria;
    });
    return tramitesOut;
}



const useStyles = makeStyles(styles);
type Props = RouteComponentProps;
const MasTramites: FC<Props> = ({history}) => {
    const ioc = useContext(IoC);
    const [, pageDispatcher] = usePage();
    const isMobile = window.innerWidth <= 590;
    const classes = useStyles();
    const terms = useContext(LiteralsContext)
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    const [showLogin, setShowLogin] = useState(false);
    const [tramites, setTramites] = useState<TMenuItem[]>()

    const [lang, setLang] = useState<string>()
    const tramitesGateway: TramitesGateway = ioc.get(TramitesGateway);    

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWalletTravel,
                title: <Term component="Global" text="TRAMITES" />,
                text: <Term component="Global" text="TRAMITES_DESCRIPCION" />,

            },
            menu: true,
        });
    }, [pageDispatcher, handleToggleSubMenu, tramitesGateway]);

    useEffect(() => {
        (async () => {
            let response:ITramitesData[] = await tramitesGateway.getAllTramites();
            setTramites(newPrepareTramitesData(response,terms)); //prepareTramitesData(response))
            setLang(getLang());
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[terms]);


    /**
     * <Card className={classes.root}>
            <Grid container direction='row' justify="flex-start">
                {MENU_ITEMS.map( (menuitem, idx) => (
                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`cartero-${idx}`}>
                        <BoxMenuItem item={menuitem}/>
                    </Grid>
                    
                ))}
            </Grid>
        </Card>
     */
    return (
        <>
            <Card className={classes.root}>
                <Grid container direction='row' justify="flex-start">
                {tramites?.map((x, idx) => (  
                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`tramite-${idx}`}>
                        <BoxMenuItem 
                            item={x} 
                            onLogin={(accessLevel) => {
                                if(isMobile){history.push('/acceso', { goBack: history.location.pathname }) } 
                                else {
                                    if(accessLevel === 2){history.push('/acceso/clave', { goBack: history.location.pathname } )}
                                    if(accessLevel === 1){setShowLogin(true)}
                                } 
                            }}  
                        />
                    </Grid>
                ))}
                </Grid>
            </Card>
            <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />

        </>
    );
}

export default MasTramites;