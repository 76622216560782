import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Grid, FormControl, Switch, FormControlLabel, Button, TextField, CircularProgress, makeStyles, useTheme, Card, IconButton } from '@material-ui/core';
import Term from 'components/term';
import { amber } from '@material-ui/core/colors';
import { FileField } from 'components/file-field';
import IoC from 'contexts/ioc.context';
import { SolicitudesGateway } from 'gateways/solicitudes.gateway';
import ConfirmarSolicitudDialog from './modals/confirmar-solicitud';
import { translate } from 'utils/i18n';
import usePage from 'hooks/page.hook';
import { ISolicitudCodigoContribuyente } from 'gateways/solicitudes.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { RouteComponentProps, withRouter } from 'react-router';
import { mdiShieldAccount } from '@mdi/js';
import { AlertsContext } from 'contexts/alerts.context';
import { validateNif } from 'utils/validateNif';
import ConfirmDialog from 'containers/perfil/modals/confirmacion.modal';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { mdiInformationVariant } from "@mdi/js";
import Icon from '@mdi/react';
import { MoreInfoContext } from 'contexts/more_info.context';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        margin: '0 20px'
    },
    centerContent: {
        textAlign: 'center',
    },
    formControl: {
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    warningMessage: {
        color: amber[900],
        fontWeight: 'bold',
    },
    marginTop5: {
        marginTop: 5,
        flexDirection: 'row-reverse'
    },
    containerAddDocAndInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    infoIcon: {
        padding: 0,
        border: '1px solid'
    },
    [theme.breakpoints.up('md')]: {
        formContainer: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
        formContainerWithoutGrid: {
            padding: '0 20px'
        }
    }
}));

const CodigoContribuyente: React.FC<RouteComponentProps> = ({ history }) => {
    const [checkRepresentante, setCheckRepresentante] = useState(false);
    const [nifFile, setNifFile] = useState<string | null>(null);
    const [nifRepresentanteFile, setNifRepresentanteFile] = useState<string | null>(null);
    const [autorizacionRepresentacion, setAutorizacionRepresentacion] = useState<string | null>(null);
    const [nombre, setNombre] = useState('');
    const [nif, setNif] = useState('');
    const [regexEmail,] = useState(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const [email, setEmail] = useState('');
    const [emailConfirmed, setEmailConfirmed] = useState('');
    const [movil, setMovil] = useState('');
    const [direccion, setDireccion] = useState('');
    const [numero, setNumero] = useState('');
    const [escalera, setEscalera] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [provincia, setProvincia] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [representante, setRepresentante] = useState('');
    const [nifRepresentante, setNifRepresentante] = useState('');
    const [smsDialogOpen, setSmsDialogOpen] = useState(false);
    const [confirmacionDialogOpen, setConfirmacionDialogOpen] = useState(false);
    const [bloqueado, setBloqueado] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationNombre, setValidationNombre] = useState<string | null>(null);
    const [validationNif, setValidationNif] = useState<string | null>(null);
    const [validationNifAdjunto, setValidationNifAdjunto] = useState<string | null>(null);
    const [validationEmail, setValidationEmail] = useState<string | null>(null);
    const [validationEmailConfirm, setValidationEmailConfirm] = useState<string | null>(null);
    const [validationMovil, setValidationMovil] = useState<string | null>(null);
    const [validationRepresentante, setValidationRepresentante] = useState<string | null>(null);
    const [validationNifRepresentante, setValidationNifRepresentante] = useState<string | null>(null);
    const [validationNifRepresentanteAdjunto, setValidationNifRepresentanteAdjunto] = useState<string | null>(null);
    const [validationAutorizacion, setValidationAutorizacion] = useState<string | null>(null);
    const [codigoContribuyente, setCodigoContribuyente] = useState<ISolicitudCodigoContribuyente | null>(null);
    const [confirmarSolicitud, setConfirmarSolicitud] = useState(false);
    const [body, setBody] = useState('');
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, pageDispatcher] = usePage();
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const solicitudesG = ioc.get(SolicitudesGateway) as SolicitudesGateway;
    const sujetoG = ioc.get(SujetosGateway) as SujetosGateway;
    const contenidoGateway = ioc.get(ContenidosGateway) as ContenidosGateway;
    const theme = useTheme();
    const classes = useStyles(theme);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: <Term component="Solicitudes" text="Solicitud código contribuyente" />,
            },
            menu: true,
        });
    }, [pageDispatcher]);

    const handleModalOpenMovil = useCallback(() => {
        setSmsDialogOpen(true);
    }, []);
    const handleModalCloseMovil = useCallback(() => {
        setSmsDialogOpen(false);
    }, []);
    const handleNombre = useCallback((value: any) => {
        setNombre(value);
    }, [])
    const handleNif = useCallback((value: string) => {
        setNif(value.toUpperCase());
    }, [])
    const handleEmail = useCallback((value: any) => {
        setEmail(value);
    }, [])
    const handleEmailConfirmed = useCallback((value: any) => {
        setEmailConfirmed(value);
    }, [])
    const handleMovil = useCallback((value: any) => {
        const regex = /^[0-9\b]+$/;
        if (value === '' || regex.test(value)) {
            setMovil(value);
        }
    }, [])
    const handleDireccion = useCallback((value: any) => {
        setDireccion(value);
    }, [])
    const handleNumero = useCallback((value: any) => {
        setNumero(value);
    }, [])
    const handleEscalera = useCallback((value: any) => {
        setEscalera(value);
    }, [])
    const handleMunicipio = useCallback((value: any) => {
        setMunicipio(value);
    }, [])
    const handleProvincia = useCallback((value: any) => {
        setProvincia(value);
    }, [])
    const handleCodigoPostal = useCallback((value: any) => {
        setCodigoPostal(value);
    }, [])
    const handleRepresentante = useCallback((value: any) => {
        setRepresentante(value);
    }, [])
    const handleNifRepresentante = useCallback((value: any) => {
        setNifRepresentante(value);
    }, [])

    const handleNifAttach = useCallback((files: string) => {
        setNifFile(files);
    }, [])

    const handleNifRepresentanteAttach = useCallback((files: string) => {
        setNifRepresentanteFile(files);
    }, [])

    const handleAutorizacion = useCallback((files: string) => {
        setAutorizacionRepresentacion(files);
    }, [])

    const handleCloseModal = useCallback(() => {
        setConfirmacionDialogOpen(false);
    }, [])

    const handleConfirmModal = useCallback(async () => {
        await sujetoG.sendMailSolicitudRecuperarContrasenya(nif, email);
        pageDispatcher({
            type: 'show-notification', payload: {
                message: translate('Acceso', 'Se le ha enviado un correo a su dirección de correo electrónico', terms),
                variant: 'success',
            }
        });
        setConfirmacionDialogOpen(false);
    }, [sujetoG, nif, email, pageDispatcher, terms])

    const handleModalInfoAttachNif = () => {
        infoDispatch({
            type: 'show-info',
            payload: {
                templateName: "info_adjunto_nif_cc",
            }
        });
    }

    const handleModalInfoAttachNifRepresentante = () => {
        infoDispatch({
            type: 'show-info',
            payload: {
                templateName: "info_adjunto_nif_representante_cc",
            }
        });
    }

    const handleModalInfoAttachAuth = () => {
        infoDispatch({
            type: 'show-info',
            payload: {
                templateName: "info_adjunto_autorizacion_cc",
            }
        });
    }

    const handleChangeCheckRepresentante = useCallback(() => {
        const checkRepresentanteTemp = checkRepresentante;
        setCheckRepresentante(!checkRepresentanteTemp);
    }, [checkRepresentante])

    const validateForm = () => {
        let textNombre: any = null;
        let textNif: any = null;
        let textNifFile: any = null;
        let textEmail: any = null;
        let textEmailConfirmed: any = null;
        let textMovil: any = null;
        let textRepresentante: any = null;
        let textNifRepresentante: any = null;
        let textNifRepresentanteFile: any = null;
        let textAutorizacion: any = null;
        if (nombre === "") {
            textNombre = <Term component="Solicitudes" text="El campo no puede estar vacio" />;
        }
        if (nif === "" || !validateNif(nif)) {
            textNif = <Term component="Solicitudes" text="Introduzca un valor correcto" />;
        }
        if (nifFile == null) {
            textNifFile = <Term component="Solicitudes" text="El campo no puede estar vacio" />;
        }
        if (email === "" || !regexEmail.test(email)) {
            textEmail = <Term component="Solicitudes" text="Introduzca un valor correcto" />;
        }
        if (emailConfirmed === "") {
            textEmailConfirmed = <Term component="Solicitudes" text="El campo no puede estar vacio y debe coincidir" />;

        }
        if (email !== emailConfirmed) {
            textEmail = <Term component="Solicitudes" text="Los correos introducidos no coinciden" />;
        }
        if (movil === "") {
            textMovil = <Term component="Solicitudes" text="Introduzca un valor correcto" />;
        }
        if (checkRepresentante) {
            if (representante === "") {
                textRepresentante = <Term component="Solicitudes" text="El campo no puede estar vacio" />;
            }
            if (nifRepresentante === "" || !validateNif(nifRepresentante)) {
                textNifRepresentante = <Term component="Solicitudes" text="Introduzca un valor correcto" />;
            }
            if (nifRepresentanteFile == null) {
                textNifRepresentanteFile = <Term component="Solicitudes" text="El campo no puede estar vacio" />;
            }
            if (autorizacionRepresentacion == null) {
                textAutorizacion = <Term component="Solicitudes" text="El campo no puede estar vacio" />;
            }
        }
        setValidationNombre(textNombre);
        setValidationNif(textNif);
        setValidationNifAdjunto(textNifFile);
        setValidationEmail(textEmail);
        setValidationEmailConfirm(textEmailConfirmed);
        setValidationMovil(textMovil);
        setValidationRepresentante(textRepresentante);
        setValidationNifRepresentante(textNifRepresentante);
        setValidationNifRepresentanteAdjunto(textNifRepresentanteFile);
        setValidationAutorizacion(textAutorizacion);
        return !textNombre && !textNif && !textNifFile && !textEmail && !textEmailConfirmed && !textMovil && !textRepresentante && !textNifRepresentante && !textNifRepresentanteFile;
    }

    const enviarCodigo = async () => {
        if (validateForm()) {
            const solicitud: ISolicitudCodigoContribuyente = {
                nombre,
                nif,
                nifFile,
                email,
                emailConfirmed,
                movil,
                codigoPostal,
                direccion,
                escalera,
                municipio,
                provincia,
                numero,
                representante,
                nifRepresentante,
                nifRepresentanteFile,
                autorizacion: autorizacionRepresentacion
            };
            setCodigoContribuyente(solicitud);
            setLoading(true);
            try {
                await solicitudesG.envioSmsSolicitud(movil);
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Solicitudes', 'Se le ha enviado un sms con el código para finalizar la solicitud', terms),
                        variant: 'success',
                    }
                });
                handleModalOpenMovil();
                setConfirmarSolicitud(true);
            }
            catch (e) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Solicitudes', 'Ha ocurrido un error procesando la solicitud', terms),
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
    }

    const enviarSolicitud = async (key: string) => {
        setLoading(true);
        if (codigoContribuyente) {
            try {
                const response = await sujetoG.sendCodigoContribuyente({ ...codigoContribuyente, representacion: checkRepresentante }, key);
                pageDispatcher({
                    type: 'show-notification', payload: {
                        message: translate('Solicitudes', 'La solicitud se ha procesado correctamente', terms),
                        variant: 'success',
                    }
                });
                history.push({
                    pathname: '/solicitudes/solicitud-finalizada',
                    state: {
                        nif,
                        idSolicitud: response.idMensaje,
                    }
                });
            } catch (e) {
                pageDispatcher({
                    type: 'show-notification', payload: {
                        message: translate('Solicitudes', 'Ha ocurrido un error procesando la solicitud', terms),
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
        else {
            pageDispatcher({
                type: 'show-notification', payload: {
                    message: translate('Solicitudes', 'La solicitud esta vacia', terms),
                    variant: 'error',
                }
            });
        }

    }

    useEffect(() => {
        async function load() {
            if ((nif !== "" && validateNif(nif)) && regexEmail.test(email) && email === emailConfirmed) {
                if (await solicitudesG.existeSolicitudAprobada(nif, email)) {
                    setBloqueado(true);
                    setConfirmacionDialogOpen(true);
                    const bodyAux = await contenidoGateway.getContent(
                        'codigo_contribuyente_ya_aceptado',
                        {}
                    );
                    setBody(bodyAux[0].contenido);
                }
            }
        }
        load();
    }, [])

    return (
        <>
            <Card className={classes.root}>
                <Grid container className={classes.formContainer}>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Global" text="apellidosynombre" />}
                                value={nombre}
                                error={validationNombre !== null}
                                helperText={validationNombre}
                                onChange={(e) => handleNombre(e.target.value)}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Global" text="Nif" />}
                                value={nif}
                                error={validationNif !== null}
                                helperText={validationNif}
                                onChange={(e) => handleNif(e.target.value)}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Global" text="Email" />}
                                value={email}
                                error={validationEmail !== null}
                                helperText={validationEmail}
                                onChange={(e) => handleEmail(e.target.value)}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Global" text="Confirmación email" />}
                                value={emailConfirmed}
                                error={validationEmailConfirm !== null}
                                helperText={validationEmailConfirm}
                                onChange={(e) => handleEmailConfirmed(e.target.value)}
                                onPaste={(e) => { e.preventDefault() }}
                                onCopy={(e) => { e.preventDefault() }}
                                onCut={(e) => { e.preventDefault() }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Global" text="Movil" />}
                                value={movil}
                                onChange={(e) => handleMovil(e.target.value)}
                                error={validationMovil !== null}
                                helperText={validationMovil}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Global" text="Dirección" />}
                                value={direccion}
                                onChange={(e) => handleDireccion(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Solicitudes" text="Numero" />}
                                value={numero}
                                onChange={(e) => handleNumero(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Solicitudes" text="escaleraplantapuerta" />}
                                value={escalera}
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => handleEscalera(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Solicitudes" text="municipio" />}
                                value={municipio}
                                onChange={(e) => handleMunicipio(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Solicitudes" text="Provincia" />}
                                value={provincia}
                                onChange={(e) => handleProvincia(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label={<Term component="Solicitudes" text="codigopostal" />}
                                value={codigoPostal}
                                onChange={(e) => handleCodigoPostal(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={[classes.formControl, classes.containerAddDocAndInfo].join(' ')}>
                            <div>
                                <FileField
                                    label={<Term component="Solicitudes" text="adjunto" />}
                                    error={validationNifAdjunto !== null}
                                    helperText={validationNifAdjunto}
                                    onChange={handleNifAttach}
                                />
                            </div>
                            <IconButton className={classes.infoIcon} style={{ width: 20 }} onClick={() => handleModalInfoAttachNif()}>
                                <Icon path={mdiInformationVariant}></Icon>
                            </IconButton>
                        </FormControl>
                    </Grid>
                </Grid>


                <FormControlLabel
                    className={classes.formContainerWithoutGrid}
                    label={<Term component="Solicitudes" text="representante" />}
                    control={
                        <Switch
                            checked={checkRepresentante}
                            value={checkRepresentante}
                            onChange={handleChangeCheckRepresentante}
                            color="primary"
                        />
                    }
                />

                {
                    !checkRepresentante ? null
                        :
                        <Grid container className={classes.formContainer}>
                            <Grid item xs={12} sm={4}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        label={<Term component="Solicitudes" text="representante" />}
                                        value={representante}
                                        error={validationRepresentante !== null}
                                        helperText={validationRepresentante}
                                        onChange={(e) => handleRepresentante(e.target.value)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        label={<Term component="Solicitudes" text="dni/nif/cif" />}
                                        value={nifRepresentante}
                                        error={validationNifRepresentante !== null}
                                        helperText={validationNifRepresentante}
                                        onChange={(e) => handleNifRepresentante(e.target.value)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className={[classes.formControl, classes.containerAddDocAndInfo].join(' ')}>
                                    <div>
                                        <FileField
                                            label={<Term component="Solicitudes" text="adjunto" />}
                                            error={validationNifRepresentanteAdjunto !== null}
                                            helperText={validationNifRepresentanteAdjunto}
                                            required
                                            onChange={handleNifRepresentanteAttach}
                                        />
                                    </div>
                                    <IconButton className={classes.infoIcon} style={{ width: 20 }} onClick={() => handleModalInfoAttachNifRepresentante()}>
                                        <Icon path={mdiInformationVariant}></Icon>
                                    </IconButton>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className={[classes.formControl, classes.containerAddDocAndInfo].join(' ')}>
                                    <div>
                                        <FileField
                                            label={<Term component="Solicitudes" text="autorizacion" />}
                                            error={validationAutorizacion !== null}
                                            helperText={validationAutorizacion}
                                            required
                                            onChange={handleAutorizacion}
                                        />
                                    </div>
                                    <IconButton className={classes.infoIcon} style={{ width: 20 }} onClick={() => handleModalInfoAttachAuth()}>
                                        <Icon path={mdiInformationVariant}></Icon>
                                    </IconButton>
                                </FormControl>
                            </Grid>
                        </Grid>
                }
                <Grid container className={`${classes.marginTop5} ${classes.formContainer}`} alignItems="center">
                    <Grid item xs={12} sm={3} md={2}>
                        <FormControl className={classes.formControl}>
                            {
                                !confirmarSolicitud ?
                                    <Button variant="outlined" color="primary" disabled={bloqueado} onClick={enviarCodigo}>
                                        <Term component="Solicitudes" text="Enviar Solicitud" />
                                        {
                                            loading ?
                                                <CircularProgress size={20} />
                                                :
                                                null
                                        }
                                    </Button>
                                    :
                                    <Button variant="outlined" color="primary" onClick={handleModalOpenMovil}>
                                        <Term component="Solicitudes" text="Verificar Código" />
                                        {
                                            loading ?
                                                <CircularProgress size={20} />
                                                :
                                                null
                                        }
                                    </Button>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                <ConfirmarSolicitudDialog
                    open={smsDialogOpen}
                    onVerify={enviarSolicitud}
                    number={movil}
                    onClose={handleModalCloseMovil}
                />
            </Card>

            <ConfirmDialog
                open={confirmacionDialogOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmModal}
                text={body}
                notificado={false}
                type={'H'}
            />
        </>
    )
}


export default withRouter(CodigoContribuyente);