import { FC } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import { withLiterals } from 'containers/shared/literals';
import ProtectedRoute from "components/protected-route";
import JOCS from './jocs';
import conductor from './multas/conductor';
import Certificados from './certificados';
import MasTramites from './tramites';
import ProcedimientosIndex from './procedimientos/index';
import FormularioMulta from './multas/formulario-multa';
import ConsultasExternas from './consultas/consultas-externas';
import censo from './consultas/consultar-censo/censo';
import ConsultaCid from './consultas/consultar-cid/consulta-cid';
import Embargos from './consultas/consultar-embargos/embargos';
import EmbargosList from './consultas/consultar-embargos/embargos.list';
import SimuladorPlusvalias from './simulador-plusvalias';

const TramitesIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <ProtectedRoute exact path={`${match.path}/certificados`} component={Certificados} accessLevel={2} idModulos={[11]} />
            <ProtectedRoute exact path={`${match.path}/jocs`} component={JOCS} accessLevel={2} idModulos={[13]} />
            <ProtectedRoute exact path={`${match.path}/conductor`} component={conductor} accessLevel={2} idModulos={[15]} />
            <ProtectedRoute exact path={`${match.path}/censo`} component={censo} accessLevel={1} idModulos={[35]} />
            <Route exact path={`${match.path}/embargos`} component={Embargos}  idModulos={[]} />
            <Route exact path={`${match.path}/embargos/list`} component={EmbargosList}  idModulos={[]} />
            <Route exact path={`${match.path}/consulta-cid`} component={ConsultaCid} idModulos={[44]} />
            {/* <Protected Route path={`${match.path}/presentaciones`} component={ExpedientesIndex} accessLevel={2} idModulos={[14]} /> */}
            {/* <ProtectedRoute path={`${match.path}/representaciones`} component={Representaciones} accessLevel={1} idModulos={[10]} /> */}
            <Route exact path={`${match.path}/identificacionConductor`} component={FormularioMulta} />
            <Route path={`${match.path}/procedimientos`} component={ProcedimientosIndex} />
            <ProtectedRoute exact path={`${match.path}/consulta-deuda-externos`} component={ConsultasExternas} accessLevel={2} idModulos={[16]} />
            <Route exact path={`${match.path}/simulador-plusvalia`} component={SimuladorPlusvalias} idModulos={[]}/>

            <Route exact path={`${match.path}/`} component={MasTramites} />
        </>
    );
}

export default withLiterals(['Global', 'Tramites', 'Tributos', 'Wizard', 'Perfil', 'CarteroVirtual'])(withRouter(TramitesIndex));
