import { inject } from "aurelia-dependency-injection";
import { AxiosInstance, AxiosResponse } from "axios";
import moment from 'moment';
import { IPlusvalVendedor, IResultPaso, TDTDatosOperacionM600, TDUSTIInfo, TEndDUTIcausa, TTipoModelo, TTipoTransmisionDerecho, TTipoTransmitente } from "containers/DUTI/steps/types";
import { IBaseResponse } from "./base.response.interfaces";
import { IModelGateway } from "./modelGateway.interface";
import { 
    IDUTIMunicipio, IDUTIstep, IDUTIsubStep, IDatosBienesInmuebles, IDatosCatastroByRefCatastral, 
    IDeclaracion, TBodySavePaso, IInfoSujetoTransmitente, 
    IDeclaracionJustificantes, TBodyCleanPasos, IConsultaEscrituraPublica, IDocumentoAtib, ITramiteMunicipio, ResponseTramitesMunicipio, ITramiteMunicipioChild, ITramiteMunicipioParent, IConceptoM600, IOficina, TTipoDoc, INotario, ITasasTramiteMunicipio, IDetallePagoDeclaracion 
} from "./duti.interface";
import { CustomError } from "utils/custom.error";


@inject('axios')
export default class DUTIGateway {
    constructor(
        private axios: AxiosInstance
    ) {
        if (!axios) {
            throw new Error('ModelGateway requires an axios instance');
        }
    }
    
    public async getPasosByIdPasoPadre (id: number|undefined| null) : Promise<IDUTIstep[] | IDUTIsubStep[]> {
        const response = await this.axios.get(`dusti/get-pasos-by-idPasoPadre?idPasoPadre=${id}`)
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data 
    }

    public async getParentOfSubstep (id:number): Promise<IDUTIstep> {
        const response = await this.axios.get(`dusti/get-paso-padre?idPaso=${id}`)
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data 
    }

    public async getAllMunicipios () : Promise<IDUTIMunicipio[]> {
        const response = await this.axios.get("dusti/all-municipios")
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data
    }

    public async getTramitesMunicipio (idMuni: number, tipoTransm: TTipoTransmitente, actuaNombrePropio: boolean) : Promise<ResponseTramitesMunicipio[]> {
        const response: AxiosResponse<ITramiteMunicipio[]> = await this.axios.get("dusti/tramites-municipio",{ 
            params: {idMunicipio:idMuni, tipoTransmitente: tipoTransm, actuaNombrePropio: actuaNombrePropio}
        })
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        if(response.data.length>0){
            const tParents = response.data.filter(t => t.idTramitePadre === null && t.muniGest !== null) as ITramiteMunicipioParent[]
            const groupedTramites: ResponseTramitesMunicipio[] = tParents.map( p => {
                const childs = response.data.filter(c=> c.idTramitePadre === p.idTramite)
                return({...p, childs: childs as ITramiteMunicipioChild[]})
            });

            return groupedTramites;
        } else { 
            return [];
        }
    }  
    
    public async getTasasTramitesMunicipio (idMuni: number |undefined, tipoTransm: TTipoTransmitente |undefined, actuaNombrePropio: boolean|undefined) : Promise<ITasasTramiteMunicipio[]> {
        if(idMuni && tipoTransm && actuaNombrePropio !== undefined){
            const response: AxiosResponse<ITasasTramiteMunicipio[]> = await this.axios.get("dusti/tasas-tramites-municipio",{ 
                params: {idMunicipio:idMuni, tipoTransmitente: tipoTransm, actuaNombrePropio: actuaNombrePropio}
            });
            if (response.status !== 200) {
                throw new CustomError(response.statusText, response.status);
            }
            return response.data;
        } else {
            return [];
        }
    }   


    public async getInfoRefCatastral (ref: string) : Promise<{ inmueble: IDatosBienesInmuebles|null, ok:boolean, error: {codigo: string, descripcion:string} | undefined}> {
        const response: AxiosResponse<IDatosCatastroByRefCatastral> = await this.axios.get(
            `catastro/getDatosCatastroByRefCatastral`, {
            params: {referenciaCatastral: ref},
            timeout: 60000
        })
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        if( !response.data.ok ){
            return {inmueble: null, ok: response.data.ok, error: response.data.error}
        }
        if(response.data.ok && response.data.bienesInmuebles && response.data.bienesInmuebles.length === 1){
            
            return {inmueble: response.data.bienesInmuebles[0], ok: response.data.ok, error: undefined}
        }
        return {inmueble: null, ok: false, error: undefined}
    }

    public async getAllDeclaraciones (): Promise<IDeclaracion[]>{
        const response = await this.axios.get("dusti")
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async getDeclaracion (idDeclaracion: number): Promise<IDeclaracion>{
        const response = await this.axios.get(`dusti/declaracion?idDeclaracion=${idDeclaracion}`);
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async getDeclaracionJustificantes (idDeclaracion: number): Promise<IDeclaracionJustificantes>{
        const response = await this.axios.get(`dusti/justificantes?idDeclaracion=${idDeclaracion}`);
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }
    
    public async getDetalleDeclaracion (idDeclaracion: number): Promise<TDUSTIInfo>{
        const response = await this.axios.get(`dusti/declaracion-to-object?idDeclaracion=${idDeclaracion}`);
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async getDetallePagoDeclaracion (idDeclaracion: number): Promise<IDetallePagoDeclaracion>{
        const response = await this.axios.get(`dusti/detalle-pago-declaracion?idDeclaracion=${idDeclaracion}`);
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data
    }

    public async checkIfExistTramite (
        idDeclaracion: number, ejercicio: number, nifNotario: string,
        numProtocolo: string, codMuni: string, tipoDerecho: TTipoTransmisionDerecho,
        refCatastro:string, fecha:Date, porcentaje: number
    ) : Promise<{ok: boolean, descripcion?: 'porcentaje-plusval-excedido' | 'escritura-presentada' }> {
        // Validar si hay algun tramite o modelo para esta dusti idDecl o si hay algo presentado fuera de la dusti
        const params = {
            idDeclaracion,
            ejercicio,
            nifNotario, 
            numProtocolo,
            refCatastral: refCatastro,
            fechaDevengo : fecha,
            idMunicipio:codMuni,
            tipoDerecho,
            porcentajeTransmitido:porcentaje,
        }
        const response: AxiosResponse = await this.axios.post(`dusti/estado-autoliquidacion`, params);
        if (response.status !== 201) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async getDocAtib (idDoc:string) : Promise<IDocumentoAtib>{
        const params : {idDocumento:string}= {idDocumento: idDoc}
        const response: AxiosResponse = await this.axios.get(`dusti/doc-atib`, {params: params})
        //console.log('getDocAtib - Response: ', response)
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        if( !response.data.ok && response.data.error){
            throw new Error (response.data.error.descripcion)
        }

        const out : IDocumentoAtib = {
            idDocumento: response.data.idDocumento,
            NombreFichero: response.data.NombreFichero,
            TipoFichero: response.data.TipoFichero,
            FechaAlta: response.data.FechaAlta,
            Referencia: response.data.Referencia,
            datosFichero: response.data.datosFichero,
            NRD: response.data.NRD
        }
        
        return out;
    }
    
    public async getEscrituraPublica (fecha:Date|undefined, anyo: number|undefined, numProtocolo: string|undefined,nifNotario:string|undefined) : Promise<IDocumentoAtib> {
        if(!fecha ||!anyo ||!numProtocolo || !nifNotario){ throw new Error('not_enought_info_consulta_escritura')}
            const params : IConsultaEscrituraPublica= {
            fecha: fecha,
            anyo: anyo,
            numProtocolo: numProtocolo,
            nif: nifNotario
        }
        const response: AxiosResponse = await this.axios.get(`dusti/escritura-publica`, {params: params})
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        if( !response.data.ok && response.data.error){
            throw new Error (response.data.error.descripcion)
        }

        const out : IDocumentoAtib = {
            idDocumento: response.data.idDocumento,
            NombreFichero: response.data.NombreFichero,
            TipoFichero: response.data.TipoFichero,
            FechaAlta: response.data.FechaAlta,
            Referencia: response.data.Referencia,
            datosFichero: response.data.datosFichero,
            NRD: response.data.NRD
        };
        
        return out;
    }

    public async getEscrituraPublicaByNRD (nrd:string): Promise<{ 
        info: { fecha: Date, anyo: number, protocolo: string, nifNotario: string, notario:string, idEscritura:string} | null, 
        escritura: IDocumentoAtib | null
    }>{
        try {
            const params = {NRD: nrd}
            const response: AxiosResponse = await this.axios.get(`dusti/escritura-publica-by-nrd`, {params: params})
            if (response.status !== 200) {
                throw new CustomError(response.statusText, response.status);
            }
            if( !response.data.ok && response.data.error){
                throw new Error (response.data.error.descripcion)
            }
            
            return response.data

        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async checkNRD (fecha: Date|undefined, anyo: number|undefined, numProtocolo: string, nifNotario: string, NRD:string): Promise<boolean>{
        try {
            const params = {fecha,anyo,numProtocolo,nifNotario, NRD}
            const response: AxiosResponse = await this.axios.get(`dusti/check-nrd?idDeclaracion`,{params: params})
            if (response.status !== 200) {
                throw new CustomError(response.statusText, response.status);
            }
            return response.data
        } catch (error) {
            throw new Error(error as string);
        }
    }
    

    public async searchNotarios (nomApe: string) : Promise<INotario[]> {
        const response = await this.axios.get(`dusti/buscar-notarios`, {params: {nomApe: nomApe}})
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data
    }

    public async cancelDeclaracion(idDUSTI: number, idPaso:number, motivo?: TEndDUTIcausa): Promise<boolean> {
        const body = {
            idDeclaracion: idDUSTI,
            idPaso: idPaso,
            causaCancel: motivo,
            status: 'CAN'
        }
        const response: AxiosResponse<boolean> = await this.axios.post(`dusti/cambio-estado-declaracion`, body)

        if (response.status !== 200 && response.status !== 201 ) {
            throw new CustomError(response.statusText, response.status);
        }
        // saved object
        return response.data
    }
    
    public async savePaso<T extends any>(idDeclaracion:number | null, idPaso: number, pasoInfo: T): Promise<{idDeclaracion: number, saved: boolean, infoDeclaracion: TDUSTIInfo}>{
        const body :TBodySavePaso<T> = {
            idDeclaracion, 
            idPaso: idPaso, 
            datosPaso: pasoInfo 
        }
        const response: AxiosResponse = await this.axios.post(
            `dusti/save-paso`, body
        )

        if (response.status !== 200 && response.status !== 201 ) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async calcularModelo(
        tipoModelo: TTipoModelo,
        idDeclaracion: number | null,
        idGestion: number | null,
        idGestionSujeto: number | null,
        infoModelo: TDTDatosOperacionM600 | IPlusvalVendedor,
    ){
        const response = await this.axios.post(
            `dusti/calcular-modelo?idDeclaracion=${idDeclaracion}`, 
            {
                tipoModelo,
                infoModelo,
                idGestion,
                idGestionSujeto,
            },
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;        
    }

    public async cleanPasos(idDeclaracion: number | null, idPasos: number[]): Promise<IResultPaso>{
        const body :TBodyCleanPasos = {
            idDeclaracion, 
            idPasos: idPasos,
        }
        // console.log('call savePaso ', body )
        const response: AxiosResponse<{idDeclaracion: number, saved: boolean}> = await this.axios.post(
            `dusti/clean-pasos`, body
        )

        if (response.status !== 200 && response.status !== 201 ) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async confirmarPago(idOperacion: string): Promise<IBaseResponse<IModelGateway>> {
        const response = await this.axios.post(`dusti/confirmar-pago?idOperacion=${idOperacion}`);

        if (response.status !== 201 && response.status !== 403) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async finalizarModelosDusti(idDeclaracion: number, modelos: string[]): Promise<boolean> {
        const response = await this.axios.put(`dusti/finalizar-modelos?idDeclaracion=${idDeclaracion}`, modelos);

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async finalizarDusti(idDeclaracion: number): Promise<{ ok: boolean; pago?: boolean }> {
        const response = await this.axios.post(`dusti/finalizar-declaracion?idDeclaracion=${idDeclaracion}`);

        if (response.status !== 201) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async getSujeto(nif: string): Promise<IInfoSujetoTransmitente | null>{
        const response = await this.axios.get(`dusti/sujeto?nifSujeto=${nif}`);
        if (response.status !== 200) {
            return null;
        }
        return response.data 
            // ? {
            //     ...response.data,
            //     direccion:{
            //         ...response.data.direccion, 
            //         pais:{
            //             ...response.data.direccion.pais, 
            //             nombre: response.data.direccion.direccion.pais.nombre.trim()
            //             }
            //         },
            //     } as IInfoSujetoTransmitente
            // : null
            // )
    }

    public async getConceptosM600 (ejercicio:string): Promise<IConceptoM600[]>{
        const response = await this.axios.get(`dusti/conceptos-m600`, {params: {
            ejercicio: ejercicio
        }});
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async getOficinas (): Promise<IOficina[]>{
        const response = await this.axios.get(`dusti/oficinas-registro`);
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }
    
    public async adjuntarDoc(idDeclaracion: number,tipoDoc: TTipoDoc, doc:File, tipoModelo: TTipoModelo): Promise<string>{
        const data = new FormData();
        data.append('file', doc);
        const response: AxiosResponse<string> = await this.axios.post(`dusti/adjuntar-documento?idDeclaracion=${idDeclaracion}&tipoDocumento=${tipoDoc}&tipoModelo=${tipoModelo}`, data)
        if ((response.status !== 200 && response.status !== 201 ) || (response.data === '')) {
            throw new CustomError(response.statusText, response.status);
        }
        // saved object
        return response.data
    }

    public async eliminarDocumento(idDeclaracion: number, idDocumento: string): Promise<boolean> {
        const response: AxiosResponse<boolean> = await this.axios.delete(`dusti/eliminar-documento?idDeclaracion=${idDeclaracion}&idDocumento=${idDocumento}`)
        if ((response.status !== 200 && response.status !== 201 )) {
            return false;
        }
        // saved object
        return response.data
    }

    public async print(
        idDeclaracion: number,
        tipoDocumento: TTipoDoc,
    ): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get(
            `dusti/print?idDeclaracion=${idDeclaracion}&tipoDocumento=${tipoDocumento}`, {
            responseType: 'blob',
        });

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'justidicante_dusti.pdf';

        return {
            fileName,
            blob: response.data
        };
    }

    public async printAll(idDeclaracion: number): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get(`dusti/print-all?idDeclaracion=${idDeclaracion}`, {
            responseType: 'blob',
        });

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'justidicante_dusti.pdf';

        return {
            fileName,
            blob: response.data
        };
    }

    public async getUrlPagoModelo(idModelo: string): Promise<string | null> {
        const response = await this.axios.get(`dusti/url-pago-modelo?idModelo=${idModelo}`);

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async getUrlPagoModelos(idDeclaracion: number): Promise<string | null> {
        const response = await this.axios.get(`dusti/url-pago-modelos?idDeclaracion=${idDeclaracion}`);

        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async presentarTramite(idExpediente: number): Promise<string | null> {
        const response = await this.axios.get(`dusti/url-presentar-tramite?idExpediente=${idExpediente}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new CustomError(response.statusText, response.status);
        }

        return response.data;
    }

    public async getValorReferencia (refCat: string, fecha:Date): Promise<number|undefined>{
        const response = await this.axios.get(`dusti/valor-referencia?refCatastral=${refCat}&fecha=${moment(fecha).format('DD/MM/YYYY')}`);

        if (response.status !== 200 && response.status !== 403) {
            return undefined;
        }
        return response.data;
    }

    public async esPersonaFisica (nif:string): Promise<boolean>{
        const response = await this.axios.get(`dusti/es-persona-fisica?nifSujeto=${nif}`);
        if (response.status !== 200) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }

    public async validarFechaNacimiento(nif: string, fecha: Date): Promise<boolean>{
        const response = await this.axios.get(`dusti/validar-fecha-nacimiento?nif=${nif}&fecha=${fecha}`);
        if (response.status !== 200 && response.status !== 403) {
            throw new CustomError(response.statusText, response.status);
        }
        return response.data;
    }
}
