
import { IInfoSujetoTransmitente } from "gateways/duti.interface";
import { TSelect, TSelectDUTI } from "../utils";
import { EMPTY_DIRECTION, } from "components/DUTI/direction-form";
import { EMPTY_CONTACTO, TContacto } from "components/DUTI/contact-form";
import { TDirectionATIB } from "components/DUTI/direction-form-ATIB";
import { TTipoInmueble, TUsoInmueble } from "gateways/catastro.interface";
import { IInfoModeloPLV } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";


export type TTipoTransmisionInterVivos = 'compra-venta' | 'donacion' | 'otras-transm';
export type TTipoTransmisionDerecho = 'pleno-dominio';
export type TTipoDocTransmision = 'publico-notarial' |'privado' |'publico-administrativo' | 'publico-judicial';
export type TTipoM600 = 'transm-patrimonial'| 'acto-juridico-docum'
export type TTipoModelo= '600'|'080'|'081'|'082'

// --------------------------------------------------------------------------------------------------------------------------------------------------
// 0 - DATOS TRANSMISION 
// --------------------------------------------------------------------------------------------------------------------------------------------------

// Datos previos
export type TTipoTransmitente = 'comprador' |'vendedor';
export type TTipoConceptoGestion = 'DUSTI' | 'PLUSVAL' | 'IBI' | 'TASAS_MUNI' | 'COM_DOMFISC' | 'MODELO600'; //'AUTOLIQ_PLUSVAL'|
export type TMuniGest = Record<TTipoConceptoGestion, boolean>
export type TUserSelection = {idTramite: number; selected: boolean}[]
//export type TTramitesGest = Record<TTipoConceptoGestion, boolean | Record<string, boolean | undefined> >
export type TTramitesGest = {
    [K in TTipoConceptoGestion]: 
        K extends 'PLUSVAL' ? {autoliquidacion: boolean,declaracion:boolean,comunicacion:boolean, sujetoPasivo?:boolean} | false
            : K extends 'MODELO600' ? {autoliquidacion?: boolean, autoliquidacionPresentacion?:boolean} | false
                : boolean
}

// Info Catastral
export type TDTInfoCatastral_DatosEconomicos = {
    anyoValorCatastral?: string;
    valorCatastral?: string;
    valorCatastralSuelo?: string;
    valorCatastralConstruccion?: string;
    valorReferencia?: string;
    uso: TUsoInmueble;
    superficieConstruida?: string;
}
export type TDTInfoCatastral = {
    refCatastral: string ;
    tipoBien: TTipoInmueble | undefined;
    direccion: string | undefined,
    datosEconomicos: TDTInfoCatastral_DatosEconomicos |undefined
    urlCatastro:string,
    urlGoogle:string;
}
export type TRepresentante= {
    nif: string;
    nomApe: string;
    direccion: TDirectionATIB;
    contacto: TContacto;
    //direccionFiscal: TDireccionFiscal;
    personaFisica: boolean;
}

export type TDTPrevios = {
    idDeclaracion: number | null,
    muniGest: TMuniGest,
    tramitesGest: TTramitesGest,
    municipio?: TSelectDUTI,
    esComplementaria?: boolean,
    esPlenoDominio?: boolean,
    actuaNombrePropio?: boolean,
    nifSujeto?: string,
    representante?: TRepresentante | undefined,
    tipoTransmitente?: TTipoTransmitente,
    infoCatastral?:TDTInfoCatastral | undefined
}


// Info Transmision -- con o sin datos PLUSVALI
export type TInfoTransPlusval = {
    // fechaAdquisicion: Date | undefined;
    // porcentajeTransmision: number;
    oficina: string | undefined; //TSelect | undefined;
    tomo: string | undefined;
    libro: string | undefined;
    folio: string | undefined;
    finca: string | undefined;
}
export type TDTInfoTransmision = {
    withPlusval: boolean;
    refCatastral: string;
    codMuni: string;
    fecha: Date | undefined;
    tipoTransmision: TSelect | undefined;
    derechoTransmision: TSelect | undefined;
    tipoDocTransmision: TSelect| undefined;
    anyo: number| undefined;
    numeroProtocolo: string |undefined;
    nifNotario: string | undefined;
    notario: string | undefined;
    nrd: string | undefined;
    plusval: TInfoTransPlusval;
    valorDeclarado: number,
    porcentaje: number,
    idDocumentoEscritura: string
}

// Info trnasmisión Vendedor / comprador
export type TTipoDirFisc= 'fiscal'|'inmueble'|'sujeto'
export type TDireccionFiscal = {
    tipo: TTipoDirFisc,
    direccion: string,
} 
export type TPTPlusval = {
    fechaAdquisicion: Date | undefined;
    sujetoPasivo: boolean,
    nifSujetoPasivo: string | undefined
}
export interface IPersonaTransmitente {
    nif: string,
    nomApe: string,
    fechaNacimiento: Date | undefined
    direccion: TDirectionATIB,
    contacto: TContacto,
    porcentaje: number,
    direccionFiscal: TDireccionFiscal | undefined,
}
export interface IComprador extends IPersonaTransmitente {viviendaHabitual: boolean}
export interface IVendedor extends IPersonaTransmitente {numOperacionesAdquisicion: number}
export type TDTDatosComprador= IComprador[] 
export type TDTDatosVendedor = IVendedor[] 
export type TPlusvOperacion = {
    fechaAnteriorAdquisicion: Date | undefined;
    porcentajeAnteriorAdquisicion: number | undefined;
    importeAnteriorAdquisicion: number | undefined;
    idEscrituraAnterior: string | undefined,
}
export interface IPlusvalVendedor {
    nifVendedor: string;
    operaciones: TPlusvOperacion[]
    sujetoPasivo: boolean
    nifSujetoPasivo: string
    idGestion: number | undefined;
    idGestionSujeto: number | undefined;
    modelo080?: TDatosM08x,
    modelo081?: TDatosM081,
    modelo082?: TDatosM08x,
}

export type TDTDatosVendedorPlusval = IPlusvalVendedor[]

export type TDTDocTransmision = {
    idDocumentoEscritura?: string 
    idDocumentoAdicional?: string
    declaracionResponsable: boolean
}
export type TDTDatosOperacionM600 = {
    concepto: TSelect | undefined;
    exento: boolean;
    noSujeto: boolean;
    observaciones: string | undefined;
    baseImponible: number;
    tipo: TSelect | undefined;
    tipoAplicable: number | undefined;
    cuotaIntegra: number | undefined;
    interesesDemora: number | undefined;
    recargo: number | undefined;
    pagaRecargo: boolean;
    reduccion: number | undefined;
    reduccionSobreImponible: number | undefined;
    bonificacion: number | undefined;
    bonificacionSobreCuota: number | undefined;
    totalIngresar: number | undefined;
    idExpedientePresentado: string | undefined;
    baseLiquidable?: number;
    aIngresar?: number;
}

export interface TDatosM08x {
    nifSujetoPasivo: string | undefined,
    otrosSupuestos?:string,
    fechaPresentacion?: Date,
    valorTransmision?: number, // aplica en 080 y 082
    valorAdquisicion?: number // aplica 080
    tipoDocAdquisicion?: string // aplica 080
    fechaDocAdquisicion?: Date //aplica 080
}

export interface IDatosAdquisicion {
    metodoObjetivo: {
        fechaAdquisicion: Date;
        porcentaje: number;
        importeAnteriorAdquisicion : number | null
        valorParcial: number;
        anyosTenencia: number;
        tipoAnual: number;
        baseImponible: number;
        tipoGravamen: number;
        cuotaIntegra: number;
        valorSuelo: number;
        valorCatastral: number;
    }
    metodoReal: { 
        fechaAdquisicion: Date;
        porcentaje: number;
        importeAnteriorAdquisicion : number | null
        valorParcial: number;
        anyosTenencia: number;
        tipoAnual: number;
        baseImponible: number;
        tipoGravamen: number;
        cuotaIntegra: number;
        valorSuelo: number;
        valorCatastral: number;
    } | null
}
export interface InfoModelo081 {
    baseImponible?: number ;
    importeMetodoObjetivo: number |null;
    importeMetodoReal: number | null;
    reduccion?:number;
    bonificacion?: number;
    tipoGravamen?: number;
    cuotaLiquida?: number;
    cuotaIntegra?:number;
    recargo?: number;
    interesesDemora?:number;
    importeAdquisicion?: number; // Suma de adquisiciones definidas en Operaciones
    importeTransmision?: number; // Calculado
    totalIngresar: number | undefined;
    adquisiciones: IDatosAdquisicion[];
}
export interface TDatosM081 extends InfoModelo081 {
    declaracionResponsable: boolean,
    nifSujetoPasivo: string | undefined,
    fechaPresentacion?: Date,
    // idEscrituraAnterior: string | undefined,
    // importeMetodoObjetivo: number | undefined
    // importeMetodoReal: number | undefined
    // importeAnteriorTransmision: number | undefined
    // Paso borrador => Datos Plusval
    // comunes - otrosSupuestos?:string,
    // comunes - fechaPresentacion?: Date,
    fechaDevengo?: Date, 
    // reduccion?:number,
    // bonificacion?: number,
    // recargo?: number,
    valorSueloReducido?: number,
    porcentajeTransm: number | undefined,
    edadUsufructuaria?: number,
    fechaAdquisicionBien?: Date,
    // // Paso borrador => Liquidacion
    // baseImponibleReal?: number,
    // baseImponibleObjetivo?: number,
    // tipoGravamen?: number,
    // cuotaIntegraReal?:number,
    // cuotaIntegraObjetiva?:number,
    // bonificacion081?: number,
    // cuotaLiq081?: number,
    // interesesDemora081?:number ,
    // recargo081?: number ,
    // importeAdquisicion081?: number, //Suma de adquisiciones definidas en Operaciones
    // importeTransmision081?: number, // Calculado
    // totalIngresar: number | undefined,
    
}

// export type TDTDatosOperacionPlusval = {
//     modelo080: TDatosM080[],
//     modelo081: TDatosM081[],
//     modelo082: TDatosM082[],
// }

export interface TPasoDatosTransmision {
    datosPrevios: TDTPrevios;
    // infoCatastral: TDTInfoCatastral;
    infoTransmision: TDTInfoTransmision;
    datosComprador: TDTDatosComprador
    datosVendedor: TDTDatosVendedor;
    documentacionTransmision: TDTDocTransmision
    datosOperacionModelo600: TDTDatosOperacionM600,
    datosOperacionPlusvalia: TDTDatosVendedorPlusval
}

// Causas FIN DUTI
export type TCausaUserChoice= 'continuar-mas-tarde'
export type TCausaDatosTransmPrevios= 'municipio-seleccionado'|'refCat-diff-muni'| 'es-complemntaria'| 'no-pleno-dominio' | 'no-tramites'
export type TCausaDatosTransmInfoTransm = 'porcentaje-plusval-excedido' | 'escritura-presentada' | 'escritura-no-encontrada' | TCausaUserChoice
export type TCausaDatosTramGenerados = 'no-selecciona-tramites' | TCausaUserChoice
export type TEndDUTIcausa = TCausaUserChoice | TCausaDatosTransmInfoTransm | TCausaDatosTransmPrevios | TCausaDatosTramGenerados
// fin DATOS TRANSMISION ---------------------------------------------------------------------------------------------------------------------------


// --------------------------------------------------------------------------------------------------------------------------------------------------
// 1 - TRAMITES GENERADOS
// --------------------------------------------------------------------------------------------------------------------------------------------------
export type TPasoTramitesGenerados = {
    modelo600: boolean,
    modelo080: boolean,
    modelo081: boolean,
    modelo082: boolean,
    comunicacionCatastro: boolean,
    comunicacionTasasATIB: boolean,
    comunicacionDomicilioFiscalAEAT:boolean
}

// fin TRAMITES GENERADO ---------------------------------------------------------------------------------------------------------------------------



// --------------------------------------------------------------------------------------------------------------------------------------------------
// 2 - PAGO FINALIZACION
// --------------------------------------------------------------------------------------------------------------------------------------------------
export type TPasoFinalizacionPago = {

}

// fin PAGO FINALIZACION ----------------------------------------------------------------------------------------------------------------------------



// --------------------------------------------------------------------------------------------------------------------------------------------------
// 3 - JUSTIFICANTES 
// --------------------------------------------------------------------------------------------------------------------------------------------------
export type TPasoJustificantes = {
    
}

// fin JUSTIFICANTES --------------------------------------------------------------------------------------------------------------------------------


// OBJETO Info DUSTI
export type TNameStep =   'DatosTransmision' | 'TramitesGenerados' | 'FinalizacionPago' | 'Justificantes' // Padres
export type TNameSubStep = 'datosPrevios'| 'infoCatastral'| 'infoTransmision' |'datosComprador' | 'datosVendedor' 
                            |'datosOperacionModelo600' |'datosOperacionPlusvalia' ; 
export type TNameDUTIstep = TNameStep | TNameSubStep

/**
 * null - Paso que aún no ha sido iniciado
 * INI - Paso iniciado que no ha finalizado
 * FIN - Paso que ha sido completado
 * EDT - Paso que ha sido finalizado, pero hemos vuelto atrás
 */
export type TStatusStep = 'INI'|'FIN'| 'EDT'| null;

export type TDUSTIInfo = {
    [K in TNameStep]: 
        K extends 'DatosTransmision' ? TPasoDatosTransmision
            : K extends 'TramitesGenerados' ? TPasoTramitesGenerados
                : K extends 'FinalizacionPago' ? TPasoFinalizacionPago : TPasoJustificantes
}





// CONSTANTES  ---------------------------------------------------------------------------
// 1 - EMPTY OBJECTS 
export const EMPTY_TRAMITES_GEST: TTramitesGest= {
    DUSTI: true,
    TASAS_MUNI: true,
    COM_DOMFISC: true,
    IBI: false,					
    PLUSVAL: {
        sujetoPasivo: undefined,
        declaracion: false,
        autoliquidacion: false,
        comunicacion: false,
    },
    MODELO600:{
        autoliquidacion: undefined,
        autoliquidacionPresentacion:undefined
    }
}
export const EMPTY_DT_PREVIOS: TDTPrevios = {
    idDeclaracion: null,
    muniGest: {
        DUSTI: false, PLUSVAL: false,  IBI: false, TASAS_MUNI: false,
        COM_DOMFISC: false,
        MODELO600: false
    },
    municipio: undefined,
    esComplementaria: undefined,
    esPlenoDominio: undefined,
    actuaNombrePropio: undefined,
    representante: undefined,
    tipoTransmitente: undefined,
    nifSujeto: undefined,
    infoCatastral: undefined,
    tramitesGest: {...EMPTY_TRAMITES_GEST}
}

export const EMPTY_DT_INFOCATASTRAL : TDTInfoCatastral={
    refCatastral: '',
    tipoBien: undefined,
    direccion: undefined,
    datosEconomicos: undefined,
    urlCatastro: "",
    urlGoogle: ""
} 
export const EMPTY_DT_INFOTRANS_PLUSV: TInfoTransPlusval = {
    // fechaAdquisicion: undefined,
    // porcentajeTransmision: 0,
    oficina: undefined,
    tomo: undefined,
    libro: undefined,
    folio: undefined,
    finca: undefined,
}
export const EMPTY_DT_INFOTRANSMISION: TDTInfoTransmision = {
    withPlusval: false,
    refCatastral: "",
    codMuni: "",
    fecha: undefined,
    tipoTransmision: undefined,
    derechoTransmision: undefined,
    tipoDocTransmision: undefined,
    anyo: undefined,
    numeroProtocolo: undefined,
    nifNotario: undefined,
    notario: undefined,
    nrd: undefined,
    plusval: { ...EMPTY_DT_INFOTRANS_PLUSV },
    valorDeclarado: 0,
    porcentaje: 0,
    idDocumentoEscritura: ""
}
export const EMPTY_VENDEDOR_PLUSVAL: TPTPlusval = {
    fechaAdquisicion: undefined,
    nifSujetoPasivo:undefined,
    sujetoPasivo: true
}
export const EMPTY_DT_DATOSVENDEDOR: IPersonaTransmitente = {
    nif: "",
    nomApe: "",
    fechaNacimiento: undefined,
    direccion: EMPTY_DIRECTION as TDirectionATIB,
    contacto: EMPTY_CONTACTO,
    porcentaje: 0,
    direccionFiscal: undefined,
    //plusval: {...EMPTY_VENDEDOR_PLUSVAL}
}

export const EMPTY_DT_DATOSCOMPRADOR: IComprador = {
    viviendaHabitual: false,
    nif: "",
    nomApe: "",
    fechaNacimiento: undefined,
    direccion: EMPTY_DIRECTION as TDirectionATIB,
    contacto: EMPTY_CONTACTO,
    porcentaje: 0,
    direccionFiscal: undefined,
}

export const EMPTY_DT_DOCTRANSMISION :TDTDocTransmision = {
    declaracionResponsable: false,
    idDocumentoAdicional: undefined,
    idDocumentoEscritura: undefined,
};
export const EMPTY_DT_DATOSOPERACION_M600 :TDTDatosOperacionM600= {
    baseImponible: 0,
    concepto: undefined,
    exento: false,
    noSujeto: false,
    observaciones: undefined,
    cuotaIntegra: undefined,
    interesesDemora: undefined,
    recargo: undefined,
    pagaRecargo: false,
    reduccion: undefined,
    reduccionSobreImponible: undefined,
    bonificacion: undefined,
    bonificacionSobreCuota: undefined,
    totalIngresar: undefined,
    idExpedientePresentado: undefined,
    tipo: undefined,
    tipoAplicable: undefined,
};

export const EMPTY_PLUSVAL_OPERACION: TPlusvOperacion = {
    fechaAnteriorAdquisicion:undefined,
    porcentajeAnteriorAdquisicion: undefined,
    importeAnteriorAdquisicion: undefined,
    idEscrituraAnterior: undefined
}

export const EMPTY_DT_DATOSOPERACION_PLUSVAL :IPlusvalVendedor= {
    nifVendedor: "",
    operaciones: [],
    sujetoPasivo: false,
    nifSujetoPasivo: "",
    idGestion: undefined,
    idGestionSujeto: undefined
};

export const EMPTY_TG: TPasoTramitesGenerados = {
    modelo600: false,
    modelo080: false,
    modelo081: false,
    modelo082: false,
    comunicacionCatastro: false,
    comunicacionTasasATIB: false,
    comunicacionDomicilioFiscalAEAT: false
}


export const EMPTY_MUNIGEST : TMuniGest= {
    DUSTI: false, PLUSVAL: false, IBI: false, TASAS_MUNI: false,
    COM_DOMFISC: false,
    MODELO600: false
}

// RADIO BUTOTNS options ------------------------------------------------------------------------------------------------------------------------------
export const fieldSiNo_OPTIONS = [
    {id: true, term: 'si'},
    {id: false, term: 'no'},
]
export const fieldActuaNombrePropio_OPTIONS = [
    {id: true, term: 'DT_ActuoNombrePropio_radioBtn_si'},
    {id: false, term: 'DT_ActuoNombrePropio_radioBtn_no'},
]
export const fieldTipoTransmisionInterVivos_OPTIONS : { id: TTipoTransmisionInterVivos; term: string; }[]=  [
    {id: 'compra-venta', term: 'DT_TipoTransmision_radioBtn_compra_venta'},
    // {id: 'donacion', term: 'DT_TipoTransmision_radioBtn_donacion'},
    // {id: 'otras-transm', term: 'DT_TipoTransmision_radioBtn_otras_transm'},
]
export const fieldTipoTransmitente_OPTIONS : { id: TTipoTransmitente; term: string; }[]=  [
    {id: 'comprador', term: 'DT_TipoTransmitente_radioBtn_comprador'},
    {id: 'vendedor', term: 'DT_TipoTransmitente_radioBtn_vendedor'},
]


// SELECTORES OPTIONS => opt:string to make TSelect id: opt, nombre translate('DUTI','opt', terms) ----------------------------------------------------
export const TipoTransmision_OPTIONS : TTipoTransmisionInterVivos[]= ['compra-venta']
export const TipoDocTransmision_OPTIONS : TTipoDocTransmision[]= ['publico-notarial']
export const TipoDerechoTransmision_OPTIONS: TTipoTransmisionDerecho[]=  ['pleno-dominio']
export const TipoM600_OPTIONS: TTipoM600[]=  ['transm-patrimonial', 'acto-juridico-docum']

export interface IResultPaso {
    saved: boolean,
    idDeclaracion: number,
    error?: Error,
}