import { Button, Grid, IconButton, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import InputNumber from "components/DUTI/input-number";
import InputSelect from "components/DUTI/input-select";
import { TSelect } from "containers/DUTI/utils";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { TCalcPlusvalia, TCalculo, TErrorsCalcPlusvalia, TOperacion, TOperacionErr } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { getTiposDerechoTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import {FC, useContext, useEffect, useMemo, useState} from "react";
import { translate } from "utils/i18n";
import InputText from "components/DUTI/input-text";
import { cloneDeep } from "lodash";
import FullLoading from "components/full-loading";
import { BLUE } from "containers/shared/colors";
import Modal from "components/modal";
import IoC from "contexts/ioc.context";
import { DocumentosGateway } from "gateways/documentos.gateway";
import { TTipoInmueble } from "gateways/catastro.interface";
import TypedInputNumber from "components/DUTI/typed-input-number";
import { mdiEyeCircleOutline, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import CalcPlusvaliaAddCalculo from "./calcPlusvaliaAddCalculo";

type TCalculoError= Record<keyof TCalculo,boolean> 
const EMPTY_ERR: TErrorsCalcPlusvalia = {
    nifVendedor: false,
    sujetoPasivo: false,
    nifSujetoPasivo:false,
    calculos:false,
}

const EMPTY_CALCULO: TCalculo = {
    tipoDerecho: undefined,
    edadUsufructuaria: undefined,
    porcentaje: 0,
    numOperaciones: 0,
    operaciones: [],
    idGestion: undefined,
    idGestionSujeto: undefined,
    modelo: undefined,
    declaracionResponsable: undefined
}
const EMPTY_CALCULO_ERR: TCalculoError = {
    tipoDerecho: false,
    edadUsufructuaria: false,
    porcentaje: false,
    numOperaciones: false,
    operaciones: false,
    idGestion: false,
    idGestionSujeto: false,
    modelo: false,
    declaracionResponsable: false
}
const EMPTY_OPERACION: TOperacion = {
    fechaAnteriorAdquisicion: undefined,
    porcentajeAnteriorAdquisicion: 0,
    importeAnteriorAdquisicion: undefined,
    idDocAnteriorAdquisicion: undefined
}

interface IPropsOperacionPLV extends WithStyles<typeof styles>{
    renderType: 'form' | 'detail'
    initialValue: TCalcPlusvalia;
    onChange: (v: TCalcPlusvalia, action: 'save'|'cancel') => void
    idRef:string
    apartado: string
    fechaTransmision: Date
    valorDeclarado: number|undefined
    infoVend: { nif:string, nomApe: string, titularidad: number}
    compradoresList: TSelect[]
    tipoModelo: '080'| '081' |'082'
    tipoBien?: TTipoInmueble
    tipoBienMSG?: string
}



const CalcPlusvaliaForm : FC<IPropsOperacionPLV>= ({
    classes, renderType, initialValue, onChange, idRef,apartado,fechaTransmision, infoVend, compradoresList, tipoModelo, tipoBien, tipoBienMSG, valorDeclarado
}) => {
    const terms= useContext(LiteralsContext)

    //Gateways
    const ioc = useContext(IoC);
    const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);
    
    
    const OPTs = getTiposDerechoTransmsisionByTipoAsistente('plusvalia', terms)

    const [localInfo, setLocalInfo]= useState({} as TCalcPlusvalia)
    const [localInfoErr, setLocalInfoErr]= useState<TErrorsCalcPlusvalia>({...EMPTY_ERR})
    const [loading, setLoading]= useState(false)


    const [calc, setCalc]= useState<TCalculo>(EMPTY_CALCULO)
    const [calcErr, setCalcErr]= useState<TCalculoError>(EMPTY_CALCULO_ERR)

    const [showAddCalculos, setShowAddCalculos] = useState(false) 
    const [infoToShow, setInfoToShow] = useState<TCalculo | undefined>(undefined)
    const [showDetail, setShowDetail] = useState(false)

    //const [showModalRU, setShowModalRU]= useState(false) 
    const isRU = useMemo(() => (tipoBien && tipoBien==="RU") ? true : false, [tipoBien])

    const EMPTY_INFO_ERR: TErrorsCalcPlusvalia =useMemo(()=> ({...EMPTY_ERR}), [initialValue])

    // Porcentaje disponible por tipo de derecho
    const [percentPlenoDom, setPercentPlenoDom] = useState(100)
    const [percentOtro, setPercentOtros] = useState(1000)


    const OPTsDispo = useMemo(() => {
        let optDispo=OPTs
        if(percentPlenoDom === 0){
            optDispo = OPTs.filter(opt => opt.id !== 'pleno-dominio')
        }
        if(percentOtro === 0){
            optDispo = OPTs.filter(opt => opt.id === 'pleno-dominio')
        }

        if(localInfo.calculos){
            const tipoDer = localInfo.calculos.map( c => c.tipoDerecho?.id)
            const td_dispo = optDispo.filter( opt => !tipoDer.includes(opt.id))
            return  td_dispo
        } else {
            return optDispo
        }
        
    },[OPTs, localInfo, percentPlenoDom, percentOtro])

    const onSave= async () => {
        // Validate 
        let err = Object.values(localInfoErr).includes(true) 
            && (!localInfo.nifSujetoPasivo  || localInfo.nifSujetoPasivo === '') 
            && (tipoModelo!== '082' ? localInfo.calculos.length>0  : localInfo.calculos.length===0 );
        
        if( !err ){   
            onChange(localInfo, 'save')
        } else {
            setLocalInfoErr({
                nifSujetoPasivo: localInfo.nifSujetoPasivo === undefined,
                nifVendedor:  localInfo.nifVendedor === '',
                sujetoPasivo: false,
                calculos: tipoModelo!== '082' && localInfo.calculos.length === 0
            })
        }
       
    }

    const onSaveCalc= (calc: TCalculo) => {
        const newC = cloneDeep(localInfo.calculos)
        newC.push(calc)
        setLocalInfo( curr => ({...curr, calculos: newC}))
        setCalc(EMPTY_CALCULO)
        setCalcErr({...EMPTY_CALCULO_ERR})
    }
   

    const onCancel = async () => {
        setLocalInfo({...initialValue})
        setLocalInfoErr({...EMPTY_INFO_ERR})
        setCalc({...EMPTY_CALCULO})
        setCalcErr({...EMPTY_CALCULO_ERR})
        onChange({...initialValue}, 'cancel')
    }

    

    const onAdd = () => { 

        const asDisabled = !calc.tipoDerecho 
            || (calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio' && (!calc.edadUsufructuaria || calc.edadUsufructuaria===0))
            || calc.numOperaciones===0 || calc.porcentaje === 0

        if(asDisabled){
            setCalcErr(curr=> ({
                ...curr,
                tipoDerecho: calc.tipoDerecho===undefined ,
                edadUsufructuaria: (calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio' && (!calc.edadUsufructuaria || calc.edadUsufructuaria===0) )? true : false ,
                numOperaciones: calc.numOperaciones===0,
                porcentaje: calc.porcentaje === 0
            }))

        } else{

            if(tipoModelo !=='082'){
                setCalc(curr =>({
                    ...curr,
                    operaciones: Array(curr.numOperaciones).fill({...EMPTY_OPERACION})
                }))
                setShowAddCalculos(true) 
            } else {

                // Modelos 082 y 080 No requieren calculos
                const newC = cloneDeep(localInfo.calculos)
                newC.push(calc)
                setLocalInfo( curr => ({...curr, calculos: newC}))
                setCalc(EMPTY_CALCULO)
            }
            
        }
        
    }


    const handleDeleteCalc = async (index:number) => {
        // Ver si tiene operaciones con doc Adjunto y eliminarlos
        let errDeleteDoc= false
        if(tipoModelo === '081' && localInfo.calculos[index] && localInfo.calculos[index].operaciones){

            const idDocDelete = localInfo.calculos[index].operaciones.map( op => op.idDocAnteriorAdquisicion)
            const allOK = await Promise.all( 
                idDocDelete.map(async (id) => id? await documentosGateway.deleteDocAtib(id) : true)
            )
            if(allOK.includes(false)){
                errDeleteDoc=true
            }
            
        }

        const newC = cloneDeep(localInfo.calculos)
        newC.splice(index,1)
        setLocalInfo( curr => ({...curr, calculos: newC}))
    }

    useEffect(() => {
        const plenoDom = localInfo.calculos ? localInfo.calculos.find(c => c.tipoDerecho?.id ==='pleno-dominio') : undefined
        const otros = localInfo.calculos ? localInfo.calculos.filter(c => c.tipoDerecho?.id !=='pleno-dominio') : undefined
        if(plenoDom){
            // Calculamos dispo del resto de derechos 
            // Si plenodominio tiene un 100% - Ya no pueden añadirse más derechos
            // Si pleno dominio e sun 70% - Pueden añadirse otros derechos con un máx de 30%
            setPercentPlenoDom(0)
            setPercentOtros(100-plenoDom.porcentaje)
        } else{
            // Calculamos dispo Pleno dominio en base al max % de los otros tipo
            // Si hay otros tipos con 100% ya no tiene cabida Pleno dominio
            // Si hay un max de un 60% en otros tipos - podmeos aplicar hasta un 40% en pleno dominio
            const maxPercent = otros 
                ? otros.reduce( (prev: number, c: TCalculo) => ( prev < c.porcentaje ? c.porcentaje: prev) , 0) 
                : 0;
            setPercentOtros(100)
            setPercentPlenoDom(100-maxPercent)
        }
    },[localInfo])

    useEffect(() => {
        if(initialValue){

            setLocalInfo({...initialValue})
            setLocalInfoErr({...EMPTY_INFO_ERR})
        }
    },[])

    return(
        <>
            <div>
                <FullLoading loading={loading}/>

                <Grid container direction="column">
                    <Grid item xs={12} sm={renderType === 'detail' ? 9: 12} md={renderType === 'detail' ? 9: 12} lg={renderType === 'detail' ? 9: 12}>
                        { localInfo.sujetoPasivo 
                            ? <InputText
                                    required 
                                    disabled
                                    fieldTerm='nifSujetoPasivo'
                                    value = {localInfo.nifSujetoPasivo === infoVend.nif ?  infoVend.nomApe + ' - '+ localInfo.nifSujetoPasivo : localInfo.nifSujetoPasivo }// item.nifVendedor}
                                    onChangeValue= {(v: TSelect, err: boolean) => {}}
                                    className={classes.input470}
                                />
                            :  <InputSelect
                                    required
                                    fieldTerm='nifSujetoPasivo'
                                    disabled={renderType==="detail"}
                                    value={compradoresList.find(c => c.id === localInfo.nifSujetoPasivo)}
                                    options={compradoresList}
                                    onChangeValue= {(v: TSelect|undefined, err: boolean) => {
                                        setLocalInfo(curr => ({...curr, nifSujetoPasivo: v ? v.id: undefined}))
                                        setLocalInfoErr(curr => ({...curr, nifSujetoPasivo: err}))

                                    }}
                                    className={classes.input470}
                                    error={localInfoErr.nifSujetoPasivo}
                                />
                        }
                        
                    </Grid>

                    {/** Lista de cálculo/s */}
                    { localInfo.calculos && localInfo.calculos.length>0 ?
                        <Grid item container direction="column" style={{marginBottom: 15}}>
                            {localInfo.calculos.map((c, idx) => (
                                <Grid item container direction="row" alignItems='center' className={[classes.row, classes.tableRow].join(' ')}>
                                    <Grid item xs={12} className={[classes.row, classes.headerDerecho].join(' ')}>
                                        <Typography className={classes.textDerecho}>{translate('Global','derechoTransmision', terms)}</Typography>
                                        <Typography className={classes.textDerecho} style={{paddingRight:10 , paddingLeft: 10}}>-</Typography>
                                        <Typography className={classes.textDerecho}>{c.tipoDerecho?.nombre}</Typography>
                                    </Grid>
                                    <Grid item xs={2} className={classes.row}>
                                        <Typography>{translate('DUTI','porcentajeTitularidad', terms)}:</Typography>
                                        <Typography className={classes.textBold}>{c.porcentaje} %</Typography>
                                    </Grid>
                                    <Grid item xs={2} className={classes.row}>
                                        <Typography>{translate('Plusvalias', c.tipoDerecho?.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria", terms)}:</Typography>
                                        <Typography className={classes.textBold}>{c.edadUsufructuaria ? `${c.edadUsufructuaria} ${translate('Plusvalias','anyos', terms).toLowerCase()} `: ''}</Typography>
                                    </Grid>
                                    
                                    <Grid item xs={2} className={classes.row}>
                                        <Typography>{translate('Plusvalias','numOperacionesShort', terms)}:</Typography>
                                        <Typography className={classes.textBold}>{c.numOperaciones}</Typography>
                                    </Grid>
                                    {tipoModelo === '081'?
                                        <>
                                        <Grid item xs={3} className={classes.row}>
                                        <Typography>{translate('DUTI','metodo_calculo_m081',terms)}:</Typography>   
                                            <Typography className={classes.textBold}>
                                                { c.modelo && c.modelo.totalIngresar 
                                                    ? translate('DUTI', c.modelo.importeMetodoReal && c.modelo.importeMetodoReal < c.modelo.importeMetodoObjetivo ? 'metodoReal':'metodoObjetivo', terms) 
                                                    : ''
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} className={classes.row}>
                                            <Typography>{translate('DUTI','totalIngresar', terms)}:</Typography>
                                            <Typography className={classes.textBold}>
                                                {c.modelo ? c.modelo.totalIngresar.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFractionDigits:0}) : ' - '} €
                                            </Typography></Grid>
                                        </>
                                        :null
                                    }
                                    <Grid item xs={1} style={{textAlign: 'right'}}>
                                        {renderType ==='form' 
                                            ? <IconButton color="inherit" 
                                                    onClick={() => handleDeleteCalc(idx)}>
                                                    <Icon path={mdiTrashCan} size={1} />
                                                </IconButton>
                                            : null 
                                        }
                                        {renderType ==='detail' && tipoModelo === '081' && c.modelo
                                            ? <IconButton color="inherit" 
                                                    onClick={() => { 
                                                        setInfoToShow(c); 
                                                        setShowDetail(true)
                                                    }}>
                                                    <Icon path={mdiEyeCircleOutline} size={1} />
                                                </IconButton>
                                            : null 
                                        }
                                    </Grid>
                                </Grid>
                            ))
                            }
                        </Grid>
                        : null
                    }
                    { renderType ==='form' && 
                    <Grid item container direction="row" justify="space-between" style={{paddingTop: 25}}>
                        <div style={{flexGrow: 1}}>
                            { !showAddCalculos ?
                                <Grid container direction="row" >
                                    <InputSelect
                                        key='tipoDerecho'
                                        componentIG='Global'
                                        fieldTerm='derechoTransmision'
                                        value={calc.tipoDerecho}
                                        options={OPTsDispo}
                                        disabled={OPTsDispo.length===0 }
                                        required
                                        onChangeValue={(v: TSelect|undefined, err: boolean) => {
                                            setCalc(curr => ({...curr, tipoDerecho: v, edadUsufructuaria: undefined,porcentaje: 0,numOperaciones: 0}))
                                            setCalcErr(curr => ({...curr, tipoDerecho: err, edadUsufructuaria:false, porcentaje:false, numOperaciones:false}))
                                        }}
                                        error={calcErr.tipoDerecho}
                                    />
                                    <TypedInputNumber
                                        key='percentaje'
                                        type="percentaje"
                                        componentIG="Plusvalias"
                                        fieldTerm={"porcentajeTitularidad"}
                                        value={calc.porcentaje}
                                        onChangeValue={(v,err) => {
                                            setCalc(curr => ({...curr, porcentaje: v}))
                                            setCalcErr(curr => ({...curr, porcentaje: err}))
                                        }}
                                        required
                                        disabled={OPTsDispo.length==0 || !calc.tipoDerecho || OPTsDispo.length===0 }
                                        allowZero={false}
                                        maxDecimal={2}
                                        max={
                                            calc.tipoDerecho 
                                            ? calc.tipoDerecho.id === 'pleno-dominio' ? percentPlenoDom : percentOtro 
                                            : undefined
                                        }
                                        error={calcErr.porcentaje}
                                        className={classes.input200}
                                    />
                                    {calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio' && 
                                        <InputNumber 
                                            key="edadUsufructuaria"
                                            fieldTerm={calc.tipoDerecho && calc.tipoDerecho.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria"}
                                            componentIG='Plusvalias'
                                            value={calc.edadUsufructuaria}
                                            required={(calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio')}
                                            allowZero={false}
                                            maxDecimal={0}
                                            min={1}
                                            onChangeValue={(v: number, err:boolean) =>{ 
                                                setCalc(curr => ({...curr, edadUsufructuaria: v}))
                                                setCalcErr(curr => ({...curr, edadUsufructuaria: err}))
                                            }}
                                            error={calcErr.edadUsufructuaria}
                                            disabled={ calc.tipoDerecho===undefined || (calc.tipoDerecho && calc.tipoDerecho.id === 'pleno-dominio') || OPTsDispo.length==0 }
                                            className={classes.input200}
                                        />
                                    }
                                    
                                    <InputNumber key="numOperaciones"
                                        fieldTerm="numOperacionesAdquisicion"
                                        value={calc.numOperaciones}
                                        required
                                        disabled={ OPTsDispo.length==0 || !calc.tipoDerecho }
                                        error={calcErr.numOperaciones}
                                        maxDecimal={0}
                                        min={1}
                                        onChangeValue={(v: number, err:boolean) =>{ 
                                            setCalc(curr => ({...curr, numOperaciones: v}))
                                            setCalcErr(curr => ({...curr, numOperaciones: err}))
                                        }}
                                        moreInfo={{template: 'plusvalias_info_field_numOperaciones', size: 'xs'}}
                                        className={classes.input285}
                                    />
                                    <div style={{ marginTop: 6.5 }}>
                                        <Button variant="contained" color='primary' 
                                            disabled={ OPTsDispo.length==0  || showAddCalculos}
                                            onClick={onAdd}
                                        >
                                            {translate('Global', 'btnAdd', terms)}
                                        </Button>
                                    </div>
                                </Grid>
                            : null}

                            { showAddCalculos ?  
                                <CalcPlusvaliaAddCalculo 
                                    calculo={calc} 
                                    setCalculo={ (v:TCalculo) => {
                                        onSaveCalc(v)
                                        setShowAddCalculos(false)
                                        //window.alert('TODO  CalcPlusvaliaAddCalculo setCalculo from calcPlusvForm + CLOSE MODAL ')
                                    }}
                                    onCancel={ () => {
                                        setShowAddCalculos(false)
                                    }}
                                    tipoModelo={tipoModelo}
                                    fechaTransmision={fechaTransmision}
                                    hideImporte={(valorDeclarado === undefined || valorDeclarado ===0)?true:false}
                                    idRef={idRef}
                                    apartado={apartado}
                                    infoVend={infoVend}
                                    nifSujPasivo={localInfo.nifSujetoPasivo}
                                    setSujetoInfoErr={setLocalInfoErr}
                                />
                            : null}
                        </div>
                        <div style={{ marginTop: 6.5, display: 'inline-flex', marginLeft: 10 }}>
                            <div>
                                <Button 
                                    disabled={showAddCalculos}
                                    variant="contained" color="inherit"
                                    onClick={onCancel}
                                >
                                    {translate('Global', 'Cancelar', terms)}
                                </Button>
                            </div>
                            <div>
                                <Button style={{marginLeft:10}}
                                    disabled={showAddCalculos || localInfo.calculos.length === 0}
                                    variant="contained" color="primary"
                                    onClick={onSave}
                                >
                                    {translate('Global', 'btnSave', terms)}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    }
                    
                    
                    {/* {showAddCalculos 
                        ?   <CalcPlusvaliaAddCalculo 
                                calculo={calc} 
                                setCalculo={ (v:TCalculo) => {
                                    onSaveCalc(v)
                                    setShowAddCalculos(false)
                                    //window.alert('TODO  CalcPlusvaliaAddCalculo setCalculo from calcPlusvForm + CLOSE MODAL ')
                                }}
                                onCancel={ () => {
                                    setShowAddCalculos(false)
                                }}
                                fechaTransmision={fechaTransmision}
                                idRef={idRef}
                                apartado={apartado}
                                infoVend={infoVend}
                                nifSujPasivo={localInfo.nifSujetoPasivo}
                            />
                        : null
                    } */}


                </Grid>

                
                
            </div>

            <Modal
                fullWidth
                maxWidth="md"
                open={showDetail}
                title={translate('DUTI','metodo_calculo_m081',terms).toUpperCase()}
                renderContent={() => (
                        <>
                            {infoToShow && infoToShow.modelo && 
                                <Grid container direction='column' >
                                    <Grid item container direction="row">
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('Plusvalias','cuotaIntegra',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>{infoToShow.modelo.cuotaIntegra} €</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('Plusvalias','bonificacion',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.bonificacion  ? `${infoToShow.modelo.bonificacion} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('Plusvalias','reduccion',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.reduccion  ? `${infoToShow.modelo.reduccion} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="row">
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','interesesDemora',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.interesesDemora  ? `${infoToShow.modelo.interesesDemora} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','recargo',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.recargo  ? `${infoToShow.modelo.recargo} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="row">
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','metodoObjetivo',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>{infoToShow.modelo.importeMetodoObjetivo} €</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','metodoReal',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.importeMetodoReal  ? `${infoToShow.modelo.importeMetodoReal} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('DUTI','totalIngresar',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>{infoToShow.modelo.totalIngresar} €</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            }
                        </>
                    )
                }
                renderActions={ () =>(
                        <Button variant='contained' color='primary'
                            onClick={() => { 
                                setShowDetail(false) 
                                setInfoToShow(undefined) 
                            }}>
                            {translate('Global','Cerrar', terms)}
                        </Button>
                    )
                }
            />



        </>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    input200: { 
        width: 200
    },
    input285: { 
        width: 285
    },
    
    input470: { 
        width: 470    
    },
    row: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    rowPlusvalDetail: {
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        // alignContent: 'flex-end',
    },
    plusvalDetail_title: {
        fontWeight:'bolder',
    },
    plusvalDetail_value: {
        marginLeft: 10,
    },
    divTextBorderPlusval: {
        display: 'flex',            /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',              /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',       /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,                  /* Alinea a la derecha */
        top: -20,                   /* Alinea al borde superior */
    },
    textBorderPlusval: {
        padding: 10,
        color: BLUE,
        backgroundColor: 'white'
    },
    headerDerecho:{
        borderBottom: '#3587a9 solid 2px ',
    },
    textDerecho: {
        color: '#3587a9',
        fontSize: 18,
        fontWeight: 500,
    },
    textBold: {
        paddingLeft:5,
        fontWeight: "bolder",
        marginRight: 15
    }
    // tableHeader: {
    //     backgroundColor:'#006993',
    //     color:'white', 
    //     paddingRight: 5,
    //     paddingLeft: 5,
    //     alignItems: 'center'
    // },
    // tableRow: {
    //     borderBottom: '#006993 solid 1px ',
    //     borderRight: '#006993 solid 1px',
    //     borderLeft: '#006993 solid 1px',
    //     paddingRight: 5,
    //     paddingLeft: 5
    // }
})


export default withLiterals(['DUTI','Plusvalias', 'Global'])(withStyles(styles)(CalcPlusvaliaForm))