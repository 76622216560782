import { Button, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { FC, useState } from 'react';
import styles from './styles';
import { IHorasDisabled } from 'gateways/citaPrevia.interface';
import { uniqueId } from 'lodash';

const useStyles = makeStyles(styles);
interface IGridComponent {
    data: IHorasDisabled[];
    horaCitaSeleccionada: string;
    handleHour: (value: string) => void;
}

const GridComponent: FC<IGridComponent> = (props) => {
    const {
        data,
        horaCitaSeleccionada,
        handleHour
    } = props;
    const classes = useStyles();
    const [selected, setSelected] = useState<number>();


    const handleClick = (e: any, i: number) => {
        handleHour(data[i].hour)
        setSelected(i)
    }

    return (
        <div className={classes.gridContainer}>
            {data && data.map((horas, i) => (
                <div className={classes.gridItem} key={uniqueId()}>
                    <Button
                        className={selected === i && horaCitaSeleccionada ? classes.selectedButton : ""}
                        key={`horasDisponibles${i}__citaPrevia`}
                        onClick={(e) => handleClick(e, i)}
                        disabled={horas.disabled}
                    >
                        {horas.hour === "noHour" ? "-" : moment(horas.hour, 'HH:mm:ss').format('HH:mm')}
                    </Button>
                </div>
            ))
            }
        </div >
    )
};

export default GridComponent;